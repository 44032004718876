import React, { useState } from 'react';
import Knit from '../Fabric/knit';
import Woven from '../Fabric/woven';
import { MetaHeader } from '../../../SideBar/Header/Header'
import { Profile } from '../../../SideBar/Profile/profile';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { fabricType } from './../../../../models/enum';
import { useHistory } from 'react-router-dom';


const Fabric = (props) => {
    const history = useHistory();

    const [activeTab, setActiveTab] = useState('1');
    const [headerRoute, setHeaderRoute] = useState('/fabric?fabricType');

    const toggle = (tab, fabricType) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            // history.push(`/fabric?fabricType=${fabricType}`);
        }
    }

    return (
        <>
            <MetaHeader routeesss={setHeaderRoute} />
            <Row className="m-0">
                <Col sm="2" className="sidenav p-0">
                    <Profile activateRoute={headerRoute} />
                </Col>
                <Col sm="10" className="rightcontent">
                    <Nav tabs className="admin-tab greybox">
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggle('1') }}
                            >
                                Woven
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}>
                                Knit
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">

                            <Woven />
                        </TabPane>
                        <TabPane tabId="2">

                            <Knit />

                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </>
    );
}


export default Fabric;