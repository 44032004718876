import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  FormText,
  Card,
} from "reactstrap";
import "./CompanyProfile.css";

import "../../SignUp/SignUp.css";
import edit from "../../../assets/edit.svg";
import { ProfileReview, submitCompanyProfileApi } from "../../../services";

const CompanyPrFinal = (props) => {
  let companyId = localStorage.getItem("companyId");
  const [items, setItems] = useState({});

  const GetProfile = () => {
    ProfileReview(companyId).then((res) => {
      if (res.isSuccess) {
        setItems(res.data);
      }
    });
  };

  useEffect(() => {
    // window.location.reload()
    GetProfile();
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    props.fromBack(1);
  };

  const handleForward = () => {
    submitCompanyProfileApi().then((res) => {
      if (res.isSuccess) {
        props.fromAction(1);
      }
    });
  };

  const summaryBack = () => {
    props.summaryBack(1);
  };
  const businessBack = () => {
    props.businessBack(1);
  };

  const LocationsBack = () => {
    props.LocationsBack(1);
  };
  const GalleryBack = () => {
    props.GalleryBack(1);
  };

  return (
    <>
      {
        <div className="MainCopany">
          {/* <h4 class="heading-Coprofile">Profile Setup Review</h4> */}
          <div className="CompanyPrfinaldiv">
            <div className="Imagediv">
              <img
                className="CompFinaledi"
                onClick={summaryBack}
                src={edit}
              ></img>
              <p>{items.companyName}</p>
              <span>{items.companyWebsite ? items.companyWebsite : ""}</span>
              <div>
                {items.profileImageUrl ? (
                  <img
                    className="ComapnyImge"
                    src={items.profileImageUrl}
                  ></img>
                ) : (
                  <span></span>
                )}
              </div>
            </div>

            <div className="whatwedo-div">
              <p>What do we do?</p>
              <span>{items.companyBriefDescription}</span>
            </div>

            <div className="whatwedo-div">
              <p>Year Founded</p>
              <span>
                {!items.foundationYear == 0 ? items.foundationYear : " "}
              </span>
            </div>

            <div className="whatwedo-div">
              <p>Number of Employees</p>
              <span>
                {!items.numberOfEmployees == 0
                  ? items.numberOfEmployees
                  : " "}
              </span>
            </div>

            <div className="whatwedo-div">
              <p>Number of Locations</p>
              <span>
                {!items.numberOfLocations == 0
                  ? items.numberOfLocations
                  : " "}
              </span>
            </div>

            <div className="whatwedo-div">
              <p>Certifications/Compliances</p>
              {/* {items?.certificates?.length > 1 ? (
                items?.certificates?.map((item) => (
                  <p className="certificates-2 mt-0 mb-8">{item}</p>
                ))
              ) : (
                <span> </span>
              )} */}
              {/* {items?.certificates?.map((item) => (
                                <p>{item}</p>
                            ))} */}
                            <div className="row">
                {items?.companyCertificates?.length > 0 ? (
                  items?.companyCertificates?.map((item) =>
                    item.certificateImageUrl ? (
                      item.certificateImageUrl.includes(".jpeg") ||
                      item.certificateImageUrl.includes(".png") ||
                      item.certificateImageUrl.includes(".jpg") ? (
                        <div className="col-md-4 col-xs-12 pl-1">
                          <img
                            className="add-client-images1"
                            src={item.certificateImageUrl}
                          />
                        </div>
                      ) : item.certificateImageText ? (
                        <div className="col-md-4 col-xs-12 pl-1">
                          {item.certificateImageText.length > 10 ? (
                            <p
                              title={item.certificateImageText}
                              className="companyLogodiv1"
                            >
                              {item.certificateImageText.substring(0, 10)}...
                            </p>
                          ) : (
                            <p
                              title={item.certificateImageText}
                              className="companyLogodiv1"
                            >
                              {item.certificateImageText}
                            </p>
                          )}
                        </div>
                      ) : null
                    ) : item.certificateImageText ? (
                      <div className="col-md-4 col-xs-12 pl-1">
                        {item.certificateImageText.length > 10 ? (
                          <p
                            title={item.certificateImageText}
                            className="companyLogodiv1"
                          >
                            {item.certificateImageText.substring(0, 10)}...
                          </p>
                        ) : (
                          <p
                            title={item.certificateImageText}
                            className="companyLogodiv1"
                          >
                            {item.certificateImageText}
                          </p>
                        )}
                      </div>
                    ) : null
                  )
                ) : (
                  <span> </span>
                )}
              </div>
            </div>

            <div className="Imagediv mt-18">
              <img
                className="CompFinaledi"
                onClick={businessBack}
                src={edit}
              ></img>

              <p>Business Description</p>
            </div>
            <div className="whatwedo-div">
              <p>History</p>
              <span>{!items.history == "" ? items.history : " "}</span>
            </div>

            <div className="whatwedo-div">
              <p>Capabilities</p>
              <span>
                {!items.capabilities == "" ? items.capabilities : " "}
              </span>
            </div>
            <div className="whatwedo-div">
              <p>Products</p>
              <span>{!items.products == "" ? items.products : " "}</span>
            </div>

            <div className="whatwedo-div clint-dv">
              <p>Clients</p>
              <div className="row">
                {items?.clients?.length > 0 ? (
                  items?.clients?.map((item) =>
                    item.clientImageUrl ? (
                      item.clientImageUrl.includes(".jpeg") ||
                      item.clientImageUrl.includes(".png") ||
                      item.clientImageUrl.includes(".jpg") ? (
                        <div className="col-md-4 col-xs-12 pl-1">
                          <img
                            className="add-client-images1"
                            src={item.clientImageUrl}
                          />
                        </div>
                      ) : item.clientImageText ? (
                        <div className="col-md-4 col-xs-12 pl-1">
                          {item.clientImageText.length > 10 ? (
                            <p
                              title={item.clientImageText}
                              className="companyLogodiv1"
                            >
                              {item.clientImageText}
                            </p>
                          ) : (
                            <p
                              title={item.clientImageText}
                              className="companyLogodiv1"
                            >
                              {item.clientImageText}
                            </p>
                          )}
                        </div>
                      ) : null
                    ) : item.clientImageText ? (
                      <div className="col-md-4 col-xs-12 pl-1">
                        {item.clientImageText.length > 10 ? (
                          <p
                            title={item.clientImageText}
                            className="companyLogodiv1"
                          >
                            {item.clientImageText}
                          </p>
                        ) : (
                          <p
                            title={item.clientImageText}
                            className="companyLogodiv1"
                          >
                            {item.clientImageText}
                          </p>
                        )}
                      </div>
                    ) : null
                  )
                ) : (
                  <span> </span>
                )}
              </div>
            </div>

            <div className="whatwedo-div1">
              <img
                className="CompFinaledi"
                onClick={LocationsBack}
                src={edit}
              ></img>
              <p className="loc1">Locations</p>
              <p>
                {items?.addresses?.map((item, index) => (
                  <>
                    <div className="d-flex add-text">
                      Address {index + 1}
                      {item.locationType == "CORPORATE_OFFICE" ? (
                        <p className="lowercases">(Corporate Office)</p>
                      ) : (
                        <p className="lowercases mb-0">
                          (
                          {`${item.locationType
                            .charAt(0)
                            .toUpperCase()}${item.locationType
                            .split("_")
                            .join(" ")
                            .substring(1)
                            .toLowerCase()}`}
                          )
                        </p>
                      )}
                    </div>
                    <p className="lowercase">
                      {" "}
                      {item.streetAddress},{item.city}, {item.state}, {item.postalCode}
                    </p>
                  </>
                ))}
              </p>
            </div>

            <div className="whatwedo-div">
              <img
                className="CompFinaledi"
                onClick={GalleryBack}
                src={edit}
              ></img>
              <p className="loc1 mb-16">Picture Gallery</p>
              <Row className="companyLogodiv gall-ry">
                {items?.galleries?.length > 0 ? (
                  items?.galleries?.map((item) => (
                    <img
                      className="gallery-section"
                      src={item.galleryImageUrl}
                    />
                  ))
                ) : (
                  <span className="ml-1"> </span>
                )}
              </Row>
            </div>

            <div className="Buis-Button-Company">
            <Button
                className="BusinessBack-btn"
                outline
                color="primary"
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                className="BusinessNext-btn"
                color="primary"
                type="submit"
                onClick={handleForward}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default CompanyPrFinal;
