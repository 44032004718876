import React from "react";
import "./Fabric/Fabric.css";
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Table,
  Card,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import DeletPopUp from "../MetadataUpdates/deletePopup";
import { Profile } from "../../SideBar/Profile/profile";
import { MetaHeader } from "../../SideBar/Header/Header";
import { AddYarnApi, ViewYarnApi, deleteYarnApi, deleteSustainabilityApi, addSustainability } from "../../../services";
import { Errors, Success, Warning } from "../../Alert";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import { withRouter } from "react-router";
import uuid from "react-uuid";
import Cookies from "js-cookie";
import Blends from "./Blends";
import { getUserInfoAction } from '../../../actions/user.actions'

class Yarn extends React.Component {
  
  constructor() {
    super();
    this.state = {
      SNO: 1,
      storage: "",
      items: [],
      activeTab: 1,
      // submitted: false,
      headerRoute: "/metadata",
      errors: false,
    };

  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.storage == "" ||
      this.state.storage.replace(/\s+/g, " ") == " "
    ) {
      this.state.errors = true;
    }
    // this.setState({ submitted: true })

    let items = [...this.state.items];

    if (this.state.name) {
      items.push({ SNO: this.state.SNO, storage: this.state.storage });
    }
    this.setState({
      ...this.state,

      items,
      SNO: this.state.SNO + 1,
      storage: "",
    });
    const body = {
      id: 0,
      name: this.state.storage,
    };
    if (!this.state.errors) {
      AddYarnApi(body).then((data) => {
        if (data.isSuccess) {
          // toast.success(data.message);
          this.getYarnData();
        }
      });
    }
  };

  componentDidMount = () => {
    let token = localStorage.getItem("token");
    let user = localStorage.getItem("role");
    if (token && Cookies.get("token")) {
      if (user == "FABTRACK_ADMIN") {
        this.props.history.push(`/metadata`);
      } else {
        window.history.back();
      }
    } else {
      this.props.history.push(`/`);
    }
    this.getYarnData();
    let userId=localStorage.getItem('id');
    getUserInfoAction(userId);
  };

  getYarnData() {
    ViewYarnApi().then((res) => {
      if (res.isSuccess && res.data && res.data.length) {
        let items = res.data.map(item => {
          item.addSustain = false;
          return item;

        })
        console.log(items)
        this.setState({ items: [] });
        this.setState({ items: items });
      } else {
        this.setState({ items: res.data });
      }
    });
  }
  toggle = (idx) => {
    this.setState({
      ...this.state,
      activeTab: idx
    })
    if (idx == 1) {
      this.getYarnData();
    }
  }
  handleItemDelete(i) {
    var items = this.state.items;
    items.splice(i, 1);
    this.setState({
      items: items,
    });
  }
  handleInputChange = (e) => {
    let input = e.target;
    let name = e.target.name;
    let value = input.value;
    if (value.length == 0) {
      this.state.errors = true;
    }
    if (value.length > 0) {
      this.state.errors = false;
    }

    this.setState({
      [name]: value,
    });
  };
  //delete api

  handleClickDelete = (id, type, close) => {
    //
    if (type != 'sustainability') {
      deleteYarnApi(id)
        .then((data) => {
          if (data.isSuccess) {
            // Warning(data.message);
            this.getYarnData();
            //this.handleItemDelete();
            close();
          }
        })
        .catch((err) => {
          Errors(err.message);
        });
    }
    else {
      deleteSustainabilityApi(id).then((data) => {
        if (data.isSuccess) {
          // Warning(data.message);
          this.getYarnData();
          //this.handleItemDelete();
          close();
        }
      })

    }
  };
  render() {
    return (
      <>
        <MetaHeader />
        <Row className="m-0">
          <Col sm="2" className="sidenav p-0">
            <Profile activateRoute={this.state.headerRoute} />
          </Col>
          <Col sm="10" className="rightcontent">
            <Nav tabs className="admin-tab greybox">
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === 1 })}
                  onClick={() => {
                    this.toggle(1);
                  }}>
                  Individual
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === 2 })}
                  onClick={() => {
                    this.toggle(2);
                  }}>
                  Blends
                </NavLink>
              </NavItem>
            </Nav>
            {this.state.activeTab == 1 &&
              <div className="Knit">
                <Form
                  handleFormSubmit={this.handleFormSubmit}
                  handleClickDelete={this.handleClickDelete}
                  handleInputChange={this.handleInputChange}
                  newSNO={this.state.SNO}
                  newStorage={this.state.storage}
                  // submitted={this.state.submitted}
                  errors={this.state.errors}
                // newDelete={<DeletPopUp/> }
                />
                <Table1
                  items={this.state.items}
                  delete={this.handleClickDelete}

                />
              </div>
            }
            {this.state.activeTab == 2 &&
              <Blends />
            }
          </Col>
        </Row>
      </>
    );
  }
}

class Table1 extends React.Component {
  constructor() {
    super();

    this.state = {
      addsustain: false,
      susName: '',
      susId: 1,
      items: []
    };
  }
  handleInputChange = (e) => {
    let input = e.target;
    let name = e.target.name;
    let value = input.value;
    if (value.length == 0) {
      this.state.errors = true;
    }
    if (value.length > 0) {
      this.state.errors = false;
    }

    this.setState({
      [name]: value,
    });
    console.log(this.state)
  };

  componentDidUpdate = () => {
    const { items } = this.props
    if (this.state.items.length != this.props.items.length) {
      this.setState({
        items: items
      })
      console.log(items, this.state.items)
    }
  }

  handlenewsustain = (item) => {
    this.setState({
      ...this.State,
      susName: '',
      yarnName: item.name,
      items: this.state.items.map(i => {
        if (i.id == item.id) {
          i.addSustain = true;
        } else {
          i.addSustain = false;
        }
        return i;
      })
    })

  }
  getYarnData() {
    ViewYarnApi().then((res) => {
      if (res.isSuccess && res.data && res.data.length) {
        let items = res.data.map(item => {
          item.addSustain = false;
          return item;

        })
        console.log(items)
        this.setState({ items: items });
      } else {
        this.setState({ items: res.data });
      }
    });
  }
  addNewSustainability = () => {
    let data = {
      yarnName: this.state.yarnName,
      name: this.state.susName
    }
    addSustainability(data).then(res => {
      if (res.isSuccess) {
        this.getYarnData()
      }
    })
  }
  closeNewSustainability = (item) => {
    this.setState({
      ...this.State,
      susName: '',
      yarnName: item.name,
      items: this.state.items.map(i => {
        if (i.id == item.id) {
          item.addSustain = false;
        }
        return i;
      })
    })
  }
  render() {

    const items = this.state.items;

    return (
      <div id="Table">
        <Table>
          <tbody>
            <div className="tabless">
              <div className="Scroll yarn-table">
                <tr>
                  <th className="sno">SI.No</th>
                  <th className="storage">Yarn Type</th>
                </tr>

                {items.map((item, id) => {
                  return (
                    <>
                      <tr key={uuid()}>
                        <td>{id + 1}</td>
                        <td>
                          <div className="d-flex justify-content-between content-yarn">
                            <div> </div>
                            <div>{item.name}</div>
                            <div>
                              <DeletPopUp
                                handleClickDelete={this.props.delete}
                                id={item.id}
                              />
                            </div>
                          </div>

                        </td>
                        {item.sustainabilityTypes.length == 0 && !item.addSustain &&
                          <td className="p-0"> <button
                            onClick={() => this.handlenewsustain(item)}
                            className="sustain-btn btn btn-primary">
                            Add Sustainability
                          </button></td>
                        }
                      </tr>
                      {
                        item.sustainabilityTypes.length > 0 ?
                          item.sustainabilityTypes.map((sus, index) => {
                            return (<tr>
                              <td className="no-background"></td>
                              <td className="sus-table-sr">
                                <div className="sus-serial-no">{id + 1 + '.' + (index + 1)}</div>
                              </td>
                              <td className="sus-name-td">
                                <div className="d-flex justify-content-between align-items-center w-100 gray-back">
                                  {/* <div className="sus-serial-no">{id+1 + '.' + (index+1)}</div> */}
                                  <div></div>
                                  <div>{sus.name}</div>
                                  <div>
                                    <DeletPopUp
                                      handleClickDelete={this.props.delete}
                                      id={sus.id}
                                      type='sustainability'
                                    />
                                  </div>
                                </div>

                              </td>
                              {index == item.sustainabilityTypes.length - 1 && !item.addsustain ?
                                <td>
                                  <a onClick={() => this.handlenewsustain(item)}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <circle cx="8" cy="8" r="7.5" stroke="#6F6F6F" />
                                      <path d="M12.6673 8.66634H8.66732V12.6663H7.33398V8.66634H3.33398V7.33301H7.33398V3.33301H8.66732V7.33301H12.6673V8.66634Z" fill="black" fill-opacity="0.54" />
                                    </svg>

                                  </a>
                                </td>
                                : ''}
                            </tr>
                            )
                          })

                          : ''}
                      {item.addSustain &&
                        <>
                          <tr>
                            <td className="no-background"></td>
                            <td className="sus-table-sr">
                              <div className="sus-serial-no">{id + 1 + '.' + (item.sustainabilityTypes.length + 1)}</div>
                            </td>
                            <td className="sus-input-td">
                              <input
                                name="susName"
                                placeholder="Type sustainability type "
                                onChange={this.handleInputChange}
                                className=" sus-input-box"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="no-background"></td>
                            <td className="no-background justify-content-end">
                              <button className="btn btn-outline-primary" onClick={() => this.closeNewSustainability(item)}>Cancel</button>
                              <button className="btn btn-primary" onClick={this.addNewSustainability} >Submit</button>
                            </td>
                          </tr>
                        </>
                      }

                    </>
                  )
                })
                }

              </div>
            </div>

          </tbody>
        </Table>
      </div>
    );
  }
}

class Form extends React.Component {
  render() {
    // const submit = this.props.submitted
    const error = this.props.errors;

    return (
      <>
        <form onSubmit={this.props.handleFormSubmit} className="MetaForm">
          <p className="heading-fabric">Yarn Type</p>
          <div id="Add-Fields">
            <label for="storage">
              <input
                id="storage"
                value={this.props.newStorage}
                type="storage"
                name="storage"
                placeholder="Type name of the Yarn"
                onChange={this.props.handleInputChange}
                maxLength="50"
              />

              {error == true ? (
                <small className="form-text text-danger">
                  mandatory field must be filled to proceed
                </small>
              ) : null}
            </label>
            <button type="submit" value="Submit">
              Add
            </button>
          </div>
          <NotificationContainer />
        </form>
      </>
    );
  }
}

export default withRouter(Yarn);
