import axios from 'axios'
import { authHeader } from '../helpers/auth.header'
import { apiService } from './api.service'
import { apiRoot } from './api.service'
export const SignupApi = body => {
  //
  return apiService.postRequest(`user/signUp`, body).then(data => data.data)
}

export const ForgotPwApi = (data) => {
  return apiService.postRequest(`user/forgotPassword?email=${data}`).then(data => data.data);
};
export const ResetPwApi = (body) => {
  return apiService.postRequest(`user/reset-password`, body).then(data => data.data);
};

export const UpdatePwApi = (body) => {
  return apiService.postRequest(`user/update-password`, body).then(data => data.data);
};

export const GetTokenApi = (token) => {
  return apiService.getRequest(`user/verify-token?token=${token}`)
    .then((data) => data.data);
}

//company profile api
export const SummaryApi = body => {
  //
  return apiService.postRequest(`company/summary`, body).then(data => data.data)
}

export const GetSummaryApi = id => {
  return apiService
    .getRequest(`company/summary?id=${id}`)
    .then(data => data.data)
}

export const DeleteCompanyImageApi = (id) => {

  return apiService.deleteRequest(`company/profile-image?companyId=${id}`)
    .then((data) => data.data)
}

export const FileUploadApi = (body) => {
  const baseHeaders = {
    headers: {
      "content-type": "multipart/form-data",
       //...authHeader()
    }
  }
  return axios
    .post(
      `${apiRoot}/file/upload`,
      // `http://4ba5-2405-201-5803-f0b3-a11f-d2b6-66e3-ed36.ngrok.io/fabtrack/api/v1/file/upload`,
      body,
      baseHeaders
    )
    .then(data => data.data)
}

export const GalleryApi = body => {
  const baseHeaders = {
    headers: {
      // "content-type": "multipart/form-data",
      ...authHeader(),
    },
  }

  return axios
    .post(
      `${apiRoot}/company/gallery`,
      body,
      baseHeaders
    )
    .then(data => data.data)
}

export const GetGalleryApi = id => {
  return apiService.getRequest(`company/gallery`).then(data => data.data)
}

export const DeleteGalleryApi = (data) => {

  return apiService.deleteRequest(`company/gallery?fileName=${data.fileName}&id=${data.id}`)
    .then((data) => data.data)
}
export const DeleteImageApi = (data) => {

  return apiService.deleteRequest(`file?fileName=${data}`)
    .then((data) => data.data)
}
export const BriefDescApi = (body) => {
  //  
  return apiService.postRequest(`company/business-description`, body)
    .then((data) => data.data);

}
export const GetBriefDescriptionApi = id => {
  return apiService
    .getRequest(`company/business-description`)
    .then(data => data.data)
}

export const DeleteClientApi = (data) => {

  return apiService.deleteRequest(`company/client?companyId=${data.companyId}&clientId=${data.clientId}`)
    .then((data) => data.data)
}
export const DeleteCertificateApi = (data) => {

  return apiService.deleteRequest(`company/certificate?companyId=${data.companyId}&certificateId=${data.clientId}`)
    .then((data) => data.data)
}

export const LocationsApi = (body) => {
  //  
  return apiService.postRequest(`company/addresses`, body)
    .then((data) => data.data);

}

export const GetAddressApi = id => {
  return apiService
    .getRequest(`company/addresses?id=${id}`)
    .then(data => data.data)
}

export const DeleteLocationApi = (data) => {

  return apiService.deleteRequest(`company/addresses?companyId=${data.companyId}&addressId=${data.addressId}`)
    .then((data) => data.data)
}

export const ProfileReview = (id) => {


  return apiService.getRequest(`company/profile?id=${id}`)
    .then((data) => data.data);

}

//user request api

export const NewUserApi = (pageno, status) => {
  return apiService
    .getRequest(`user/new-rejected?pageNo=${pageno}&userStatus=${status}&size=100`)
    .then(data => data.data)
}

export const NewUserAcceptRejectApi = body => {
  return apiService
    .postRequest(`user/accept-reject`, body)
    .then(data => data.data)
}

//buyer api
export const BuyersApi = (pageno, status, type) => {
  return apiService
    .getRequest(
      `user/buyer-seller?pageNo=${pageno}&userStatus=${status}&userType=${type}&size=100`
    )
    .then(data => data.data)
}

export const BuyersSellersActionApi = body => {
  return apiService
    .postRequest(`user/buyer-seller/action`, body)
    .then(data => data.data)
}

// export const UserDetailsApi = (id) => {

//     return apiService.getRequest(`user/details?id=${id}`)
//         .then((data) => data.data);

// }

export const SignIn = body => {
 
  return apiService.postRequest(`user/signIn`, body).then(data => data.data)
}

export const getUserInfo = id => {
  return apiService.getRequest(`user/details?id=${id}`).then(data => data.data)
}

export const updateUserInfo = body => {
  return apiService.putRequest(`user/details`, body).then(data => data.data)
}
export const getCompanyInfo = id => {
  return apiService
    .getRequest(`company/details?id=${id}`)
    .then(data => data.data)
}
export const resetVerifyToken = (data) => {
  return apiService.getRequest(`user/reset-password?email=${data.email}&token=${data.token}`).then((resp => resp.data))
}