import React from 'react';
import '../Fabric/Fabric.css';
import { Table } from 'reactstrap';
import DeletPopUp from '../deletePopup';
import { AddFabricApi, deleteFabricApi, ViewFabricApi } from '../../../../services';
import { fabricType } from '../../../../models/enum';
import { Success, Warning } from '../../../Alert';
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { toast } from 'react-toastify';
import uuid from 'react-uuid';



class Knit extends React.Component {
  constructor() {
    super();

    this.state = {
      SNO: 1,
      storage: '',
      items: [],
      fabricType: fabricType,
      errors: false,
      id: ''

    }
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.state.storage == "" || this.state.storage.replace(/\s+/g, ' ') == " ") {
      this.state.errors = true
    }


    let items = [...this.state.items];

    if (this.state.stageName) {
      items.push({ SNO: this.state.SNO, storage: this.state.storage });
    }

    this.setState({
      ...this.state,
      items,
      SNO: this.state.SNO + 1,
      storage: '',
      fabricType: fabricType,
      // data:data

    });

    const body = {
      "id": null,
      "stageName": this.state.storage,
      "fabricType": fabricType.KNIT
    }
    if (!this.state.errors) {
      AddFabricApi(body).then((res) => {
        if (res.isSuccess && res.data) {
          // toast.success(res.data.message);

          this.getYarnData();
          this.setState({ id: res.data.id })
        }
      })
    }
  };

  componentDidMount = () => {
    this.getYarnData();
  }

  getSerialNumber(i) {
    return i + ((this.page - 1) * this.pageSize);
  }

  getYarnData() {
    ViewFabricApi(fabricType.KNIT).then((res) => {
      if (res.isSuccess && res.data) {
        this.setState({ items: res.data.fabricStages })
        this.setState({ id: res.data.id })
      }
      //  else{
      //    this.setState({items:res.data.fabricStages})
      //  }
    })
  }

  handleItemDelete(i) {
    var items = this.state.items;
    items.splice(i, 1);
    this.setState({
      items: items
    });
  }

  handleInputChange = (e) => {
    let input = e.target;
    let name = e.target.name;
    let value = input.value;
    if (value.length == 0) {
      this.state.errors = true

    }
    if (value.length > 0) {
      this.state.errors = false

    }
    this.setState({
      [name]: value
    })
  };

  handleClickDelete = (id, close) => {
    deleteFabricApi(this.state.id, id).then((data) => {
      if (data.isSuccess) {
        Warning(data.message)
        this.getYarnData();
        this.handleItemDelete();
        close();
      }
    })
  };


  render() {
    return (
      <div className="Knit3">
        <Form
          handleFormSubmit={this.handleFormSubmit}
          handleClickDelete={this.handleClickDelete}
          handleInputChange={this.handleInputChange}
          newSNO={this.state.SNO}
          newStorage={this.state.storage}
          errors={this.state.errors}

        // newDelete={<DeletPopUp />}
        />
        <Table1 items={this.state.items} delete={this.handleClickDelete} />
      </div>

    );
  }
}

class Table1 extends React.Component {
  render() {
    const items = this.props.items;
    return (
      <div id="Table">

        <Table className="yarn-table">
          <tbody>
            <tr>
              <th className="sno">SI.No
              </th>
              <th className="storage">Fabric Stage</th>
            </tr>
            {items.length > 0 ? items.map((item, id) => {
              return (
                <tr key={uuid()}>
                  <td>{id + 1}</td>
                  <td>
                  <div className="d-flex justify-content-between w-100">
                    <div></div>
                      <div>{item.stageName}</div>
                      <div> <DeletPopUp handleClickDelete={this.props.delete} id={item.id} /></div>
                    </div>
                    </td>
                </tr>
              );
            }) : <h5>No data found</h5>}
          </tbody>
        </Table>
      </div>
    );
  }
}

class Form extends React.Component {
  render() {
    const error = this.props.errors

    return (
      <>
        <form onSubmit={this.props.handleFormSubmit} className="MetaForm">
          <p className="heading-fabric">Fabric Stage</p>

          <div id="Add-Fields">
            <label for="storage">
              <input
                id="storage"
                placeholder="Enter fabric stage"
                value={this.props.newStorage}
                type="storage"
                name="storage"
                onChange={this.props.handleInputChange}
              />
              {error == true ?
                <small className="form-text text-danger">
                  mandatory field must be filled to proceed
                </small>
                : null}
            </label>
            <button type="submit" value="Submit">Add</button>
          </div>
          {/* <NotificationContainer /> */}

        </form>
      </>
    );
  }
}

export default Knit;
