import React, { useState, useEffect } from "react";
import { Card, ListGroup, ListGroupItem, NavLink } from "react-bootstrap";
import ProfilePic from "../../../assets/profile.png";
import edit from "../../../assets/edit.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./profile.css";
import { MetaDataSide } from "../Header/TopSideNav";
import {
  PROFILE,
  BuyerOpenEnq,
  PROFILE_SELLER_PP,
  PROFILE_BUYER,
} from "./constant";
import { PROFILE_LINK, PROFILE_SELLER } from "./constant";
import { useSelector, useDispatch } from "react-redux";
import { ProfileOverlay } from "./overlay";
import { Nav } from "reactstrap";
import {redirectRoot} from "../../../services/api.service"; 
import { getUserInfoAction } from "../../../actions";

export const Profile = (props) => {
  /**for overlay* */
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const handleClick = () => {
    if (modalShow) {
      setModalShow(false);
    } else {
      setModalShow(true);
    }
  };

  const handleImageUrl = (val) => {
    image.preview = val;
    if (modalShow) {
      setModalShow(false);
    } else {
      setModalShow(true);
    }
  };
  const user = useSelector((state) => state.userReducer);
  // useEffect(() => {
  //   console.log("critical1", user);
  //   const id = localStorage.getItem("id");
  //   getUserInfo(id).then((res) => {  
  //     if (res.isSuccess) {
  //       console.log("critical2", res);
  //       setuser(res.data);
  //       // setImage({
  //       //   ...image, 
  //       //   preview: user?.data?.profileImage ? user.data.profileImage : ""
  //       // })
  //     }
  //   })
  // }, []);
  const { pathname } = useLocation();
  const [headerRoute, setHeaderRoute] = useState("");

  const [image, setImage] = useState({
    preview: user?.data?.profileImage ? user.data.profileImage : "",
    raw: "",
    invalidFile: false,
  });
  const handleChange = (e) => {
    const files = e.target.files;
    if (files !== null && files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        invalidFile: true,
      });
    }
  };
useEffect(()=>{

  if (props.activateRoute === "/buyer") {
    setHeaderRoute("/buyer");
  } else if (props.activateRoute === "/seller") {
    setHeaderRoute("/seller");
  } else if (props.activateRoute === "/newrequest") {
    setHeaderRoute("/newrequest");
  } else if (props.activateRoute === "/fabric?fabricType") {
    setHeaderRoute("/fabric?fabricType");
  } else if (props.activateRoute === "/metadata") {
    setHeaderRoute("/metadata");
  } else if (props.activateRoute === "/queries") {
    setHeaderRoute("/fab-admin/queries/usercomplaints");
  } else {
    setHeaderRoute(null);
  }
  console.log(user)
    })
    const history = useHistory() 

    useEffect(() => {
       return history.listen((location) => { 
        if(location.pathname.includes('meta')){
          let userId=localStorage.getItem('id');
   dispatch(getUserInfoAction(userId)).then(user=>{
    
   });
        }
       }) 
    },[history]) 


  return (
    <>
      <div className="Sidebar-Admin">
        <Card>
          <button onClick={handleClick} className="Profile-overlay">
            {modalShow && (
              <ProfileOverlay
                type="account"
                show={modalShow}
                handleClick={handleClick}
                handleImageUrl={handleImageUrl}
              />
            )}
            ...
          </button>
          <div className="image-border">
            <img
              src={image.preview ? image.preview : ProfilePic}
              className="profile"
            />
            <label for="editProfile">
              {/* <img src={edit} className="image-icon" /> */}
            </label>
            <input
              type="file"
              onChange={handleChange}
              accept="image/x-png,image/jpeg,image/svg+xml"
              className="sr sr-only"
              id="editProfile"
              name="photo"
            />
          </div>
          <Card.Body className="Card-Sidebar">
            <Card.Title>
              {user.data.firstName + " " + user.data.lastName
                ? user.data.firstName + " " + user.data.lastName
                : ""}
            </Card.Title>
            <Card.Text className="Profile-card-text">
              <p className="mb-0"> {getdesig(user.data.accountType)}</p>
              <p className="buyer-head">{user.data.companyName}</p>
              <hr className="profile-hr" />
            </Card.Text>
          </Card.Body>
          <ListGroup className="list-group-flush">
            {/* {PROFILE.map((o, i) => {
              return i === 0 ? (
                <ListGroupItem
                  className={
                    headerRoute === "/buyer"
                      ? "active"
                      : null || headerRoute === "/seller"
                      ? "active"
                      : null || headerRoute === "/newrequest"
                      ? "active"
                      : null
                  }
                >
                  <Link to={o.to}>
                    {o.displayName}
                  </Link>
                </ListGroupItem>
              ) : i === 1 ? (
                <ListGroupItem
                  className={
                    headerRoute === "/metadata"
                      ? "active"
                      : null || headerRoute === "/fabric?fabricType"
                      ? "active"
                      : null
                      ? "active"
                      : null
                  }
                >
                  <Link to={o.to}>{o.displayName}</Link>
                </ListGroupItem>
              ) : null;
            })} */}
            <ListGroupItem
            className={
              headerRoute === "/buyer"
                ? "active"
                : null || headerRoute === "/seller"
                ? "active"
                : null || headerRoute === "/newrequest"
                ? "active"
                : null
            }>
              <Link to="/newrequest">Users</Link>
            </ListGroupItem>
            {/* <ListGroupItem>
              <a href="${redirectRoot}/fab-admin/message">Messages</a>
            </ListGroupItem> */}

            <ListGroupItem>
              <a href={`${redirectRoot}/fab-admin/queries/usercomplaints`} >
                Queries
              </a>
            </ListGroupItem>
            <ListGroupItem
             className={
              headerRoute === "/metadata"
                ? "active"
                : null || headerRoute === "/fabric?fabricType"
                ? "active"
                : null
                ? "active"
                : null
            }
            >
              <Link to="/metadata"
               
              >
                Metadata Updates
              </Link>
            </ListGroupItem>
            <ListGroupItem>
              <a href={``}>
                Super Admin Users
              </a>
            </ListGroupItem>
            <ListGroupItem>
              <a href={`${redirectRoot}/fab-admin/queries/usercomplaints`}>
                Logistics
              </a>
            </ListGroupItem>
            <ListGroupItem>
              <a href={`${redirectRoot}/fab-admin/account`}>Accounts</a>
            </ListGroupItem><ListGroupItem>
              <a href={`${redirectRoot}/fab-admin/internal/rfq`}>Internal Monitoring</a>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    </>
  );
};

export const CompnayProfilSidebar = (props) => {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  let token = localStorage.getItem("token");
  const { pathname } = useLocation();

  const [image, setImage] = useState({
    preview : user?.data?.profileImage? user.data.profileImage : ProfilePic,
    raw: "",
    invalidFile: false,
  });
  // useEffect(() => {
  //   console.log("raja-useeff");
  //   getUserInfo(localStorage.getItem("id")).then((res) => {
  //     console.log("raja", res);
  //     if (res.isSuccess) {
  //       localStorage.setItem('role', res.data.accountType);
  //       setUser(res.data);
  //      } else {
  //     }
  //   }).catch((err) => {
  //   })

  // });

  // const dispatch = useDispatch()
  useEffect(() => {
  setImage({
  preview : user?.data?.profileImage? user.data.profileImage : ProfilePic,
  raw: "",
  invalidFile: false,
})
    let userId=localStorage.getItem('id');
    dispatch(getUserInfoAction(userId)).then((user)=>{
      console.log('user',user)
    });
  },[]);
  const handleChange = (e) => {
    const files = e.target.files;
    if (files !== null && files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        invalidFile: true,
      });
    }
  };

  /**for overlay* */
  const [modalShow, setModalShow] = useState(false);

  const handleClick = () => {
    if (modalShow) {
      setModalShow(false);
    } else {
      setModalShow(true);
    }
  };
  const handleImageUrl = (val) => {
    image.preview = val;
    if (modalShow) {
      setModalShow(false);
    } else {
      setModalShow(true);
    }
  };
 

  return (
    
    <div className="Sidebar-Admin">
      <Card>
        <button onClick={handleClick} className="Profile-overlay">
          {modalShow && (
            <ProfileOverlay
              type="account"
              show={modalShow}
              handleClick={handleClick}
              handleImageUrl={handleImageUrl}
            />
          )}
          ...
        </button>
        <div className="image-border">
          <img
            src={image.preview}
            className="profile"
          />
          <label for="editProfile">
            {/* <img src={edit} className="image-icon" /> */}
          </label>
          <input
            type="file"
            onChange={handleChange}
            accept="image/x-png,image/jpeg,image/svg+xml"
            className="sr sr-only"
            id="editProfile"
            name="photo"
          />
        </div>
        <Card.Body className="Card-Sidebar">
          <Card.Title title={`${user.data.firstName} ${user.data.lastName ? user.data.lastName : ""}`}>
            {user.data.firstName} {user.data.lastName ? user.data.lastName : ""}
          </Card.Title>
          <Card.Text className="Profile-card-text">
            <p  className="mb-0">
             {getdesig(user.data.accountType)}
            </p>
            <p title={user.data.companyName} className="buyer-head">
              {" "}
              {user.data.companyName}
            </p>
            <hr className="profile-hr" />
          </Card.Text>
        </Card.Body>
        <ListGroup className="list-group-flush">
          {user.data.userType == "BUYER" ? (
            <>
              <ListGroupItem>
                <a href={`${redirectRoot}/buyer/dashboard`}>
                  {" "}
                  Order Management
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <a href={`${redirectRoot}/buyer/sample-new`}>
                  {" "}
                  Sampling
                </a>
              </ListGroupItem>
              {PROFILE_LINK.map((o, i) => {
                return (
                  <ListGroupItem
                    className={o.to === pathname ? "active" : null}
                  >
                    <Link to={o.to}>{o.displayName}</Link>
                  </ListGroupItem>
                );
              })}
              {/* <ListGroupItem>
                <a href={``}>Showrooms</a>
              </ListGroupItem> */}
              <ListGroupItem>
                <a href={`${redirectRoot}/buyer/message`}>Messages</a>
              </ListGroupItem>
              {/* <ListGroupItem>
                <a href={``}>Reports</a>
              </ListGroupItem> */}
              {(user.data.accountType == "ADMIN_BUYER" || user.data.accountType == "OWNER") &&
                <ListGroupItem>
                <a href={`${redirectRoot}/account`}>Financial Accounts</a>
                </ListGroupItem>
              }
              {user.data.accountType == "ADMIN_BUYER" && 
                <ListGroupItem
                  className={
                    window.location.pathname == "/companyprofile"
                      ? "active"
                      : null || window.location.pathname == "/userteam"
                      ? "active"
                      : null
                  }
                >
                  <Link to={"/companyprofile"}>
                    Admin Settings
                  </Link>
                </ListGroupItem>
              }
               <ListGroupItem>
                <a href={`${redirectRoot}/buyer/monitoring`}>
                  {" "}
                 Monitoring
                </a>
              </ListGroupItem>
            </>
          ) : null}

          {user.data.userType == "SELLER" ? (
            <>
              <ListGroupItem>
                <a
                  href={`${redirectRoot}/seller/respondtoquotation`}
                >
                  Respond to Quotation
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <a href={`${redirectRoot}/seller/dashboard`}>
                  Order Management
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <a href={`${redirectRoot}/seller/sample-new`}>
                  {" "}
                  Sampling
                </a>
              </ListGroupItem>
              {PROFILE_SELLER.map((o, i) => {
                return (
                  <ListGroupItem
                    className={o.to === pathname ? "active" : null}
                  >
                    <Link to={o.to}>{o.displayName}</Link>
                  </ListGroupItem>
                );
              })}
              {/* <ListGroupItem>
                <a href={``}>
                  Showrooms
                </a>
              </ListGroupItem> */}
              <ListGroupItem>
                <a href={`${redirectRoot}/seller/message`}>Messages</a>
              </ListGroupItem>
              {/* <ListGroupItem>
                <a href={``}>Reports</a>
              </ListGroupItem> */}
              {(user.data.accountType == "ADMIN_SELLER" || user.data.accountType == "OWNER") &&
                <ListGroupItem>
                <a href={`${redirectRoot}/account`}>Financial Accounts</a>
                </ListGroupItem>
              }
              {user.data.accountType == "ADMIN_SELLER" && 
                <ListGroupItem
                  className={
                    window.location.pathname == "/companyprofile"
                      ? "active"
                      : null || window.location.pathname === "/userteam"
                      ? "active"
                      : null || window.location.pathname === "/productprofile"
                      ? "active"
                      : null
                  }
                >
                  <Link to={"/companyprofile"}>
                    Admin Settings
                  </Link>
                </ListGroupItem>
              } 
            </>
          ) : null}
        </ListGroup>
      </Card>
    </div>
  );
};

/****for buyer open enq */

export const BuyerOpenEnqSide = (props) => {
  const dispatch = useDispatch()

  /**for overlay* */
  const [modalShow, setModalShow] = useState(false);

  const handleClick = () => {
    if (modalShow) {
      setModalShow(false);
    } else {
      setModalShow(true);
    }
  };

  const { pathname } = useLocation();

  const [image, setImage] = useState({
    preview: "",
    raw: "",
    invalidFile: false,
  });
  const handleChange = (e) => {
    const files = e.target.files;
    if (files !== null && files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        invalidFile: true,
      });
    }
  };
  useEffect(() => {
    let userId=localStorage.getItem('id');
    dispatch(getUserInfoAction(userId));
  },[]);
  const user = useSelector((state) => state.userReducer);

  return (
    <div className="Sidebar-Admin">
      <Card>
        <button onClick={handleClick} className="Profile-overlay">
          {modalShow && (
            <ProfileOverlay
              type="account"
              show={modalShow}
              handleClick={handleClick}
            />
          )}
          ...
        </button>
        <div className="image-border">
          <img
            src={image.preview ? image.preview : ProfilePic}
            className="profile"
          />
          <label for="editProfile">
            {/* <img src={edit} className="image-icon" /> */}
          </label>
          <input
            type="file"
            onChange={handleChange}
            accept="image/x-png,image/jpeg,image/svg+xml"
            className="sr sr-only"
            id="editProfile"
            name="photo"
          />
        </div>
        <Card.Body className="Card-Sidebar">
          <Card.Title title={user.data.firstName + " " + user.data.lastName}>
            {user.data.firstName + " " + user.data.lastName.substring(0, 10)}
          </Card.Title>
          <Card.Text className="Profile-card-text">
            <p title={user.data.designation} className="mb-0">
              {" "}
              {user.data.designation.substring(0, 10)}
            </p>
            <p title={user.data.companyName} className="buyer-head">
              {" "}
              {user.data.companyName.substring(0, 10)}
            </p>
            <hr className="profile-hr" />
          </Card.Text>
        </Card.Body>
        <ListGroup className="list-group-flush">
          {BuyerOpenEnq.map((o, i) => {
            return (
              <ListGroupItem className={o.to === pathname ? "active" : null}>
                <Link to={o.to}>{o.displayName}</Link>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </Card>
    </div>
  );
};
 
const getdesig=  (name)=>{
  let userType = window.localStorage.getItem('usertype');
  if (userType === 'BUYER') {

    if (name === 'MANAGER') {
      return 'Purchase Manager'
    } else if (name === 'JUNIOR') {
      return 'Jr. Purchase Manager'

    } else if (name === 'MERCHANDISER') {
      return 'Merchandiser'

    } else if (name === 'OWNER') {
      return 'Owner'

    } else if (name === 'ACCOUNTS') {
      return 'Accountant'

    } else if (name === 'ADMIN_BUYER') {
      return 'Administrator'

    }


  } else if (userType === 'SELLER') {

    if (name === 'MANAGER') {
      return 'Sales Manager'
    } else if (name === 'JUNIOR') {
      return 'Jr. Sales Manager'

    } else if (name === 'MERCHANDISER') {
      return 'Merchandiser'

    } else if (name === 'OWNER') {
      return 'Owner'

    } else if (name === 'ACCOUNTS') {
      return 'Accountant'

    } else if (name === 'ADMIN_SELLER') {
      return 'Administrator'

    }




  }
  else if (userType === 'INTERNAL') {
    return 'Fabtrack Admin'
  }

};