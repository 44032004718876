import React, { useEffect, useState } from 'react';
import './SignUp.css';
import { Card, Spinner } from 'reactstrap';
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import validate from 'validate.js';
import { decrypt } from '../../services/crypto.service';
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { signUp } from '../../services'
import { SignupApi } from '../../services/auth.service'
import { roles } from '../../models/enum';
import { Warning, Errors, Success } from '../Alert';
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { toast } from 'react-toastify';
import {toastOptions} from "../../helpers/toast-options";

//form validation
validate.validators.presence.options = { message: '^mandatory field must be filled to proceed' };
const disablingConstraints = {
  firstName: { presence: { allowEmpty: false } },
  lastName: { presence: { allowEmpty: false } },
  companyName: { presence: { allowEmpty: false } },
  designation: { presence: { allowEmpty: false } },
  pinCode: { presence: { allowEmpty: false } }, 
  // companyWebsite: { presence: { allowEmpty: false } }, 
  companyAddress: { presence: { allowEmpty: false } }, 
  email: { presence: { allowEmpty: false } }, 
  phoneNumber: { presence: { allowEmpty: false } },  
  companyBriefDescription: { presence: { allowEmpty: false } },
  specialization: { presence: { allowEmpty: false } }
};
const constraints = {
  firstName: {
    presence: { allowEmpty: false },
    format: {
      pattern: /^[a-zA-Z\s]*$/,
      flags: "i",
      message: "can only contain a-z"
    }
  },
  lastName: {
    presence: { allowEmpty: false },
    format: {
      pattern: /^[a-zA-Z\s]*$/,
      flags: "i",
      message: "can only contain a-z"
    }
  },
  email: {
    presence: { allowEmpty: false },
    format: {
      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      flags: "i"
    },
    // email: true,
  },
  companyName: { presence: { allowEmpty: false } },
  designation: { presence: { allowEmpty: false } },
  companyAddress: { 
    presence: { allowEmpty: false },
    length: { minimum: 1, maximum: 150, message: "must be between 1 and 150 characters"}
  },
  // companyWebsite: { 
  //   presence: { allowEmpty: false },
  //   length: { minimum: 9, maximum: 30, message: "must be between 9 and 30 characters"}
  // },
  
  pinCode: {
    presence: { allowEmpty: false },
    format: {
      pattern: /^([1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3})$/,
      flags: "i",
      message: "invalid"
    },
  },
  phoneNumber: {
    presence: { 
      allowEmpty: false,
    },

    length: { minimum: 10, maximum: 10, message: "must be of 10 characters"}

    // format: {
    //   pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
    //   flags: "i",
    //   message: "invalid"
    // },
    // length: { minimum: 10, maximum: 10 },
    // pattern:"[0-9]{3}-[0-9]{2}-[0-9]{3}"
  },
  // companyWebsite: { presence: { allowEmpty: false } },
  companyBriefDescription: { presence: { allowEmpty: false } },
  specialization: { presence: { allowEmpty: false } }
};

const SignUpForm = (props) => {
  const history = useHistory();
  const { search } = useLocation();
  const queryValues = queryString.parse(search);
  let role = queryValues.roles

  const [user, setUser] = useState({
    firstName: '',
    lastName: "",
    email: '',
    companyName: '',
    designation: '',
    companyAddress: '',
    pinCode: '',
    phoneNumber: '',
    companyWebsite: '',
    companyBriefDescription: '',
    specialization: '',
    userType: role,
  })
  const [isLoading, setisLoading] = useState(false)
  const [disablingErrors, setDisablingErrors] = useState();
  const [toastId, setToastId] = useState();
  const funText = (e) => {

    if (e.keyCode == 190) {
      e.preventDefault();
      return false;

    }
    if ((e.keyCode == 16 && (e.keyCode == 191 || e.keyCode == 111 || e.keyCode == 106 || e.keyCode == 107 || e.keyCode == 109))) {
      e.preventDefault();
    }
    if ((e.keyCode == 219 || e.keyCode == 221 || e.keyCode == 189 || e.keyCode == 187 || e.keyCode == 220 || e.keyCode == 106 || e.keyCode == 49 || e.keyCode == 50 || e.keyCode == 51 || e.keyCode == 52 || e.keyCode == 53 || e.keyCode == 54 || e.keyCode == 55 || e.keyCode == 56 || e.keyCode == 57 || e.keyCode == 48 || e.keyCode == 111 || e.keyCode == 186 || e.keyCode == 190 || e.keyCode == 222 || e.keyCode == 191 || e.keyCode == 188 || e.keyCode == 107 || e.keyCode == 192)) {
      e.preventDefault();
      return false;

    }
  }

  const fun = (e) => {

    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    if (e.keyCode == 190) {
      e.preventDefault();
      return false;

    }
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }

  }

  const [formDetails, setFormDetails] = useState({
    submitted: false,
    errors: null,
    valid: false
  });
  //state defined
  useEffect(() => {
    const errors = validate(user, constraints);
    setFormDetails((prev) => ({
      ...prev,
      errors
    }));
    setDisablingErrors(validate(user, disablingConstraints));

  }, [user]);

  const handleChange = (event) => {

    const { name, value } = event.target;
    setUser((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setFormDetails((prev) => ({
      ...prev,
      submitted: true
    }));
    if(formDetails.errors){
      const currentError = Object.keys(formDetails.errors).map(key => formDetails.errors[key][0]);
      if(!toastId){
        const id = toast.error(currentError[0], {...toastOptions, onClose: ()=> setToastId(null)});
        setToastId(id);
      }
      return;
    }
    if (!formDetails.errors) {
      setisLoading(true)
for (let key in user){
  user[key]=user[key].trim();
}
      SignupApi(user).then((data) => {
        if (data.isSuccess) {
          history.push(`/success`)
        }
      })
        .catch((err) => {
          console.log("er", err);
          setisLoading(false)
        });
    }
  };

  const handleBack = () => {
    history.push(`/dashboard`)
  }

  return (
    <>

      <Row>
        <Col sm='12'>
          <h5 className='headingSignUp'>Sign Up</h5>
        </Col>
      </Row>

      <div className="Main">
        <div className="SignUpForm">
          <Card className="Card">
            <p className="SignUp-heading">Fill out information</p>

            <Form onSubmit={submitHandler}>

              <Row>
                <Col md={6} className='pl-0'>
                  <FormGroup>
                    <Label className="required" for="Firstname">First Name</Label>
                    <Input
                      type="text"
                      name="firstName"
                      id="Firstname"
                      placeholder=""
                      minLength="1"
                      maxLength="20"
                      onKeyDown={(e) => funText(e)}
                      value={user.firstName}
                      onChange={handleChange}
                    />
                    {/* {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.firstName && (
                        <small className="form-text text-danger">
                          {formDetails.errors.firstName[0]}
                        </small>
                      )} */}
                  </FormGroup>
                </Col>

                <Col md={6} className='pr-0'>
                  <FormGroup>
                    <Label className="required" for="Lastname">Last Name</Label>
                    <Input
                      type="text"
                      name="lastName"
                      minLength="1"
                      maxLength="20"
                      placeholder=""
                      onKeyDown={(e) => funText(e)}

                      value={user.lastName}
                      onChange={handleChange}

                    />
                    {/* {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.lastName && (
                        <small className="form-text text-danger">
                          {formDetails.errors.lastName[0]}
                        </small>
                      )} */}
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label className="required" for="exampleAddress">E-mail</Label>
                <Input
                  type="text"
                  name="email"
                  id="exampleAddress"
                  autoComplete="off"
                  // onKeyUp={() => ValidateEmail(user.email)}
                  placeholder=""
                  value={user.email}
                  onChange={handleChange}

                />
                {/* {valid?
                <p>invalid</p>
              :null} */}
                
                {/* {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.email && (
                    <small className="form-text text-danger">
                      {formDetails.errors.email[0]}
                    </small>
                  )} */}
              </FormGroup>
              <FormGroup>
                <Label className="required" for="exampleAddress2">Company Name</Label>
                <Input
                  type="text"
                  name="companyName"
                  id="exampleAddress2"
                  placeholder=""
                  minLength="1"
                  maxLength="50"
                  value={user.companyName}
                  onChange={handleChange}

                />
                {/* {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.companyName && (
                    <small className="form-text text-danger">
                      {formDetails.errors.companyName[0]}
                    </small>
                  )} */}
              </FormGroup>
              <FormGroup>
                <Label className="required" for="exampleAddress2">Company Designation</Label>
                <Input
                  type="text"
                  name="designation"
                  id="exampleAddress2"
                  placeholder=""
                  minLength="1"
                  maxLength="50"
                  value={user.designation}
                  onChange={handleChange}
                />
                {/* {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.designation && (
                    <small className="form-text text-danger">
                      {formDetails.errors.designation[0]}
                    </small>
                  )} */}
              </FormGroup>
              <FormGroup>
                <Label className="required" for="exampleAddress">Company Address</Label>
                <textarea
                  type="text"
                  name="companyAddress"
                  id="exampleAddress"
                  placeholder=""
                  minLength="1"
                  maxLength="150"
                  value={user.companyAddress}
                  onChange={handleChange}
                />
                {/* {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.companyAddress && (
                    <small className="form-text text-danger">
                      {formDetails.errors.companyAddress[0]}
                    </small>
                  )} */}
              </FormGroup>
              <Row>
                <Col md={6} className='pl-0'>
                  <FormGroup>
                    <Label className="required" for="Firstname">Pincode</Label>
                    <Input
                      type="text"
                      name="pinCode"
                      id="Firstname"
                      onKeyDown={(e) => fun(e)}
                      autoComplete="off"
                      maxLength="6"

                      placeholder=""
                      value={user.pinCode}
                      onChange={handleChange}
                    />
                    {/* {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.pinCode && (
                        <small className="form-text text-danger">
                          {formDetails.errors.pinCode[0]}
                        </small>
                      )} */}
                  </FormGroup>
                </Col>
                <Col md={6} className='pr-0'> 
                  <FormGroup>
                    <Label className="required" for="Lastname">Phone Number</Label>
                    <Input
                      type="tel"
                      // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      name="phoneNumber"
                      onKeyDown={(e) => fun(e)}
                      // id="lastname"
                      autoComplete="off"
                      minLength="10"
                      maxLength="10"
                      placeholder=""
                      value={user.phoneNumber}
                      onChange={handleChange}
                    />
                    {/* {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.phoneNumber && (
                        <small className="form-text text-danger">
                          {formDetails.errors.phoneNumber[0]}
                        </small>
                      )} */}
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="exampleAddress">Company Website</Label>
                <Input
                  type="text"
                  name="companyWebsite"
                  id="exampleAddress"
                  placeholder=""
                  // minLength="9"
                  // maxLength="30"
                  value={user.companyWebsite}
                  onChange={handleChange}
                />

              </FormGroup>
              <FormGroup>
                <Label className="required" for="exampleAddress">What does your Company do?</Label>
                <textarea
                  type="text"
                  name="companyBriefDescription"
                  id="companyPurpose"
                  minLength="1"
                  maxLength="1000"
                  placeholder="1000 characters max"
                  value={user.companyBriefDescription}
                  onChange={handleChange}

                />
                {/* {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.companyBriefDescription && (
                    <small className="form-text text-danger">
                      {formDetails.errors.companyBriefDescription[0]}
                    </small>
                  )} */}
              </FormGroup>
              <FormGroup>
                <Label className="required" for="exampleAddress">What products are you specialized in?</Label>
                <textarea
                  type="text"
                  name="specialization"
                  id="companyPurpose"
                  minLength="1"
                  maxLength="1000"
                  placeholder="1000 characters max"
                  value={user.specialization}
                  onChange={handleChange}
                />
                {/* {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.specialization && (
                    <small className="form-text text-danger">
                      {formDetails.errors.specialization[0]}
                    </small>
                  )} */}
              </FormGroup>
              <Button className="SignUp-Submit" disabled={isLoading || disablingErrors} color="primary" type="submit">
                {isLoading ? <Spinner style={{height: "10px", width: "10px"}}/> : 'Join'}
              </Button>
              <Button className="SignUp-Back" outline color="primary" onClick={handleBack}>Back</Button>

            </Form>
            <NotificationContainer />
          </Card>
        </div>
      </div>
    </>
  );
}

export default SignUpForm;