import React, { useState } from 'react';

import { Col, Row, FormGroup, Label, Input, Form } from 'reactstrap';


const NewreqForm = (props) => {

  //  console.log( props.tableData,'.....')

  let [formData] = props.tableData.data.filter(val => val.id == props.tableData.id)

  //  console.log( formData.,'fn')

  const [user, setUser] = useState({
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    companyName: formData.companyName,
    designation: formData.designation,
    companyAddress: formData.companyAddress,
    pinCode: formData.pinCode,
    phoneNumber: formData.phoneNumber,
    companyWebsite: formData.companyWebsite,
    companyBriefDescription: formData.companyBriefDescription,
    specialization: formData.specialization
  })


  const handleChange = () => {

  }

  return (
    <>
      <Form>

        <Row>
          <Col md={6}  className="p0 pr-2" >
            <FormGroup>
              <Label className="required" for="Firstname">First Name</Label>
              <Input
                type="firstname"
                name="firstname"
                id="Firstname"
                // placeholder="firstname"
                value={user.firstName}
                onChange={handleChange}
                disabled
              />
            </FormGroup>
          </Col>

          <Col md={6}  className="p0" >
            <FormGroup>
              <Label className="required" for="Lastname">Last Name</Label>
              <Input
                type="lastname"
                name="lastname"
                id="lastname"
                // placeholder="lastname"
                value={user.lastName}
                onChange={handleChange}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label className="required" for="exampleAddress">E-mail</Label>
          <Input
            type="email"
            name="email"
            id="exampleAddress"
            // placeholder="email"
            value={user.email}
            onChange={handleChange}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label className="required" for="exampleAddress2">Company Name</Label>
          <Input
            type="text"
            name="company"
            id="exampleAddress2"
            // placeholder="company"
            value={user.companyName}
            onChange={handleChange}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label className="required" for="exampleAddress2">Company Designation</Label>
          <Input
            type="text"
            name="designation"
            id="exampleAddress2"
            // placeholder="designation"
            value={user.designation}
            onChange={handleChange}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label className="required" for="exampleAddress">Company Address</Label>
          <textarea
            type="text"
            name="companyaddress"
            id="exampleAddress"
            // placeholder="companyaddress"
            value={user.companyAddress}
            onChange={handleChange}
            disabled
          />
        </FormGroup>
        <Row>
          <Col md={6} className="p0 pr-2"  >
            <FormGroup>
              <Label className="required" for="Firstname">Pincode</Label>
              <Input
                type="text"
                name="pincode"
                id="Firstname"
                // placeholder="pincode"
                value={user.pinCode}
                onChange={handleChange}

                disabled
              />
            </FormGroup>
          </Col>
          <Col md={6}  className="p0">
            <FormGroup>
              <Label className="required" for="Lastname">Phone Number</Label>
              <Input
                type="phone"
                name="phone"
                id="lastname"
                // placeholder="phone"
                value={user.phoneNumber}
                onChange={handleChange}
                disabled

              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="exampleAddress">Company Website</Label>
          <Input
            type="text"
            name="website"
            id="exampleAddress"
            // placeholder="website"
            value={user.companyWebsite}
            onChange={handleChange}
            disabled

          />
        </FormGroup>
        <FormGroup>
          <Label className="required" for="exampleAddress">What does your Company do?</Label>
          <textarea
            type="text"
            name="purpose"
            id="exampleAddress"
            // placeholder="What does your Company do?"
            value={user.companyBriefDescription}
            onChange={handleChange}
            disabled

          />
        </FormGroup>
        <FormGroup>
          <Label className="required" for="exampleAddress">What products are you specialized in?</Label>
          <textarea
            type="text"
            name="products"
            id="exampleAddress"
            // placeholder="What products are you specialized in?"
            value={user.specialization}
            onChange={handleChange}

            disabled
          />
        </FormGroup>
      </Form>
    </>
  );
}

export default NewreqForm;