import React from "react";
import { Progress } from "reactstrap";
import './CompanyProfile.css'


const StepperComponent = (props) => {
    //  
    let currentStep = props.currentStep

    const stepsArray = [
        "Summary",
        "Business Description",
        "Locations",
        "Gallery"
    ];
    return (
        <>
            <Progress value={((currentStep + 1) / stepsArray.length) * 100} activeStep={currentStep} />

            <div className="stepsArray d-flex">
                <p className={currentStep == 0 ? 'activeStep' : 'null'}> Summary </p>
                <p className={currentStep == 1 ? 'activeStep' : 'null'} > Business Description </p>
                <p className={currentStep == 2 ? 'activeStep' : 'null'}> Locations </p>
                <p className={currentStep == 3 ? 'activeStep' : 'null'}> Gallery </p>

            </div>
        </>

    )
}

export default StepperComponent;

