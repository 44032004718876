import {NotificationContainer, NotificationManager} from 'react-notifications';
 
  export const Success=(data)=>{
    NotificationManager.success(data);
  }

  export const Warning=(data)=>{
    NotificationManager.warning(data);
  }

  export const Errors=(data)=>{
    // NotificationManager.error(data);
  }

  export const Info=(data)=>{
    NotificationManager.info("Form submitted");
  }



