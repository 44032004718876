import React, { useEffect, useState } from 'react';
import { Card, Button, CardTitle, CardText, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import './Rfq.css'
import validate from 'validate.js'
import { stages } from '../../../models/enum';
import { AddrfqFabricApi, GetRFQApi, getAllYarn, ViewYarnApi } from '../../../services';
import edit from '../../../assets/edit.png';
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { isBefore } from 'date-fns/esm';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import * as moment from "moment";
import DatePicker from "react-datepicker";

validate.validators.presence.options = { message: '^mandatory field must be filled to proceed' };

let constraints = {
  warpCount: {
    presence: { allowEmpty: false },

  },
  warp: { presence: { allowEmpty: false }, },

  weftCount: {
    presence: { allowEmpty: false },

  },
  weft: { presence: { allowEmpty: false }, },
  fabricReed: {
    presence: { allowEmpty: false },

  },
  fabricPick: {
    presence: { allowEmpty: false },

  },
  gsmValue: {
    presence: { allowEmpty: false },

  },
  printWidth: {
    presence: { allowEmpty: false },

  },
  dyedWidth: {
    presence: { allowEmpty: false },

  },
};
let constraintsPrint = {
  warpCount: {
    presence: { allowEmpty: false },

  },
  warp: { presence: { allowEmpty: false }, },

  weftCount: {
    presence: { allowEmpty: false },

  },
  weft: { presence: { allowEmpty: false }, },
  fabricReed: {
    presence: { allowEmpty: false },

  },
  fabricPick: {
    presence: { allowEmpty: false },

  },
  gsmValue: {
    presence: { allowEmpty: false },
  },
  printWidth: {
    presence: { allowEmpty: false },

  },
  printRawWidth: {
    presence: { allowEmpty: true },

  },
};
let constraintsDyed = {
  warpCount: {
    presence: { allowEmpty: false },

  },
  warp: { presence: { allowEmpty: false }, },

  weftCount: {
    presence: { allowEmpty: false },

  },
  weft: { presence: { allowEmpty: false }, },
  fabricReed: {
    presence: { allowEmpty: false },

  },
  fabricPick: {
    presence: { allowEmpty: false },

  },
  gsmValue: {
    presence: { allowEmpty: false },

  },
  dyedWidth: {
    presence: { allowEmpty: false },
  },
  dyedRawWidth: {
    presence: { allowEmpty: true },

  },
};
let constraintsGreige = {
  warpCount: {
    presence: { allowEmpty: false },

  },
  warp: { presence: { allowEmpty: false }, },

  weftCount: {
    presence: { allowEmpty: false },

  },
  weft: { presence: { allowEmpty: false }, },
  fabricReed: {
    presence: { allowEmpty: false },

  },
  fabricPick: {
    presence: { allowEmpty: false },

  },
  gsmValue: {
    presence: { allowEmpty: false },

  },
  greigeWidth: {
    presence: { allowEmpty: false },

  }
};

const OrderKateweave = (props) => {
  const dispatch = useDispatch();
  const linkType = useSelector(state => state.fabricLinkReducer.linkType)
  let body;
  let newObe;
  let rfdNumber = localStorage.getItem('rfqNumber')
  let rfdID = localStorage.getItem('rfqID')
  let stage = stages.RFQ_IN_PRG2
  const [type, setType] = useState('')
  const [fabricstage, setfabricstage] = useState('')
  const [weave, setWeave] = useState('')
  const [warpYarn, setWarpYarn] = useState();
  const [warpSelected, setwarpSelected] = useState(false);
  const [weftSelected, setweftSelected] = useState(false);
  const [weftYarn, setWeftYarn] = useState();
  const [yarn, setYarn] = useState({ blend: [], yarn: [] })
  const [isLoading, setIsLoading] = useState(false);
  const [applyValidation, setapplyValidation] = useState(true);

  const [processType, setprocessType] = useState('')

  const [formDetails, setFormDetails] = useState({
    submitted: false,
    // submittedP:false,
    errors: null,
  })
  const [addFabric, setAddFabric] = useState({
    rfdNumber: rfdNumber,
    warpCount: "",
    warp: '',
    warpComment: '',
    weftCount: "",
    weft: '',
    weftComment: '',
    fabricReed: "",
    fabricPick: "",
    fabricConstructionComment: '',
    gsmValue: "",
    printWidth: "",
    printRawWidth: "",
    dyedRawWidth: "",
    dyedWidth: "",
    warpYarnDTOList: [],
    weftYarnDTOList: [],
    jobWorkStartDate: '',
    jobWorkEndDate: ''
    // greigeWidth: '',
    // errors: false
  })

  const handleChange = event => {
    const { name, value } = event.target
    if (type.toLowerCase() == 'knit') {
      constraintsPrint = {
        ...constraintsPrint, fabricReed: {
          presence: { allowEmpty: false },

        },
        fabricPick: {
          presence: { allowEmpty: false },

        }
      }
    }
    if (name == 'warp') {
      setwarpSelected(false)
      let fabric = [];
      let a = yarn.yarn.find(item => item.name == value);
      if (a == undefined || !a) {
        a = yarn.blend.find(item => item.name == value)
        a.yarnType = 'Blend';
        a.blendYarns.map(item => {
          fabric.push({ id: 0, yarnId: item.yarnTypeId, sustainabilityId: 0, blendId: item.blendId });
          if (item.yarnType.sustainabilityTypes.length > 0) {
            setwarpSelected(true)
          }
        })
      } else {
        a.yarnType = 'individual';
        fabric.push({ id: 0, yarnId: a.id, sustainabilityId: 0, blendId: 0 });
        if (a.sustainabilityTypes.length > 0) {
          setwarpSelected(true)
        }
      }
      setAddFabric(prev => ({
        ...prev,
        warpYarnDTOList: fabric,
      }))
      console.log(a)
      setWarpYarn(a);

    }
    if (name == 'weft') {
      setweftSelected(false)
      let fabric = [];
      let a = yarn.yarn.find(item => item.name == value)
      if (!a) {
        a = yarn.blend.find(item => item.name == value)
        a.yarnType = 'Blend';
        a.blendYarns.map(item => {
          fabric.push({ id: 0, yarnId: item.yarnTypeId, sustainabilityId: 0, blendId: item.blendId });
          if (item.yarnType.sustainabilityTypes.length > 0) {
            setweftSelected(true)
          }
        })
      } else {
        a.yarnType = 'individual';
        fabric.push({ id: 0, yarnId: a.id, sustainabilityId: 0, blendId: 0 });
        if (a.sustainabilityTypes.length > 0) {
          setweftSelected(true)
        }
      }
      setAddFabric(prev => ({
        ...prev,
        weftYarnDTOList: fabric,
      }))
      setWeftYarn(a);

    }
    setAddFabric(prev => ({
      ...prev,
      [name]: value,
    }))

  }
  const addwarpsustainability = (event, item) => {
    const { name, value } = event.target
    let fabric;
    if (item) {
      console.log(value)
      if (addFabric.warpYarnDTOList && addFabric.warpYarnDTOList.length > 0) {
        let isFound = false;
        fabric = addFabric.warpYarnDTOList.map((i) => {
          if (i.yarnId == item.yarnTypeId) {
            i.sustainabilityId = value;
            isFound = true;
          }
          return i;
        })
        if (!isFound) {
          fabric.push({ id: 0, yarnId: item.yarnTypeId, sustainabilityId: value, blendId: item.blendId });
        }
      } else {
        fabric = [{ id: 0, yarnId: item.yarnTypeId, sustainabilityId: value, blendId: item.blendId }]
      }
    } else {

      fabric = addFabric.warpYarnDTOList.map((i) => {
        if (i.yarnId == warpYarn.id) {
          i.sustainabilityId = value;
        }
        return i;
      })

    }

    setAddFabric(prev => ({
      ...prev,
      warpYarnDTOList: fabric,
    }))
    console.log(addFabric, fabric)

  }
  const addweftsustainability = (event, item) => {
    const { name, value } = event.target
    let fabric;
    if (item) {
      console.log(value)
      if (addFabric.weftYarnDTOList && addFabric.weftYarnDTOList.length > 0) {
        let isFound = false;
        fabric = addFabric.weftYarnDTOList.map((i) => {
          if (i.yarnId == item.yarnTypeId) {
            i.sustainabilityId = value;
            isFound = true;
          }
          return i;
        })
        if (!isFound) {
          fabric.push({ id: 0, yarnId: item.yarnTypeId, sustainabilityId: value, blendId: item.blendId });
        }
      } else {
        fabric = [{ id: 0, yarnId: item.yarnTypeId, sustainabilityId: value, blendId: item.blendId }]
      }
    } else {

      fabric = addFabric.weftYarnDTOList.map((i) => {
        if (i.yarnId == weftYarn.id) {
          i.sustainabilityId = value;
        }
        return i;
      })
    }


    setAddFabric(prev => ({
      ...prev,
      weftYarnDTOList: fabric,
    }))
    console.log(addFabric, fabric)
  }
  const getsusValue = (item, type) => {

    let fabric;
    if (type == 'warp') {
      if (item.blendId > 0) {
        if (addFabric.warpYarnDTOList.length > 0) {
          let isFound = false;
          fabric = addFabric.warpYarnDTOList.find((i) => (i.yarnId == item.yarnTypeId)
          )
          if (fabric) {
            return fabric.sustainabilityId
          }
        }
      } else {
        console.log(warpYarn);
        fabric = addFabric.warpYarnDTOList.find((i) => i.yarnId == warpYarn.id)
        if (fabric) {
          return fabric.sustainabilityId;
        }
      }
    }
    if (type == 'weft') {
      if (item.blendId > 0) {
        if (addFabric.weftYarnDTOList.length > 0) {
          let isFound = false;
          fabric = addFabric.weftYarnDTOList.find((i) => (i.yarnId == item.yarnTypeId)
          )
          if (fabric) {
            return fabric.sustainabilityId;
          }
        }
      } else {

        fabric = addFabric.weftYarnDTOList.find((i) => i.yarnId == weftYarn.id)
        if (fabric) {
          return fabric.sustainabilityId;
        }
      }
    }
  }
  // if (fabricstage != 'Processed') {

  //   newObe = Object.assign(constraints, {
  //     greigeWidth: {
  //       presence: { allowEmpty: false },

  //     }
  //   })

  // }
  // if (processType == 'PRINT') {

  //   newObe = Object.assign(constraints, {
  //     printWidth: {
  //       presence: { allowEmpty: false },

  //     },

  //   })

  // } if (processType == 'Print & Dyed') {
  //   //  
  //   newObe = Object.assign(constraints, {
  //     printWidth: {
  //       presence: { allowEmpty: false },

  //     },
  //     dyedWidth: {
  //       presence: { allowEmpty: false },

  //     },
  //   })

  // } if (processType == 'DYED') {

  //   newObe = Object.assign(constraints, {

  //     dyedWidth: {
  //       presence: { allowEmpty: false },

  //     },
  //   })

  // }
  const getFabricTypeApi = () => {
    setIsLoading(true)

    if (props.viewType == 'view') {
      localStorage.setItem('rfqID', props.viewData.rfqId)
      localStorage.removeItem('rfqNumber')
      localStorage.setItem('rfqNumber', props.viewData.rfqNumber)
      localStorage.setItem('orderName', props.viewData.orderName)
      body = {

        id: localStorage.getItem('rfqID'),
        rfqStage: stage
      }
    } else {
      body = {
        id: rfdID,
        rfqStage: stage
      }
    }

    GetRFQApi(body).then((res) => {
      if (res.isSuccess) {
        setType(res.data.fabricType);
        setfabricstage(res.data.fabricStage)
        setWeave(res.data.weaveType)
        setprocessType(res.data.processType)
        console.log(res.data)
        if (res.data.workType == 'FABRIC') {

          dispatch({ type: 'fabric' })
          if (localStorage.getItem('linkType') != 'fabric')
            localStorage.setItem('linkType', 'fabric');

        } else {

          dispatch({ type: 'job_work' })
          if (localStorage.getItem('linkType') != 'job_work')
            localStorage.setItem('linkType', 'job_work');


        }
        setAddFabric({
          ...res.data,
          jobWorkStartDate: res.data.jobWorkStartDate
            ? moment(
              res.data.jobWorkStartDate,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD")
            : "",
          jobWorkEndDate: res.data.jobWorkEndDate
            ? moment(
              res.data.jobWorkEndDate,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD")
            : ""
        })
        console.log(addFabric)
        if (res.data.warp && (res.data.warp).trim() != '') {
          let warpselect = false;
          let a = yarn.yarn.find(item => item.name == res.data.warp);
          if (a == undefined || !a) {
            a = yarn.blend.find(item => item.name == res.data.warp)
            if (a) {
              a.yarnType = 'Blend';
              a.blendYarns.forEach(element => {
                if (element.yarnType.sustainabilityTypes.length > 0) {
                  warpselect = true;
                }
              })
            }
          } else {
            a.yarnType = 'individual';
            if (a.sustainabilityTypes.length > 0) {
              warpselect = true;

            }
          }
          setWarpYarn(a);
          setwarpSelected(warpselect);
        }
        if (res.data.weft && (res.data.weft).trim() != '') {
          let weftselect = false;
          let a = yarn.yarn.find(item => item.name == res.data.weft);
          if (a == undefined || !a) {
            a = yarn.blend.find(item => item.name == res.data.weft)

            if (a) {
              a.yarnType = 'Blend';
              a.blendYarns.forEach(element => {
                if (element.yarnType.sustainabilityTypes.length > 0) {
                  weftselect = true;
                }
              })
            }

          } else {
            a.yarnType = 'individual';
            if (a.sustainabilityTypes.length > 0) {
              weftselect = true;
            }
          }
          console.log(a)
          setWeftYarn(a);
          setweftSelected(weftselect);
        }
        setCustomValiation(res.data); // it use to set custom validation        
        setIsLoading(false)
      }
    })
  }

  const setCustomValiation = (data) => {
    // if (data.weftCount <= 0) {
    //   setAddFabric((pre) => ({ ...pre, weftCount: "" }))
    // } if (data.warpCount <= 0) {
    //   setAddFabric((pre) => ({ ...pre, warpCount: "" }))
    // }
    if (data.gsmValue <= 0) {
      setAddFabric((pre) => ({ ...pre, gsmValue: "" }))
    }
    if (data.fabricPick <= 0) {
      setAddFabric((pre) => ({ ...pre, fabricPick: "" }))
    } if (data.fabricReed <= 0) {
      setAddFabric((pre) => ({ ...pre, fabricReed: "" }))
    }
    if (data.printWidth <= 0) {
      setAddFabric((pre) => ({ ...pre, printWidth: "" }))
    } if (data.dyedWidth <= 0) {
      setAddFabric((pre) => ({ ...pre, dyedWidth: "" }))
    } if (data.greigeWidth <= 0) {
      setAddFabric((pre) => ({ ...pre, greigeWidth: "" }))
    }
  }

  const getYarnData = () => {
    getAllYarn().then((res) => {
      if (res.isSuccess && res.data && res.data.length) {
        setYarn(res.data)

      } else {
        setYarn(res.data)
      }
    })
  }
  useEffect(() => {
    getYarnData();

  }, []);
  useEffect(() => {
    if (yarn.blend.length > 0 || yarn.yarn.length > 0) {
      console.log(yarn)
      getFabricTypeApi();
    }
  }, [yarn]);
  useEffect(() => {

    if (addFabric.processType == 'PRINT') {
      if (type.toLowerCase() == 'knit') {
        constraintsPrint = {
          ...constraintsPrint, fabricReed: {
            presence: { allowEmpty: true },
          },
          fabricPick: {
            presence: { allowEmpty: true },
          }
        }
      }
      const errors = validate(addFabric, constraintsPrint);
      setFormDetails((prev) => ({
        ...prev,
        errors
      }));
    }
    if (addFabric.processType == 'DYED') {
      if (type.toLowerCase() == 'knit') {
        constraintsDyed = {
          ...constraintsDyed, fabricReed: {
            presence: { allowEmpty: true },

          },
          fabricPick: {
            presence: { allowEmpty: true },

          }
        }
      }
      const errors = validate(addFabric, constraintsDyed);
      setFormDetails((prev) => ({
        ...prev,
        errors
      }));
    }
    if (addFabric.processType == 'Print & Dyed') {
      if (type.toLowerCase() == 'knit') {
        constraints = {
          ...constraints, fabricReed: {
            presence: { allowEmpty: true },

          },
          fabricPick: {
            presence: { allowEmpty: true },

          }
        }
      }
      const errors = validate(addFabric, constraints);
      setFormDetails((prev) => ({
        ...prev,
        errors
      }));
    }
    if (addFabric.fabricStage != 'Processed') {
      if (type.toLowerCase() == 'knit') {
        constraintsGreige = {
          ...constraintsGreige, fabricReed: {
            presence: { allowEmpty: true }
          },
          fabricPick: {
            presence: { allowEmpty: true }
          }
        }
      }
      const errors = validate(addFabric, constraintsGreige);
      setFormDetails((prev) => ({
        ...prev,
        errors
      }));

    }

  }, [addFabric]);


  const submitHandler = () => {
    //  
    let susselected;
    if (warpSelected) {
      addFabric.warpYarnDTOList.forEach(item => {
        console.log(item)
        if (item.blendId > 0) {
          let a = yarn.blend.find(i => i.id == item.blendId);
          console.log(a);

          if (a.blendYarns.find(b => b.yarnTypeId == item.yarnId)) {
            if (a.blendYarns.find(b => b.yarnTypeId == item.yarnId).yarnType.sustainabilityTypes.length > 0) {
              if (item.sustainabilityId == 0) {
                susselected = true;
              }
            }
          }
        } else {
          if (yarn.yarn.find(b => b.id == item.yarnId)) {
            if (yarn.yarn.find(b => b.id == item.yarnId).sustainabilityTypes.length > 0) {
              if (item.sustainabilityId == 0) {
                susselected = true;
              }
            }
          }
        }

      })
    }
    if (weftSelected) {
      addFabric.weftYarnDTOList.forEach(item => {
        if (item.blendId > 0) {
          let a = yarn.blend.find(i => i.id == item.blendId);
          if (a.blendYarns.find(b => b.yarnTypeId == item.yarnId)) {
            if (a.blendYarns.find(b => b.yarnTypeId == item.yarnId).yarnType.sustainabilityTypes.length > 0) {
              if (item.sustainabilityId == 0) {
                susselected = true;
              }
            }
          }
        } else {
          if (yarn.yarn.find(b => b.id == item.yarnId)) {
            if (yarn.yarn.find(b => b.id == item.yarnId).sustainabilityTypes.length > 0) {
              if (item.sustainabilityId == 0) {
                susselected = true;
              }
            }
          }

        }

      })
    }
    if (susselected) {
      toast.error('Please select sustainability');
      return;
    }
    // if (addFabric.warpCount == "" || addFabric.warp == "" || addFabric.weftCount == "" || addFabric.weft == "" || addFabric.fabricPick == "" || addFabric.fabricReed == "" || addFabric.gsmValue == "" || addFabric.printWidth == "" || addFabric.dyedWidth == "" || addFabric.greigeWidth == "") {
    //   addFabric.errors = true
    // }
    setFormDetails(prev => ({
      ...prev,
      submitted: true,

    }))


    let body = {
      id: rfdID,
      warpCount: addFabric.warpCount,
      warp: addFabric.warp,
      weftCount: addFabric.weftCount,
      weft: addFabric.weft,
      warpComment: addFabric.warpComment,
      weftComment: addFabric.weftComment,
      fabricReed: (type.toLowerCase() == 'woven') ? addFabric.fabricReed : 2,
      fabricPick: (type.toLowerCase() == 'woven') ? addFabric.fabricPick : 2,
      fabricConstructionComment: addFabric.fabricConstructionComment,
      gsmValue: addFabric.gsmValue,
      greigeWidth: addFabric.greigeWidth,
      warpYarnDTOList: addFabric.warpYarnDTOList,
      weftYarnDTOList: addFabric.weftYarnDTOList,
      jobWorkStartDate: moment(addFabric.jobWorkStartDate).format(
        "DD/MM/YYYY"
      ),
      jobWorkEndDate: moment(addFabric.jobWorkEndDate).format(
        "DD/MM/YYYY"
      )
    }
    if (linkType == 'job_work') {
      if (!addFabric.jobWorkStartDate || !addFabric.jobWorkEndDate) {
        toast.error('Please Select job work dates ');
        return;
      }
      var valid = moment(addFabric.jobWorkEndDate).isAfter(addFabric.jobWorkStartDate);
      if (!valid) {
        toast.error('End Date can not be before start date')
        return;
      }
    }
    if (processType == 'PRINT') {
      body.printWidth = document.getElementsByName('printWidth')[0].value;
      body.printRawWidth = document.getElementsByName('printRawWidth')[0]?.value||0;
    }
    if (processType == 'DYED') {
      body.dyedWidth = document.getElementsByName('dyedWidth')[0].value;
      body.dyedRawWidth = document.getElementsByName('dyedRawWidth')[0]?.value ||0;
    }
    if (processType == 'Print & Dyed') {
      body.printWidth = document.getElementsByName('printWidth')[0].value;
      body.printRawWidth = document.getElementsByName('printRawWidth')[0]?.value||0;
      body.dyedWidth = document.getElementsByName('dyedWidth')[0].value;
      body.dyedRawWidth = document.getElementsByName('dyedRawWidth')[0]?.value ||0;
    }
    if (fabricstage != 'Processed') {
      body.greigeWidth = document.getElementsByName('greigeWidth')[0].value;
    }
    // if (addFabric.warpCount > 0 && addFabric.weftCount > 0 && addFabric.warp && addFabric.weft && addFabric.fabricPick > 0 && addFabric.fabricReed > 0 && addFabric.gsmValue > 0 && addFabric.printWidth > 0 || addFabric.dyedWidth > 0 || addFabric.greigeWidth > 0) {
    if (!formDetails.errors) {
      AddrfqFabricApi(body).then((res) => {
        if (res.isSuccess) {

          if (fabricstage == "Greige") {

            props.fromActions(1)

          } else {
            props.fromAction(1)
          }
        }
      })
    }

  }
  const handleBack = () => {
    props.fromBack(1);
  }
  const handleBack2 = () => {
    props.fromBack2(1)
  }


  const fun = (e) => {

    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    if (e.keyCode == 190) {
      e.preventDefault();
      return false;
    }
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }
  const funs = (e) => {
    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    if (e.keyCode == 190) {
      // e.preventDefault();
      return true;
    }
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  };
  const handleDateChange = (e, fieldName) => {
    console.log('start date')
    if (fieldName) {
      var name = fieldName;
      var value = moment(e).format("YYYY-MM-DD");
      setAddFabric(prev => ({
        ...prev,
        [name]: value,
      }))
      var valid = moment(addFabric.jobWorkEndDate).isAfter(addFabric.jobWorkStartDate);
      console.log(valid)
    } else {
      var name = "";
      var value = "";
    }

    console.log(addFabric)
  };

  const getprocesstype = (processtype) => {
    if (linkType == 'fabric') {
      return `(${processtype})`;
    } else {
      switch (processtype) {
        case 'PRINT':
          {
            return 'Printing';
            break;
          }
        case 'DYED': {
          return 'Dyeing';
        }
        default: {
          return 'Printing & Dyeing';
        }
      }
    }
  }
  const capitalizeFirstLowercaseRest = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };


  return (
    <>
      {/* <h4 className="RFQ-h4">{addFabric.name} | RFQ #{rfdNumber}</h4> */}
      <h4 className="RFQ-h4">Order {localStorage.getItem('orderName')} | RFQ #{rfdNumber} {linkType != 'fabric' && ' | Job Work'}</h4>

      <Row className="RFQ-Row">
        <Card className="RFQ-order-kate1">
          <div className="para-edit py-0">
            <div className="para-para">
              <p className="input-saved">1. Type of Fabric:<span className="bold-text text-capitalize">{capitalizeFirstLowercaseRest(type)}</span></p>


              {
                fabricstage != 'Processed' ?
                  <p className="inputs-saved">2. Fabric Stage:<span className="bold-text text-capitalize">{fabricstage==='YarnDyed'?'Yarn Dyed': capitalizeFirstLowercaseRest(fabricstage)}</span></p>

                  : <p className="inputs-saved">
                    {linkType == 'fabric' ? ' 2. Fabric Stage:' : '2. Job Work:'}
                    <span className="bold-text">
                      {linkType == 'fabric' && fabricstage} {getprocesstype(processType)}
                    </span>
                  </p>
              }
            </div>
            <img src={edit} className="edit-icon" onClick={handleBack2} />
          </div>

          <hr className="hr-order-kate" />
          <div className="para-edit">
            <p className="input-saved"> 3.{type.toLowerCase() == 'woven' ? ' Type of Weave' : type.toLowerCase() == 'knit' ? ' Fabric Structure' : ''}<span className="bold-text">{weave}</span></p>
            <img src={edit} className="edit-icon" onClick={handleBack} />
          </div>

          <hr className="hr-order-kate" />
          {/* <Col sm="10"> */}
          <Card body className="RFQ-order-weave-card">
            <p className="RFQ-Title">Fill out information</p>
            {linkType == 'job_work' && <p className="input-weave" ><i>Details of fabric being sent for job work</i> </p>}

            <Row form>
              <Label for="exampleZip" className="input-weave"> 4.{type.toLowerCase() == 'woven' ? ' Warp and Weft parameters' : type.toLowerCase() == 'knit' ? ' Wale and Course parameters' : ''}</Label>
              <div className="col-12 warp-weft-div px-0">
                {/* <Col md={2}> */}
                <Row>
                  <Col md={6} className="pr-0 pl-0">
                    <FormGroup className="mb-8">
                      <Label for="exampleZip" className="input-weave">(i){type.toLowerCase() == 'woven' ? ' Warp' : type.toLowerCase() == 'knit' ? ' Wale' : ''}</Label>
                      <div className="warp-div">
                        <Input type="text" name="warpCount"
                          value={addFabric.warpCount}
                          onChange={(e) => handleChange(e)}
                          // onKeyDown={(e) => fun(e)}
                          autoComplete="off"
                          style={{marginRight:'1.12vw'}}
                          id="exampleZip" placeholder="Count" />
                        {/* {addFabric.errors == true ?
                      <small className="form-text text-danger">
                        this field is required
                      </small>
                      : null} */}

                        <Label for="exampleZip" className="input-weave"></Label>
                        <Input type="select" name="warp"
                          onChange={(e) => handleChange(e)}
                          value={addFabric.warp}
                          placeholder="Type of Yarn" id="yarn-type">
                          <option value="" disabled selected hidden>Type of Yarn</option>
                          {yarn.blend.map((item) => (

                            <option value={item.name}>{item.name}</option>
                          ))
                          }
                          {yarn.yarn.map((item) => (

                            <option value={item.name}>{item.name}</option>
                          ))
                          }
                        </Input>
                        {/* {addFabric.errors == true ?
                      <small className="form-text text-danger">
                        this field is required
                      </small>
                      : null} */}
                      </div>
                    </FormGroup>


                    {/* </Col> */}

                    {/* <Col md={2}> */}
                  </Col>
                  <Col md={6} className="pr-0 pl-3">
                    <FormGroup className="mb-8">
                      <Label for="exampleZip" className="input-weave">(ii) {type.toLowerCase() == 'woven' ? ' Weft' : type.toLowerCase() == 'knit' ? ' Course' : ''}</Label>
                      <div className="warp-div">
                        <Input type="text" name="weftCount"
                          value={addFabric.weftCount}
                          // onKeyDown={(e) => fun(e)}
                          autoComplete="off"
                          style={{marginRight:'1.12vw'}}
                          onChange={(e) => handleChange(e)}
                          placeholder="Count" id="exampleZip" />
                        {/* {addFabric.errors == true ?
                      <small className="form-text text-danger">
                        this field is required
                      </small>
                      : null} */}

                        <Label for="exampleZip" className="input-weave"></Label>
                        <Input type="select" name="weft"
                          onChange={(e) => handleChange(e)}
                          value={addFabric.weft}
                          placeholder="Type of Yarn" id="yarn-type">
                          <option value="" disabled selected hidden>Type of Yarn</option>
                          {yarn.blend.map((item) => (

                            <option value={item.name}>{item.name}</option>
                          ))
                          }
                          {yarn.yarn.map((item) => (

                            <option value={item.name}>{item.name}</option>
                          ))
                          }

                        </Input>
                        {/* {addFabric.errors == true ?
                      <small className="form-text text-danger">
                        this field is required
                      </small>
                      : null} */}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              {/* </Col> */}
              <div className="warp-weft-div">
                <div className="ff">
                  {formDetails.submitted &&
                    formDetails.errors &&
                    formDetails.errors.warpCount && (
                      <small className="form-text text-danger">
                        {formDetails.errors.warpCount[0]}
                      </small>
                    )}

                </div>
                <div className="fff">
                  {formDetails.submitted &&
                    formDetails.errors &&
                    formDetails.errors.warp && (
                      <small className="form-text text-danger">
                        {formDetails.errors.warp[0]}
                      </small>
                    )}

                </div>
                <div className="ff">
                  {formDetails.submitted &&
                    formDetails.errors &&
                    formDetails.errors.weftCount && (
                      <small className="form-text text-danger">
                        {formDetails.errors.weftCount[0]}
                      </small>
                    )}

                </div>
                <div className="fff">
                  {formDetails.submitted &&
                    formDetails.errors &&
                    formDetails.errors.weft && (
                      <small className="form-text text-danger">
                        {formDetails.errors.weft[0]}
                      </small>
                    )}

                </div>

              </div>
              <div className="col-12 px-0">
                <Row>
                  {(warpSelected || weftSelected) && <Col md={6} className="pr-0 warp-width pl-0">
                    <Row>
                      <Col md={12}>
                        <label className='underline'>Yarn Sustainability</label>
                      </Col>
                    </Row>
                    {warpSelected && <Row>
                      {warpYarn.yarnType == 'Blend' ?
                        warpYarn.blendYarns.map(item => {
                          return (
                            <>
                              <Col md={6}>
                                <FormGroup style={{ position: 'relative' }}>
                                  {item.yarnType.sustainabilityTypes.length > 0 &&
                                    <>
                                      <Label for="exampleText" className="input-weave">{item.yarnName}</Label><br />

                                      <Input type="select" name="sustainability"
                                        onChange={(e) => addwarpsustainability(e, item)}
                                        className="sus-select"
                                        value={getsusValue(item, 'warp')}
                                        placeholder="Type of Yarn" id="yarn-type">
                                        <option disabled selected value={0}>Select </option>
                                        {item.yarnType.sustainabilityTypes.map((item) => (
                                          <option value={item.id}>{item.name}</option>
                                        ))
                                        }
                                      </Input>
                                    </>}
                                </FormGroup>
                              </Col>
                            </>
                          )
                        })
                        : <Col md={6}>
                          <FormGroup style={{ position: 'relative' }}>
                            <Label for="exampleText" className="input-weave">{warpYarn.name}</Label><br />
                            {warpYarn.sustainabilityTypes.length > 0 &&
                              <Input type="select" name="sustainability"
                                onChange={(e) => addwarpsustainability(e)}
                                value={getsusValue(warpYarn, 'warp')}
                                className="sus-select"
                                placeholder="Type of Yarn" id="yarn-type">
                                <option disabled selected value={0}>Select </option>

                                {warpYarn.sustainabilityTypes.map((item) => (

                                  <option value={item.id}>{item.name}</option>
                                ))
                                }
                              </Input>}
                          </FormGroup>
                        </Col>}

                    </Row>}
                  </Col>
                  }
                  {weftSelected && <Col md={6} className="pl-0 pr-0 warp-width">
                    <Row>
                      <Col md={12}>
                        <label></label>
                      </Col>
                    </Row>
                    <Row>
                      {weftYarn.yarnType == 'Blend' ?
                        weftYarn.blendYarns.map(item => {
                          return (
                            <>
                              <Col md={6}>
                                <FormGroup style={{ position: 'relative' }}>
                                  {item.yarnType.sustainabilityTypes.length > 0 &&
                                    <>
                                      <Label for="exampleText" className="input-weave">{item.yarnName}</Label><br />

                                      <Input type="select" name="sustainability"
                                        onChange={(e) => addweftsustainability(e, item)}
                                        value={getsusValue(item, 'weft')}
                                        className="sus-select"
                                        placeholder="Type of Yarn" id="yarn-type">
                                        <option disabled selected value={0}>Select </option>
                                        {item.yarnType.sustainabilityTypes.map((item) => (
                                          <option value={item.id}>{item.name}</option>
                                        ))
                                        }
                                      </Input>
                                    </>}
                                </FormGroup>
                              </Col>
                            </>
                          )
                        })
                        : <Col md={6}>
                          <FormGroup style={{ position: 'relative' }}>
                            <Label for="exampleText" className="input-weave">{weftYarn.name}</Label><br />
                            {weftYarn.sustainabilityTypes.length > 0 &&
                              <Input type="select" name="sustainability"
                                onChange={(e) => addweftsustainability(e)}
                                value={getsusValue(weftYarn, 'weft')}
                                className="sus-select"
                                placeholder="Type of Yarn" id="yarn-type">
                                <option disabled selected value={0}>Select </option>
                                {weftYarn.sustainabilityTypes.map((item) => (

                                  <option value={item.id}>{item.name}</option>
                                ))
                                }
                              </Input>}
                          </FormGroup>
                        </Col>}

                    </Row>
                  </Col>}
                </Row>
              </div>
              <div className="col-12 px-0">
                <Row>
                  <Col md={6} className="pr-0 warp-width pl-0">
                    <FormGroup style={{ position: 'relative' }}>
                      <Label for="exampleText" className="input-weave">Comments (Optional)</Label>

                      <Input type="textarea" name="warpComment"
                        value={addFabric.warpComment}
                        onChange={(e) => handleChange(e)}
                        placeholder="1000 characters max"
                        id="exampleOrderText" className="optional-comments mr-0" />
                    </FormGroup>
                  </Col>
                  <Col md={6} className="pl-0 pr-0 warp-width">
                    <FormGroup style={{ position: 'relative', float: 'right' }}>
                      <Label for="exampleText" className="input-weave mr-0 float-end">Comments (Optional)</Label>

                      <Input type="textarea" name="weftComment"
                        value={addFabric.weftComment}
                        onChange={(e) => handleChange(e)}
                        placeholder="1000 characters max"
                        id="exampleOrderText" className="orderWeave-textarea optional-comments"
                        style={{ width: "25.2vw" }} />

                    </FormGroup>
                  </Col>
                </Row>
              </div>
              {type.toLowerCase() == 'woven' ?
                <div className="reed-pick-div">
                  <Label for="exampleZip" className="input-weave"> 5. Fabric Construction</Label>

                  <FormGroup>

                    <div className="reed-head">
                      <Label for="exampleZip" className="Reed">Reed</Label>
                      <Label for="exampleZip" className="input-weave">Pick</Label>
                    </div>
                    <div className="reed mb-8">
                      <Input type="text" name="fabricReed"
                        value={addFabric.fabricReed > 0 ? addFabric.fabricReed : null}
                        onChange={(e) => handleChange(e)}
                        onKeyDown={(e) => fun(e)}
                        onPaste={(e) => e.preventDefault()}
                        maxLength="4"
                        autoComplete="off"
                        placeholder="" id="exampleZip" className="mr-0" />

                      {/* {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.fabricReed && (
                        <small className="form-text text-danger">
                          mandatory field must be filled to proceed
                        </small>
                      )} */}

                      {/* </Col> */}
                      <p className="X">X</p>
                      {/* <Col md={2}> */}

                      <Input type="text" name="fabricPick"
                        value={addFabric.fabricPick > 0 ? addFabric.fabricPick : null}
                        onChange={(e) => handleChange(e)}
                        onKeyDown={(e) => fun(e)}
                        onPaste={(e) => e.preventDefault()}
                        maxLength="4"
                        autoComplete="off"
                        placeholder=" " id="exampleZip" />
                      {/* {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.fabricPick && (
                        <small className="form-text text-danger">
                          mandatory field must be filled to proceed
                        </small>
                      )} */}

                    </div>
                    <div className="warp-weft-div">
                      <div className="ff">
                        {formDetails.submitted &&
                          formDetails.errors &&
                          formDetails.errors.fabricReed && (
                            <small className="form-text text-danger">
                              {formDetails.errors.fabricReed[0]}
                            </small>
                          )}

                      </div>
                      <div className="ff">
                        {formDetails.submitted &&
                          formDetails.errors &&
                          formDetails.errors.fabricPick && (
                            <small className="form-text text-danger">
                              {formDetails.errors.fabricPick[0]}
                            </small>
                          )}

                      </div>
                    </div>
                  </FormGroup>
                  {/* </Col> */}
                </div> : ''}

            </Row>
            {type.toLowerCase() == 'woven' ?
              <FormGroup >
                <Label for="exampleText" className="input-weave">Comments (Optional)</Label>
                <div style={{ position: 'relative' }}>

                  <Input type="textarea" name="fabricConstructionComment"
                    value={addFabric.fabricConstructionComment}
                    onChange={(e) => handleChange(e)}
                    placeholder="1000 characters max"
                    id="exampleOrderText" className="optional-comments" />
                </div>
              </FormGroup>
              : ''}
            {/* <Col md={2}> */}
            <FormGroup>
              <Label for="exampleZip" className="input-weave">{type.toLowerCase() == 'woven' ? '6.' : '5.'} GSM</Label>
              <Input type="text" name="gsmValue"
                value={addFabric.gsmValue > 0 ? addFabric.gsmValue : null}
                onKeyDown={(e) => fun(e)}
                onPaste={(e) => e.preventDefault()}
                autoComplete="off"
                onChange={(e) => handleChange(e)}
                maxLength="4"
                placeholder="" id="exampleZip" />
              {formDetails.submitted &&
                formDetails.errors &&
                formDetails.errors.gsmValue && (
                  <small className="form-text text-danger">
                    {formDetails.errors.gsmValue[0]}
                  </small>
                )}

            </FormGroup>
            {/* </Col> */}
            {/* <Col md={3}> */}
            {fabricstage != 'Processed' ?
              <FormGroup>

                <Label for="exampleZip" className="input-weave">{type.toLowerCase() == 'woven' ? '7.' : '6.'} {linkType === 'job_work'?'Fabric':'Preferred fabric'} width {type.toLowerCase() == 'woven' ? '(L-100)' : '(open width)'}</Label>
                <div className="d-flex">
                  <Input type="text" name="greigeWidth"
                    value={addFabric.greigeWidth > 0 ? addFabric.greigeWidth : null}
                    onChange={handleChange}
                    onKeyDown={(e) => funs(e)}
                    onPaste={(e) => e.preventDefault()}
                    maxLength="4"
                    autoComplete="off"
                    placeholder="" id="exampleZip" className="mr-0" />


                  <p className="X">inches</p>
                </div>
                {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.greigeWidth && (
                    <small className="form-text text-danger">
                      {formDetails.errors.greigeWidth[0]}
                    </small>
                  )}
              </FormGroup>
              : null}

            {processType == 'PRINT' ?
              <FormGroup>

                <Label for="exampleZip" className="input-weave">{type.toLowerCase() == 'woven' ? '7.' : '6.'} {linkType === 'job_work'?'Fabric':'Preferred fabric'} width {type.toLowerCase() == 'woven' ? '(L-100)' : '(open width)'}</Label>
                
                  {/* <p className="X">(i)Print</p> */}
                  {linkType === 'job_work' ?
                    <>
                    <FormGroup>
                      <p className="input-weave">(i) Greige Fabric Width</p>
                      <div className="d-flex">
                      <Input type="text" name="printRawWidth"
                        value={addFabric.printRawWidth > 0 ? addFabric.printRawWidth : null}
                        onChange={handleChange}
                        onKeyDown={(e) => funs(e)}
                        onPaste={(e) => e.preventDefault()}

                        maxLength="4"
                        autoComplete="off"
                        placeholder="" id="exampleZip" className="mr-0" />
                      <p className="X">inches</p>
                      </div>
                      </FormGroup>
                      <FormGroup>
                      <p className="input-weave">(ii)Preferred Finished Fabric Width:</p>
                      <div className="d-flex">
                      <Input type="text" name="printWidth"
                        value={addFabric.printWidth > 0 ? addFabric.printWidth : null}
                        onChange={handleChange}
                        onKeyDown={(e) => funs(e)}
                        onPaste={(e) => e.preventDefault()}

                        maxLength="4"
                        autoComplete="off"
                        placeholder="" id="exampleZip" className="mr-0" />
                      <p className="X">inches</p>
                      </div>
                      </FormGroup>
                    </> :
                      <div className="d-flex">
                      <Input type="text" name="printWidth"
                        value={addFabric.printWidth > 0 ? addFabric.printWidth : null}
                        onKeyDown={(e) => funs(e)}
                        onPaste={(e) => e.preventDefault()}
                        maxLength="4"
                        autoComplete="off"
                        onChange={handleChange}
                        placeholder="" id="exampleZip" className="mr-0" />


                      <p className="X">inches</p>
                      </div>
                  }

               
                {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.printWidth && (
                    <small className="form-text text-danger">
                      {formDetails.errors.printWidth[0]}
                    </small>
                  )}
              </FormGroup>
              : null}
            {processType == 'DYED' ?
              <FormGroup>

                <Label for="exampleZip" className="input-weave">{type.toLowerCase() == 'woven' ? '7.' : '6.'} {linkType === 'job_work'?'Fabric':'Preferred fabric'}  width {type.toLowerCase() == 'woven' ? '(L-100)' : '(open width)'}</Label>
               
                  {/* <p className="X">(a) Dyed</p> */}
                  {linkType === 'job_work' ?
                   <>
                   <FormGroup>
                     <p className="input-weave">(i) Greige Fabric Width</p>
                     <div className="d-flex">
                     <Input type="text" name="dyedRawWidth"
                       value={addFabric.dyedRawWidth > 0 ? addFabric.dyedRawWidth : null}
                       onChange={handleChange}
                       onKeyDown={(e) => funs(e)}
                       onPaste={(e) => e.preventDefault()}

                       maxLength="4"
                       autoComplete="off"
                       placeholder="" id="exampleZip" className="mr-0" />
                     <p className="X">inches</p>
                     </div>
                     </FormGroup>
                     <FormGroup>
                     <p className="input-weave">(ii)Preferred Finished Fabric Width:</p>
                     <div className="d-flex">
                     <Input type="text" name="dyedWidth"
                       value={addFabric.dyedWidth > 0 ? addFabric.dyedWidth : null}
                       onChange={handleChange}
                       onKeyDown={(e) => funs(e)}
                       onPaste={(e) => e.preventDefault()}

                       maxLength="4"
                       autoComplete="off"
                       placeholder="" id="exampleZip" className="mr-0" />
                     <p className="X">inches</p>
                     </div>
                     </FormGroup>
                   </> :
                     <div className="d-flex">
                      <Input type="text" name="dyedWidth"
                        value={addFabric.dyedWidth > 0 ? addFabric.dyedWidth : null}
                        onChange={handleChange}
                        onKeyDown={(e) => funs(e)}
                        onPaste={(e) => e.preventDefault()}

                        maxLength="4"
                        autoComplete="off"
                        placeholder="" id="exampleZip" className="mr-0" />
                      <p className="X">inches</p>
                    </div>
                  }

               
                {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.dyedWidth && (
                    <small className="form-text text-danger">
                      {formDetails.errors.dyedWidth[0]}
                    </small>
                  )}
              </FormGroup>
              : null}
            {/* </Col> */}

            {processType == 'Print & Dyed' ?
              <FormGroup>

                <Label for="exampleZip" className="input-weave">{type.toLowerCase() == 'woven' ? '7.' : '6.'} {linkType === 'job_work'?'Fabric':'Preferred fabric'} width {type.toLowerCase() == 'woven' ? '(L-100)' : '(open width)'}</Label>
                <div className="order11 d-flex">
                  <div >
                    {linkType !== 'job_work' &&
                      <div className="d-flex">
                        <p className="X col-828282">(a) Print</p>
                        <Input type="text" name="printWidth"
                          value={addFabric.printWidth > 0 ? addFabric.printWidth : null}
                          onChange={handleChange}
                          onKeyDown={(e) => funs(e)}
                          onPaste={(e) => e.preventDefault()}
                          maxLength="4"
                          autoComplete="off"
                          placeholder="" id="exampleZip" className="mr-0" />
                        {/* {formDetails.submitted &&
 formDetails.errors &&
 formDetails.errors.dyedWidth && (
   <small className="form-text text-danger">
     mandatory field must be filled to proceed
   </small>
 )} */}

                        <p className="X">inches</p>
                      </div>
                    }

                    {linkType == 'job_work' &&
                      <>
                        <FormGroup>
                          <p className="input-weave">(i) Greige Fabric Width</p>
                          <div className="d-flex">
                          <Input type="text" name="printRawWidth"
                            value={addFabric.printRawWidth > 0 ? addFabric.printRawWidth : null}
                            onKeyDown={(e) => funs(e)}
                            onPaste={(e) => e.preventDefault()}
                            maxLength="4"
                            autoComplete="off"
                            onChange={handleChange}
                            placeholder="" id="exampleZip" className="mr-0" />
                          <span className="X"> inches</span>
                          </div>
                          </FormGroup>
                          <FormGroup>
                          <p className="input-weave">(ii) Preferred Finished Fabric Width</p><br />

                            <p className="input-weave ml-3">(a) Print</p>
                            <div className="d-flex mt-1  ml-3">
                            <Input type="text" name="printWidth"
                              value={addFabric.printWidth > 0 ? addFabric.printWidth : null}
                              onKeyDown={(e) => funs(e)}
                              onPaste={(e) => e.preventDefault()}
                              maxLength="4"
                              autoComplete="off"
                              onChange={handleChange}
                              placeholder="" id="exampleZip" className="mr-0" />
                            <p className="X"> inches</p>
                          </div>
                          </FormGroup>
                          <FormGroup>
                            <p className="input-weave ml-3">(a) Dyed</p>
                            <div className="d-flex mt-1  ml-3">
                            <Input type="text" name="dyedWidth"
                              value={addFabric.dyedWidth > 0 ? addFabric.dyedWidth : null}
                              onKeyDown={(e) => funs(e)}
                              onPaste={(e) => e.preventDefault()}
                              maxLength="4"
                              autoComplete="off"
                              onChange={handleChange}
                              placeholder="" id="exampleZip" className="mr-0" />
                            <p className="X"> inches</p>

                          </div>
                          </FormGroup>
                      
                      </>
                    }

                    {/* {formDetails.submitted &&
  formDetails.errors &&
  formDetails.errors.printWidth && (
    <small className="form-text text-danger">
      mandatory field must be filled to proceed
    </small>
  )} */}


                  </div>
                  <div>
                    {linkType !== 'job_work' &&
                      <div className="d-flex">
                        <p className="X col-828282 ml-59">(b) Dyed</p>
                        <Input type="text" name="dyedWidth"
                          value={addFabric.dyedWidth > 0 ? addFabric.dyedWidth : null}
                          onChange={handleChange}
                          onKeyDown={(e) => funs(e)}
                          onPaste={(e) => e.preventDefault()}
                          maxLength="4"
                          autoComplete="off"
                          placeholder="" id="exampleZip" className="mr-0" />
                        {/* {formDetails.submitted &&
  formDetails.errors &&
  formDetails.errors.dyedWidth && (
    <small className="form-text text-danger">
      mandatory field must be filled to proceed
    </small>
  )} */}

                        <p className="X">inches</p>
                      </div>
                    }
                    {/* {linkType == 'job_work' &&
                    <>
                      <p className="X ml-0 col-828282">(a) Dyed</p>
                     
                      <div className="d-flex mt-1">
                        <p className="X ml-0 col-828282">(ii) Finished Width</p>
                        <Input type="text" name="dyedWidth"
                          value={addFabric.dyedWidth > 0 ? addFabric.dyedWidth : null}
                          onKeyDown={(e) => funs(e)}
                          onPaste={(e) => e.preventDefault()}
                          maxLength="4"
                          autoComplete="off"
                          onChange={handleChange}
                          placeholder="" id="exampleZip" className="mr-0" />
                        <p className="X"> inches</p>
                      </div>
                    </>
                  } */}
                  </div>

                </div>
                <div className="width-div">
                  <div className="fff2">
                    {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.printWidth && (
                        <small className="form-text text-danger">
                          {formDetails.errors.printWidth[0]}
                        </small>
                      )}

                  </div>
                  <div className="ffff">
                    {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.dyedWidth && (
                        <small className="form-text text-danger">
                          {formDetails.errors.dyedWidth[0]}
                        </small>
                      )}

                  </div>
                </div>
              </FormGroup>
              : null}
            {linkType == 'job_work' && <FormGroup>
              <Label for="exampleZip" className="input-weave">{type.toLowerCase() == 'woven' ? '8.' : '7.'}  Fabric dispatch dates to jobwork vendor</Label>
              <div className="input-p">
                <div style={{ position: "relative", width: "125px" }}>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date(Date.now() + 86400000)}
                    placeholderText="Start"
                    selected={
                      addFabric.jobWorkStartDate
                        ? moment(addFabric.jobWorkStartDate)._d
                        : null
                    }
                    onChange={(date) => {
                      //setDateState(date);
                      handleDateChange(date, "jobWorkStartDate");
                    }}
                    onChangeRaw={(e) => e.preventDefault()}
                    id="printFromDate"
                    className="form-control"
                  // showYearDropdown
                  // scrollableYearDropdown
                  // excludeDates={[new Date(), subDays(new Date(), 3)]}
                  />

                  <label
                    htmlFor="printFromDate"
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: "10px",
                    }}
                  >
                    <svg
                      width="1.6vh"
                      height="1.8vh"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="place-inside"
                    >
                      <path
                        _ngcontent-xls-c248=""
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13 2.33341H13.8333C14.75 2.33341 15.5 3.08341 15.5 4.00008V15.6667C15.5 16.5834 14.75 17.3334 13.8333 17.3334H2.16667C1.24167 17.3334 0.5 16.5834 0.5 15.6667L0.508333 4.00008C0.508333 3.08341 1.24167 2.33341 2.16667 2.33341H3V0.666748H3.83333V2.33341H12.1667V0.666748H13V2.33341ZM1.33333 16.5001H14.6667V6.50008H1.33333V16.5001ZM14.6667 5.66675H1.33333V3.16675H14.6667V5.66675ZM8 9.83342H13V14.8334H8V9.83342Z"
                        fill="#2F80ED"
                      ></path>
                    </svg>
                  </label>
                </div>
                {/* <Input
                        type="text"
                        name="printFromDate"
                        onChange={(e) => handleChange(e)}
                        onFocus={e => e.currentTarget.type = "date"}
                        onBlur={e => e.currentTarget.type = "text"}
                        value={rfqDetails.printFromDate}
                        placeholder="joun"
                        id="date"
                        className="form-control"
                        min={disablePastDate()}

                      /> */}
                <p className="X">to</p>
                <div style={{ position: "relative", width: "125px" }}>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date(Date.now() + 86400000)}
                    placeholderText="End"
                    selected={
                      addFabric.jobWorkEndDate
                        ? moment(addFabric.jobWorkEndDate)._d
                        : null
                    }
                    onChange={(date) => {
                      // setDateState(date);
                      handleDateChange(date, "jobWorkEndDate");
                    }}
                    onChangeRaw={(e) => e.preventDefault()}
                    id="printToDate"
                    className="form-control"
                  />

                  <label
                    htmlFor="printToDate"
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: "10px",
                    }}
                  >
                    <svg
                      width="1.6vh"
                      height="1.8vh"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="place-inside"
                    >
                      <path
                        _ngcontent-xls-c248=""
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13 2.33341H13.8333C14.75 2.33341 15.5 3.08341 15.5 4.00008V15.6667C15.5 16.5834 14.75 17.3334 13.8333 17.3334H2.16667C1.24167 17.3334 0.5 16.5834 0.5 15.6667L0.508333 4.00008C0.508333 3.08341 1.24167 2.33341 2.16667 2.33341H3V0.666748H3.83333V2.33341H12.1667V0.666748H13V2.33341ZM1.33333 16.5001H14.6667V6.50008H1.33333V16.5001ZM14.6667 5.66675H1.33333V3.16675H14.6667V5.66675ZM8 9.83342H13V14.8334H8V9.83342Z"
                        fill="#2F80ED"
                      ></path>
                    </svg>
                  </label>
                </div>
              </div>

            </FormGroup>}
            {/* {console.log("det", formDetails.errors)} */}
            {/* {console.log("test", !(formDetails.errors && Object.keys(formDetails.errors).length === 0))} */}
            <div className="RFQButtons mt-16">
              <Button className="RFQ-Submit" disabled={formDetails.errors} onClick={submitHandler}>Submit</Button>
              <Button className="RFQ-Back" onClick={handleBack}>Back</Button>
            </div>

            {/* </Col> */}
            <NotificationContainer />
          </Card>
        </Card>
      </Row>
      {isLoading ? <><div className="cdf"> <div className='loader' /></div> </> : null}

    </>
  );
};

export default OrderKateweave;