import React, { useEffect, useState } from 'react';
import ProfileFianl from '../../../../assets/profile.png'
import './Preview.css';
import '../CompanyProfile.css'

import { Container, Row, Col } from 'react-bootstrap';
import { GetGalleryApi } from '../../../../services';

const GalleryPre = () => {
  const [galleries, setGalleries] = useState([])


  const getGalleryDetails = () => {
    // console.log(companyId,'/////////////////')
    GetGalleryApi().then((res) => {
      if (res.isSuccess) {
        setGalleries(res.data)
      }
    })
  }
  useEffect(() => {
    getGalleryDetails();
  }, [])

  return (

    <>

      <Container className="GalleryPreview">
        <Row className="GalleryPreviewRow">
          <Col className="p-0 gallery-sec">    {galleries.length ? galleries?.map((item) => (
            <img className="gallery-section" src={item.galleryImageUrl} />
          )) : <p className="galle text-center">No item in gallery</p>}
          </Col>

        </Row>
      </Container>



    </>

  );
};

export default GalleryPre;