import React, { useState } from "react";
import "./NewreqForm.css";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import NewReqForm from "./NewReqForm";
import CompanyPreview from "../../../../adminSettings/companyProfile/Preview/CompanyPreview";
export const RequestModal = (props) => {
  const { className } = props;

  const [modal1, setModal1] = useState(true);

  const toggle1 = () => setModal1(!modal1);

  const closeBtn = (
    <button className="close" onClick={props.handleClick}>
      &times;
    </button>
  );

  return (
    <div className="newuserreqadmin">
      <Modal
      modalClassName='newUserModaladmin'
        isOpen={modal1}
        toggle={toggle1}
        className={className}
        backdrop={false}
      >
        <ModalHeader
            className="Modal-Newreq"
            toggle={toggle1}
            close={closeBtn}
          />
        <ModalBody>
          <NewReqForm
            tableData={{ data: props.tableData.data, id: props.tableData.id }}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

// export default RequestModal;
export const PreviewProfile = (props) => {
  // console.log(props.tableData.data, '/////////////////////////');
  const { className } = props;

  const [modal1, setModal1] = useState(true);

  const toggle1 = () => setModal1(!modal1);

  const closeBtn = (
    <button className="close" onClick={props.handleClick}>
      &times;
    </button>
  );

  return (
    <div className="companypreview">
      <Modal
        isOpen={modal1}
        toggle={toggle1}
        className={className}
        id="companypreview"
        backdrop={false}
      >
        {/* <ModalBody> */}
        {/* <ModalHeader className="Modal-Newreq" toggle={toggle1} close={closeBtn}/> */}
        <button className="close-modal" onClick={props.handleClick}>
          &times;
        </button>
        <CompanyPreview />
        {/* </ModalBody> */}
      </Modal>
    </div>
  );
};

// export default RequestModal;
