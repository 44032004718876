import { apiService } from './api.service';
import { fabricType } from '../models/enum';

// YARN api's
export const AddYarnApi = (data) => {
    return apiService.postRequest(`metadata/addYarn`, data).then((data) => data.data)
}
export const AddBlendApi = (data) => {
    console.log(data)
    return apiService.postRequest(`metadata/addBlend`, data).then((data) => data.data)
}

export const ViewYarnApi = () => {
    return apiService.getRequest(`metadata/viewYarn`).then((data) => data.data);
}
export const getAllYarn = () => {
    return apiService.getRequest(`metadata/viewAllYarn`).then((data) => data.data);
}
export const ViewBlendApi = () => {
    return apiService.getRequest(`metadata/viewBlend`).then((data) => data.data);
}

export const deleteYarnApi = (id) => {
    return apiService.deleteRequest(`metadata/deleteYarn?id=${id}`).then((data) => data.data)
}
export const deleteBlendApi = (id) => {
    return apiService.deleteRequest(`metadata/deleteBlend?id=${id}`).then((data) => data.data)
}
export const deleteSustainabilityApi = (id) => {
    return apiService.deleteRequest(`metadata/deleteSustanbility?id=${id}`).then((data) => data.data)
}
export const addSustainability=(data)=>{
    return apiService.postRequest(`metadata/addSustainabilityType`, data).then((data) => data.data)

}

//FABRIC API

export const AddFabricApi = (data) => {
    return apiService.postRequest(`metadata/addFabricStage`, data).then((data) => data.data)
}

export const ViewFabricApi = (type) => {
    return apiService.getRequest(`metadata/fabricType/fabricStage?fabricType=${type}`).then((data) => data.data);
}

export const deleteFabricApi = (typeid, id) => {
    // let type = 0;
    // if (typeid == fabricType.KNIT) {
    //     type = 2
    // }
    // else {
    //     type = 1
    // }
    return apiService.deleteRequest(`metadata/fabricType/fabricStage?fabricTypeId=${typeid}&fabricStageId=${id}`)
        .then((data) => data.data)
}

//ADD PRODUCT API

export const AddProductApi = (body) => {
    return apiService.postRequest(`product`, body)
        .then((data) => data.data);
}

export const GetProductApi = (id) => {
    // console.log(id,'id')
    return apiService.getRequest(`product?companyId=${id}`)
        .then((data) => data.data);
}


export const deleteProductApi = (id) => {
    console.log(id, 'id')
    return apiService.deleteRequest(`product?productId=${id}`)
        .then((data) => data.data)
}

export const GetFabricApi = () => {
    // console.log(id,'id')
    return apiService.getRequest(`metadata/fabricType`)
        .then((data) => data.data);
}

export const GetStagesApi = (data) => {
    // console.log(id,'id')
    return apiService.getRequest(`metadata/fabricType/fabricStage?fabricType=${data}`)
        .then((data) => data.data);
}

//PRODUCT CATALOGUE API

export const AddProductCatalogueApi = (body) => {
    return apiService.postRequest(`product/catalogue`, body)
        .then((data) => data.data);
}

export const GetProductCatalogueApi = (id) => {
    // console.log(id,'id')
    return apiService.getRequest(`product/catalogue?companyId=${id}`)
        .then((data) => data.data);
}
export const deleteProductCatalogueApi = (id) => {

    return apiService.deleteRequest(`product/catalogue?productCatalogueId=${id}`)
        .then((data) => data.data)
}

//USER AND TEAMS API

export const AddUserApi = (body) => {
    return apiService.postRequest(`team/user`, body)
        .then((data) => data.data);
}
export const GetUsersApi = (id) => {
    // console.log(id,'id')
    return apiService.getRequest(`team/user`)
        .then((data) => data.data);
}
export const AddTeamApi = (body) => {
    return apiService.postRequest(`team`, body)
        .then((data) => data.data);
}

export const GetTeamApi = (id) => {
    // console.log(id,'id')
    return apiService.getRequest(`team/users`)
        .then((data) => data.data);
}

//UNASSIGNED USERS API

export const GetUnassignedUsersApi = (id) => {
    // console.log(id,'id')
    return apiService.getRequest(`team/user/unassigned`)
        .then((data) => data.data);
}

//RFQ APIS
export const AddOrderApi = (body) => {
    return apiService.postRequest(`rfq/order`, body)
        .then((data) => data.data);
}

export const TypeFabricApi = (body) => {
    return apiService.postRequest(`rfq/fabric/type`, body)
        .then((data) => data.data);
}
export const AddRfqWeaveApi = (body) => {
    return apiService.postRequest(`rfq/weave`, body)
        .then((data) => data.data);
}

///////////////RFQ get/////////////////

export const GetRFQApi = (data) => {
    if(data.id == "undefined"||data.id==null||data.id=='null'){return Promise.reject(null); }
        return apiService.getRequest(`rfq?id=${data.id}&rfqStage=${data.rfqStage}`)
        .then((data) => data.data);
}


export const deleteRfqApi = (id) => {

    return apiService.deleteRequest(`rfq?id=${id}`)
        .then((data) => data.data)
}
export const deleteRfqCustomPrintApi = (id) => {

    return apiService.deleteRequest(`rfq/fabric/customization/print?printId=${id}`)
        .then((data) => data.data)
}
export const deleteRfqCustomDyedApi = (id) => {

    return apiService.deleteRequest(`rfq/fabric/customization/dye?dyeId=${id}`)
        .then((data) => data.data)
}

///////////////////////////////////////
export const AddrfqFabricApi = (body) => {
    return apiService.postRequest(`rfq/fabric`, body)
        .then((data) => data.data);
}

export const AddrfqDetailsApi = (body) => {
    return apiService.putRequest(`rfq/order/details`, body)
        .then((data) => data.data);
}
export const RfqSubmitApi = (id, body) => {
    return apiService.putRequest(`rfq/submit?rfqId=${id}`, body)
        .then((data) => data.data);
}
export const AddFabricCustomizationApi = (body) => {
    return apiService.postRequest(`rfq/fabric/customization`, body)
        .then((data) =>{ 
            console.log(data.data);
           return data.data});
}

export const GetSellerRFQApi = (data) => {
    // console.log(id,'id')
    return apiService.getRequest(`seller/rfq-list?pageNo=${data.pno}&size=10&rfqType=${data.reqType}`)
        .then((data) => data.data);
}
export const GetSavedRFQApi = (pno) => {
    // console.log(id,'id')
    return apiService.getRequest(`rfq/list?pageNo=${pno}&size=60`)
        .then((data) => data.data);
}
export const checkDuplicateRfq = (ordName) => {
    // console.log(id,'id')
    return apiService.putRequest(`rfq/check/duplicate/order-name`,ordName)
        .then((data) => data.data);
}
//OPEN ENQ

export const GetOpenEnquiries = (id, pno) => {
    // console.log(id,'id')
    return apiService.getRequest(`rfq/enquiries?companyId=${id}&limit=10&offset=${pno}`)
        .then((data) => data.data);
}
export const GetOpenEnquiry = (id) => {
    // console.log(id,'id')
    return apiService.getRequest(`rfq/enquiry?rfqId=${id}`)
        .then((data) => data.data);
}
export const GetResponse = (id) => {
    // console.log(id,'id')
    return apiService.getRequest(`seller/rfq/order/${id}`)
        .then((data) => data.data);
}

export const AddSellerResponseApi = (body) => {
    return apiService.postRequest(`rfq/order/response`, body)
        .then((data) => data.data);
}

//// user-controll

export const changeCommissionApi = (body) => {
    return apiService.putRequest(`user/commission?id=${body.id}&commission=${body.commission}`, body)
        .then((data) => data.data);
}

export const changePaymentApi = (body) => {
    return apiService.putRequest(`user/payment-cycle?id=${body.id}&paymentCycle=${body.paymentCycle}`, body)
        .then((data) => data.data);
}

export const submitCompanyProfileApi = (body) => {
    return apiService.putRequest(`company/summary`, body)
        .then((data) => data.data);
}



//linked order api

export const GetLinkedOrder = (id) => {
    // console.log(id,'id')
    return apiService.getRequest(`rfq/order-names`)
        .then((data) => data.data);
}

// romanize
export const toRoman = (num) => {
    if (typeof num !== 'number') 
    return false; 
    const digits = String(+num).split("");
    const key = ["","c","cc","ccc","cd","d","dc","dcc","dccc","cm",
    "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
    "","i","ii","iii","iv","v","vi","vii","viii","ix"];
    let roman_num = "";
    let i = 3;
    while (i--){
        roman_num = (key[+digits.pop() + (i * 10)] || "") + roman_num;
    }
    return Array(+digits.join("") + 1).join("M") + roman_num;
}

// notifications 
export const updateNotificationById = (body) => {
    return apiService.putRequest(`notifications/${body.id}?${body.seen ? "seen=true": "deleted=true"}`)
        .then((data) => data.data);
}
export const getlateststageofrfq = (body) => {
    return apiService.getRequest(`rfq/latest-stage/${body}`)
        .then((data) => data.data);
}

