import React, { useEffect, useState } from "react";
import { Header } from "../../../SideBar/Header/Header";
import { Profile } from "../../../SideBar/Profile/profile";
import "./New Request/NewRequest.css";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Badge,
} from "reactstrap";
import classnames from "classnames";
import { NewUserAcceptRejectApi, NewUserApi } from "../../../../services";
import { Button, Spinner, Table } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { RequestModal, Fun } from "./New Request/NewreqModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { status } from "../../../../models/enum";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import alert from "../../../../assets/alert.jpg";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { getUserInfoAction } from '../../../../actions/user.actions'

const NewUserRequest = (props) => {
  const dispatch=useDispatch();
  const user = useSelector((state) => state.userReducer);

  let token = localStorage.getItem("token");
  const [activeTab, setActiveTab] = useState("1");
  const [headerRoute, setHeaderRoute] = useState(null);
  const [rejectData, setrejectData] = useState(false);
  const [counter, setCounter] = useState(0);
  const [rejectCounter, setRejectCounter] = useState(0);
  const history = useHistory();
  const [selectedId, setSelectedid] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [pageNumber, setpageNumber] = useState(0);
  const [tdata, setTdata] = useState([]);

  const [selectedId1, setSelectedid1] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);

  const [pageNumber1, setpageNumber1] = useState(0);

  const [tdata1, setTdata1] = useState([]);

  /// for getting new Request

  const getNewRequestDate = () => {
    setIsLoading(true);
    NewUserApi(0, "NEW").then((res) => {
      setIsLoading(false);

      setCounter(res.data.total);
      setTdata(res.data.users);
    });
  };
  const handleClick = (id) => {
    //
    if (id) {
      setSelectedid(id);
    }

    if (modalShow) {
      setModalShow(false);
    } else {
      setModalShow(true);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    localStorage.setItem("activeTab", tab);

    if (tab === "2") {
      setpageNumber1(1);

      setIsLoading(true);
      NewUserApi(0, status.REJECTED).then((res) => {
        setRejectCounter(res.data.total);
        setTdata1(res.data.users);
        // setpageNumber1(pageNumber1 + 1)
        setIsLoading(false);
      });
    } else {
      setpageNumber(1);
      getNewRequestDate();
    }
  };

  useEffect(() => {
    if (token && Cookies.get("token")) {
      // if (localStorage.getItem('activeTab') == '1') {
      //     setActiveTab('1')
      //     setpageNumber(0);

      //     NewUserApi(pageNumber, status.NEW)
      //         .then((res) => {
      //             setCounter(res.data.total);
      //             setTdata([...tdata, ...res.data.users]);
      //             setIsLoading(false)

      //             // setpageNumber((previous) => previous + 1);
      //             setpageNumber(pageNumber + 1)
      //         })
      // } else

      if (localStorage.getItem("activeTab") == "2") {
        setActiveTab("2");

        setpageNumber1(0);
        NewUserApi(pageNumber1, status.REJECTED).then((res) => {
          setRejectCounter(res.data.total);
          setTdata1([...tdata1, ...res.data.users]);
          setpageNumber1(pageNumber1 + 1);
          setIsLoading(false);
        });
      } else {
        setActiveTab("1");
        setpageNumber(0);

        NewUserApi(pageNumber, status.NEW).then((res) => {
          setCounter(res.data.total);
          setTdata([...tdata, ...res.data.users]);
          setIsLoading(false);

          // setpageNumber((previous) => previous + 1);
          setpageNumber(pageNumber + 1);
        });
        // if (activeTab === '1') {
        //     setpageNumber(0);

        //     fetchData();
        // } if (activeTab === '2') {

        //     setpageNumber1(0);
        //     fetchData1();
        // }
      }
    } else {
      history.push(`/`);
    }
    let userId=localStorage.getItem('id');
   dispatch(getUserInfoAction(userId)).then(user=>{
    
   });
  }, []);

  const fetchData = () => {
    if (activeTab === "1") {
      setIsLoading(true);
      NewUserApi(pageNumber, status.NEW).then((res) => {
        setCounter(res.data.total);
        setTdata([...tdata, ...res.data.users]);
        setIsLoading(false);

        // setpageNumber((previous) => previous + 1);
        setpageNumber(pageNumber + 1);
      });
    }
  };

  const handleAccept = (id) => {
    setIsLoading(true);

    let aceptBody = {
      acceptReject: status.ACCEPTED,
      id: id,
    };
    NewUserAcceptRejectApi(aceptBody).then((data) => {
      if (data.isSuccess) {
        NewUserApi(0, status.NEW)
          .then((res) => {
            setCounter(res.data.total);
            setTdata(res.data.users);
            // setpageNumber((previous) => previous + 1);
            setpageNumber(0);
            setIsLoading(false);
          })
          .catch((err) => console.log(err));
      }
    });
  };
  const handleReject = (id) => {
    setIsLoading(true);

    let rejectBody = {
      acceptReject: status.REJECTED,
      id: id,
    };
    NewUserAcceptRejectApi(rejectBody).then((data) => {
      //
      if (data.isSuccess) {
        setpageNumber();
        NewUserApi(0, status.NEW)
          .then((res) => {
            setCounter(res.data.total);
            setTdata(res.data.users);
            setIsLoading(false);
            window.location.reload();

            // setpageNumber((previous) => previous + 1);
            setpageNumber(0);
          })
          .catch((err) => console.log(err));
      }
    });
  };

  //////////////////// for getting rejected Request

  const handleClick1 = (id) => {
    if (id) {
      setSelectedid1(id);
    }
    if (modalShow1) {
      setModalShow1(false);
    } else {
      setModalShow1(true);
    }
  };
  const fetchData1 = () => {
    if (activeTab === "2") {
      NewUserApi(pageNumber1, status.REJECTED).then((res) => {
        setRejectCounter(res.data.total);
        setTdata1([...tdata1, ...res.data.users]);
        setpageNumber1(pageNumber1 + 1);
        setIsLoading(false);
      });
    }
  };

  const handleRestore = (id) => {
    setIsLoading(true);

    let aceptBody = {
      acceptReject: status.NEW,
      id: id,
    };

    NewUserAcceptRejectApi(aceptBody).then((data) => {
      if (data.isSuccess) {
        setIsLoading(true);
        NewUserApi(0, status.REJECTED).then((res) => {
          setRejectCounter(res.data.total);
          setTdata1(res.data.users);
          // setpageNumber1(pageNumber1 + 1)
          setpageNumber1(1);

          setIsLoading(false);
        });
      }
    });
  };

  return (
    <>
      <Header routeesss={setHeaderRoute} />
      <Row className="m-0">
        <Col sm="2" className="sidenav p-0">
          <Profile activateRoute={headerRoute} />
        </Col>
        <Col sm="10" className="rightcontent">
          <Nav tabs className="admin-tab greybox">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => toggle("1")}
              >
                New Requests{" "}
                {activeTab === "1" ? (
                  <Badge className="badge-number">{counter}</Badge>
                ) : null}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => toggle("2")}
              >
                Rejected{" "}
                {activeTab === "2" ? (
                  <Badge className="badge-number">{rejectCounter}</Badge>
                ) : null}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="admin-table">
                {modalShow && (
                  <RequestModal
                    type="account"
                    className='newUserModal'
                    show={modalShow}
                    tableData={{ data: tdata, id: selectedId }}
                    handleClick={handleClick}
                  />
                )}
                <InfiniteScroll
                  dataLength={tdata.length} //This is important field to render the next data
                  next={fetchData}
                  hasMore={true}
                >
                  <Table bordered className="admin-table">
                    <thead>
                      <tr className="heading-row">
                        <th className="newReqSr">Sr.No</th>
                        <th className="newReqSdate">Request date</th>
                        <th className="newReqcName">Company Name</th>
                        <th className="newRequname">User name</th>
                        <th className="newReqlink">Form Link</th>
                        <th className="newReqType">Type </th>
                        <th className="newReqAction">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tdata.length > 0 ? (
                        tdata.map((row, id) => (
                          <tr className="data-row">
                            <td>{id + 1}</td>
                            <td>{row.createdDate}</td>

                            <td title={row.companyName}>{row.companyName}</td>

                            <td>
                              {row.firstName} {row.lastName}
                            </td>

                            <td>
                              <Link
                                onClick={() => handleClick(row.id)}
                                className="View-Linkreq"
                              >
                                View
                              </Link>
                            </td>
                            <td title={row.designation}>{row.designation}</td>
                            <td className="pending-button d-flex justify-content-center align-items-center">
                              {row.userStatus == "PENDING" ? (
                                <>
                                  <p className="pending">Pending</p>
                                  <img className="alert-icon" src={alert} />
                                </>
                              ) : (
                                <div className="d-inline">
                                  <Button
                                    Accept
                                    color="danger"
                                    className="typereject-btn"
                                    onClick={() => handleReject(row.id)}
                                  >
                                    Reject
                                  </Button>

                                  <Button
                                    Accept
                                    color="primary"
                                    className="typeaccept-btn"
                                    onClick={() => handleAccept(row.id)}
                                  >
                                    Accept
                                  </Button>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <>
                          {" "}
                          <h5 className="text-center">No new requests </h5>
                        </>
                      )}
                    </tbody>
                  </Table>
                </InfiniteScroll>
                <NotificationContainer />
                {isLoading ? (
                  <>
                    <div className="cdf">
                      {" "}
                      <div className="loader" />
                    </div>{" "}
                  </>
                ) : null}
              </div>

              {/* <NewRequestTable newData1={newData} requestCount={setCounter} /> */}
            </TabPane>
            <TabPane tabId="2">
              <div className="admin-table">
                {modalShow1 && (
                  <RequestModal
                    type="account"
                    show={modalShow1}
                    tableData={{ data: tdata1, id: selectedId1 }}
                    handleClick={handleClick1}
                  />
                )}
                <InfiniteScroll
                  dataLength={tdata1.length} //This is important field to render the next data
                  next={fetchData1}
                  hasMore={true}
                >
                  <Table bordered className="admin-table">
                    <thead>
                      <tr className="heading-row">
                        <th>Sr.No</th>
                        <th>Request date</th>
                        <th>Company Name</th>
                        <th>User name</th>
                        <th>Form Link</th>
                        <th>Type </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tdata1.length > 0 ? (
                        tdata1.map((row, id) => (
                          <tr className="data-row">
                            <td>{id + 1}</td>
                            <td>{row.createdDate}</td>

                            <td>{row.companyName}</td>

                            <td>
                              {row.firstName} {row.lastName}
                            </td>

                            <td>
                              <Link
                                onClick={() => handleClick1(row.id)}
                                className="View-Linkreq"
                              >
                                View
                              </Link>
                            </td>
                            <td>{row.designation}</td>
                            <td className="admin-button">
                              <div className="d-inline">
                                <Button
                                  Accept
                                  color="primary"
                                  className="type-btn"
                                  onClick={() => handleRestore(row.id)}
                                >
                                  Restore
                                </Button>

                                {/* <Button Accept color="danger" className="type-btn" onClick={() => handleReject(row.id)}>Reject</Button> */}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <>
                          {" "}
                          <h5 className="text-center">
                            No rejected user requests{" "}
                          </h5>
                        </>
                      )}
                    </tbody>
                  </Table>
                </InfiniteScroll>
                <NotificationContainer />
                {isLoading ? (
                  <>
                    <div className="cdf">
                      {" "}
                      <div className="loader" />
                    </div>{" "}
                  </>
                ) : null}
              </div>

              {/* <NewRequestTable /> */}
              {/* <RejectedTable rejectRequest={rejectData} requestCount={setRejectCounter} /> */}
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </>
  );
};

export default NewUserRequest;
