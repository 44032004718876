export const userConstants = {

    FORGET_PASSWORD:'FORGET_PASSWORD',
    FORGET_PASSWORD_SUCCESS:'FORGET_PASSWORD_SUCCESS',
 
    LOGOUT: 'USERS_LOGOUT',

    REQUEST_INITIATE:'REQUEST_INITIATE',
    REQUEST_SUCCESS:'REQUEST_SUCCESS',
    REQUEST_FAILED:'REQUEST_FAILED',
    REQUEST_ERROR:'REQUEST_ERROR',

    COMPANY_REQUEST_INITIATE:'COMPANY_REQUEST_INITIATE',
    COMPANY_REQUEST_SUCCESS:'COMPANY_REQUEST_SUCCESS',
    COMPANY_REQUEST_FAILED:'COMPANY_REQUEST_FAILED',
    COMPANY_REQUEST_ERROR:'COMPANY_REQUEST_ERROR', 
    fabric:'fabric',
    job_work:'job_work'
};
