import React, { useEffect, useState } from 'react';
import { Header } from '../../../SideBar/Header/Header'
import { Profile } from '../../../SideBar/Profile/profile';
import ActiveSellerTable from './Active-Sellers/ActiveSeller';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Badge } from 'reactstrap';
import classnames from 'classnames';
import DeleteSeller from './Delete-Sellers/DeleteSeller';


const Seller = () => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const [counter, setCounter] = useState(0);
    const [rejectCounter, setRejectCounter] = useState(0);
    const [headerRoute, setHeaderRoute] = useState(null);


    return (
        <>
            <Header routeesss={setHeaderRoute} />
            <Row>
                <Col sm="2" className="sidenav p-0">
                    <Profile activateRoute={headerRoute} />
                </Col>
                <Col sm="10" className="rightcontent">
                    <Nav tabs className="admin-tab greybox">
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggle('1') }}
                            >
                                Active Sellers {activeTab === '1' ?
                                    <Badge className="badge-number">{counter}</Badge>
                                    : null}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggle('2'); }}
                            >
                                Delete Sellers {activeTab === '2' ?
                                    <Badge className="badge-number">{rejectCounter}</Badge>
                                    : null}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <ActiveSellerTable requestCount={setCounter} />
                        </TabPane>
                        <TabPane tabId="2">
                            <DeleteSeller requestCount={setRejectCounter} />
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </>
    );
}


export default Seller;