import { combineReducers } from 'redux';

import { userReducer,fabricLinkReducer } from './users.reducer';
import { uploadCustfileReducer } from './rfq.reducer';
import { alert } from './alert.reducer';


const rootReducer = combineReducers({
 
    userReducer,
    alert,
    fabricLinkReducer,
    uploadCustfileReducer
});

export default rootReducer;