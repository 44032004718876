import React, { Component } from "react";
import { render } from "react-dom";
import { GetProductCatalogueApi } from "../../../services";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import ProductCatalogueEdit from "./ProductCatalogueEdit";

export default class CombinedProductAddCatal extends Component {
  constructor() {
    super();
    let companyId = localStorage.getItem("companyId");
    this.state = {
      companyId: companyId,
      productName: "",
      active: 0,
      productId: "",
      products: [],
      selectedProduct: {},
    };
  }

  componentDidMount() {
    this.setState({ active: this.props.previousTab }); //
    GetProductCatalogueApi(this.state.companyId).then((res) => {
      if (res.isSuccess) {
        this.setState({ products: res.data.productResponseDTO });

        //

        let selectedPro = res.data?.productResponseDTO[this.props.previousTab];
        this.setState({ selectedProduct: selectedPro });
      }
    });
  }

  toggle = (index, productId) => {
    // console.log('ddddd', index);
    this.setState({ active: index });
    this.props.setActiveTab(index)
    let [selectedPro] = this.state.products.filter(
      (value) => value.productId == productId
    );
    this.setState({ selectedProduct: selectedPro });
  };

  render() {
    const tabs = this.state.products.map((tab, index) => (
      <NavItem className="nav-items-productcata">
        <NavLink
          className={index === this.state.active ? "navigation--active" : ""}
          key={index}
          onClick={(event) => this.toggle(index, tab.productId)}
        >
          <a>{`${tab.productName.charAt(0).toUpperCase()}${tab.productName
            .substring(1)
            .toLowerCase()}`}</a>
        </NavLink>
      </NavItem>
    ));

    const tabContents = [];

    if (this.state.products.length) {
      for (let i = 0; i < this.state.products.length; i++) {
        tabContents.push(
          <ProductCatalogueEdit
            previousTab={this.state.active}
            handleBacks={this.props}
            selectedProduct={this.state.selectedProduct}
            selectedTab={this.props.tabName}
          />
        );
      }
    }

    const selectedTab = tabContents.map((item, index) => {
      if (index === this.state.active) {
        return <TabPane key={index}>{item}</TabPane>;
      }
    });

    return (
      <Col sm="12" className="p-0">
        <Nav tabs className="productcata-navtabs greybox">
          <TabContent className="product-tabcontent">{tabs}</TabContent>
        </Nav>
        <div>
          <Nav active={this.state.active} className="justify-content-center">{selectedTab}</Nav>
        </div>
      </Col>
    );
  }
}
