export const sessionService = {
    getSession,
    setSession,
    removeSession,
    removeSessions
}

function getSession(key) {
    window.localStorage.getItem(key);

}
function setSession(key, value) {
    window.localStorage.setItem(key, value);

}
function removeSession(key) {
    window.localStorage.removeItem(key);

}
function removeSessions() {
    window.localStorage.clear();

}