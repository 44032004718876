import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ProfileScreen from './ProfileScreen';
import './Profilescreen.css'
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const ProfileOverlay = (props) => {
  const {
    className
  } = props;
  const user = useSelector(state => state.userReducer);

  const [modal1, setModal1] = useState(true);

  const toggle1 = () => setModal1(!modal1);

  const closeBtn = <button className="close" onClick={props.handleClick}>&times;</button>;

  return (
    <div className="Modal-ProfileScreen">
      <div className="ProfileOverlay-Modal">
        <Modal modalClassName="ProfileOverlay-Modal" isOpen={modal1} toggle={toggle1} backdrop={false} >
          <div className="ProfileOverlayContent">
            <ModalBody className="ProfileOverlay-body">
              {/* <ModalHeader className="ProfileOverlay-User" toggle={toggle1} close={closeBtn} /> */}
              <ProfileScreen handleClick={props.handleClick} handleImageUrl={props.handleImageUrl}/>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </div>
  );
}


// export const ProfileOverlay = (props) => {
//     const [show, setShow] = useState(false);

//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);

//     return (
//       <>
//         <Button variant="primary" onClick={handleShow}>
//           Launch demo modal
//         </Button>

//         <Modal show={show} onHide={handleClose}>
//           <Modal.Header closeButton>
//           </Modal.Header>
//           <Modal.Body><ProfileScreen /></Modal.Body>

//         </Modal>
//       </>
//     );
//   }



