import {userConstants} from "../constants/user.constants";
  
  
  const initialState={
    loading:false,
    data:'',
    message:'',
    linkType:'fabric'
  }

  export const userReducer=(state=initialState,action)=>{
    switch(action?.type){
      case userConstants.REQUEST_INITIATE: return{
        ...state,
        loading:true

      };
      case userConstants.REQUEST_SUCCESS: return{
        ...state,
        loading:false,
        data:action.user,
        message:action.message
      };
      case userConstants.REQUEST_FAILED: return{
        ...state,
        loading:false,
        data:action.user,
        message:action.message
        
      };
      case userConstants.REQUEST_ERROR: return{
        ...state,
        loading:false,
        data:action.user,
        message:action.message
      };
      default:return state
    }
  }
  
  export const companyReducer=(state=initialState,action)=>{
    switch(action?.type){
      case userConstants.COMPANY_REQUEST_INITIATE: return{
        ...state,
        loading:true

      };
      case userConstants.COMPANY_REQUEST_SUCCESS: return{
        ...state,
        loading:false,
        data:action.user,
        message:action.message
      };
      case userConstants.COMPANY_REQUEST_FAILED: return{
        ...state,
        loading:false,
        data:action.user,
        message:action.messageaction.message
        
      };
      case userConstants.COMPANY_REQUEST_ERROR: return{
        ...state,
        loading:false,
        data:action.user,
        message:action.message
      };
      default:return state
    }
  }
 
   export const fabricLinkReducer=(state=initialState,action)=>{
    console.log(action ,'in fabric link')
    switch(action?.type){
      case 'fabric': return{
        ...state,
       linkType:'fabric'

      }; case 'job_work': return{
        ...state,
       linkType:'job_work'

      };
    
      default:return state
    }
  }
  