import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {
  FileUploadApi,
} from "../../../services";
import {addImageinCust} from '../../../actions/index'
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState } from 'react';

export const FileUpload = (props) => {
  const inputFile= useRef()
  const [showModal,setShowModal]=useState(props.show)
const dispatch=useDispatch();
const uploadedFiles= useSelector(state => state.uploadCustfileReducer.files)
const handleBack= ()=>{
  setShowModal(false);
  props.handleBack()
}

const removeFile=(index)=>{
dispatch({type:'REMOVE_IMAGE',payload:index})

}
const handleSubmit =()=>{
  setShowModal(false);
  props.handleBack()
}
const handleDrop = async (e) => {
  console.log('handle drop')
  e.preventDefault();
  e.stopPropagation();
 
  if (e.dataTransfer.files && e.dataTransfer.files.length >0) {
    console.log(e.dataTransfer.files)
    // Handle dropped files
    let files= Array.from(e.dataTransfer.files)
    for (let i=0;i<files.length;i++){
     let file=files[i];
     if (file.size <= 10485760) {
      let formData = new FormData();
      formData.append("file", file);
      await FileUploadApi(formData).then((data) => {
        if (data.isSuccess) {
          let payload={
            imageName:file.name,
            imageSize:file.size,
            imageUrl:data.data
          }
        dispatch({type:'ADD_IMAGE',payload})
        }
      })
    }
}
  }
};
const handlePicChange =async(e)=>{
  let file = e.target.files[0];
  if(!file){
    return;
  }
    if (file.size <= 10485760) {
      let formData = new FormData();
      formData.append("file", file);

      await FileUploadApi(formData).then((data) => {
        if (data.isSuccess) {
          let payload={
            imageName:file.name,
            imageSize:file.size,
            imageUrl:data.data
          }
        dispatch({type:'ADD_IMAGE',payload})
        }
      })
    }
}
const handleDragOver = (event) => {
  event.preventDefault();
};
    return (
        
          <Modal modalClassName="file-upload-modal" isOpen={showModal}  backdrop={false} >
            <div className="file-upload-modal-content"
              onDrop={handleDrop}
              onDragOver={handleDragOver}>
              <ModalBody className="file-upload-modal-body">
                {/* <ModalHeader className="ProfileOverlay-User" toggle={toggle1} close={closeBtn} /> */}
               <h2 className='file-header'>Upload Files</h2>
               <div className="file-zone">
      { uploadedFiles.map((file,index)=>{
        return (
          <div className="d-flex">
         <span className='file-serial'>{index+1}</span> <p className='file-name'>{file.imageName}</p> <a className='file-cross'  onClick={(e)=>removeFile(index)}>&times;</a>
          </div>
        )
      })}
               </div>
               <div className="drop-zone"
              >
                <span>Drag files here or</span> <a onClick={()=>inputFile.current.click()}>&nbsp;Browse</a>
                <input ref={inputFile} type="file"
                onChange={(e) => handlePicChange(e)}
                accept="image/x-png,image/jpeg,image/svg+xml,application/pdf"
                ></input>
               </div>
               <div className="upload-button">            
               <button className="upload-Back" onClick={handleBack}>
                Back
              </button>
                <button
                  className="upload-Submit"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
            
             
            </div>
              </ModalBody>
            </div>
          </Modal>
       
    );
}
