import React, { useReducer } from "react";
import { Button } from "antd";
import { Table, Row, Col, Input, Spinner } from "reactstrap";
import DeletPopUp from "../../Admin/MetadataUpdates/deletePopup";
import "./ProductProfile.css";
import {
  AddProductCatalogueApi,
  deleteProductCatalogueApi,
  GetProductCatalogueApi,
  getAllYarn,
} from "../../../services";
import edit from "../../../assets/edit.png";
import { Success, Warning } from "../../Alert";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { toast } from "react-toastify";
import {toastOptions} from "../../../helpers/toast-options";

class ProductCatalogue extends React.Component {
  constructor() {
    super();
    let companyId = localStorage.getItem("companyId");
    //  let productId=this.props.selectedProduct.productId;

    this.state = {
      SNO: 0,
      warp: "",
      weft: "",
      minGSMValue: "",
      maxGSMValue: "",
      yarnItems: [],
      items: [],
      companyId: companyId,
      sendData: [],
      isLoading: false,
      loader: false,
      toastId: false
      // productId:productId
    };
  }

  handleFormSubmit = (e) => {
    e.preventDefault();

    let sendData = [...this.state.sendData];

    sendData.push({
      id: 0,
      warp: this.state.warp,
      weft: this.state.weft,
      minGSMValue: Number(this.state.Range),
      maxGSMValue: Number(this.state.Range1),
    });

    const modifyProduct = {
      productId: this.props.selectedProduct.productId,
      productCatalogueDTOList: sendData,
    };

    if (
      this.state.warp === "" ||
      this.state.weft === "" ||
      this.state.Range === "" ||
      this.state.Range1 === ""
    ) {
      if(!this.state.toastId){
        const id = toast.error("All fields are mandatory must be filled to proceed", {...toastOptions, onClose: ()=> this.setState({...this.state, toastId: null})});
        this.setState({...this.state, toastId: id});
      }
      this.setState({ isLoading: false });
      this.setState({ loader: false });
    } else if (parseInt(this.state.Range) > parseInt(this.state.Range1)) {
      if(!this.state.toastId){
        const id = toast.error("Min range should not exceed max range", {...toastOptions, onClose: ()=> this.setState({...this.state, toastId: null})});
        this.setState({...this.state, toastId: id});
      }
      document.getElementById("warp").selectedIndex = 0;
      document.getElementById("weft1").selectedIndex = 0;
      this.setState({ Range: "", Range1: "" });
    } else {
      AddProductCatalogueApi(modifyProduct)
        .then((res) => {
          if (res.isSuccess) {
            // Success(res.message)
            this.setState({
              // items,
              SNO: this.state.SNO + 1,
              warp: "",
              weft: "",
              minGSMValue: "",
              maxGSMValue: "",
              items: [],
              sendData: [],
              isDataFetch: false,
              data: [],
              isLoading: true,
            });

            document.getElementById("warp").selectedIndex = 0;
            document.getElementById("weft1").selectedIndex = 0;
            this.GetProductCatalogue();
            this.setState({ isLoading: false });
            this.setState({ Range: "", Range1: "" });

            sendData = [];
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          document.getElementById("warp").selectedIndex = 0;
          document.getElementById("weft1").selectedIndex = 0;
          this.setState({ Range: "", Range1: "" });
        });
    }
  };

  handleInputChange = (e) => {
    let input = e.target;
    let name = e.target.name;
    let value = input.value;

    this.setState({
      [name]: value,
    });
  };

  GetProductCatalogue() {
    this.setState({ loader: true });
    // if (this.props.selectedProduct.productId) {

    GetProductCatalogueApi(this.state.companyId).then((res) => {
      this.setState({ items: [] });

      if (res.isSuccess) {
        this.setState({ items: res.data.productResponseDTO });

        const [filterData] = res.data.productResponseDTO.filter(
          (val) => val.productId == this.props.selectedProduct.productId
        );
        this.setState({ data: filterData?.productCatalogueDTOS });
        this.setState({ isDataFetch: true });
        this.setState({ loader: false });
      }
    });
    // }
  }

  componentDidMount = () => {
    this.getYarnData();
    this.GetProductCatalogue();
    // this.handleFormSubmit();
  };

  getYarnData() {
    //
    getAllYarn().then((res) => {
      if (res.isSuccess) {
        this.setState({ yarnItems: [...res.data.yarn,...res.data.blend] });
      }
    });
  }

  handleItemDelete(i) {
    var items = this.state.items;
    items.splice(i, 1);
    this.setState({
      items: items,
    });
  }
  handleClickDelete = (id,type, close) => {
    const items = [...this.state.items];
    this.setState({
      items: items.filter((item) => item.SNO !== id),
    });

    deleteProductCatalogueApi(id).then((data) => {
      if (data.isSuccess) {
        // Warning(data.message)
        this.GetProductCatalogue();
        this.handleItemDelete();
        close();
      }
    });

    // close();
  };

  editProduct = () => {
    this.props.handleBacks.fromBack(1);
  };

  submitHandler = () => {
    this.props.tabName(this.props.selectedProduct.productName);

    this.props.handleAction.fromAction(1);
  };
  transform(val){
    switch(val){
      case 'PROCESSING_WITH_FABRIC':{
        return 'Processing with Fabric';
      }
      case 'JOB_WORK':{
        return 'Job Works';
      }
      case 'BOTH':{
        return 'Job Works and Processing with Fabric';
      }
      default:{
        return val;
      }
    }
  }
  capitalizeFirstLowercaseRest = str => {
    if(str)
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  
  render() {
    return (
      <>
        <div className="Product-catalogue">
          <p className="heading-Product1 mb-0">
            Product Catalogue
            <div className="mb-33"></div>
            <div className="type-edit">
              <div style={{'whiteSpace':'nowrap'}}>
              <h6>
                a) Type of fabric:{" "}
                <span className="heading-backend">{`${this.props?.selectedProduct?.productName
                  ?.split("-")[0]
                  .charAt(0)
                  .toUpperCase()}${this.props?.selectedProduct?.productName
                  ?.split("-")[0]
                  .substring(1)
                  .toLowerCase()}`}</span>
              </h6>
           
            <h6>
              b) Fabric stage/Type :{" "}
              <span className="heading-backend">
                {this.props?.selectedProduct?.productName?.split("-")[1]}{" "}
              </span>
            </h6>
            </div>
            {!this.props?.selectedProduct?.productName?.includes('Greige') &&
            !this.props?.selectedProduct?.productName?.includes('Yarn') &&
            <div className="w-100" style={{'marginLeft':'10vw'}}>
            
              <div className="d-flex justify-content-between">
              
              <h6>
                c) Process Type:{" "}
                <span className="heading-backend">{`${this.props?.selectedProduct?.processType=='BOTH'?
                'Print & Dyed':this.capitalizeFirstLowercaseRest(this.props?.selectedProduct?.processType)}`}</span>
              </h6>
  
              <img
                className=" Edit-product"
                src={edit}
                alt="Profile"
                onClick={this.editProduct}
              />
           </div>
            <h6>
              d) Service Offered :{" "}
              <span className="heading-backend">
                {this.transform(this.props?.selectedProduct?.servicesOffered)}{" "}
              </span>
            </h6>
            </div>
  }
  {(this.props?.selectedProduct?.productName?.includes('Greige') ||
   this.props?.selectedProduct?.productName?.includes('Yarn')) &&

            <div className="w-100" style={{'marginLeft':'10vw'}}>
            
              <div className="d-flex justify-content-end">
              <img
                className=" Edit-product"
                src={edit}
                alt="Profile"
                onClick={this.editProduct}
              />
           </div>
            
            </div>
  }
            </div>
            <hr />
            <span className="please">
              Please enter the different types of fabric you supply. Select yarn
              combinations and the GSM range that you produce.
            </span>
          </p>
          <Form
            handleFormSubmit={this.handleFormSubmit}
            handleClickDelete={this.handleClickDelete}
            handleInputChange={this.handleInputChange}
            newSNO={this.state.SNO}
            newWarp={this.state.warp}
            newWeft={this.state.weft}
            newRange={this.state.Range}
            newRange1={this.state.Range1}
            isLoading={this.state.isLoading}
            yarnItems={this.state.yarnItems}
            productName={this.props?.selectedProduct?.productName
              ?.split("-")[0]
              .toLowerCase()}
          />

          <Table1
            items={this.state.items}
            get={this.GetProductCatalogue}
            datas={this.state.data}
            fetch={this.state.isDataFetch}
            data={this.state.sendData}
            productName={this.props?.selectedProduct?.productName
              ?.split("-")[0]
              .toLowerCase()}
            productId={this.props?.selectedProduct?.productId}
            delete={this.handleClickDelete}
          />
          {/* <Button className="btn btn-primary" onClick={this.submitHandler}>Submit</Button> */}
          <div className="ProductCatS-button">
            {this?.state?.data?.length > 0 ? (
              <Button className="btn btn-primary" onClick={this.submitHandler}>
                Submit 
              </Button>
            ) : null}
          </div>
        </div>
        <NotificationContainer />
        {this.state.loader ? (
          <>
            <div className="cdf">
              {" "}
              <div className="loader" />
            </div>{" "}
          </>
        ) : null}
      </>
    );
  }
}

class Table1 extends React.Component {
  // constructor() {
  //   super();
  //   this.state = {
  //     isDataFetch: false,
  //     data: []
  //   }
  // }
  // const getd = this.props.get;

  render() {
    // const datas = this.props.data;
    return (
      <>
        <div id="TableProcat">
          <Table className="profile-table position-relative">
            <tbody>
              <tr>
                <th className="srno">SI.No </th>
                <th className="fabricstorage">{this.props?.productName
                  ?.split("-")[0]
                  .toLowerCase()=='knit'?'Wale':'Warp'} </th>
                <th className="fabricStage">{this.props?.productName
                  ?.split("-")[0]
                  .toLowerCase()=='knit'?'Course':'Weft'}</th>
                <th className="range">GSM Range</th>
              </tr>

              {this.props.fetch ? (
                <>

                  {this.props?.datas?.length ? this.props.datas.map((product, id) =>

                    <tr>
                      <td>{id + 1}</td>
                      <td value={product.warp}>{product.warp}</td>
                      <td value={product.weft}>{product.weft}</td>
                      <td style={{'maxWidth':'13.89vw'}} value={product.gsmRange}>{product.gsmRange}</td>
                      <td id="Deletetd">
                        <DeletPopUp handleClickDelete={this.props.delete} id={product.id} /></td>
                    </tr>
                  ) : <tr><td>-</td><td>-</td><td>-</td><td style={{'maxWidth':'13.89vw'}}>-</td></tr>}
                </>
              ) : (
                <p className="text-center">
                  {" "}
                  Please wait... <Spinner color="primary" />{" "}
                </p>
              )}
            </tbody>
          </Table>
        </div>

        {/* <button className="Product-Submit primary" type="submit" value="Submit"><Link to="/productcata">Submit</Link></button> */}
      </>
    );
  }
}

class Form extends React.Component {
  render() {
    const fun = (e) => {
      if (
        [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
        (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
        (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
        (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
        (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
        (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
        (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
        (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
        (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        return;
      }
      if (e.keyCode == 190) {
        e.preventDefault();
        return false;
      }
      if (
        (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
        (e.keyCode < 96 || e.keyCode > 105)
      ) {
        e.preventDefault();
      }
    };

    const yarnItems = this.props.yarnItems;

    const isLoad = this.props.isLoading;

    return (
      <>
        &nbsp;
        <form onSubmit={this.props.handleFormSubmit} className="ProductForm">
          <div id="Add-Fab-Fields">
            <label for="warp">
              <span className="warp-i">{this.props?.productName
                  ?.split("-")[0]
                  .toLowerCase()=='knit'?'Wale':'Warp'}</span>
              <Input
                id="warp"
                value={this.props.warp}
                type="select"
                name="warp"
                onChange={this.props.handleInputChange}
              >
                <option value="" disabled selected hidden>
                  Type of Yarn
                </option>
                {yarnItems.map((item) => {
                  return <option value={item.name}>{item.name}</option>;
                })}
              </Input>
            </label>
            <label htmlFor="weft">
              <span id="text">{this.props?.productName
                  ?.split("-")[0]
                  .toLowerCase()=='knit'?'Course':'Weft'}</span>
              <Input
                id="weft1"
                value={this.props.weft}
                type="select"
                name="weft"
                onChange={this.props.handleInputChange}
              >
                <option value="" disabled selected hidden>
                  Type of Yarn
                </option>

                {yarnItems.map((item) => {
                  return <option value={item.name}>{item.name}</option>;
                })}
              </Input>
            </label>
            <label>
              {" "}
              <span id="text">GSM Range</span>
              <div className="twoinputs">
                <Input
                  id="Range"
                  value={this.props.newRange}
                  type="text"
                  onKeyDown={(e) => fun(e)}
                  autoComplete="off"
                  minLength="0"
                  maxLength="4"
                  // min="0"
                  // max="9998"
                  name="Range"
                  onChange={this.props.handleInputChange}
                  placeholder="e.g. 100"
                />
                <p className="dash">-</p>
                {/* </label>

            <label> <span id="text"></span> */}
                <Input
                  id="Range"
                  value={this.props.newRange1}
                  type="text"
                  onKeyDown={(e) => fun(e)}
                  autoComplete="off"
                  maxLength="4"
                  name="Range1"
                  onChange={this.props.handleInputChange}
                  placeholder="e.g. 200"
                />
              </div>
            </label>
            <label>
              <button type="submit" disabled={isLoad} value="Submit">
                {isLoad ? <Spinner /> : "Add"}
              </button>
            </label>
          </div>
        </form>
      </>
    );
  }
}

export default ProductCatalogue;
