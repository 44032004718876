import React, { useState, useEffect } from 'react'
import './Login.css'
import { Col, Row, Button, Form, FormGroup, Label, Input, Spinner } from 'reactstrap'
import { useHistory, useLocation, } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Header1 } from '../SideBar/Header/Header-lgin'
import validate from 'validate.js'
import { getUserInfoAction } from '../../actions/user.actions'
import { useDispatch, useSelector } from 'react-redux'
import { SignIn, getUserInfo } from '../../services/auth.service'
import { sessionService } from '../../services/session.service'
import { Errors, Success } from '../Alert'
import { NotificationContainer, NotificationManager, } from 'react-notifications'
import { toast } from 'react-toastify'
import { toastOptions } from "../../helpers/toast-options"
import Cookies from 'js-cookie'
import { apiService, redirectRoot } from '../../services/api.service'

// import {Alerts} from '../../services';

validate.validators.presence.options = { message: '^mandatory field must be filled to proceed' }

const constraints = {
  // firstName: { presence: { allowEmpty: false } },
  email: {
    presence: { allowEmpty: false },
    // email: true
  },
  password: {
    presence: { allowEmpty: false },

  },
}

const Login = props => {
  const company = useSelector(state => state.companyReducer)
  const user = useSelector(state => state.userReducer);

  const history = useHistory()
  //initial state
  const [userLogin, setUserLogin] = useState({
    email: '',
    password: '',
    isLoading: false
  })

  // const loggingIn = useSelector(state => state.authentication.loggingIn);
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [toastId, setToastId] = useState();
  const location = useLocation()
  const [resetpw, setResetPw] = useState(false)

  //defining initial state to error and formsubmit
  const [formDetails, setFormDetails] = useState({
    submitted: false,
    errors: null,
  })
  let token = localStorage.getItem('token')
  // let cookieToken = ("token")
  //state defined
  useEffect(() => {

    if (token && Cookies.get('token')) {
      let role = window.localStorage.getItem('role')
      if (role == 'ADMIN_BUYER') {
        // history.push(`/companyprofile`)
        window.location.replace(`${redirectRoot}/buyer/dashboard`);
      } else if (role == 'FABTRACK_ADMIN') {

        history.push(`/newrequest`)
      } else if (role == 'ADMIN_SELLER') {
        // history.push(`/companyprofile`)
        window.location.replace(`${redirectRoot}/seller/dashboard`);


      } else {
        history.push(`/`)

      }

    } else {
      localStorage.removeItem('token');
      history.push(`/`);
    }

  }, [])

  useEffect(() => {


    // console.log(location.search);
    if (location.search.includes('resetpw')) {
      setResetPw(true)
      //  
    } else {
      setResetPw(false)

    }
    const errors = validate(userLogin, constraints)
    setFormDetails(prev => ({
      ...prev,
      errors,
    }))
  }, [userLogin])

  const handleChange = event => {
    const { name, value } = event.target
    setUserLogin(prev => ({
      ...prev,
      [name]: value,
    }))
  }


  const submitHandler = event => {
    event.preventDefault()
    // setUserLogin({ isLoading: true })
    setFormDetails(prev => ({
      ...prev,
      submitted: true,

    }))
    if (formDetails.errors) {
      const currentError = Object.keys(formDetails.errors).map(key => formDetails.errors[key][0]);
      if (!toastId) {
        const id = toast.error(currentError[0], { ...toastOptions, onClose: () => setToastId(null) });
        setToastId(id);
      }
      return;
    }
    const body = {
      email: userLogin.email,
      password: userLogin.password,
    }
    if (!formDetails.errors) {
      setFormDetails(prev => ({
        ...prev,
        submitted: false,

      }))
      setUserLogin({ isLoading: true })
      SignIn(body)
        .then(res => {
          setUserLogin({ isLoading: false })
          if (res.isSuccess) {
            // document.cookie = `token=${res.data.token}`
            if (redirectRoot.includes('fabtrack')) {
              Cookies.set('token', res.data.token, { path: '/', domain: 'fabtrack.in' });
              Cookies.set('id', res.data.userId, { path: '/', domain: 'fabtrack.in' });
            } else {
              Cookies.set('id', res.data.userId);

              Cookies.set('token', res.data.token,);
            }


            sessionService.setSession('token', res.data.token)
            sessionService.setSession('id', res.data.userId)
            sessionService.setSession('email', res.data.email)
            sessionService.setSession('companyId', res.data.companyId)
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("id", res.data.userId);
            localStorage.setItem("email", res.data.email);
            localStorage.setItem("companyId", res.data.companyId);
            dispatch(getUserInfoAction(res.data.userId)).then(actionData => {
              localStorage.setItem('usertype', actionData.user.userType);
              if (actionData.user.accountType == 'FABTRACK_ADMIN') {
                history.push(`/newrequest`)
              } else if (actionData.user.accountType == 'ADMIN_SELLER') {
                actionData.user.profileCompleted ?
                  window.location.replace(`${redirectRoot}/seller/dashboard`) :
                  history.push(`/companyprofile`)
              } else if (actionData.user.accountType == 'ADMIN_BUYER') {
                actionData.user.profileCompleted ?
                  window.location.replace(`${redirectRoot}/buyer/dashboard`) :
                  history.push(`/companyprofile`)
              }
              else if (actionData.user.accountType == 'ACCOUNTS') {
                window.location.replace(
                  `${redirectRoot}/account`
                );
              }
              else if (
                actionData.user.userType == "BUYER" &&
                (
                  actionData.user.accountType == "OWNER" ||
                  actionData.user.accountType == "JUNIOR" ||
                  actionData.user.accountType == "MERCHANDISER" ||
                  actionData.user.accountType == "MANAGER")
              ) {
                window.location.replace(`${redirectRoot}/buyer/dashboard`);
              } else if (
                actionData.user.userType == "SELLER" &&
                (
                  actionData.user.accountType == "OWNER" ||
                  actionData.user.accountType == "JUNIOR" ||
                  actionData.user.accountType == "MERCHANDISER" ||
                  actionData.user.accountType == "MANAGER")
              ) {
                window.location.replace(`${redirectRoot}/seller/dashboard`);
              }
              else {
                history.push(`/`)   //edited by Anand Mishra
                // history.push(`/companyprofile`)
              }
            })

            setIsLoading(true)
            // setUserLogin({ email: '', password: '' })
          }

        })
        .catch(res => {
          setUserLogin({ email: '', password: '', isLoading: false })
          // setUserLogin({ isLoading: false })

        })
    }
  }

  const handleClick = () => {
    history.push(`/dashboard`)
  }
  return (
    <>
      <Row>
        <Header1 />
      </Row>
      <Form className="login-form" onSubmit={submitHandler}  >
        <Row>
          <Col>
            {resetpw ? <h5 className="text-center mb-2">The password has been succesfully changed.</h5> : null}
            <h5 className="headingLogin">Log In</h5>

            <FormGroup className="Loginform">
              <Label className="required" for="email">
                User Id
              </Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your registered User id"
                value={userLogin.email}
                onChange={(e) => handleChange(e)}
              />

              {/* {formDetails.submitted &&
                formDetails.errors &&
                formDetails.errors.email && (
                  <small className="form-text text-danger">
                    {formDetails.errors.email[0]}
                  </small>
                )} */}
            </FormGroup>
            <FormGroup>
              <Label className="required" for="password">
                Password
              </Label>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Enter your password"
                value={userLogin.password} autoComplete="new-password"
                onChange={(e) => handleChange(e)}
              />
              {/* {formDetails.submitted &&
                formDetails.errors &&
                formDetails.errors.password && (
                  <small className="form-text text-danger">
                    {formDetails.errors.password[0]}
                  </small>
                )} */}
            </FormGroup>


            {/* <Button color="primary" disabled={userLogin.loading} className='login-btn' type="submit"> */}
            <Button color="primary" disabled={userLogin.isLoading} className="login-btn" type="submit">
              {/* {userLogin.isLoading?:null} */}
              {userLogin.isLoading ? <Spinner /> : 'Log in'}
            </Button>
            <Link to="/forgotpw" className="link-forgetps">
              Forget Password?
            </Link>
            <Button
              color="primary"
              className="forget-btn signup-not-account"
              style={{ color: '#2f80ed !important' }}
              onClick={handleClick}
            >
              Don’t have an account. Sign Up !
            </Button>
          </Col>
        </Row>
      </Form>
      <NotificationContainer />
      {isLoading ? <><div className="cdf"> <div className='loader' /></div> </> : null}

    </>
  )
}

export default Login
