import React, { useEffect, useState } from "react";
import { Form, Col, Row, FormGroup, Label, Input, Spinner } from "reactstrap";
import editicon from "../../../assets/edit.svg";
import delicon from "../../../assets/delete2.png";
import plus from "../../../assets/plus.svg";
import "./CompanyProfile.css";
import { AddButton } from "./AddButton";
import validate from "validate.js";
import { AddLocation } from "./AddButton";
import { useDispatch } from "react-redux";
import {
  FileUploadApi,
  getCompanyInfo,
  SummaryApi,
  GetSummaryApi,
  DeleteCompanyImageApi,
  DeleteCertificateApi,
} from "../../../services";
import { Button } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { Errors, Success } from "../../Alert";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { toast } from "react-toastify";
import {toastOptions} from "../../../helpers/toast-options";
import Cookies from "js-cookie";


//form validation
validate.validators.presence.options = {
  message: "^mandatory field must be filled to proceed",
};

const constraints = {
  companyName: { presence: { allowEmpty: false } },
  companyBriefDescription: { presence: { allowEmpty: false } },
};

let olddataCerti;
let localA = [];

const Summary = (props) => {
  // let certificate = ''
  let imageContainer = [];
  const [certificate, setCertificate] = useState("");
  const [Singlecertificate, setSinglecertificate] = useState(false);
  const [fileImage, setFileImage] = useState("");
  const [logoText, setLogoText] = useState(null);
  const [isSingle, setSingle] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [ImageContainer, setImageContainer] = useState([]);

  let companyId = localStorage.getItem("companyId");

  const [summary, setSummary] = useState({
    companyName: "",
    companyWebsite: "",
    companyBriefDescription: "",
    foundationYear: "",
    numberOfEmployees: "",
    numberOfLocations: "",
    
    companyCertificates:[],
    // id: companyId,
    profileImageUrl: null,
    companyId: companyId,
  });
  const getImage = (e) => {
    if (e.clientImageUrl && e.image) {
      let payload = {
        clientImageUrl: e.image,
      };
      let payload1 = {
        clientImageUrl: e.clientImageUrl,
      };
      imageContainer.push(payload);
      imageContainer.push(payload1);
    } else {
      imageContainer.push(e);
    }
  };
  // const setFields = (val) => {
  //   setCertificate(val);
  // };
  const setFields = (val) => {
    console.log(val)
    if (
      val.clientImageUrl != "" &&
      ImageContainer.some((res) => res.clientImageUrl === val.clientImageUrl)
    ) {
      if(!toastId){
        const id = toast.error("duplicate entry - client already exists", {...toastOptions, onClose: ()=> setToastId(null)});
        setToastId(id);
      }
    }
console.log(ImageContainer.some((res) => res.certificateImageText == val.clientImageText))
    if (
      val.clientImageText != "" &&
      ImageContainer.some((res) => res.certificateImageText === val.clientImageText)
    ) {
      if(!toastId){
        const id = toast.error("duplicate entry - client already exists", {...toastOptions, onClose: ()=> setToastId(null)});
        setToastId(id);
      }
    } else {
      if (ImageContainer.length < 10) {
        let tempClient = summary.companyCertificates;
        tempClient.push({certificateImageUrl: val.clientImageUrl,
          certificateImageText:val.clientImageText,
        id:val.id});
        setImageContainer(tempClient)
        setSummary({ ...summary, companyCertificates: tempClient });
        // setImageContainer((pre) => [...pre, val])
        // imageContainer = val;
      } else {

      if(!toastId){
        const id = toast.error("you have exceeded the maximum number of entries", {...toastOptions, onClose: ()=> setToastId(null)});
        setToastId(id);
      }
    }
    }
  };
  const [showText, setShowText] = useState(false);
  const [arr, setArr] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isValidImage, setIsvalidImage] = useState(false);
  const [required, setRequired] = useState(false);
  const [toastId, setToastId] = useState();
  // const [open, setOpen] = useState('')
  const [pic, setPic] = useState({
    preview: null,
    previewVisible: false,
    raw: "",
    invalidFile: false,
  });

  const [open, setopen] = useState(false);
  const setOpen = (e) => {
    // setOpen(e)
    setopen(e);
  };

  const handlePicChange = (e) => {
    e.preventDefault();
    const files = e.target.files;
    if (files.length) {
      setFileImage(e.target.files[0]);
      const exts = ["png", "jpg", "jpeg"];
      const breckedName = files[0].name.split(".");
      if (
        breckedName &&
        exts.indexOf(breckedName[breckedName.length - 1].toLowerCase().trim()) === -1
      ) {
        setIsvalidImage(true);
      } else {
        setIsvalidImage(false);
        setPic({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
          invalidFile: true,
        });
      }
    }
  };
  function handleAdd() {
    setIsShow(true);
    setopen(true);
  }
  const picShow = () => {
    if (!showText) {
      setShowText(true);
    }
  };
  const fun = (e) => {
    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    if (e.keyCode == 190) {
      e.preventDefault();
      return false;
    }
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  };

  const [formDetails, setFormDetails] = useState({
    submitted: false,
    errors: null,
  });

  const getSummaryDetails = () => {
    GetSummaryApi(companyId).then((res) => {
      if (res.isSuccess) {
        setSummary({
          companyName: res.data.companyName,
          companyWebsite: res.data.companyWebsite,
          companyBriefDescription: res.data.companyBriefDescription,
          foundationYear: res.data.foundationYear,
          numberOfEmployees: res.data.numberOfEmployees,
          numberOfLocations: res.data.numberOfLocations,
          profileImageUrl: res.data.profileImageUrl,
          //certificates: res.data,
          companyCertificates:res.data.companyCertificates
        });
        //setArr(res.data.certificates);
        setImageContainer(res.data.companyCertificates);
        setPic((pre) => ({ ...pre, preview: res.data.profileImageUrl }));
      }
    });
  };
  const checkRequired = (val) => {
    //
    setRequired(val);
  };

  const history = useHistory();
  let token = localStorage.getItem("token");

  useEffect(() => {
    if (token && Cookies.get("token")) {
      // getCompanyDetails();
      getSummaryDetails();
    } else {
      history.push(`/`);
    }
  }, []);

  //state defined
  useEffect(() => {
    const errors = validate(summary, constraints);
    setFormDetails((prev) => ({
      ...prev,
      errors,
    }));
  }, [summary]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSummary((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  /**for delete function**/
  const deleteGallery = (id, i) => {
    let getTempId = id.toString().search("temp-");

    if (getTempId !== -1) {
      let newCleintArry = summary.companyCertificates.filter(
        (client) => client.id !== id
      );
      console.log("newCleintArry", newCleintArry);
      setSummary({ ...summary, companyCertificates: newCleintArry });
    } else {
      let modifiedData = {
        companyId: Number(companyId),
        clientId: parseInt(id),
      };
      let arr=ImageContainer.filter(c=>c.id!=id);
      setImageContainer(arr);
      DeleteCertificateApi(modifiedData).then((res) => {
        if (res.isSuccess) {
          let newCleintArry = summary.companyCertificates.filter(
            (client) => client.id !== id
          );
          console.log("newCleintArry", newCleintArry);
          setSummary({ ...summary, companyCertificates: newCleintArry });
        }
      });
    }
  };

  function handleGetPicRemove() {
    if (summary.profileImageUrl) {
      DeleteCompanyImageApi(companyId).then((res) => {
        if (res.isSuccess) {
          getSummaryDetails();
        }
      });
    } else {
      setPic((pre) => ({ ...pre, preview: null, raw: null }));
    }
  }

  async function submitHandler(event) {
    event.preventDefault();
    
    if (open != true) {
      setFormDetails((prev) => ({
        ...prev,
        submitted: true,
      }));
      let imageUrl = "";

      if (pic.raw) {
        let formData = new FormData();
        formData.append("file", fileImage);

        await FileUploadApi(formData).then((data) => {
          if (data.isSuccess) {
            imageUrl = data.data;
          }
        });
      }
      let FInalArray = [];
      FInalArray = summary.companyCertificates.filter((client) => {
        if (client.id.toString().search("temp-") !== -1) {
          client.id = 0;
        }
        return client;
      });
      // if (isSingle) {
      //   FInalArray.push(logoText);
      // }
      if (Singlecertificate) {
        FInalArray.push(logoText);
      }

      let modifyBody;
      modifyBody = {
        ...summary,
        id: companyId,
        // certificates: ceritificateArray,
        companyCertificates:FInalArray,
        profileImageUrl: imageUrl || summary.profileImageUrl,
      };

      if (!formDetails.errors) {
        if (summary.foundationYear > new Date().getFullYear()) {
          if(!toastId){
            const id = toast.error("year founded can not be greater than current year", {...toastOptions, onClose: ()=> setToastId(null)});
            setToastId(id);
          }
        } else {
          setisLoading(true);

          await SummaryApi(modifyBody)
            .then((data) => {
              if (data.isSuccess) {
                props.fromAction(1);
              }
            })
            .catch((err) => {
              setisLoading(false);
            });
        }
      }
    } else {
      if(!toastId){
        const id = toast.error("Please fill or close the box", {...toastOptions, onClose: ()=> setToastId(null)});
        setToastId(id);
      }
    }
  }

  const getMultiCertificates = (val) => {
    if (arr.some((res) => res === val)) {
      if(!toastId){
        const id = toast.error("duplicate entry - certificate already exists", {...toastOptions, onClose: ()=> setToastId(null)});
        setToastId(id);
      }
    } else {
      if (arr.length < 10) {
        setArr((pre) => [...pre, val]);
      } else {
        if(!toastId){
          const id = toast.error("you have exceeded the maximum number of entries", {...toastOptions, onClose: ()=> setToastId(null)});
          setToastId(id);
        }
      }
    }
  };
  const checkSingleCertificate = (val) => {
    setSinglecertificate(val);
  };
  return (
    <>
      <div className="Cards">
        <Row>
          <Col sm="12" className="p-0">
            <h5 className="Summary-heading">Fill out information</h5>
          </Col>
        </Row>

        <Form onSubmit={submitHandler}>
          <div>
            <Row>
              <Col xs="6" className="d-flex p-0">
                {pic.preview ? (
                  <>
                    <img src={pic.preview} className="gallery-section" />
                  </>
                ) : (
                  <label htmlFor="editPicProfile" className="mb-21">
                    <span className="image-uploader">
                      Upload company profile image
                    </span>
                  </label>
                )}
                {pic.preview ? (
                  <label>
                    <img
                      src={delicon}
                      className="delete-iconn-summary"
                      onClick={handleGetPicRemove}
                    />
                  </label>
                ) : null}
              </Col>
              <Col xs="6"></Col>
            </Row>
          </div>

          <input
            type="file"
            onChange={(e) => handlePicChange(e)}
            onClick={(e) => (e.target.value = null)}
            accept="image/x-png,image/jpeg,image/svg+xml"
            className="sr sr-only"
            id="editPicProfile"
            name="photo"
          />
          {isValidImage ? (
            <span className="text-danger"> Invalid format</span>
          ) : null}
          <FormGroup>
            <Label className="required mt-21" for="Company Name*">
              Company Name
            </Label>
            <Input
              type="text"
              name="companyName"
              id="CompanyName*"
              minLength="1"
              maxLength="50"
              value={summary.companyName}
              onChange={handleChange}
              placeholder=""
              disabled
            />
            {formDetails.submitted &&
              formDetails.errors &&
              formDetails.errors.companyName && (
                <small className="form-text text-danger">
                  {formDetails.errors.companyName[0]}
                </small>
              )}
          </FormGroup>

          <FormGroup>
            <Label for="Company Website">Company Website</Label>
            <Input
              type="text"
              name="companyWebsite"
              id="companyWebsite"
              minLength="9"
              maxLength="30"
              value={summary.companyWebsite}
              onChange={handleChange}
              placeholder=""
            />
          </FormGroup>

          <FormGroup>
            <Label className="required" for="Whatwedo">
              What do we do?
            </Label>
            <textarea
              cols="100"
              type="text"
              name="companyBriefDescription"
              value={summary.companyBriefDescription}
              id="companyPurpose"
              minLength="1"
              maxLength="3000"
              placeholder="3000 characters max"
              onChange={handleChange}
            />
            {formDetails.submitted &&
              formDetails.errors &&
              formDetails.errors.companyBriefDescription && (
                <small className="form-text text-danger">
                  {formDetails.errors.companyBriefDescription[0]}
                </small>
              )}
          </FormGroup>

          <FormGroup>
            <Label for="YearFounded">Year Founded</Label>
            <Input
              type="number"
              name="foundationYear"
              max="9999"
              min="0000"
              onInput={(e) => (e.target.value = e.target.value.slice(0, 4))}
              value={summary.foundationYear}
              id="companyFounded"
              onKeyDown={(e) => fun(e)}
              onChange={handleChange}
              placeholder=""
            />
          </FormGroup>

          <FormGroup>
            <Label for="NumberofEmployees">Number of Employees</Label>
            <Input
              type="number"
              name="numberOfEmployees"
              value={
                summary.numberOfEmployees > 0 ? summary.numberOfEmployees : null
              }
              onChange={handleChange}
              onInput={(e) => (e.target.value = e.target.value.slice(0, 8))}
              min="1"
              max="99999999"
              id="companyEmployees"
              onKeyDown={(e) => fun(e)}
              placeholder=""
            />
          </FormGroup>

          <FormGroup>
            <Label for="NumberofLocations">Number of Locations</Label>
            <Input
              type="number"
              name="numberOfLocations"
              onInput={(e) => (e.target.value = e.target.value.slice(0, 4))}
              min="1"
              max="9999"
              value={
                summary.numberOfLocations > 0 ? summary.numberOfLocations : null
              }
              id="numberOfLocations"
              onKeyDown={(e) => fun(e)}
              onChange={handleChange}
              placeholder=""
            />
          </FormGroup>
          <FormGroup>
            <p className="fontsize-16 mb-16">Certification/Compliances</p>
            <div className="row" style={{marginBottom: "10px"}}>
              <div className="col-md-12 p-0">
                <div className="">
                  <button
                    className="add p-0 bg-transparent ml-0"
                    type="button"
                    onClick={() => handleAdd()}
                  >
                    <img src={plus} className="delete-photo" />
                    <span className="Btn-text">Add certificates</span>
                  </button>
                </div>
              </div>
            </div>

            {/* <AddButton
              value={setFields}
              img={getImage}
              logoText={setLogoText}
              isShow={isShow}
              setIsShow={setIsShow}
              single={setSingle}
              open={setOpen}
            /> */}
            <AddLocation
                  value={setFields}
                  img={getImage}
                  logoText={setLogoText}
                  isShow={isShow}
                  certificate={true}
                  setIsShow={setIsShow}
                  single={setSingle}
                  open={setOpen}
                />
            {/* <AddButton
              value={setFields}
              multipleCertificate={getMultiCertificates}
              isSingle={checkSingleCertificate}
              isRequired={checkRequired}
              open={getOpen}
              isShow={isShow}
              setIsShow={setIsShow}
            /> */}
            {
              <ul className="oodl-ul mb-0">
                <div className="row add-client-parent">
              {summary.companyCertificates && summary?.companyCertificates.length
                ? summary.companyCertificates?.map((item, index) =>
                    item?.certificateImageUrl?.includes(".png") ||
                    item?.certificateImageUrl?.includes(".jpg") ||
                    (item?.certificateImageUrl?.includes(".jpeg") &&
                      (item?.certificateImageUrl != null ||
                        item?.certificateImageText != null)) ? (
                      <div className="col-md-4 pl-0">
                        <div className="client-innfo-container">
                          <img
                            className="add-client-images"
                            src={item?.certificateImageUrl}
                          />

                          <img
                            src={delicon}
                            className="client-delete-icon"
                            onClick={() => deleteGallery(item.id, index)}
                          />
                        </div>
                      </div>
                    ) : item?.certificateImageText ? (
                      <div className="col-md-4 pl-0">
                        <div className="client-innfo-container">
                          {item?.certificateImageText?.length > 10 ? (
                            <p
                              title={item?.certificateImageText}
                              className="companyLogodiv"
                            >
                              {item?.certificateImageText &&
                                item?.certificateImageText?.substring(0, 10)}
                              ...
                            </p>
                          ) : (
                            <p
                              title={item?.certificateImageText}
                              className="companyLogodiv"
                            >
                              {item?.certificateImageText && item?.certificateImageText}
                            </p>
                          )}
                          <img
                            src={delicon}
                            className="client-delete-icon"
                            onClick={() => deleteGallery(item.id, index)}
                          />
                        </div>{" "}
                      </div>
                    ) : null
                  )
                : null}
            </div>
                {/* {arr?.length > 0
                  ? arr?.map((val, index) => (
                      <div className="certi-delete-icon">
                        <li title={val} className="oodl">
                          {val}
                          <span
                            className="certificate-delete-icon"
                            onClick={(e) => deleteGallery(val, index)}
                          >
                            x
                          </span>
                        </li>
                      </div>
                    ))
                  : null} */}
              </ul>
            }
            {/* {required ? <small className="text-danger">required</small> : null} */}
          </FormGroup>

          <div className="Buis-Button pt-0">
            <Button
              className="BusinessNext-btn"
              disabled={isValidImage || formDetails.errors}
              color="primary"
              type="submit"
            >
              {/* {isLoading ? <Spinner /> : 'Next'} */}
              Next
            </Button>
            {/* <Button className="BusinessBack-btn" outline color="primary" >Back</Button> */}
          </div>
          <NotificationContainer />
        </Form>
      </div>
    </>
  );
};

export default Summary;
