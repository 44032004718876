
const initialState = {
  files: []
}
export const uploadCustfileReducer = (state = initialState, action) => {
  console.log(action, 'in upload custfile')
  switch (action?.type) {
    case 'ADD_IMAGE': return {
      ...state,
      files: [
        ...state.files,
        action.payload
      ]
    };
    case 'REMOVE_IMAGE': {
      return {
        ...state,
        files: [
          ...state.files.slice(0, action.payload),
          ...state.files.slice(action.payload + 1)
        ],

      };
    }
    case 'REMOVE_ALL_IMAGES': {
      return {
        ...state,
        files: [],

      };
    }
    case 'ADD_ALL_IMAGES': {
      console.log(action,'Add all image')
      return {
        ...state,
        files: action.payload

      };
    }

    default: return state
  }
}