import { userConstants } from "../constants/user.constants";
import { apiService } from "../services";
import { getCompanyInfo, getUserInfo } from "../services/auth.service";


//login action creator
export const getUserInfoAction = (id) => {
  
  return (dispatch) => {
    dispatch({ type: userConstants.REQUEST_INITIATE });
    return getUserInfo(id).then((res) => {
      
      if (res.isSuccess) {
        localStorage.setItem('role', res.data.accountType)

        return dispatch({ type: userConstants.REQUEST_SUCCESS, user: res.data, message: res.message });
      } else {
        dispatch({ type: userConstants.REQUEST_FAILED, user: res.data, message: res.message });
      }
    }).catch((err) => {
      dispatch({ type: userConstants.REQUEST_ERROR, user: '', message: err });
    })
  }

};

//company details info
export const getCompanyInfoAction = (id) => {
  return (dispatch) => {
    dispatch({ type: userConstants.COMPANY_REQUEST_INITIATE });
    return getCompanyInfo(id).then((res) => {
      if (res.isSuccess) {
        return dispatch({ type: userConstants.COMPANY_REQUEST_SUCCESS, user: res.data, message: res.message });
      } else {
        dispatch({ type: userConstants.COMPANY_REQUEST_FAILED, user: res.data, message: res.message });
      }
    }).catch((err) => {
      dispatch({ type: userConstants.COMPANY_REQUEST_ERROR, user: '', message: err });
    })
  }

};


//Forget Password acion creator
export const ForgetPasswordActionCreator = (email) => async (dispatch) => {

  let formData = new FormData();    //formdata object

  formData.append('email', email);   //append the values with key, value pair

  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  }

  const data = await apiService.postRequest(`user/forgotPassword`, formData, config);
  
  dispatch({
    type: userConstants.FORGET_PASSWORD_SUCCESS,
    // payload:email
  });
};

//logout action creator
// export const LogoutActionCreator = (token) => async (dispatch) => {
//   const data = await axios.post(USER_LOGOUT, "", {
//     headers: { Authorization: token },
//   });
//   dispatch({
//     type: userConstants.USER_LOGOUT_SUCCESS,
//   });
//   window.localStorage.clear();
// };



export const changeLinkTypeAction=()=>(dispatch)=>{
 
  dispatch({
    type:userConstants.FABRIC});
}
export const changeLinkTypeActionjob=(dispatch)=>{
 
  dispatch({
    type:userConstants.JOB_WORK});
}
