import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import del from '../../assets/delete2.png'
import { Card, Button, CardTitle, CardText, Row, Col, Spinner } from 'reactstrap'

import Alert from '../../assets/alert.jpg'



class DeleteRfq extends React.Component {
    constructor(props) {

        super()

    }
    submit = (props) => {

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <p className="Alert">   <img src={Alert} className="alert-product" onClick={this.submit} />Alert !</p>
                        <h6 className="AlertNotheading">Are you sure you want to permanently delete this entry?</h6>
                        <div className="Alrtbuttons">
                            <button onClick={onClose} className="Notdelted">No</button>
                            <Button className="YesDeleteIt" onClick=
                                {() => this.props.handleClickDelete(this.props.id, onClose)}>
                                Yes
                            </Button>
                        </div>

                    </div>
                );
            }
        });
    };

    render() {

        return (
            <div className='del-container'>
                <img src={del} className="delete-rfq" onClick={this.submit} />
            </div>
        );
    }
}

export default DeleteRfq;