import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap";
import "./Rfq.css";
import edit from "../../../assets/edit.png";
import delicon from "../../../assets/delete2.png";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import { stages } from "../../../models/enum";
import { AddrfqDetailsApi, FileUploadApi, GetRFQApi } from "../../../services";
import { Success } from "../../Alert";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import * as moment from "moment";
import validate from "validate.js";
import save from "../../../assets/save.png";
import DatePicker from "react-datepicker";
// import DatePicker from "react-bootstrap-date-picker";
import { DatePicker as NewPicker } from "reactstrap-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import { toRoman } from "../../../services/main.service";
import { toast } from 'react-toastify';
import { toastOptions } from "../../../helpers/toast-options";
import { useDispatch, useSelector } from "react-redux";

validate.validators.presence.options = {
  message: "^mandatory field must be filled to proceed",
};

const constraints = {
  printFromDate: { presence: { allowEmpty: false } },
  printToDate: { presence: { allowEmpty: false } },
  printshrinkage: { presence: { allowEmpty: false } },

  // printPrice: { presence: { allowEmpty: false }, },
  dyedToDate: { presence: { allowEmpty: false } },
  dyedStartDate: { presence: { allowEmpty: false } },
  dyedshrinkage: { presence: { allowEmpty: false } },

  // dyedPrice: { presence: { allowEmpty: false }, },
};
const constraintsPrint = {
  printFromDate: { presence: { allowEmpty: false } },
  printToDate: { presence: { allowEmpty: false } },
  printshrinkage: { presence: { allowEmpty: false } },

  // printPrice: { presence: { allowEmpty: false }, },
};
const constraintsDyed = {
  dyedToDate: { presence: { allowEmpty: false } },
  dyedStartDate: { presence: { allowEmpty: false } },
  dyedshrinkage: { presence: { allowEmpty: false } },

  // dyedPrice: { presence: { allowEmpty: false }, },
};
const constraintsGreige = {
  printQuantity: { presence: { allowEmpty: false } },
  printFromDate: { presence: { allowEmpty: false } },
  printToDate: { presence: { allowEmpty: false } },


  // printPrice: { presence: { allowEmpty: fConstructionalse }, },
};

const RfqDyed13 = (props) => {
  const dispatch = useDispatch();
  const linkType = useSelector(state => state.fabricLinkReducer.linkType)
  let rfdNumber = localStorage.getItem("rfqNumber");
  let rfdID = localStorage.getItem("rfqID");
  let stageName = localStorage.getItem("stageName");
  let stage;
  let body;

  if (stageName == "Processed" || stageName == 'YarnDyed') {
    stage = stages.RFQ_IN_PRG4;
  } else {
    stage = stages.RFQ_IN_PRG3;
  }
  let fileImage = "";
  const [isLoading, setIsLoading] = useState(false);
  const [disablingErrors, setDisablingErrors] = useState();

  const [details, setDetails] = useState([]);
  const [toastId, setToastId] = useState();

  const [dateState, setDateState] = useState(null);
  const [type, setType] = useState("");
  const [rfqDetails, setRfqDetails] = useState({
    id: rfdID,
    endBuyerName: "",
    orderImageUrl: "",
    orderComment: "",
    printQuantity: "",
    printPrice: "",
    printshrinkage: "",
    printFromDate: "",
    printToDate: "",
    dyedStartDate: "",
    dyedToDate: "",
    dyedPrice: "",
    dyedshrinkage: "",
    dyedQuantity: "",
    rfqOrderDetailDTOS: [],
    minDate: moment().format("YYYY-MM-DD"),
  });
  const [apiValues, setApiValues] = useState({});
  const [valueGet, setValueGet] = useState({
    warpCount: "",
    warpTypeId: "",
    weftComment: "",
    weftCount: "",
    weftTypeId: "",
    warpComment: "",
    fabricConstructionComment: "",
    gsmValue: "",
    processType: "",
    fabricReed: "",
    fabricPick: "",
    dyedWidth: "",
    printWidth: "",
    fabricType: "",
    fabricStage: "",
    weaveType: "",
    dyeCustomizations: [],
    printCustomizations: [],
    warpYarnDTOList: [],
    weftYarnDTOList: []
  });
  const fun = (e) => {
    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    if (e.keyCode == 190) {
      e.preventDefault();
      return false;
    }
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  };

  const funs = (e) => {
    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    if (e.keyCode == 190) {
      // e.preventDefault();
      return true;
    }

    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  const [formDetails, setFormDetails] = useState({
    submitted: false,
    errors: null,
  });
  useEffect(() => {
    if (valueGet.processType == "PRINT") {
      const errors = validate(rfqDetails, constraintsPrint);
      if (
        rfqDetails.printFromDate &&
        rfqDetails.printToDate &&
        new Date(rfqDetails.printFromDate).getTime() >
        new Date(rfqDetails.printToDate).getTime()
      ) {
        setFormDetails((prev) => ({
          ...prev,
          errors: { ...errors, reverseDate: ["To date must be greater than From date"] },
        }));
      } else {
        setFormDetails((prev) => ({
          ...prev,
          errors,
        }));
      }
      setDisablingErrors(validate(rfqDetails, constraintsPrint));
    }
    if (valueGet.processType == "DYED") {
      const errors = validate(rfqDetails, constraintsDyed);
      if (
        rfqDetails.dyedStartDate &&
        rfqDetails.dyedToDate &&
        new Date(rfqDetails.dyedStartDate).getTime() >
        new Date(rfqDetails.dyedToDate).getTime()
      ) {
        setFormDetails((prev) => ({
          ...prev,
          errors: { ...errors, reverseDate: ["To date must be greater than From date"] },
        }));
      } else {
        setFormDetails((prev) => ({
          ...prev,
          errors,
        }));
      }
      setDisablingErrors(validate(rfqDetails, constraintsDyed));
    }
    if (valueGet.processType == "Print & Dyed") {
      const errors = validate(rfqDetails, constraints);
      if (
        new Date(rfqDetails.printFromDate).getTime() >
        new Date(rfqDetails.printToDate).getTime() ||
        new Date(rfqDetails.dyedStartDate).getTime() >
        new Date(rfqDetails.dyedToDate).getTime()
      ) {
        setFormDetails((prev) => ({
          ...prev,
          errors: { ...errors, reverseDate: ["To date must be greater than From date"] },
        }));
      } else {
        setFormDetails((prev) => ({
          ...prev,
          errors,
        }));
      }
      setDisablingErrors(validate(rfqDetails, constraints));
    }

    if (valueGet.fabricStage != "Processed") {
      const errors = validate(rfqDetails, constraintsGreige);
      if (
        rfqDetails.printFromDate &&
        rfqDetails.printToDate &&
        new Date(rfqDetails.printFromDate).getTime() >
        new Date(rfqDetails.printToDate).getTime()
      ) {
        setFormDetails((prev) => ({
          ...prev,
          errors: { ...errors, reverseDate: ["To date must be greater than From date"] },
        }));
      } else {
        setFormDetails((prev) => ({
          ...prev,
          errors,
        }));
      }
      setDisablingErrors(validate(rfqDetails, constraintsGreige));
    }
  }, [rfqDetails]);

  // const setCustomValiation = (data) => {
  //
  //   if (data.printFromDate <= 0) {
  //     setRfqDetails((pre) => ({ ...pre, printFromDate: "" }))
  //   } if (data.printPrice <= 0) {
  //     setRfqDetails((pre) => ({ ...pre, printPrice: "" }))
  //   } if (data.printToDate <= 0) {
  //     setRfqDetails((pre) => ({ ...pre, printToDate: "" }))
  //   }
  //   if (data.dyedToDate <= 0) {
  //     setRfqDetails((pre) => ({ ...pre, dyedToDate: "" }))
  //   } if (data.dyedStartDate <= 0) {
  //     setRfqDetails((pre) => ({ ...pre, dyedStartDate: "" }))
  //   }
  //   if (data.dyedPrice <= 0) {
  //     setRfqDetails((pre) => ({ ...pre, dyedPrice: "" }))
  //   }
  // }

  const getFabricTypeApi = () => {
    setIsLoading(true);
    console.log(props)
    if (props.viewType == "view") {
      localStorage.setItem("rfqID", props.viewData.rfqId);
      // localStorage.removeItem('stageName')

      localStorage.removeItem("rfqNumber");
      // lo                                                                                                                                       calStorage.setItem('rfqNumber', props.viewData.rfqNumber)
      localStorage.setItem("rfqNumber", props.viewData.rfqNumber);
      body = {
        id: localStorage.getItem("rfqID"),
        rfqStage: stage,
      };
    } else {
      body = {
        id: rfdID,
        rfqStage: stage,
      };
    }
    GetRFQApi(body).then((res) => {
      //
      if (res.isSuccess) {
        setIsLoading(false);

        let details1 = res.data.rfqOrderDetails.map((item) => {
          let payload = {
            id: item.id,
            processTypes: item.processType,
          };
          return payload;
        });
        setDetails([...details1]);
        setType(res.data.fabricType);
        setValueGet(res.data);
        setApiValues(res.data);
        setRfqDetails({
          id: rfdID,
          endBuyerName: res.data.endBuyerName,
          orderComment: res.data.orderComment,
        });
        if (res.data.workType == 'FABRIC') {

          dispatch({ type: 'fabric' })
          if (localStorage.getItem('linkType') != 'fabric')
            localStorage.setItem('linkType', 'fabric');

        } else {

          dispatch({ type: 'job_work' })
          if (localStorage.getItem('linkType') != 'job_work')
            localStorage.setItem('linkType', 'job_work');


        }
        if (res.data.fabricStage === "Processed") {
          if (res.data.processType === "Print & Dyed") {
            res.data.rfqOrderDetails.map((val, index) => {
              if (val.processType === 'PRINT') {
                setRfqDetails((prev) => ({
                  ...prev,
                  printQuantity: val.quantity,
                  printPrice: val.targetPrice <= 0 ? "" : val.targetPrice,
                  printshrinkage: val.shrinkage <= 0 ? "" : val.shrinkage,
                  printFromDate: val.expectedDeliveryStartDate
                    ? moment(
                      val.expectedDeliveryStartDate,
                      "DD/MM/YYYY"
                    ).format("YYYY-MM-DD")
                    : "",
                  printToDate: val.expectedDeliveryEndDate
                    ? moment(val.expectedDeliveryEndDate, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    )
                    : "",
                }));
              } else {
                setRfqDetails((prev) => ({
                  ...prev,
                  dyedQuantity: val.quantity,
                  dyedPrice: val.targetPrice <= 0 ? "" : val.targetPrice,
                  dyedshrinkage: val.shrinkage <= 0 ? "" : val.shrinkage,
                  dyedStartDate: val.expectedDeliveryStartDate
                    ? moment(
                      val.expectedDeliveryStartDate,
                      "DD/MM/YYYY"
                    ).format("YYYY-MM-DD")
                    : "",
                  dyedToDate: val.expectedDeliveryEndDate
                    ? moment(val.expectedDeliveryEndDate, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    )
                    : "",
                }));
              }
            });
            //
          } else if (res.data.processType === "PRINT") {
            console.log('print')
            res.data.rfqOrderDetails.map((val) => {
              setRfqDetails((prev) => ({
                ...prev,
                printQuantity: val.quantity,
                printPrice: val.targetPrice <= 0 ? "" : val.targetPrice,
                printshrinkage: val.shrinkage <= 0 ? "" : val.shrinkage,
                printFromDate: val.expectedDeliveryStartDate
                  ? moment(val.expectedDeliveryStartDate, "DD/MM/YYYY").format(
                    "YYYY-MM-DD"
                  )
                  : "",
                printToDate: val.expectedDeliveryEndDate
                  ? moment(val.expectedDeliveryEndDate, "DD/MM/YYYY").format(
                    "YYYY-MM-DD"
                  )
                  : "",
              }));
            });
          } else if (res.data.processType === "DYED") {
            res.data.rfqOrderDetails.map((val) => {
              setRfqDetails((prev) => ({
                ...prev,
                dyedQuantity: val.quantity,
                dyedPrice: val.targetPrice <= 0 ? "" : val.targetPrice,
                dyedshrinkage: val.shrinkage <= 0 ? "" : val.shrinkage,
                dyedStartDate: val.expectedDeliveryStartDate
                  ? moment(val.expectedDeliveryStartDate, "DD/MM/YYYY").format(
                    "YYYY-MM-DD"
                  )
                  : "",
                dyedToDate: val.expectedDeliveryEndDate
                  ? moment(val.expectedDeliveryEndDate, "DD/MM/YYYY").format(
                    "YYYY-MM-DD"
                  )
                  : "",
              }));
            });
          }
        } else {
          res.data.rfqOrderDetails.map((val) => {
            setRfqDetails((prev) => ({
              ...prev,
              printQuantity: val.quantity <= 0 ? "" : val.quantity,
              printPrice: val.targetPrice <= 0 ? "" : val.targetPrice,
              printshrinkage: val.shrinkage <= 0 ? "" : val.shrinkage,
              printFromDate: val.expectedDeliveryStartDate
                ? moment(val.expectedDeliveryStartDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                )
                : "",
              printToDate: val.expectedDeliveryEndDate
                ? moment(val.expectedDeliveryEndDate, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                )
                : "",
            }));
          });
        }

        if (res.data.orderImageUrl) {
          setRfqDetails((prev) => ({
            ...prev,
            id: rfdID,
            endBuyerName: res.data.endBuyerName,
            orderComment: res.data.orderComment,
            orderImageUrl: res.data.orderImageUrl,
          }));
          setPic({
            preview: res.data.orderImageUrl,
            pdf: res.data.orderImageUrl.includes('.pdf') ? true : false
          });
          setShowText(true);
        }
        if (res.data.processType == "DYED") {
          setRfqDetails((prev) => ({
            ...prev,
            id: rfdID,
            endBuyerName: res.data.endBuyerName,
            orderComment: res.data.orderComment,
            // orderImageUrl: res.data.orderImageUrl,
          }));
          setShowText(true);
        }
        // setCustomValiation(res.data)
      }
    });
  };

  useEffect(() => {
    getFabricTypeApi();
  }, []);

  const [showText, setShowText] = useState(false);
  const [pic, setPic] = useState({
    preview: "",
    previewVisible: false,
    raw: "",
    invalidFile: false,
    pdf: false,
  });
  const handlePicChange = (e) => {
    //
    const files = e.target.files;
    fileImage = e.target.files[0];
    const fileName = e.target.value;
    // setFileImage(e.target.files[0])
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;
    if (!allowedExtensions.exec(fileName)) {
      toast.error('Please upload file having extensions .jpeg/.jpg/.png/.pdf only.');
      e.target.value = '';
      return false;
    }
    if (files !== null && files.length) {

      setPic({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        invalidFile: true,
        pdf: fileImage.type == 'application/pdf' ? true : false
      });

    }
  };
  const getprocesstype = (processtype) => {
    if (linkType == 'fabric') {
      return `(${processtype})`;
    } else {
      switch (processtype) {
        case 'PRINT':
          {
            return 'Printing';

          }
        case 'DYED': {
          return 'Dyeing';
        }
        default: {
          return 'Printing & Dyeing';
        }
      }
    }
  }
  const picShow = () => {
    if (!showText) {
      setShowText(true);
    }
  };

  function handlePicRemove() {
    if (showText) {
      rfqDetails.orderImageUrl = "";
      setPic({ preview: false });
    }
  }

  const handleChange = (e, fieldName) => {

    const { name, value } = e.target;
    console.log(name, value)
    if (name == 'printshrinkage' || name == 'dyedshrinkage') {
      if (value > 99) {
        e.target.value = ''
        return;
      }
    }
    setRfqDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (e, fieldName) => {
    if (fieldName) {
      var name = fieldName;
      var value = moment(e).format("YYYY-MM-DD");
    } else {
      var name = "";
      var value = "";
    }

    setRfqDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBack = () => {
    if (valueGet.fabricStage == "Processed") {
      console.log(valueGet.fabricStage)
      // props.fromBack1(1);
      props.fromBack(1);
    } else {
      props.fromBack2();
    }
  };

  const submitHandler = async () => {
    setFormDetails((prev) => ({
      ...prev,
      submitted: true,
    }));
    if (formDetails.errors) {
      const currentError = Object.keys(formDetails.errors).map(key => formDetails.errors[key][0]);
      if (!toastId) {
        const id = toast.error(currentError[0], { ...toastOptions, onClose: () => setToastId(null) });
        setToastId(id);
      }
      return;
    }
    let imageUrl = "";

    if (pic.raw) {
      let formData = new FormData();
      formData.append("file", pic.raw);

      await FileUploadApi(formData).then((data) => {
        //
        if (data.isSuccess) {
          //
          imageUrl = data.data;
          // setRfqDetails({orderImageUrl:data.data})
          rfqDetails.orderImageUrl = imageUrl;
        }
      });
    }

    if (apiValues.fabricStage === "Processed") {
      if (apiValues.processType === "Print & Dyed") {
        apiValues.rfqOrderDetails.map((val, index) => {
          if (val.processType.toUpperCase() === 'PRINT') {
            val.quantity = rfqDetails.printQuantity;
            val.targetPrice = rfqDetails.printPrice;
            val.shrinkage = rfqDetails.printshrinkage;
            val.expectedDeliveryStartDate = moment(
              rfqDetails.printFromDate
            ).format("DD/MM/YYYY");
            val.expectedDeliveryEndDate = moment(rfqDetails.printToDate).format(
              "DD/MM/YYYY"
            );
          } else {
            val.quantity = rfqDetails.dyedQuantity;
            val.targetPrice = rfqDetails.dyedPrice;
            val.shrinkage = rfqDetails.dyedshrinkage;
            val.expectedDeliveryStartDate = moment(
              rfqDetails.dyedStartDate
            ).format("DD/MM/YYYY");
            val.expectedDeliveryEndDate = moment(rfqDetails.dyedToDate).format(
              "DD/MM/YYYY"
            );
          }
        });
      } else if (apiValues.processType === "PRINT") {
        apiValues.rfqOrderDetails.map((val) => {
          val.quantity = rfqDetails.printQuantity;
          val.targetPrice = rfqDetails.printPrice;
          val.shrinkage = rfqDetails.printshrinkage;
          val.expectedDeliveryStartDate = moment(
            rfqDetails.printFromDate
          ).format("DD/MM/YYYY");
          val.expectedDeliveryEndDate = moment(rfqDetails.printToDate).format(
            "DD/MM/YYYY"
          );
        });
      } else if (apiValues.processType === "DYED") {
        apiValues.rfqOrderDetails.map((val) => {
          val.quantity = rfqDetails.dyedQuantity;
          val.targetPrice = rfqDetails.dyedPrice;
          val.shrinkage = rfqDetails.dyedshrinkage;
          val.expectedDeliveryStartDate = moment(
            rfqDetails.dyedStartDate
          ).format("DD/MM/YYYY");
          val.expectedDeliveryEndDate = moment(rfqDetails.dyedToDate).format(
            "DD/MM/YYYY"
          );
        });
      }
    } else {
      apiValues.rfqOrderDetails.map((val) => {
        val.quantity = rfqDetails.printQuantity;
        val.targetPrice = rfqDetails.printPrice;
        val.shrinkage = rfqDetails.printshrinkage;
        val.expectedDeliveryStartDate = moment(rfqDetails.printFromDate).format(
          "DD/MM/YYYY"
        );
        val.expectedDeliveryEndDate = moment(rfqDetails.printToDate).format(
          "DD/MM/YYYY"
        );
      });
    }

    rfqDetails.rfqOrderDetailDTOS = apiValues.rfqOrderDetails;

    rfqDetails.id = rfdID;
    if (!formDetails.errors) {
      AddrfqDetailsApi(rfqDetails).then((res) => {
        if (res.isSuccess) {
          props.fromAction(1);
          // Success(res.message)
          // setTimeout(() => {

          // }, 3000);
        }
      });
    }
  };
  function romanize(num) {
    if (isNaN(num)) return NaN;
    var digits = String(+num).split(""),
      key = [
        "",
        "C",
        "CC",
        "CCC",
        "CD",
        "D",
        "DC",
        "DCC",
        "DCCC",
        "CM",
        "",
        "X",
        "XX",
        "XXX",
        "XL",
        "L",
        "LX",
        "LXX",
        "LXXX",
        "XC",
        "",
        "I",
        "II",
        "III",
        "IV",
        "V",
        "VI",
        "VII",
        "VIII",
        "IX",
      ],
      roman = "",
      i = 3;
    while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
  }
  const handleBack4 = () => {
    props.fromBack4(1);
  };
  const handleBack3 = () => {
    // props.fromBack3(1);
    props.fromBack4(1);
  };
  const handleBack2 = () => {
    props.fromBack(1);
  };

  const handleBack2A = () => {
    props.fromBack3(1);
  };
  const capitalizeFirstLowercaseRest = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  const showsusforwarp = () => {
    let i = valueGet.warpYarnDTOList.findIndex(w => w.sustainabilityId != 0);
    console.log(i)
    if (i != -1) {
      return true;
    }
    else {
      return false;
    }
  };
  return (
    <>
      {/* <h4 className="RFQ-h4">{valueGet.name} | RFQ #{rfdNumber}</h4> */}
      <h4 className="RFQ-h4">
        Order {localStorage.getItem("orderName")} | RFQ #{rfdNumber}{linkType != 'fabric' && ' | Job Work'}
      </h4>

      <Row className="RFQ-Row">
        <Card className="RFQ-order-kate1">
          <div className="para-edit py-0">
            <div className="para-para">
              <p className="input-saved">
                1. Type of Fabric:
                <span className="bold-text text-capitalize">
                  {capitalizeFirstLowercaseRest(valueGet.fabricType)}
                </span>
              </p>

              {valueGet.fabricStage != "Processed" ? (
                <p className="inputs-saved ">
                  2. Fabric Stage:
                  <span className="bold-text text-capitalize">{valueGet.fabricStage==='YarnDyed'?'Yarn Dyed': capitalizeFirstLowercaseRest(valueGet.fabricStage)}</span>
                </p>
              ) : (
                <p className="inputs-saved">
                  {linkType == 'fabric' ? ' 2. Fabric Stage:' : '2. Job Work:'}
                  <span className="bold-text text-capitalize">
                    {linkType == 'fabric' && valueGet.fabricStage} {getprocesstype(valueGet.processType)}
                  </span>
                </p>
              )}
            </div>
            <img src={edit} className="edit-icon" onClick={handleBack3} />
          </div>

          <hr className="hr-order-kate" />
          <div className="para-edit">
            <p className="input-saved">
              3. {type.toLowerCase() == 'woven' ? ' Type of Weave' : type.toLowerCase() == 'knit' ? ' Fabric Structure' : ''}:
              <span className="bold-text">{valueGet.weaveType}</span>
            </p>
            <img src={edit} className="edit-icon" onClick={handleBack2A} />
          </div>

          <hr className="hr-order-kate" />
          {linkType == 'job_work' && <p className="input-saved"><i>Details of fabric being sent for job work</i> </p>}
          <div className="para-edit">
            <p className="input-saved">4. {type.toLowerCase() == 'woven' ? ' Warp and Weft parameters' : type.toLowerCase() == 'knit' ? ' Wale and Course parameters' : ''}</p>
            <img src={edit} className="edit-icon" onClick={handleBack} />
          </div>
          <div className="row w-100 ml-0">
            <p className="col-6 warp-p pr-0 pl-32">(i) {type.toLowerCase() == 'woven' ? ' Warp' : type.toLowerCase() == 'knit' ? ' Wale' : ''}</p>
            <p className="col-6 weft-p px-0">(ii) {type.toLowerCase() == 'woven' ? ' Weft' : type.toLowerCase() == 'knit' ? ' Course' : ''}</p>
          </div>

          <div className="row w-100 ml-0 mb-24">
            <div className="col-6">
              <div className="input-saved mb-8 ml-41">
                Count:
                <span className="bold-text ">{valueGet.warpCount}</span>
              </div>
              <div className="input-saved mb-8 ml-41">
                Type of yarn:
                <span className="bold-text">{valueGet.warp}</span>
              </div>
              {valueGet.warpYarnDTOList.length > 0 && valueGet.warpYarnDTOList.findIndex(w => w.sustainabilityId != 0) != -1 && <div className="input-saved d-flex mb-8 ml-41">
                <div> Sustainability:</div><div>
                  {valueGet.warpYarnDTOList.map(item => {
                    return (
                      <>
                        {item.sustainabilityName ? <> <span className="bold-text">{item.yarnName}: {item.sustainabilityName}</span><br /></> : <span></span>}
                      </>
                    )
                  })}
                </div>
              </div>}
              <div className="input-saved ml-41">
                Comments:
                <span className="bold-text ml-18" title={valueGet.warpComment}>
                  {valueGet.warpComment
                    ? `${valueGet.warpComment.length > 400
                      ? valueGet.warpComment.substr(0, 400) + "..."
                      : valueGet.warpComment
                    }`
                    : ""}
                </span>
              </div>
            </div>

            <div className="col-6">
              <div className="input-saved mb-8 ml-2 pl-1">
                Count:
                <span className="bold-text ">{valueGet.weftCount}</span>
              </div>
              <div className="input-saved mb-8 ml-2 pl-1">
                Type of yarn:
                <span className="bold-text">{valueGet.weft}</span>
              </div>
              {valueGet.weftYarnDTOList.length > 0 && valueGet.weftYarnDTOList.findIndex(w => w.sustainabilityId != 0) != -1 && <div className="input-saved d-flex mb-8 ">
                <div> Sustainability:</div><div>
                  {valueGet.weftYarnDTOList.map(item => {
                    return (
                      <>
                        {item.sustainabilityName ? <>  <span className="bold-text">{item.yarnName}: {item.sustainabilityName}</span><br /></> : ''}
                      </>
                    )
                  })}
                </div>
              </div>}
              <div className="input-saved ml-2 pl-1">
                Comments:
                <span className="bold-text ml-18" title={valueGet.weftComment}>
                  {valueGet.weftComment
                    ? `${valueGet.weftComment.length > 400
                      ? valueGet.weftComment.substr(0, 400) + "..."
                      : valueGet.weftComment
                    }`
                    : ""}
                </span>
              </div>
            </div>
          </div>
          {type.toLowerCase() == 'woven' ? <>
            <p className="input-saved">
              5. Fabric Construction:
              <span className="bold-text">
                {valueGet.fabricReed} X {valueGet.fabricPick}
              </span>
            </p>
            <p className="input-saved ml-3 pl-3 mt-2 mb-3">
              Comments:
              <span
                className="bold-text"
                title={valueGet.fabricConstructionComment}
              >
                {valueGet.fabricConstructionComment
                  ? `${valueGet.fabricConstructionComment.length > 600
                    ? valueGet.fabricConstructionComment.substr(0, 600) +
                    "..."
                    : valueGet.fabricConstructionComment
                  }`
                  : ""}
              </span>
            </p>
          </>
            : ''}
          <p className="input-saved mb-3">
            {type.toLowerCase() == 'woven' ? '6.' : '5.'} GSM:<span className="bold-text">{valueGet.gsmValue}</span>
          </p>
          <div className="mb-14 d-flex">
            <p className="input-saved">
              {type.toLowerCase() == 'woven' ? '7.' : '6.'} {linkType === 'job_work' ? 'Fabric' : 'Preferred Fabric'} width {type.toLowerCase() == 'woven' ? '(L-100)' : '(open width)'}:
              {valueGet.processType == "Print & Dyed" ? (
                linkType !== 'job_work' ?
                  <p className="align-right">
                    {" "}
                    <span className="font-82">(a) Print :</span>{" "}
                    <span>{valueGet.printWidth} inches</span>{" "}
                    <span className="font-82 ml-3">(b) Dyed :</span>{" "}
                    <span>{valueGet.dyedWidth} inches</span>
                  </p> :
                  <>
                    <div className="mb-2 font-82" style={{ marginLeft: '1vw', marginTop: '1.6vh' }}>
                      {" "}
                      (i) Greige Fabric Width: <span className="bold-text" style={{ marginLeft: '0 !important' }}>{valueGet.printRawWidth} inches</span>{" "}

                    </div>
                    <div style={{ marginLeft: '1vw' }}>
                      <div className="font-82" >(ii) Preferred Finished Fabric Width:</div>
                      <div className="font-82 pre-ml-18 mt-2">(a) Print :{" "} <span className="bold-text" style={{ marginLeft: '0 !important' }}>{valueGet.printWidth} inches</span>{" "}</div>
                      <div className="font-82 pre-ml-18 mt-2">(b) Dyed :{" "}
                        <span className="bold-text" style={{ marginLeft: '0 !important' }}>{valueGet.dyedWidth} inches</span>{"  "}
                      </div></div>
                  </>


              ) : null}
              {valueGet.processType == "PRINT" ? (
                linkType == 'job_work' ?
                  <>
                    <p className="font-82 " style={{ marginLeft: '1.23vw', marginTop: '1.16vh', marginBottom: '0' }}>(i) Greige Fabric Width: <span className="align-right">{valueGet.printRawWidth} inches</span></p>

                    <p className="font-82 " style={{ marginLeft: '1.23vw', marginTop: '1.16vh', marginBottom: '0' }}>(ii) Preferred Finished Fabric Width:  <span className="align-right">{valueGet.printWidth} inches</span></p>

                  </> :
                  <p className="align-right">{valueGet.printWidth} inches</p>


              ) : null}
              {valueGet.processType == "DYED" ? (
                linkType == 'job_work' ?
                  <>
                    <p className="font-82 " style={{ marginLeft: '1.23vw', marginTop: '1.16vh', marginBottom: '0' }}>(i) Greige Fabric Width: <span className="align-right">{valueGet.dyedRawWidth} inches</span></p>

                    <p className="font-82 " style={{ marginLeft: '1.23vw', marginTop: '1.16vh', marginBottom: '0' }}>(ii) Preferred Finished Fabric Width:  <span className="align-right">{valueGet.dyedWidth} inches</span></p>

                  </> :
                  <p className="align-right">{valueGet.dyedWidth} inches</p>

              ) : null}
              {valueGet.processType == "GREIGE"|| valueGet.processType == 'YARNDYED'  ? (
                <p className="align-right">{valueGet.greigeWidth} inches</p>
              ) : null}
            </p>
          </div>
          {linkType == 'job_work' && <> <p className="input-saved mb-14">
            {type.toLowerCase() == 'woven' ? '8.' : '7.'} Fabric dispatch dates to job works vendor:<span className="bold-text">{valueGet.jobWorkStartDate} to {valueGet.jobWorkEndDate}</span>
          </p></>}
          {(valueGet.processType != "GREIGE" || valueGet.fabricStage == 'YarnDyed') && <hr className="hr-order-kate" />}
          <div className="tables-p-d pt-16">
            {(valueGet.fabricStage == "Processed" || valueGet.fabricStage == 'YarnDyed') ? (
              <>
                <div className="preferred-fabric d-flex mb-0">
                  <p className="input-saved mb-8 pb-1">
                    {linkType == 'job_work' ? type.toLowerCase() == 'woven' ? '9.' : '8.' : type.toLowerCase() == 'woven' ? '8.' : '7.'} Fabric Customizations
                  </p>
                  <img src={edit} className="edit-icon" onClick={handleBack2} />
                </div>
                {valueGet.processType == "DYED" ? (
                  <Table
                    borderless
                    className=" flex-column p-0 ml-3 mb-16"
                    style={{ border: "none" }}
                  >
                    {valueGet.dyeCustomizations.length > 0 && <thead>
                      <tr className="dyed-color-custom-head d-flex">
                        <th className="roman-no">&nbsp;</th>
                        <th className="color-box">
                          <div>Colour</div>
                        </th>
                        <th className="color-code">
                          <div>Pantone Code</div>
                        </th>
                        <th className="qty-boxx ">
                          <div>Quantity</div>
                        </th>
                        <th className="qty-boxx w-60">
                          <div>QTX File</div>
                        </th>
                      </tr>
                    </thead>}
                    <tbody>
                      {valueGet.dyeCustomizations.length > 0
                        ? valueGet.dyeCustomizations.map((row, id) => (
                          <tr className="dyed-color-custom-head">
                            <td className="roman-no">({toRoman(id + 1)})</td>
                            <td
                              title={
                                row.colourName.length > 8 && row.colourName
                              }
                              className="color-box colour-text"
                            >
                              {" "}
                              {row.colourName.length > 8
                                ? row.colourName.substr(0, 8) + "..."
                                : row.colourName}
                            </td>

                            <td className="color-code colour-text">
                              <p className="max-qty" title={row.colourCode}> {row.colourCode || ""}</p>
                            </td>

                            <td className="qty-boxx w-60 colour-text">
                              {new Intl.NumberFormat('en-IN', {}).format(row.quantity)}{row.quantity > 0 && type.toLowerCase() == 'woven' ? ' m' : type.toLowerCase() == 'knit' ? ' kg' : ''}
                            </td>
                            <td className="colour-text qty-boxx text-center">
                              {row.otxFile ?
                                <>
                                  {!row.otxFile.includes("pdf") ? (
                                    <small>
                                      <a
                                        href={row.otxFile}
                                        title={row.otxFile}
                                      >
                                        <img src={save} />
                                      </a>
                                    </small>
                                  ) : (
                                    <small>
                                      <a
                                        href={row.otxFile}
                                        title={row.otxFile}
                                      >
                                        <img src={save} />
                                      </a>
                                    </small>
                                  )}
                                </> : ''}
                            </td>
                          </tr>
                        ))
                        : <tr>
                          <td style={{ 'padding': '0', fontSize: '1.6vh' }} colSpan={4}>No information provided</td>
                        </tr>}
                    </tbody>
                  </Table>
                ) : null}
                {valueGet.processType == "PRINT" ? (
                  <Table
                    borderless
                    className="tablerfqdyed ml-3 mb-16"
                    style={{ border: "none" }}
                  >
                    {valueGet.printCustomizations.length > 0 && <thead>
                      <tr className="dyed-color-custom-head d-flex">
                        <th className="roman-no">&nbsp;</th>
                        <th className="color-box">
                          <div>Print Name</div>
                        </th>
                        <th className="color-code" style={{ textAlign: "left" }}>
                          <div>Quantity</div>
                        </th>
                        <th className="qty-boxx-new text-left" style={{ textAlign: "left" }}>
                          <div>Print Design</div>
                        </th>
                      </tr>
                    </thead>}
                    <tbody>
                      {valueGet.printCustomizations.length > 0
                        ? valueGet.printCustomizations.map((row, id) => (
                          <tr className="dyed-color-custom-head">
                            {/* <p>{id + 1}</p> */}

                            <p title={row.printName} className="roman-no">
                              ({toRoman(id + 1)})
                            </p>

                            <p
                              title={
                                row.printName.length > 10 && row.printName
                              }
                              className="colour-text color-box"
                            >
                              {row.printName.length > 10
                                ? row.printName.substr(0, 10) + "..."
                                : row.printName}
                            </p>

                            <p className="colour-text color-code" style={{ textAlign: "left" }}>
                              {new Intl.NumberFormat('en-IN', {}).format(row.quantity)} {row.quantity > 0 && type.toLowerCase() == 'woven' ? ' m' : type.toLowerCase() == 'knit' ? ' kg' : ''}
                            </p>

                            {!row.imageUrl.includes("pdf") ? (
                              <p className="colour-text qty-boxx-new1 text-center">
                                <small>
                                  <a href={row.imageUrl} title={row.imageUrl}>
                                    <img src={save} />
                                  </a>
                                </small>
                              </p>
                            ) : (
                              <p className="colour-text qty-boxx-new1 text-center">
                                {" "}
                                <small>
                                  <a href={row.imageUrl} title={row.imageUrl}>
                                    <img src={save} />
                                  </a>
                                </small>
                              </p>
                            )}
                          </tr>
                        ))
                        : <tr>
                          <td style={{ fontSize: '1.6vh', padding: '0' }} colSpan={2}>No information provided</td>
                        </tr>}
                    </tbody>
                  </Table>
                ) : null}
                {valueGet.processType == "Print & Dyed" ? (
                  <>
                    <div className="row w-100 ml-0 print-dyed-title">
                      <p className="col-6 warp-p pr-0 pl-3 mt-0">Print</p>
                      <p className="col-6 weft-p px-0 pl-3 mt-0">Dyed</p>
                    </div>
                    <div className="Wrap-Weft-Ot">
                      <Table
                        borderless
                        className="tablerfqdyed  ml-3 mb-8"
                        style={{ border: "none" }}
                      >
                        {valueGet.printCustomizations.length > 0 && <thead>
                          <tr className="dyed-color-custom-head d-flex">
                            <th className="roman-no">&nbsp;</th>
                            <th className="color-box">
                              <div>Print Name</div>
                            </th>
                            <th className="color-code" >
                              <div>Quantity</div>
                            </th>
                            <th className="qty-boxx text-left">
                              <div>Print Design</div>
                            </th>
                          </tr>
                        </thead>}
                        <tbody>
                          {valueGet.printCustomizations.length > 0
                            ? valueGet.printCustomizations.map((row, id) => (
                              <tr className="dyed-color-custom-head">
                                <p className="roman-no">({toRoman(id + 1)})</p>
                                {/* <p>{id + 1}</p> */}
                                {/* <p title={row.printName} className="data-d w-100px">({id + 1})  {row.printName.substring(0, 5)}...</p> */}
                                <p
                                  title={
                                    row.printName.length > 10 && row.printName
                                  }
                                  className="colour-text color-box"
                                >
                                  {" "}
                                  {row.printName.length > 10
                                    ? row.printName.substr(0, 10) + "..."
                                    : row.printName}
                                </p>

                                <p className="colour-text color-code max-qty" title={row.quantity}>
                                  {new Intl.NumberFormat('en-IN', {}).format(row.quantity)} {row.quantity > 0 && type.toLowerCase() == 'woven' ? ' m' : type.toLowerCase() == 'knit' ? ' kg' : ''}
                                </p>

                                <p className="colour-text qty-boxx text-center">
                                  {!row.imageUrl.includes("pdf") ? (
                                    <small>
                                      <a
                                        href={row.imageUrl}
                                        title={row.imageUrl}
                                      >
                                        <img src={save} />
                                      </a>
                                    </small>
                                  ) : (
                                    <small>
                                      <a
                                        href={row.imageUrl}
                                        title={row.imageUrl}
                                      >
                                        <img src={save} />
                                      </a>
                                    </small>
                                  )}
                                </p>
                              </tr>
                            ))
                            : <tr>

                              <td style={{ 'padding': '0', fontSize: '1.6vh' }} colSpan={4}>No information provided</td>
                            </tr>}
                        </tbody>
                      </Table>
                      <Table
                        borderless
                        className=" flex-column p-0 ml-3 mb-8"
                        style={{ border: "none" }}
                      >
                        {valueGet.dyeCustomizations.length > 0 && <thead>
                          <tr className="dyed-color-custom-head d-flex">
                            <th className="roman-no">&nbsp;</th>
                            <th className="color-box">
                              <div>Colour</div>
                            </th>
                            <th className="color-code">
                              <div>Pantone Code</div>
                            </th>
                            <th className="qty-boxx ">
                              <div>Quantity</div>
                            </th>
                            <th className="qty-boxx w-60">
                              <div>QTX File</div>
                            </th>
                          </tr>
                        </thead>}
                        <tbody>
                          {valueGet.dyeCustomizations.length > 0
                            ? valueGet.dyeCustomizations.map((row, id) => (
                              <tr className="dyed-color-custom-head">
                                {/* <p>{id + 1}</p> */}
                                <div className="roman-no">({toRoman(id + 1)})</div>
                                <p
                                  title={
                                    row.colourName.length > 10 &&
                                    row.colourName
                                  }
                                  className="colour-text color-box"
                                >
                                  {" "}
                                  {row.colourName.length > 10
                                    ? row.colourName.substr(0, 10) + "..."
                                    : row.colourName}
                                </p>

                                <p className="colour-text color-code max-qty" title={row.colourCode}>
                                  {row.colourCode || ""}
                                </p>
                                <p className="colour-text qty-boxx text-center">
                                  {new Intl.NumberFormat('en-IN', {}).format(row.quantity)} {row.quantity > 0 && type.toLowerCase() == 'woven' ? ' m' : type.toLowerCase() == 'knit' ? ' kg' : ''}
                                </p>
                                {/* <p className="colour-text qty-boxx text-center">
                                    {row.quantity} {type.toLowerCase()=='woven'?' m':type.toLowerCase()=='knit'?' kg':''}
                                  </p> */}
                                <p className="colour-text qty-boxx text-center">
                                  {row.otxFile ?
                                    <>
                                      {!row.otxFile.includes("pdf") ? (
                                        <small>
                                          <a
                                            href={row.otxFile}
                                            title={row.otxFile}
                                          >
                                            <img src={save} />
                                          </a>
                                        </small>
                                      ) : (
                                        <small>
                                          <a
                                            href={row.otxFile}
                                            title={row.otxFile}
                                          >
                                            <img src={save} />
                                          </a>
                                        </small>
                                      )}
                                    </> : ''}
                                </p>
                              </tr>
                            ))
                            : <tr>

                              <td style={{ 'padding': '0', fontSize: '1.6vh' }} colSpan={3}>No information provided</td>
                            </tr>}
                        </tbody>
                      </Table>
                    </div>
                  </>
                ) : null}
                {valueGet.fabricStage == 'YarnDyed' && (
                  <div className=" w-100 ml-0 mb-16">
                    <p className=" warp-p pr-0 pl-3 mt-0" style={{ marginBottom: '1.6vh' }}>(i) Images/Files</p>
                    {valueGet.yarnDyedCustomisationDTO.yarnDyedImages.length > 0 && <table className="pl-3 mt-0 image-table"
                      style={{ marginLeft: '2.5vw' }}>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Size</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {valueGet.yarnDyedCustomisationDTO.yarnDyedImages.map((file, idx) => {
                          return (
                            <tr>
                              <td>{file.imageName?.split('.')[0]}</td>
                              <td>{file.imageName?.split('.')[1]}</td>
                              <td>{(file.imageSize / 1024).toFixed(1)}KB</td>
                              <td>
                                <a href={file.imageUrl} download={file.imageName}>Download</a>

                              </td>
                            </tr>
                          )

                        })}
                        <tr>

                        </tr>
                      </tbody>
                    </table>}
                    <div style={{ marginBottom: '1.6vh' }}>
                      <p className="warp-p pr-0 pl-3 mt-0" style={{ marginBottom: '1.6vh' }}>(ii) Yarn Details(Optional) </p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-saved " style={{ marginBottom: '1.6vh' }} >
                            (a) Warp
                          </div>
                          <div className="input-saved" style={{ marginBottom: '1.6vh' }}>
                            Number of Colours:<span className="bold-text">{valueGet.yarnDyedCustomisationDTO.warpNoOfColour}</span>
                          </div>

                          {valueGet.yarnDyedCustomisationDTO.yarnDyedColours.length > 0 && <table className="view-table cust-table" style={{marginLeft:'1.12vw'}} >
                            <thead>
                              <tr>
                                <th>Colour Name</th>
                                <th>Pantone Code</th>
                                <th>Qtx File</th>

                              </tr>
                            </thead>
                            <tbody>
                              {valueGet.yarnDyedCustomisationDTO.yarnDyedColours.filter(c => c.customisationType == 'WARP').map((item, idx) => {
                                return (
                                  <tr>
                                    <td>{item.colourName}</td>
                                    <td>{item.pantoneCode}</td>
                                    <td>{item.otxFile != "" && <a href={item.otxFile}
                                      title={item.otxFile} download>Download</a>}</td>

                                  </tr>
                                )
                              })}

                            </tbody>
                          </table>}
                        </div>
                        <div className="col-md-6">
                          <div className="input-saved-nomargin" style={{ marginLeft: '0 !important', marginBottom: '1.6vh' }}>

                            (b) Weft
                          </div>
                          <div className="warp-p pr-0" style={{ marginBottom: '1.6vh' }}>
                            Number of Colours: <span className="bold-text"> {valueGet.yarnDyedCustomisationDTO.weftNoOfColour}</span>
                          </div>

                          {valueGet.yarnDyedCustomisationDTO.yarnDyedColours.filter(c => c.customisationType === 'WEFT').length > 0 && <table className="view-table cust-table">
                            <thead>
                              <tr>
                                <th>Colour Name</th>
                                <th>Pantone Code</th>
                                <th>Qtx File</th>

                              </tr>
                            </thead>
                            <tbody>
                              {valueGet.yarnDyedCustomisationDTO.yarnDyedColours.filter(c => c.customisationType === 'WEFT').map((item, idx) => {
                                return (
                                  <tr>
                                    <td>{item.colourName}</td>
                                    <td>{item.pantoneCode}</td>
                                    <td>{item.otxFile != "" && <a href={item.otxFile}
                                      title={item.otxFile} download>Download</a>}</td>

                                  </tr>
                                )
                              })}

                            </tbody>
                          </table>}
                        </div>
                      </div>
                    </div>

                  </div>
                )}
              </>
            ) : null}
          </div>
          <hr className="hr-order-kate" />

          <Card body className="RFQ-order-weave-card">
            <p className="RFQ-Title">Fill out information</p>
            {/* ///////////////////////////////////PRINT///////////////////////////////////////// */}
            {valueGet.processType == "PRINT" ? (
              <>
                {props.customizationData && props.customizationData.print && props.customizationData.print.length == 0}
                <p className="input-weave">{linkType == 'job_work' ? type.toLowerCase() == 'woven' ? '10.' : '9.' : type.toLowerCase() == 'woven' ? '9.' : '8.'} Order Details</p>

                <div className="div-print">
                  <FormGroup>
                    <Label for="exampleZip" className="input-weave">
                      (i) Quantity
                    </Label>
                    <div className="input-p">
                      <Input
                        type="text"
                        name="printQuantity"
                        autoComplete="off"
                        onChange={(e) => handleChange(e)}
                        maxLength="10"
                        value={
                          rfqDetails.printQuantity > 0
                            ? rfqDetails.printQuantity
                            : null
                        }
                        className="exampleZip"
                        id="exampleZip"
                      />
                      <p className="X">{type.toLowerCase() == 'woven' ? 'meters.' : 'kg'}</p>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleZip" className="input-weave">
                      (ii) Target Price (Optional)
                    </Label>
                    <div className="input-p">
                      <Input
                        type="number"
                        name="printPrice"


                        autoComplete="off"

                        onPaste={(e) => e.preventDefault()}
                        onChange={(e) => handleChange(e)}
                        value={
                          rfqDetails.printPrice > 0
                            ? rfqDetails.printPrice
                            : null
                        }
                        placeholder=""
                        className="exampleZip"
                        id="exampleZip"
                      />
                      <p className="X">{type.toLowerCase() == 'woven' ? 'INR per metre' : 'INR per kg'}</p>
                    </div>
                    {/* {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.printPrice && (
                        <small className="form-text text-danger">
                          {formDetails.errors.printPrice[0]}
                        </small>
                      )} */}
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleZip" className="input-weave">
                      (iii) Expected Delivery
                    </Label>

                    <div className="input-p">
                      <div style={{ position: "relative", width: "125px" }}>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date(Date.now() + 86400000)}
                          placeholderText="Start"
                          selected={
                            rfqDetails.printFromDate
                              ? moment(rfqDetails.printFromDate)._d
                              : null
                          }
                          onChange={(date) => {
                            setDateState(date);
                            handleDateChange(date, "printFromDate");
                          }}
                          onChangeRaw={(e) => e.preventDefault()}
                          id="printFromDate"
                          className="form-control"
                        // showYearDropdown
                        // scrollableYearDropdown
                        // excludeDates={[new Date(), subDays(new Date(), 3)]}
                        />

                        <label
                          htmlFor="printFromDate"
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "10px",
                          }}
                        >
                          <svg
                            width="1.6vh"
                            height="1.8vh"
                            viewBox="0 0 16 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="place-inside"
                          >
                            <path
                              _ngcontent-xls-c248=""
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13 2.33341H13.8333C14.75 2.33341 15.5 3.08341 15.5 4.00008V15.6667C15.5 16.5834 14.75 17.3334 13.8333 17.3334H2.16667C1.24167 17.3334 0.5 16.5834 0.5 15.6667L0.508333 4.00008C0.508333 3.08341 1.24167 2.33341 2.16667 2.33341H3V0.666748H3.83333V2.33341H12.1667V0.666748H13V2.33341ZM1.33333 16.5001H14.6667V6.50008H1.33333V16.5001ZM14.6667 5.66675H1.33333V3.16675H14.6667V5.66675ZM8 9.83342H13V14.8334H8V9.83342Z"
                              fill="#2F80ED"
                            ></path>
                          </svg>
                        </label>
                      </div>
                      {/* <Input
                        type="text"
                        name="printFromDate"
                        onChange={(e) => handleChange(e)}
                        onFocus={e => e.currentTarget.type = "date"}
                        onBlur={e => e.currentTarget.type = "text"}
                        value={rfqDetails.printFromDate}
                        placeholder="joun"
                        id="date"
                        className="form-control"
                        min={disablePastDate()}

                      /> */}
                      <p className="X">to</p>
                      <div style={{ position: "relative", width: "125px" }}>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date(Date.now() + 86400000)}
                          placeholderText="End"
                          selected={
                            rfqDetails.printToDate
                              ? moment(rfqDetails.printToDate)._d
                              : null
                          }
                          onChange={(date) => {
                            setDateState(date);
                            handleDateChange(date, "printToDate");
                          }}
                          onChangeRaw={(e) => e.preventDefault()}
                          id="printToDate"
                          className="form-control"
                        />

                        <label
                          htmlFor="printToDate"
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "10px",
                          }}
                        >
                          <svg
                            width="1.6vh"
                            height="1.8vh"
                            viewBox="0 0 16 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="place-inside"
                          >
                            <path
                              _ngcontent-xls-c248=""
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13 2.33341H13.8333C14.75 2.33341 15.5 3.08341 15.5 4.00008V15.6667C15.5 16.5834 14.75 17.3334 13.8333 17.3334H2.16667C1.24167 17.3334 0.5 16.5834 0.5 15.6667L0.508333 4.00008C0.508333 3.08341 1.24167 2.33341 2.16667 2.33341H3V0.666748H3.83333V2.33341H12.1667V0.666748H13V2.33341ZM1.33333 16.5001H14.6667V6.50008H1.33333V16.5001ZM14.6667 5.66675H1.33333V3.16675H14.6667V5.66675ZM8 9.83342H13V14.8334H8V9.83342Z"
                              fill="#2F80ED"
                            ></path>
                          </svg>
                        </label>
                      </div>
                    </div>
                    {/* {formDetails.submitted &&
                      formDetails.errors &&
                      (formDetails.errors.printFromDate ||
                        formDetails.errors.printToDate) && (
                        <small className="form-text text-danger">
                          mandatory field must be filled to proceed
                        </small>
                      )}

                    {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.reverseDate && (
                        <small className="form-text text-danger">
                          To date must be greater than From date.
                        </small>
                      )} */}
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleZip" className="input-weave">
                      (iv) {valueGet.processType.toLowerCase() != 'greige' ? ' Residual' : ''} Shrinkage
                    </Label>

                    <div className="input-p">
                      <Input
                        type="text"
                        name="printshrinkage"
                        onKeyDown={(e) => funs(e)}
                        autoComplete="off"
                        maxLength="5"
                        onPaste={(e) => e.preventDefault()}
                        onChange={(e) => handleChange(e)}
                        value={
                          rfqDetails.printshrinkage > 0
                            ? rfqDetails.printshrinkage
                            : null
                        }
                        placeholder=""
                        className="exampleZip"
                        id="exampleZip"
                      /> <p className="X">%</p>

                    </div>
                    {/* {formDetails.submitted &&
                      formDetails.errors &&
                      (formDetails.errors.printFromDate ||
                        formDetails.errors.printToDate) && (
                        <small className="form-text text-danger">
                          mandatory field must be filled to proceed
                        </small>
                      )}

                    {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.reverseDate && (
                        <small className="form-text text-danger">
                          To date must be greater than From date.
                        </small>
                      )} */}
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleZip" className="input-weave">
                      (v) End Buyer Name (Optional)
                    </Label>
                    <Input
                      type="text"
                      name="endBuyerName"
                      onChange={(e) => handleChange(e)}
                      value={rfqDetails.endBuyerName}
                      maxLength="50"
                      placeholder=" "
                      id="exampleRfqdyed13"
                    />
                  </FormGroup>

                  <div style={{ display: 'flex' }}>
                    <label for="editPicProfile">
                      <div className="Upload-image-rfq">
                        <span className="uppload-rfq-span">(vi)</span>
                        {!pic.preview && (
                          <>
                            <p className="image-uploader" onClick={picShow}>
                              {" "}
                              Upload File
                            </p>
                            <span className="upploadd-rfq-span">(Optional)</span>
                          </>
                        )}

                      </div>
                    </label>
                    {showText && pic.preview && (
                      <div className="Upload-Imgs">
                        <div className="imageBorder">
                          {!pic.pdf && <img src={pic.preview} alt={pic.preview} className="company-profile" />}
                          {pic.pdf && <a className="order-pdf" href={pic.preview} download>
                            {pic.preview}
                          </a>}
                        </div>
                        {/* <label for="editPicProfile">
                          <img src={edit} className="edit-icon" />
                        </label> */}
                        <label for="deleteProfile" onClick={handlePicRemove}>
                          <img src={delicon} className={pic.pdf ? 'delete-icon' : "delete-iconn-13"} />
                        </label>
                      </div>
                    )}
                  </div>

                  <input
                    type="file"
                    onChange={(e) => handlePicChange(e)}
                    accept="image/x-png,image/jpeg,image/svg+xml,application/pdf"
                    className="sr sr-only"
                    id="editPicProfile"
                    name="orderImageUrl"

                  // value={rfqDetails.orderImageUrl}
                  />

                  <FormGroup>
                    <Label for="exampleText" className="input-weave">
                      (vii) Comments (Optional)
                    </Label>
                    <Input
                      type="textarea"
                      name="orderComment"
                      onChange={(e) => handleChange(e)}
                      value={rfqDetails.orderComment}
                      id="exampleOrderText"
                      placeholder="1000 characters max"
                    />
                  </FormGroup>
                </div>
              </>
            ) : null}

            {/* //////////////////////////////////////////////DYED//////////////////////////////// */}

            {valueGet.processType == "DYED" ? (
              <>
                {props.customizationData && props.customizationData.dyed && props.customizationData.dyed.length == 0}
                <p className="input-weave">{linkType == 'job_work' ? type.toLowerCase() == 'woven' ? '10.' : '9.' : type.toLowerCase() == 'woven' ? '9.' : '8.'} Order Details</p>
                <div className="div-dyed">
                  {/* <p>Dyed</p> */}
                  <FormGroup>
                    <Label for="exampleZip" className="input-weave">
                      (i) Quantity
                    </Label>
                    <div className="input-p">
                      <Input
                        type="text"

                        autoComplete="off"
                        name="dyedQuantity"
                        onChange={(e) => handleChange(e)}
                        maxLength="10"
                        className="mr-0"

                        placeholder=""
                        id="exampleZip"

                      />{" "}
                      <p className="X">{type.toLowerCase() == 'woven' ? ' metres' : type.toLowerCase() == 'knit' ? ' kg' : ''}</p>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleZip" className="input-weave">
                      (ii) Target Price (Optional)
                    </Label>
                    <div className="input-p">
                      <Input
                        type="number"
                        name="dyedPrice"
                        onPaste={(e) => e.preventDefault()}

                        autoComplete="off"
                        onChange={(e) => handleChange(e)}

                        value={
                          rfqDetails.dyedPrice > 0 ? rfqDetails.dyedPrice : null
                        }
                        placeholder=""
                        className="exampleZip mr-0"
                        id="exampleZip"
                      />
                      <p className="X">{type.toLowerCase() == 'woven' ? ' INR per metre' : type.toLowerCase() == 'knit' ? ' INR per kg' : ''}</p>
                    </div>
                    {/* {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.dyedPrice && (
                        <small className="form-text text-danger">
                          {formDetails.errors.dyedPrice[0]}
                        </small>
                      )} */}
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleZip" className="input-weave">
                      (iii) Expected Delivery
                    </Label>

                    <div className="input-p">
                      <div style={{ position: "relative", width: "125px" }}>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date(Date.now() + 86400000)}
                          placeholderText="Start"
                          selected={
                            rfqDetails.dyedStartDate
                              ? moment(rfqDetails.dyedStartDate)._d
                              : null
                          }
                          onChange={(date) => {
                            setDateState(date);
                            handleDateChange(date, "dyedStartDate");
                          }}
                          onChangeRaw={(e) => e.preventDefault()}
                          id="printFromDate"
                          className="form-control"
                        />

                        <label
                          htmlFor="printFromDate"
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "10px",
                          }}
                        >
                          <svg
                            width="1.6vh"
                            height="1.8vh"
                            viewBox="0 0 16 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="place-inside"
                          >
                            <path
                              _ngcontent-xls-c248=""
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13 2.33341H13.8333C14.75 2.33341 15.5 3.08341 15.5 4.00008V15.6667C15.5 16.5834 14.75 17.3334 13.8333 17.3334H2.16667C1.24167 17.3334 0.5 16.5834 0.5 15.6667L0.508333 4.00008C0.508333 3.08341 1.24167 2.33341 2.16667 2.33341H3V0.666748H3.83333V2.33341H12.1667V0.666748H13V2.33341ZM1.33333 16.5001H14.6667V6.50008H1.33333V16.5001ZM14.6667 5.66675H1.33333V3.16675H14.6667V5.66675ZM8 9.83342H13V14.8334H8V9.83342Z"
                              fill="#2F80ED"
                            ></path>
                          </svg>
                        </label>
                      </div>
                      {/* <Input
                        type="text"
                        name="printFromDate"
                        onChange={(e) => handleChange(e)}
                        onFocus={e => e.currentTarget.type = "date"}
                        onBlur={e => e.currentTarget.type = "text"}
                        value={rfqDetails.printFromDate}
                        placeholder="joun"
                        id="date"
                        className="form-control"
                        min={disablePastDate()}

                      /> */}
                      <p className="X">to</p>
                      <div style={{ position: "relative", width: "125px" }}>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date(Date.now() + 86400000)}
                          placeholderText="End"
                          selected={
                            rfqDetails.dyedToDate
                              ? moment(rfqDetails.dyedToDate)._d
                              : null
                          }
                          onChange={(date) => {
                            setDateState(date);
                            handleDateChange(date, "dyedToDate");
                          }}
                          onChangeRaw={(e) => e.preventDefault()}
                          id="printToDate"
                          className="form-control"
                        />

                        <label
                          htmlFor="printToDate"
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "10px",
                          }}
                        >
                          <svg
                            width="1.6vh"
                            height="1.8vh"
                            viewBox="0 0 16 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="place-inside"
                          >
                            <path
                              _ngcontent-xls-c248=""
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13 2.33341H13.8333C14.75 2.33341 15.5 3.08341 15.5 4.00008V15.6667C15.5 16.5834 14.75 17.3334 13.8333 17.3334H2.16667C1.24167 17.3334 0.5 16.5834 0.5 15.6667L0.508333 4.00008C0.508333 3.08341 1.24167 2.33341 2.16667 2.33341H3V0.666748H3.83333V2.33341H12.1667V0.666748H13V2.33341ZM1.33333 16.5001H14.6667V6.50008H1.33333V16.5001ZM14.6667 5.66675H1.33333V3.16675H14.6667V5.66675ZM8 9.83342H13V14.8334H8V9.83342Z"
                              fill="#2F80ED"
                            ></path>
                          </svg>
                        </label>
                      </div>
                    </div>
                    {/* {formDetails.submitted &&
                      formDetails.errors &&
                      (formDetails.errors.dyedStartDate ||
                        formDetails.errors.dyedToDate) && (
                        <small className="form-text text-danger">
                          mandatory field must be filled to proceed
                        </small>
                      )}

                    {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.reverseDate && (
                        <small className="form-text text-danger">
                          To date must be greater than From date.
                        </small>
                      )} */}
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleZip" className="input-weave">
                      (iv) {valueGet.fabricStage.toLowerCase() == 'processed' ? 'Residual' : ''} Shrinkage
                    </Label>

                    <div className="input-p">
                      <Input
                        type="text"
                        name="dyedshrinkage"
                        onKeyDown={(e) => funs(e)}
                        autoComplete="off"
                        onPaste={(e) => e.preventDefault()}
                        maxLength="5"
                        onChange={(e) => handleChange(e)}
                        value={
                          rfqDetails.dyedshrinkage > 0
                            ? rfqDetails.dyedshrinkage
                            : null
                        }
                        placeholder=""
                        className="exampleZip"
                        id="exampleZip"
                      /><p className="X">%</p>

                    </div>
                    {/* {formDetails.submitted &&
                      formDetails.errors &&
                      (formDetails.errors.printFromDate ||
                        formDetails.errors.printToDate) && (
                        <small className="form-text text-danger">
                          mandatory field must be filled to proceed
                        </small>
                      )}

                    {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.reverseDate && (
                        <small className="form-text text-danger">
                          To date must be greater than From date.
                        </small>
                      )} */}
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleZip" className="input-weave">
                      (v) End Buyer Name (Optional)
                    </Label>
                    <Input
                      type="text"
                      name="endBuyerName"
                      onChange={(e) => handleChange(e)}
                      value={rfqDetails.endBuyerName}
                      maxLength="50"
                      placeholder=" "
                      id="exampleRfqdyed13"
                    />
                  </FormGroup>

                  <div style={{ display: 'flex' }}>
                    <label for="editPicProfile">
                      <div className="Upload-image-rfq">
                        <span className="uppload-rfq-span">(vi)</span>
                        {!pic.preview && (
                          <>
                            <p className="image-uploader" onClick={picShow}>
                              {" "}
                              Upload Image
                            </p>
                            <span className="upploadd-rfq-span">(Optional)</span>
                          </>
                        )}

                      </div>
                    </label>
                    {showText && pic.preview && (
                      <div className="Upload-Imgs">
                        <div className="imageBorder">
                          {!pic.pdf && <img src={pic.preview} alt={pic.preview} className="company-profile" />}
                          {pic.pdf && <a className="order-pdf" href={pic.preview} download>
                            {pic.preview}
                          </a>}
                        </div>
                        {/* <label for="editPicProfile">
                          <img src={edit} className="edit-icon" />
                        </label> */}
                        <label for="deleteProfile" onClick={handlePicRemove}>
                          <img src={delicon} className={pic.pdf ? 'delete-icon' : "delete-iconn-13"} />
                        </label>
                      </div>
                    )}
                  </div>

                  <input
                    type="file"
                    onChange={(e) => handlePicChange(e)}
                    accept="image/x-png,image/jpeg,image/svg+xml,application/pdf"
                    className="sr sr-only"
                    id="editPicProfile"
                    name="orderImageUrl"

                  // value={rfqDetails.orderImageUrl}
                  />

                  <FormGroup>
                    <Label for="exampleText" className="input-weave">
                      (vii) Comments (Optional)
                    </Label>
                    <Input
                      type="textarea"
                      name="orderComment"
                      onChange={(e) => handleChange(e)}
                      value={rfqDetails.orderComment}
                      id="exampleOrderText"
                      placeholder="1000 characters max"
                    />
                  </FormGroup>
                </div>
              </>
            ) : null}
            {/* ///////////////////////////////////////Print & Dyed/////////////////////////////////// */}
            {valueGet.processType == "Print & Dyed" ? (
              <>
                {props.customizationData && ((props.customizationData.dyed && props.customizationData.dyed.length == 0)
                  || (props.customizationData.print && props.customizationData.print.length == 0))}
                <p className="input-weave">{linkType == 'job_work' ? type.toLowerCase() == 'woven' ? '10.' : '9.' : type.toLowerCase() == 'woven' ? '9.' : '8.'} Order Details</p>
                <div className="flex-divs">
                  <div className="div-print">
                    <p className="input-weave" style={{ color: '#828282' }}>(a) Print</p>
                    <FormGroup>
                      <Label for="exampleZip" className="input-weave">
                        (i) Quantity
                      </Label>
                      <div className="input-p">
                        <Input
                          type="text"
                          name="printQuantity"
                          value={
                            rfqDetails.printQuantity > 0
                              ? rfqDetails.printQuantity
                              : null
                          }
                          autoComplete="off"
                          onChange={(e) => handleChange(e)}
                          maxLength="10"

                          className="exampleZip"
                          id="exampleZip"

                        />

                        <p className="X">{type.toLowerCase() == 'woven' ? ' metres' : type.toLowerCase() == 'knit' ? ' kg' : ''}</p>
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="exampleZip" className="input-weave">
                        (ii) Target Price (Optional)
                      </Label>
                      <div className="input-p">
                        <Input
                          type="number"
                          name="printPrice"

                          onPaste={(e) => e.preventDefault()}
                          autoComplete="off"

                          onChange={(e) => handleChange(e)}
                          value={
                            rfqDetails.printPrice > 0
                              ? rfqDetails.printPrice
                              : null
                          }
                          placeholder=""
                          className="exampleZip"
                          id="exampleZip"
                        />

                        <p className="X">{type.toLowerCase() == 'woven' ? ' INR per metre' : type.toLowerCase() == 'knit' ? ' INR per kg' : ''}</p>
                      </div>
                      {/* {formDetails.submitted &&
                        formDetails.errors &&
                        formDetails.errors.printPrice && (
                          <small className="form-text text-danger">
                            {formDetails.errors.printPrice[0]}
                          </small>
                        )} */}
                    </FormGroup>

                    <FormGroup>
                      <Label for="exampleZip" className="input-weave">
                        (iii) Expected Delivery
                      </Label>
                      <div className="input-p">
                        <div style={{ position: "relative", width: "125px" }}>
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date(Date.now() + 86400000)}
                            placeholderText="Start"
                            selected={
                              rfqDetails.printFromDate
                                ? moment(rfqDetails.printFromDate)._d
                                : null
                            }
                            onChange={(date) => {
                              setDateState(date);
                              handleDateChange(date, "printFromDate");
                            }}
                            onChangeRaw={(e) => e.preventDefault()}
                            id="printFromDate-1"
                            className="form-control"
                          />

                          <label
                            htmlFor="printFromDate-1"
                            style={{
                              position: "absolute",
                              right: "5px",
                              top: "10px",
                            }}
                          >
                            <svg
                              width="1.6vh"
                              height="1.8vh"
                              viewBox="0 0 16 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="place-inside"
                            >
                              <path
                                _ngcontent-xls-c248=""
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13 2.33341H13.8333C14.75 2.33341 15.5 3.08341 15.5 4.00008V15.6667C15.5 16.5834 14.75 17.3334 13.8333 17.3334H2.16667C1.24167 17.3334 0.5 16.5834 0.5 15.6667L0.508333 4.00008C0.508333 3.08341 1.24167 2.33341 2.16667 2.33341H3V0.666748H3.83333V2.33341H12.1667V0.666748H13V2.33341ZM1.33333 16.5001H14.6667V6.50008H1.33333V16.5001ZM14.6667 5.66675H1.33333V3.16675H14.6667V5.66675ZM8 9.83342H13V14.8334H8V9.83342Z"
                                fill="#2F80ED"
                              ></path>
                            </svg>
                          </label>
                        </div>

                        <p className="X">to</p>
                        <div style={{ position: "relative", width: "125px" }}>
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date(Date.now() + 86400000)}
                            placeholderText="End"
                            selected={
                              rfqDetails.printToDate
                                ? moment(rfqDetails.printToDate)._d
                                : null
                            }
                            onChange={(date) => {
                              setDateState(date);
                              handleDateChange(date, "printToDate");
                            }}
                            onChangeRaw={(e) => e.preventDefault()}
                            id="printToDate-1"
                            className="form-control"
                          />

                          <label
                            htmlFor="printToDate-1"
                            style={{
                              position: "absolute",
                              right: "5px",
                              top: "10px",
                            }}
                          >
                            <svg
                              width="1.6vh"
                              height="1.8vh"
                              viewBox="0 0 16 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="place-inside"
                            >
                              <path
                                _ngcontent-xls-c248=""
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13 2.33341H13.8333C14.75 2.33341 15.5 3.08341 15.5 4.00008V15.6667C15.5 16.5834 14.75 17.3334 13.8333 17.3334H2.16667C1.24167 17.3334 0.5 16.5834 0.5 15.6667L0.508333 4.00008C0.508333 3.08341 1.24167 2.33341 2.16667 2.33341H3V0.666748H3.83333V2.33341H12.1667V0.666748H13V2.33341ZM1.33333 16.5001H14.6667V6.50008H1.33333V16.5001ZM14.6667 5.66675H1.33333V3.16675H14.6667V5.66675ZM8 9.83342H13V14.8334H8V9.83342Z"
                                fill="#2F80ED"
                              ></path>
                            </svg>
                          </label>
                        </div>
                      </div>
                      {/* {formDetails.submitted &&
                        formDetails.errors &&
                        (formDetails.errors.printFromDate ||
                          formDetails.errors.printToDate) && (
                          <small className="form-text text-danger">
                            mandatory field must be filled to proceed
                          </small>
                        )}
                      {formDetails.submitted &&
                        formDetails.errors &&
                        formDetails.errors.reverseDate && (
                          <small className="form-text text-danger">
                            To date must be greater than From date.
                          </small>
                        )} */}
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleZip" className="input-weave">
                        (iv) {valueGet.fabricStage.toLowerCase() == 'processed' ? 'Residual' : ''} Shrinkage
                      </Label>

                      <div className="input-p">
                        <Input
                          type="text"
                          name="printshrinkage"
                          onKeyDown={(e) => funs(e)}
                          onPaste={(e) => e.preventDefault()}
                          autoComplete="off"
                          maxLength="5"
                          onChange={(e) => handleChange(e)}
                          value={
                            rfqDetails.printshrinkage > 0
                              ? rfqDetails.printshrinkage
                              : null
                          }
                          placeholder=""
                          className="exampleZip"
                          id="exampleZip"
                        /><p className="X">%</p>

                      </div>
                      {/* {formDetails.submitted &&
                      formDetails.errors &&
                      (formDetails.errors.printFromDate ||
                        formDetails.errors.printToDate) && (
                        <small className="form-text text-danger">
                          mandatory field must be filled to proceed
                        </small>
                      )}

                    {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.reverseDate && (
                        <small className="form-text text-danger">
                          To date must be greater than From date.
                        </small>
                      )} */}
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleZip" className="input-weave">
                        (v) End Buyer Name (Optional)
                      </Label>
                      <Input
                        type="text"
                        name="endBuyerName"
                        onChange={(e) => handleChange(e)}
                        value={rfqDetails.endBuyerName}
                        maxLength="50"
                        placeholder=" "
                        id="exampleRfqdyed13"
                      />
                    </FormGroup>

                    <div style={{ display: 'flex' }}>
                      <label for="editPicProfile">
                        <div className="Upload-image-rfq">
                          <span className="uppload-rfq-span">(vi)</span>
                          {!pic.preview && (
                            <>
                              <p className="image-uploader" onClick={picShow}>
                                {" "}
                                Upload Image
                              </p>
                              <span className="upploadd-rfq-span">(Optional)</span>
                            </>
                          )}

                        </div>
                      </label>
                      {showText && pic.preview && (
                        <div className="Upload-Imgs">
                          <div className="imageBorder">
                            {!pic.pdf && <img src={pic.preview} alt={pic.preview} className="company-profile" />}
                            {pic.pdf && <a className="order-pdf" href={pic.preview} download>
                              {pic.raw.name}
                            </a>}
                          </div>
                          {/* <label for="editPicProfile">
                          <img src={edit} className="edit-icon" />
                        </label> */}
                          <label for="deleteProfile" onClick={handlePicRemove}>
                            <img src={delicon} className={pic.pdf ? 'delete-icon' : "delete-iconn-13"} />
                          </label>
                        </div>
                      )}
                    </div>

                    <input
                      type="file"
                      onChange={(e) => handlePicChange(e)}
                      accept="image/x-png,image/jpeg,image/svg+xml,application/pdf"
                      className="sr sr-only"
                      id="editPicProfile"
                      name="orderImageUrl"

                    // value={rfqDetails.orderImageUrl}
                    />

                    <FormGroup>
                      <Label for="exampleText" className="input-weave">
                        (vii) Comments (Optional)
                      </Label>
                      <Input
                        type="textarea"
                        name="orderComment"
                        onChange={(e) => handleChange(e)}
                        value={rfqDetails.orderComment}
                        id="exampleOrderText"
                        placeholder="1000 characters max"
                      />
                    </FormGroup>
                  </div>

                  <div className="div-dyed">
                    <p className="input-weave" style={{ color: '#828282' }}>(b) Dyed</p>
                    <FormGroup>
                      <Label for="exampleZip" className="input-weave">
                        (i) Quantity
                      </Label>
                      <div className="input-p">
                        <Input
                          type="text"
                          name="dyedQuantity"
                          onChange={(e) => handleChange(e)}
                          maxLength="10"
                          value={
                            rfqDetails.dyedQuantity > 0
                              ? rfqDetails.dyedQuantity
                              : null
                          }
                          autoComplete="off"
                          placeholder=""
                          id="exampleZip"

                        />

                        <p className="X">{type.toLowerCase() == 'woven' ? ' metres' : type.toLowerCase() == 'knit' ? ' kg' : ''}</p>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleZip" className="input-weave">
                        (ii) Target Price (Optional)
                      </Label>
                      <div className="input-p">
                        <Input
                          type="number"
                          name="dyedPrice"
                          min={0}
                          onPaste={(e) => e.preventDefault()}
                          autoComplete="off"

                          onChange={(e) => handleChange(e)}
                          value={
                            rfqDetails.dyedPrice > 0
                              ? rfqDetails.dyedPrice
                              : null
                          }
                          placeholder=""
                          className="exampleZip"
                          id="exampleZip"
                        />

                        <p className="X">{type.toLowerCase() == 'woven' ? ' INR per metre' : type.toLowerCase() == 'knit' ? ' INR per kg' : ''}</p>
                      </div>
                      {/* {formDetails.submitted &&
                        formDetails.errors &&
                        formDetails.errors.dyedPrice && (
                          <small className="form-text text-danger">
                            {formDetails.errors.dyedPrice[0]}
                          </small>
                        )} */}
                    </FormGroup>

                    <FormGroup>
                      <Label for="exampleZip" className="input-weave">
                        (iii) Expected Delivery
                      </Label>
                      <div className="input-p">
                        <div style={{ position: "relative", width: "125px" }}>
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date(Date.now() + 86400000)}
                            placeholderText="Start"
                            selected={
                              rfqDetails.dyedStartDate
                                ? moment(rfqDetails.dyedStartDate)._d
                                : null
                            }
                            onChange={(date) => {
                              setDateState(date);
                              handleDateChange(date, "dyedStartDate");
                            }}
                            onChangeRaw={(e) => e.preventDefault()}
                            id="dyedStartDate-1"
                            className="form-control"
                          />

                          <label
                            htmlFor="dyedStartDate-1"
                            style={{
                              position: "absolute",
                              right: "5px",
                              top: "10px",
                            }}
                          >
                            <svg
                              width="1.6vh"
                              height="1.8vh"
                              viewBox="0 0 16 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="place-inside"
                            >
                              <path
                                _ngcontent-xls-c248=""
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13 2.33341H13.8333C14.75 2.33341 15.5 3.08341 15.5 4.00008V15.6667C15.5 16.5834 14.75 17.3334 13.8333 17.3334H2.16667C1.24167 17.3334 0.5 16.5834 0.5 15.6667L0.508333 4.00008C0.508333 3.08341 1.24167 2.33341 2.16667 2.33341H3V0.666748H3.83333V2.33341H12.1667V0.666748H13V2.33341ZM1.33333 16.5001H14.6667V6.50008H1.33333V16.5001ZM14.6667 5.66675H1.33333V3.16675H14.6667V5.66675ZM8 9.83342H13V14.8334H8V9.83342Z"
                                fill="#2F80ED"
                              ></path>
                            </svg>
                          </label>
                        </div>

                        <p className="X">to</p>
                        <div style={{ position: "relative", width: "125px" }}>
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date(Date.now() + 86400000)}
                            placeholderText="End"
                            selected={
                              rfqDetails.dyedToDate
                                ? moment(rfqDetails.dyedToDate)._d
                                : null
                            }
                            onChange={(date) => {
                              setDateState(date);
                              handleDateChange(date, "dyedToDate");
                            }}
                            onChangeRaw={(e) => e.preventDefault()}
                            id="dyedToDate-1"
                            className="form-control"
                          />

                          <label
                            htmlFor="dyedToDate-1"
                            style={{
                              position: "absolute",
                              right: "5px",
                              top: "10px",
                            }}
                          >
                            <svg
                              width="1.6vh"
                              height="1.8vh"
                              viewBox="0 0 16 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              class="place-inside"
                            >
                              <path
                                _ngcontent-xls-c248=""
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13 2.33341H13.8333C14.75 2.33341 15.5 3.08341 15.5 4.00008V15.6667C15.5 16.5834 14.75 17.3334 13.8333 17.3334H2.16667C1.24167 17.3334 0.5 16.5834 0.5 15.6667L0.508333 4.00008C0.508333 3.08341 1.24167 2.33341 2.16667 2.33341H3V0.666748H3.83333V2.33341H12.1667V0.666748H13V2.33341ZM1.33333 16.5001H14.6667V6.50008H1.33333V16.5001ZM14.6667 5.66675H1.33333V3.16675H14.6667V5.66675ZM8 9.83342H13V14.8334H8V9.83342Z"
                                fill="#2F80ED"
                              ></path>
                            </svg>
                          </label>
                        </div>
                      </div>
                      {/* {formDetails.submitted &&
                        formDetails.errors &&
                        (formDetails.errors.dyedStartDate ||
                          formDetails.errors.dyedToDate) && (
                          <small className="form-text text-danger">
                            mandatory field must be filled to proceed
                          </small>
                        )}

                      {formDetails.submitted &&
                        formDetails.errors &&
                        formDetails.errors.reverseDate && (
                          <small className="form-text text-danger">
                            To date must be greater than From date.
                          </small>
                        )} */}
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleZip" className="input-weave">
                        (iv) {valueGet.fabricStage.toLowerCase() == 'processed' ? 'Residual' : ''} Shrinkage
                      </Label>

                      <div className="input-p">
                        <Input
                          type="text"
                          name="dyedshrinkage"
                          onKeyDown={(e) => funs(e)}
                          onPaste={(e) => e.preventDefault()}
                          autoComplete="off"
                          maxLength="5"
                          onChange={(e) => handleChange(e)}
                          value={
                            rfqDetails.dyedshrinkage > 0
                              ? rfqDetails.dyedshrinkage
                              : null
                          }
                          placeholder=""
                          className="exampleZip"
                          id="exampleZip"
                        /><p className="X">%</p>

                      </div>
                      {/* {formDetails.submitted &&
                      formDetails.errors &&
                      (formDetails.errors.printFromDate ||
                        formDetails.errors.printToDate) && (
                        <small className="form-text text-danger">
                          mandatory field must be filled to proceed
                        </small>
                      )}

                    {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.reverseDate && (
                        <small className="form-text text-danger">
                          To date must be greater than From date.
                        </small>
                      )} */}
                    </FormGroup>
                  </div>
                </div>
              </>
            ) : null}
            {valueGet.fabricStage != "Processed" ? (
              <>
                <p className="input-weave mb-16">{valueGet.processType.toLowerCase()=='greige'?'8':'9'}. Order Details</p>

                <div className="div-print">
                  <FormGroup>
                    <Label for="exampleZip" className="input-weave">
                      (i) Quantity
                    </Label>
                    <div className="input-p">
                      <Input
                        type="text"
                        name="printQuantity"
                        maxLength="10"
                        onChange={(e) => handleChange(e)}
                        value={
                          rfqDetails.printQuantity > 0
                            ? rfqDetails.printQuantity
                            : null
                        }
                        // placeholder="count"
                        className="exampleZip"

                        autoComplete="off"
                        id="exampleZip"
                      />
                      <p className="X">{type.toLowerCase() == 'woven' ? ' metres' : type.toLowerCase() == 'knit' ? ' kg' : ''}</p>
                    </div>
                    {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.printQuantity && (
                        <small className="form-text text-danger">
                          {formDetails.errors.printQuantity[0]}
                        </small>
                      )}
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleZip" className="input-weave">
                      (ii) Target Price (Optional)
                    </Label>
                    <div className="input-p">
                      <Input
                        type="number"
                        name="printPrice"
                        onChange={(e) => handleChange(e)}
                        min={0}
                        value={
                          rfqDetails.printPrice > 0
                            ? rfqDetails.printPrice
                            : null
                        }
                        // placeholder="count"

                        onPaste={(e) => e.preventDefault()}
                        autoComplete="off"
                        className="exampleZip"
                        id="exampleZip"
                      />
                      <p className="X">{type.toLowerCase() == 'woven' ? ' INR per metre' : type.toLowerCase() == 'knit' ? ' INR per kg' : ''}</p>
                    </div>
                    {/* {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.printPrice && (
                        <small className="form-text text-danger">
                          {formDetails.errors.printPrice[0]}
                        </small>
                      )} */}
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleZip" className="input-weave">
                      (iii) Expected Delivery
                    </Label>
                    <div className="input-p">
                      <div style={{ position: "relative", width: "125px" }}>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date(Date.now() + 86400000)}
                          placeholderText="Start"
                          selected={
                            rfqDetails.printFromDate
                              ? moment(rfqDetails.printFromDate)._d
                              : null
                          }
                          onChange={(date) => {
                            setDateState(date);
                            handleDateChange(date, "printFromDate");
                          }}
                          onChangeRaw={(e) => e.preventDefault()}
                          id="printFromDate-2"
                          className="form-control"
                        />

                        <label
                          htmlFor="printFromDate-2"
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "10px",
                          }}
                        >
                          <svg
                            width="1.6vh"
                            height="1.8vh"
                            viewBox="0 0 16 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="place-inside"
                          >
                            <path
                              _ngcontent-xls-c248=""
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13 2.33341H13.8333C14.75 2.33341 15.5 3.08341 15.5 4.00008V15.6667C15.5 16.5834 14.75 17.3334 13.8333 17.3334H2.16667C1.24167 17.3334 0.5 16.5834 0.5 15.6667L0.508333 4.00008C0.508333 3.08341 1.24167 2.33341 2.16667 2.33341H3V0.666748H3.83333V2.33341H12.1667V0.666748H13V2.33341ZM1.33333 16.5001H14.6667V6.50008H1.33333V16.5001ZM14.6667 5.66675H1.33333V3.16675H14.6667V5.66675ZM8 9.83342H13V14.8334H8V9.83342Z"
                              fill="#2F80ED"
                            ></path>
                          </svg>
                        </label>
                      </div>

                      <p className="X">to</p>
                      <div style={{ position: "relative", width: "125px" }}>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date(Date.now() + 86400000)}
                          placeholderText="End"
                          selected={
                            rfqDetails.printToDate
                              ? moment(rfqDetails.printToDate)._d
                              : null
                          }
                          onChange={(date) => {
                            setDateState(date);
                            handleDateChange(date, "printToDate");
                          }}
                          onChangeRaw={(e) => e.preventDefault()}
                          id="printToDate-2"
                          className="form-control"
                        />

                        <label
                          htmlFor="printToDate-2"
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "10px",
                          }}
                        >
                          <svg
                            width="1.6vh"
                            height="1.8vh"
                            viewBox="0 0 16 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="place-inside"
                          >
                            <path
                              _ngcontent-xls-c248=""
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13 2.33341H13.8333C14.75 2.33341 15.5 3.08341 15.5 4.00008V15.6667C15.5 16.5834 14.75 17.3334 13.8333 17.3334H2.16667C1.24167 17.3334 0.5 16.5834 0.5 15.6667L0.508333 4.00008C0.508333 3.08341 1.24167 2.33341 2.16667 2.33341H3V0.666748H3.83333V2.33341H12.1667V0.666748H13V2.33341ZM1.33333 16.5001H14.6667V6.50008H1.33333V16.5001ZM14.6667 5.66675H1.33333V3.16675H14.6667V5.66675ZM8 9.83342H13V14.8334H8V9.83342Z"
                              fill="#2F80ED"
                            ></path>
                          </svg>
                        </label>
                      </div>
                    </div>
                    {/* {formDetails.submitted &&
                      formDetails.errors &&
                      (formDetails.errors.printFromDate ||
                        formDetails.errors.printToDate) && (
                        <small className="form-text text-danger">
                          mandatory field must be filled to proceed
                        </small>
                      )}

                    {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.reverseDate && (
                        <small className="form-text text-danger">
                          To date must be greater than From date.
                        </small>
                      )} */}
                  </FormGroup>
                  {valueGet.processType != 'GREIGE' &&
                    <FormGroup >
                      <Label for="exampleZip" className="input-weave">
                        (iv){valueGet.processType.toLowerCase() != 'greige' ? ' Residual' : ''} Shrinkage
                      </Label>

                      <div className="input-p">
                        <Input
                          type="text"
                          name="printshrinkage"
                          onKeyDown={(e) => funs(e)}
                          onPaste={(e) => e.preventDefault()}
                          autoComplete="off"
                          maxLength="5"
                          onChange={(e) => handleChange(e)}
                          value={
                            rfqDetails.printshrinkage > 0
                              ? rfqDetails.printshrinkage
                              : null
                          }
                          placeholder=""
                          className="exampleZip"
                          id="exampleZip"
                        /><p className="X">%</p>

                      </div>
                      {/* {formDetails.submitted &&
                      formDetails.errors &&
                      (formDetails.errors.printFromDate ||
                        formDetails.errors.printToDate) && (
                        <small className="form-text text-danger">
                          mandatory field must be filled to proceed
                        </small>
                      )}

                    {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.reverseDate && (
                        <small className="form-text text-danger">
                          To date must be greater than From date.
                        </small>
                      )} */}
                    </FormGroup>}
                  <FormGroup>
                    <Label for="exampleZip" className="input-weave">
                      {valueGet.processType != 'GREIGE' ? '(v)' : '(iv)'} End Buyer Name (Optional)
                    </Label>
                    <Input
                      type="text"
                      name="endBuyerName"
                      onChange={(e) => handleChange(e)}
                      value={rfqDetails.endBuyerName}
                      maxLength="50"
                      placeholder=" "
                      id="exampleRfqdyed13"
                    />
                  </FormGroup>
                  {valueGet.fabricStage != 'YarnDyed' &&
                    <>
                      <div style={{ display: 'flex' }}>
                        <label for="editPicProfile">
                          <div className="Upload-image-rfq">
                            <span className="uppload-rfq-span">{valueGet.processType != 'GREIGE' ? '(vi)' : '(v)'}</span>
                            {!pic.preview && (
                              <>
                                <p className="image-uploader" onClick={picShow}>
                                  {" "}
                                  Upload Image
                                </p>
                                <span className="upploadd-rfq-span">(Optional)</span>
                              </>
                            )}

                          </div>
                        </label>
                        {showText && pic.preview && (
                          <div className="Upload-Imgs">
                            <div className="imageBorder">
                              {!pic.pdf && <img src={pic.preview} alt={pic.preview} className="company-profile" />}
                              {pic.pdf && <a className="order-pdf" href={pic.preview} download>
                                {pic.preview}
                              </a>}
                            </div>
                            {/* <label for="editPicProfile">
                          <img src={edit} className="edit-icon" />
                        </label> */}
                            <label for="deleteProfile" onClick={handlePicRemove}>
                              <img src={delicon} className={pic.pdf ? 'delete-icon' : "delete-iconn-13"} />
                            </label>
                          </div>
                        )}
                      </div>

                      <input
                        type="file"
                        onChange={(e) => handlePicChange(e)}
                        accept="image/x-png,image/jpeg,image/svg+xml,application/pdf"
                        className="sr sr-only"
                        id="editPicProfile"
                        name="orderImageUrl"

                      // value={rfqDetails.orderImageUrl}
                      />
                    </>}
                  <FormGroup>
                    <Label for="exampleText" className="input-weave">
                      {valueGet.processType != 'GREIGE' ? valueGet.fabricStage=='YarnDyed'?'(vi)': '(vii)' : '(vi)'} Comments (Optional)
                    </Label>
                    <Input
                      type="textarea"
                      name="orderComment"
                      onChange={(e) => handleChange(e)}
                      value={rfqDetails.orderComment}
                      id="exampleOrderText"
                      placeholder="1000 characters max"
                    />
                  </FormGroup>
                </div>
              </>
            ) : null}
            <div className="RFQButtons">
              <Button
                className="RFQ-Submit"
                type="submit"
                onClick={submitHandler}
                disabled={disablingErrors}
              >
                Submit
              </Button>
              <Button className="RFQ-Back" onClick={handleBack}>
                Back
              </Button>
            </div>
          </Card>

          <NotificationContainer />
        </Card>
      </Row>
      {isLoading ? (
        <>
          <div className="cdf">
            {" "}
            <div className="loader" />
          </div>{" "}
        </>
      ) : null}
    </>
  );
};

export default RfqDyed13;
