

export const PROFILE_LINK = [
    // { displayName: 'Order Management ', href:{`http://e7e0-27-60-99-248.ngrok.io/#/home?token=${localStorage.getItem('token')}` },
    { displayName: 'Request for Quotation', to: '/requestforquotation' },
    // { displayName: 'Showrooms', to: 'http://abc.com' },
    // { displayName: 'Messages', to: 'http://abc.com' },
    // { displayName: 'Reports', to: 'http://abc.com' },
    // { displayName: 'Admin Settings', to: '/companyprofile' }

]

export const PROFILE_SELLER = [
    // { displayName: 'Respond to Quotation', to: '/respondtoquotation' },
    // { displayName: 'Order Management ', to: '/openenq' },
    // { displayName: 'Showrooms', to: 'http://abc.com' },
    // { displayName: 'Messages', to: 'http://abc.com' },
    // { displayName: 'Reports', to: '/metadata' },
    // { displayName: 'Admin Settings', to: '/companyprofile' }
]

export const PROFILE_SELLER_PP = [
    // { displayName: 'Respond to Quotation', to: '/respondtoquotation' },
    // { displayName: 'Order Management ', to: '/openenq' },
    { displayName: 'Showrooms', to: 'http://abc.com' },
    { displayName: 'Messages', to: 'http://abc.com' },
    { displayName: 'Reports', to: '/metadata' },
    // { displayName: 'Admin Settings', to: '/productprofile' }
]


export const PROFILE = [
    { displayName: '   Users', to: '/newrequest' },

    // { displayName: 'Queries', to: '' },
    { displayName: 'Metadata Updates', to: '/metadata' },

]
// export const PROFILE_BUYER = [
//     { displayName: 'Users', to: '/buyer' },
//     { displayName: 'Messages ', to: '' },
//     { displayName: 'Queries', to: '' },
//     { displayName: 'Metadata Updates', to: '/metadata' },
//     { displayName: 'Third Party Integrations', to: '' },
//     { displayName: 'Logistics', to: '' },
//     { displayName: 'Accounts', to: '/companyprofile' }
// ]


export const BuyerOpenEnq = [
    // { displayName: 'Order Management ', to: 'http://0060-27-60-99-248.ngrok.io/#/home' },
    // { displayName: 'Request for Quotation ', to: '/requestforquotation' },
    // { displayName: 'Showrooms', to: '' },
    // { displayName: 'Messages', to: '/metadata' },
    // { displayName: 'Reports', to: '' }
]