import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  FormText,
  Card,
  Spinner,
} from "reactstrap";
import "./CompanyProfile.css";
import "../../SignUp/SignUp.css";
import {
  DeleteLocationApi,
  GetAddressApi,
  LocationsApi,
} from "../../../services";
import plus from "../../../assets/plus.svg";
import editicon from "../../../assets/edit.svg";
import delicon from "../../../assets/delete2.png";
import validate from "validate.js";
import { Errors, Success } from "../../Alert";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { toast } from "react-toastify";
import {toastOptions} from "../../../helpers/toast-options";
import {states} from "../../../data.json";

validate.validators.presence.options = {
  message: "^mandatory field must be filled to proceed",
};

const disabledConstraints = {
  locationType: {presence: { allowEmpty: false }},
  streetAddress: { presence: { allowEmpty: false } },
  postalCode: {  presence: { allowEmpty: false }},
  city: {  presence: { allowEmpty: false }},
 
  state: { presence: { allowEmpty: false } },
};
const constraints = {
  locationType: {
    presence: { allowEmpty: false },
  },
  streetAddress: { presence: { allowEmpty: false } },
  postalCode: {
    presence: { allowEmpty: false },
    format: {
      pattern: /^([1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3})$/,
      flags: "i",
      message: "is invalid",
    },
  },

  state: { presence: { allowEmpty: false } },
};

function Location(props) {
  let modifyLocation;
  const [replaceId, setReplaceId] = useState(null);
  const [disablingError, setDisablingError] = useState();
  let companyId = localStorage.getItem("companyId");
  const [isEditMode, setisEditMode] = useState(false);

  const [location, setLocation] = useState({
    id: 0,
    locationType: "",
    streetAddress: "",
    state: "",
    city:"",
    postalCode: "",
    // id: Number(companyId)
  });
  const [formDetails, setFormDetails] = useState({
    submitted: false,
    errors: null,
  });
  // const [showControls, setshowControls] = useState(true)

  const [isLoading, setisLoading] = useState(false);

  const [fields, setFields] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [toastId, setToastId] = useState();
  const getAddressDetails = () => {
    GetAddressApi(companyId).then((res) => {
      if (res.isSuccess) {
        res.data.map((item) => (item.clientside = false));
        setFields(res.data);
      }
    });
  };

  useEffect(() => {
    getAddressDetails();
  }, []);
  useEffect(() => {
    const errors = validate(location, constraints);
    setDisablingError(validate(location, disabledConstraints));
    setFormDetails((prev) => ({
      ...prev,
      errors,
    }));
  }, [location]);

  const handleChange = (event) => {
    //
    const { name, value } = event.target;
    setLocation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function handleAdd() {
    // if (!formDetails.errors) {
    setLocation({
      locationType: "",
      postalCode: "",
      streetAddress: "",
      state: "",
      city:""
    });
    setFormDetails({
      submitted: false,
      errors: null,
    });
    setIsShow(true);
    // } else {
    //   toast.error('Please enter valid address')

    // }
  }

  let filterData;

  const editFields = (data, index) => {
    setReplaceId(index);

    setisEditMode(true);
    setIsShow(true);

    setLocation(data);
  };
  const checkValid = () => {
    if (fields && fields.length < 1) {
      if (
        location.locationType == "" ||
        location.postalCode == "" ||
        location.streetAddress == "" ||
        location.state == ""
      ) {
        // toast.error('Please select  values')

        return false;
      } else if (
        location.locationType != "" &&
        location.postalCode != "" &&
        location.streetAddress != "" &&
        location.state != ""
      ) {
        return true;
      } else {
        // toast.error('Please select  values')

        return false;
      }
    } else {
      if (
        location.locationType == "" &&
        location.postalCode == "" &&
        location.streetAddress == "" &&
        location.state == ""
      ) {
        return true;
      }
      if (
        location.locationType == "" ||
        location.postalCode == "" ||
        location.streetAddress == "" ||
        location.state == ""
      ) {
        // toast.error('Please select  values')

        return false;
      } else {
        return true;
      }
    }
  };

  const fun = (e) => {
    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    if (e.keyCode == 190) {
      e.preventDefault();
      return false;
    }
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  };

  const submitHandler = (event) => {
    setFormDetails((prev) => ({
      ...prev,
      submitted: true,
    }));
    if(formDetails.errors){
      const currentError = Object.keys(formDetails.errors).map(key => formDetails.errors[key][0]);
      if(!toastId){
        const id = toast.error(currentError[0], {...toastOptions, onClose: ()=> setToastId(null)});
        setToastId(id);
      }
      return;
    }
    if (isEditMode) {
      if (!formDetails.errors) {
        fields[replaceId] = location;
        setisEditMode(false);
        setReplaceId(null);

        setIsShow(false);
      }
      //
    } else if (!formDetails.errors) {
      if (
        location.locationType == "" ||
        location.postalCode == "" ||
        location.streetAddress == "" ||
        location.state == ""
      ) {
        // toast.error('Please select values')

        // setLocation({ locationType: '', postalCode: '', streetAddress: '', state: '' })

        return;
      } else {
        const values = [...fields];
        values.push(location);
        values.map((item) => (item.clientside = true));
        setFields(values);
        // document.getElementById("exampleSelect").selectedIndex = 0;
        setFormDetails((prev) => ({
          ...prev,
          submitted: false,
        }));
        setLocation({
          locationType: "",
          postalCode: "",
          streetAddress: "",
          state: "",
        });
      }

      setIsShow(false);
    } else {
      if(!toastId){
        const id = toast.error("Please enter valid address", {...toastOptions, onClose: ()=> setToastId(null)});
        setToastId(id);
      }
      
    }
  };
  const handleClose = (idx) => {
    setFormDetails((prev) => ({
      ...prev,
      submitted: false,
    }));
    setLocation({
      locationType: "",
      postalCode: "",
      streetAddress: "",
      state: "",
    });

    setIsShow(false);
    setisEditMode(false);
  };

  const handleBack = () => {
    // setshowControls(false)
    props.fromBack(1);
  };

  const removeItem = (ind) => {
    setisEditMode(false);

    fields.forEach((val, index, array) => {
      if (ind === index && val.clientside) {
        array.splice(index, 1);
      }
    });
    setFields([...fields]);
  };
  const removeItem1 = (id) => {
    let addressDelete = {
      companyId: Number(companyId),
      addressId: id,
    };

    DeleteLocationApi(addressDelete).then((res) => {
      if (res.isSuccess) {
        fields.forEach((val, index, array) => {
          if (val.id === id) {
            array.splice(index, 1);
          }
        });
        setFields([...fields]);
        setIsShow(false);
      }
    });
  };

  const handleForward = (event) => {
    event.preventDefault();

    setFormDetails((prev) => ({
      ...prev,
      submitted: true,
    }));
    if (fields.length && isShow == false) {
      let val = checkValid();
      if (val) {
        const { name, value } = event.target;
        setLocation((prev) => ({
          ...prev,
          [name]: value,
        }));

        let finalArray = [];

        if (isEditMode) {
          fields.forEach((val, index, array) => {
            if (val.id === location.id) {
              array.splice(index, 1);
            }
          });

          // finalArray = fields.concat([locatvarion])
          finalArray = fields;

          modifyLocation = {
            id: Number(companyId),
            addresses: finalArray,
          };
        } else {
          if (
            location.locationType != "" &&
            location.postalCode != "" &&
            location.streetAddress != "" &&
            location.state != ""
          ) {
            // location.id = 0

            modifyLocation = {
              id: Number(companyId),
              // addresses: fields.concat(location),
              addresses: fields,
            };
          } else {
            modifyLocation = {
              id: Number(companyId),
              addresses: fields,
            };
          }
        }

        // setisLoading(true)
        // if (!formDetails.errors) {

        LocationsApi(modifyLocation)
          .then((data) => {
            if (data.isSuccess) {
            }
          })
          .catch((err) => {
            setisLoading(false);
          });
        // }
      }
      // if (!formDetails.errors) {

      props.fromAction(1);
    } else if (isShow == true) {
      if(!toastId){
        const id = toast.error("Please close the box", {...toastOptions, onClose: ()=> setToastId(null)});
        setToastId(id);
      }

      return false;
    } else {
      if(!toastId){
        const id = toast.error("Please enter atleast 1 address", {...toastOptions, onClose: ()=> setToastId(null)});
        setToastId(id);
      }
    }
  };

  return (
    <>
      <div className="Cards">
        <div className="add-location-button">
          <button className="add" type="button" onClick={() => handleAdd()}>
            <img src={plus} className="delete-photo" />
            <span className="Btn-text">Add Address</span>
          </button>
          {/* <button className="btn btn-primary form-reset" onClick={() => resetForm()}>Reset Form</button> */}
        </div>
        <div id="App" className="App">
          {fields.length > 0
            ? fields.map((val, index) => (
                <div className="row">
                  <div className="col-md-8">
                    {val.locationType == "CORPORATE_OFFICE" ? (
                      <p className="bold">
                        Address- {index + 1}{" "}
                        <span className="lowercases">(Corporate Office)</span>{" "}
                      </p>
                    ) : (
                      <p className="bold">
                        Address- {index + 1}{" "}
                        <span className="lowercases">
                          (
                          {`${val.locationType
                            .charAt(0)
                            .toUpperCase()}${val.locationType
                            .split("_")
                            .join(" ")
                            .substring(1)
                            .toLowerCase()}`}
                          )
                        </span>{" "}
                      </p>
                    )}
                    <p className="address-para">
                      {val.streetAddress},{val.city}, {val.state}, {val.postalCode}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="location-icon">
                      <img
                        src={editicon}
                        className="location-edit-icon"
                        onClick={() => editFields(val, index)}
                      />
                      {val.clientside ? (
                        <img
                          src={delicon}
                          className="location-delete-icon"
                          onClick={() => removeItem(index)}
                        />
                      ) : (
                        <img
                          src={delicon}
                          className="location-delete-icon"
                          onClick={() => removeItem1(val.id, index)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))
            : null}

          {isShow ? (
            <div className="form-div location-div" style={{ height: "auto" }}>
              <p className="location-close-x" onClick={() => handleClose()}>
                x
              </p>

              <Form className="location-form">
                {/* <p>Address-{ 1}</p> */}

                <FormGroup>
                  <Label
                    className="required locationlabel"
                    for="exampleSelect1"
                    sm={4}
                  >
                    Location Type
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="select"
                      name="locationType"
                      className="location"
                      value={isEditMode ? location.locationType : null}
                      id="exampleSelectNew"
                      onChange={handleChange}
                    >
                      <option value="" selected={true} disabled>
                        Select
                      </option>

                      <option value="CORPORATE_OFFICE">Corporate Office</option>
                      <option value="FACTORY">Factory</option>
                      <option value="WAREHOUSE">Warehouse</option>
                    </Input>
                    {formDetails.submitted &&
                      formDetails.errors &&
                      formDetails.errors.locationType && (
                        <small className="form-text text-danger">
                          {formDetails.errors.locationType[0]}
                        </small>
                      )}
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Label className="required" for="exampleAddress">
                    Street address
                  </Label>
                  <Input
                    type="text"
                    minLength={1}
                    maxLength={150}
                    name="streetAddress"
                    id="exampleAddress"
                    value={location.streetAddress}
                    onChange={handleChange}
                    className="location"
                    placeholder=""
                  />
                  {/* {formDetails.submitted &&
                    formDetails.errors &&
                    formDetails.errors.streetAddress && (
                      <small className="form-text text-danger">
                        {formDetails.errors.streetAddress[0]}
                      </small>
                    )} */}
                </FormGroup>

                <Row form>
                  <Col md={6} className="pl-0">
                    <FormGroup>
                      <Label className="required" for="exampleSelect">
                        State
                      </Label>
                      <Input
                        type="select"
                        name="state"
                        id="exampleSelectNew"
                        value={location.state}
                        // minLength={1}
                        // maxLength={50}
                        onChange={handleChange}
                        className="location"
                        // placeholder=""
                      >
                      <option value="" selected={true} disabled>
                        Select
                      </option>
                      {states.map(state => 
                        <option value={state.code}>
                          {state.name}
                        </option>
                      )}
                      </Input>
                      {formDetails.submitted &&
                        formDetails.errors &&
                        formDetails.errors.state && (
                          <small className="form-text text-danger">
                            {formDetails.errors.state[0]}
                          </small>
                        )}
                    </FormGroup>
                  </Col>
                  <Col md={6} className="pr-0">
                    <FormGroup>
                      <Label className="required" for="examplePassword">
                        City
                      </Label>
                      <Input
                        type="text"
                        name="city"
                        id="examplePassword"
                        value={location.city}
                        
                        autoComplete="off"
                        maxLength="30"
                        placeholder=""
                        onChange={handleChange}
                        className="location"
                      />
                      {/* {formDetails.submitted &&
                        formDetails.errors &&
                        formDetails.errors.postalCode && (
                          <small className="form-text text-danger">
                            {formDetails.errors.postalCode[0]}
                          </small>
                        )} */}
                    </FormGroup>
                  </Col>
                  <Col md={6} className="pr-0">
                    <FormGroup>
                      <Label className="required" for="examplePassword">
                        Postal Code
                      </Label>
                      <Input
                        type="pincode"
                        name="postalCode"
                        id="examplePassword"
                        value={location.postalCode}
                        onKeyDown={(e) => fun(e)}
                        autoComplete="off"
                        maxLength="6"
                        placeholder=""
                        onChange={handleChange}
                       
                        className="location"
                      />
                      {/* {formDetails.submitted &&
                        formDetails.errors &&
                        formDetails.errors.postalCode && (
                          <small className="form-text text-danger">
                            {formDetails.errors.postalCode[0]}
                          </small>
                        )} */}
                    </FormGroup>
                  </Col>
                 
                  {/* <Col md={6} className="pr-0">
                    <FormGroup>
                      <Label className="required" for="exampleCity">
                        City
                      </Label>
                      <Input
                        type="select"
                        name="city"
                        id="exampleCity"
                        value={isEditMode ? location.city : null}
                        onChange={handleChange}
                        className="location"
                      >
                      <option value="" selected={true} disabled>
                        Select
                      </option>
                      {cities.map(city => 
                        <option value={city.code}>
                          {city.name}
                        </option>
                      )}
                      </Input>
                      {formDetails.submitted &&
                        formDetails.errors &&
                        formDetails.errors.city && (
                          <small className="form-text text-danger">
                            {formDetails.errors.city[0]}
                          </small>
                        )}
                    </FormGroup>
                  </Col> */}

                  <Col md={12} className="pr-0">
                    <Button
                      color="primary"
                      onClick={() => submitHandler()}
                      className="add-location"
                      disabled={disablingError}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          ) : null}

          <div className="Buis-Button">
            <Button
              className="BusinessNext-btn"
              disabled={isLoading || fields.length == 0}
              color="primary"
              type="submit"
              onClick={handleForward}
            >
              {/* {isLoading ? <Spinner /> : 'Next'} */}
              Next
            </Button>
            <Button
              className="BusinessBack-btn"
              outline
              color="primary"
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
}
export default Location;
