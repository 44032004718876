export const COMPANY_LINK = [
    { TopnavName: 'New User Requests', to: '/newrequest' },
    { TopnavName: 'Buyers', to: '/buyer' },
    { TopnavName: 'Sellers', to: '/seller' }


]

export const Company_Header = [
    // { TopnavName: 'Company Profile', to: '/companyprofile' },
    // { TopnavName: 'Users & Teams', to: '/userteam' },
    { TopnavName: 'Product Profile', to: '/productprofile' }, //buyer me nhi aaega


]
export const Company_Headers = [
    // { TopnavName: 'Company Profile', to: '/companyprofile' },
    // { TopnavName: 'Users & Teams', to: '/userteam' },
    // {TopnavName: 'Product Profile', to: '/productprofile'}, //buyer me nhi aaega


]

export const Meta_Header = [
    { TopnavName: 'Yarn Types', to: '/metadata' },
    { TopnavName: 'Fabric Stage', to: '/fabric' },
    { TopnavName: 'Tax Codes', to: '/abc' }


]

export const RFQ_Header = [
    { TopnavName: 'Fabric', to: '/requestforquotation' },
    { TopnavName: 'Accessories', to: '/abc' },
    { TopnavName: 'Jobworks', to: '/requestforquotationjob' },
    { TopnavName: 'FMIGS', to: '/abc' }


]

export const OpenEnq_Header = [
    { TopnavName: 'Dashboard', to: '/' },
    { TopnavName: 'Developments', to: '/ordermanagement' },
    { TopnavName: 'Confirmed orders', to: '/abc' },
    { TopnavName: 'Completed orders', to: '/abc' }


]



