import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import "./Rfq.css";
import edit from "../../../assets/edit.png";
// import delicon from '../../assets/delete.png'
import { Link, useHistory } from "react-router-dom";
import { Table } from "reactstrap";
import RfqDyed13 from "./RfqDyed13";
import { GetRFQApi, RfqSubmitApi } from "../../../services";
import { redirectRoot } from "../../../services/api.service";
import { stages } from "../../../models/enum";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import save from "../../../assets/save.png";
import { toRoman } from "../../../services/main.service";
import { useDispatch, useSelector } from "react-redux";

const RfqDyed14 = (props) => {
  const dispatch = useDispatch();
  const linkType = useSelector(state => state.fabricLinkReducer.linkType)
  console.log(linkType)
  let rfdNumber = localStorage.getItem("rfqNumber");
  let rfdID = localStorage.getItem("rfqID");
  let stageName = localStorage.getItem("stageName");
  let stage;
  let body;
  // if (stageName == 'Greige') {
  //   stage = stages.RFQ_IN_PRG3

  // } else {
  //   stage = stages.RFQ_IN_PRG4

  // }
  stage = stages.RFQ_IN_PRG5;
  // let printArr = [];
  // let dyedArr = [];

  const [printArr, setprintArr] = useState([]);
  const [dyedArr, setdyedArr] = useState([]);
  const [greigeArr, setGreigeArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("");
  const [valueGet, setValueGet] = useState({
    warpCount: "",
    warpTypeId: "",
    weftComment: "",
    weftCount: "",
    weftTypeId: "",
    warpComment: "",
    fabricConstructionComment: "",
    gsmValue: "",
    processType: "",
    fabricReed: "",
    fabricPick: "",
    dyedWidth: "",
    printWidth: "",
    dyeCustomizations: "",
    fabricType: "",
    fabricStage: "",
    weaveType: "",
    dyeCustomizations: [],
    printCustomizations: [],
    rfqOrderDetails: [],
    warpYarnDTOList: [],
    weftYarnDTOList: []
  });
  const history = useHistory();

  const getFabricTypeApi = () => {
    setIsLoading(true);
    console.log(props.viewData)
    if (props.viewType == "view") {
      localStorage.setItem("rfqID", props.viewData.rfqId);
      // localStorage.removeItem('stageName')

      localStorage.removeItem("rfqNumber");
      // lo                                                                                                                                       calStorage.setItem('rfqNumber', props.viewData.rfqNumber)
      localStorage.setItem("rfqNumber", props.viewData.rfqNumber);
      body = {
        id: localStorage.getItem("rfqID"),
        rfqStage: stage,
      };
    } else {
      body = {
        id: rfdID,
        rfqStage: stage,
      };
    }

    GetRFQApi(body).then((res) => {
      //
      if (res.isSuccess) {
        let printArray = res.data.rfqOrderDetails.filter((item) => {
          if (item.processType == "PRINT") {
            return item;
          }
        });
        let dyedArray = res.data.rfqOrderDetails.filter((item) => {
          if (item.processType == "DYED") {
            return item;
          }
        });
        let greigeArray = res.data.rfqOrderDetails.filter((item) => {
          if (item.processType == "GREIGE" || item.processType == 'YARNDYED') {
            return item;
          }
        });
        //
        if (res.data.workType == 'FABRIC') {

          dispatch({ type: 'fabric' })
          if (localStorage.getItem('linkType') != 'fabric')
            localStorage.setItem('linkType', 'fabric');

        } else {

          dispatch({ type: 'job_work' })
          if (localStorage.getItem('linkType') != 'job_work')
            localStorage.setItem('linkType', 'job_work');


        }
        console.log(printArray)
        setprintArr([...printArray]);
        setdyedArr([...dyedArray]);
        setGreigeArr([...greigeArray]);
        setType(res.data.fabricType);
        setValueGet(res.data);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    console.log(props.viewData)
    getFabricTypeApi();
  }, []);

  const handleBack5 = () => {
    props.fromBack5(1);
  };
  const handleBack4 = () => {
    props.fromBack4(1);
  };
  const handleBack3 = () => {
    props.fromBack3(1);
  };
  const handleBack2 = () => {
    props.fromBack2(1);
  };
  const handleBack = () => {
    props.fromBack(1);
  };

  const submitHandler = () => {
    setIsLoading(true);

    let body = {
      rfqId: Number(rfdID),
    };
    // document.cookies = `token=${localStorage.getItem('token')}`
    // document.cookie = localStorage.getItem('token')
    RfqSubmitApi(rfdID, body).then((res) => {
      setIsLoading(false);

      if (res.isSuccess) {
        // history.push(`/ordermanagement`)
        // window.location.replace(`https://app.fabtrack.in/#/buyer/ordermanagement/openenq`);
        window.location.replace(`${redirectRoot}/buyer/ordermanagement/openenq`);
        // window.location.replace(`https://app.fabtrack.in/#/ordermanagement?token=${localStorage.getItem('token')},"_self"`)
      }
    });
  };
  const capitalizeFirstLowercaseRest = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };


  const getprocesstype = (processtype) => {
    if (linkType == 'fabric') {
      return `(${processtype})`;
    } else {
      switch (processtype) {
        case 'PRINT':
          {
            return 'Printing';
            break;
          }
        case 'DYED': {
          return 'Dyeing';
        }
        default: {
          return 'Printing & Dyeing';
        }
      }
    }
  }

  return (
    <>
      <h4 className="RFQ-h4">
        {/* {valueGet.name} | RFQ #{rfdNumber} */}
        <h4 className="RFQ-h4">
          Order {localStorage.getItem("orderName")} | RFQ #{rfdNumber}{linkType != 'fabric' && ' | Job Work'}
        </h4>
      </h4>

      <Row className="RFQ-Row">
        <Card className="RFQ-order-kate1">
          <div className="para-edit py-0">
            <div className="para-para">
              <p className="input-saved">
                1. Type of Fabric:
                <span className="bold-text text-capitalize">
                  {capitalizeFirstLowercaseRest(valueGet.fabricType)}
                </span>
              </p>

              {valueGet.fabricStage != "Processed" ? (
                <p className="inputs-saved">
                  2. Fabric Stage:
                  <span className="bold-text  text-capitalize">{valueGet.fabricStage==='YarnDyed'?'Yarn Dyed': capitalizeFirstLowercaseRest(valueGet.fabricStage)}</span>
                </p>
              ) : (
                <p className="inputs-saved">
                  {linkType == 'fabric' ? ' 2. Fabric Stage:' : '2. Job Work:'}
                  <span className="bold-text text-capitalize">
                    {linkType == 'fabric' && valueGet.fabricStage} {getprocesstype(valueGet.processType)}
                  </span>
                </p>
              )}
            </div>
            <img src={edit} className="edit-icon" onClick={handleBack5} />
          </div>

          <hr className="hr-order-kate" />
          <div className="para-edit">
            <p className="input-saved">
              {" "}
              3. {type.toLowerCase() == 'woven' ? ' Type of Weave:' : type.toLowerCase() == 'knit' ? ' Fabric Structure:' : ''}
              <span className="bold-text">{valueGet.weaveType}</span>
            </p>
            <img src={edit} className="edit-icon" onClick={handleBack4} />
          </div>

          <hr className="hr-order-kate" />
          {linkType == 'job_work' && <p className="input-saved"><i>Details of fabric being sent for job work</i> </p>}
          <div className="para-edit">

            <p className="input-saved">4. {type.toLowerCase() == 'woven' ? ' Warp and Weft parameters' : type.toLowerCase() == 'knit' ? ' Wale and Course parameters' : ''}</p>
            <img src={edit} className="edit-icon" onClick={handleBack3} />
          </div>
          <div className="row w-100 ml-0">
            <p className="col-6 warp-p pr-0 pl-32">(i) {type.toLowerCase() == 'woven' ? ' Warp' : type.toLowerCase() == 'knit' ? ' Wale' : ''}</p>
            <p className="col-6 weft-p px-0">(ii) {type.toLowerCase() == 'woven' ? ' Weft' : type.toLowerCase() == 'knit' ? ' Course' : ''}</p>
          </div>
          <div className="row w-100 ml-0 mb-24">
            <div className="col-6">
              <div className="input-saved mb-8 ml-41">
                Count:
                <span className="bold-text ">{valueGet.warpCount}</span>
              </div>
              <div className="input-saved mb-8 ml-41">
                Type of yarn:
                <span className="bold-text">{valueGet.warp}</span>
              </div>
              {valueGet.warpYarnDTOList.length > 0 && valueGet.warpYarnDTOList.findIndex(w => w.sustainabilityId != 0) != -1 && <div className="input-saved d-flex mb-8 ml-41">
                <div> Sustainability:</div><div>
                  {valueGet.warpYarnDTOList.map(item => {
                    return (
                      <>
                        {item.sustainabilityName ? <>  <span className="bold-text">{item.yarnName}: {item.sustainabilityName}</span><br /></> : ''}
                      </>
                    )
                  })}
                </div>
              </div>}
              <div className="input-saved ml-41">
                Comments:
                <span className="bold-text ml-18" title={valueGet.warpComment}>
                  {valueGet.warpComment
                    ? `${valueGet.warpComment.length > 400 ? valueGet.warpComment.substr(0, 400) + '...' : valueGet.warpComment}`
                    : ""}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="input-saved mb-8 ml-2 pl-1">
                Count:
                <span className="bold-text ">{valueGet.weftCount}</span>
              </div>
              <div className="input-saved mb-8 ml-2 pl-1">
                Type of yarn:
                <span className="bold-text">{valueGet.weft}</span>
              </div>
              {valueGet.weftYarnDTOList.length > 0 && valueGet.weftYarnDTOList.findIndex(w => w.sustainabilityId != 0) != -1 && <div className="input-saved d-flex mb-8">
                <div> Sustainability:</div><div>
                  {valueGet.weftYarnDTOList.map(item => {
                    return (
                      <>
                        {item.sustainabilityName ? <>  <span className="bold-text">{item.yarnName}: {item.sustainabilityName}</span><br /></> : ''}
                      </>
                    )
                  })}
                </div>
              </div>}
              <div className="input-saved ml-2 pl-1">
                Comments:
                <span className="bold-text ml-18" title={valueGet.weftComment}>
                  {valueGet.weftComment
                    ? `${valueGet.weftComment.length > 400 ? valueGet.weftComment.substr(0, 400) + '...' : valueGet.weftComment}`
                    : ""}
                </span>
              </div>
            </div>
          </div>
          {type.toLowerCase() == 'woven' ? <>
            <p className="input-saved">
              5. Fabric Construction:
              <span className="bold-text">
                {valueGet.fabricReed} X {valueGet.fabricPick}
              </span>
            </p>
            <p className="input-saved ml-3 pl-3 mt-8 mb-16">
              Comments:
              <span
                className="bold-text"
                title={valueGet.fabricConstructionComment}
              >
                {valueGet.fabricConstructionComment
                  ? `${valueGet.fabricConstructionComment.length > 600 ? valueGet.fabricConstructionComment.substr(0, 600) + '...' : valueGet.fabricConstructionComment}`
                  : ""}
              </span>
            </p></>
            : ''}
          <p className="input-saved mb-16">
            {type.toLowerCase() == 'woven' ? '6.' : '5.'} GSM:<span className="bold-text">{valueGet.gsmValue}</span>
          </p>
          <div className="mb-14 d-flex">
            <p className="input-saved">
              {type.toLowerCase() == 'woven' ? '7.' : '6.'} {linkType === 'job_work' ? 'Fabric' : 'Preferred Fabric'} width {type.toLowerCase() == 'woven' ? '(L-100)' : '(open width)'}:
              {valueGet.processType == "Print & Dyed" ? (
                linkType !== 'job_work' ?
                  <p className="align-right">
                    {" "}
                    <span className="font-82">(a) Print :</span>{" "}
                    <span>{valueGet.printWidth} inches</span>{" "}
                    <span className="font-82 ml-3">(b) Dyed :</span>{" "}
                    <span>{valueGet.dyedWidth} inches</span>
                  </p> :
                  <>
                    <div className="mb-2 font-82" style={{ marginLeft: '1vw', marginTop: '1.6vh' }}>
                      {" "}
                      (i) Greige Fabric Width: <span className="bold-text" style={{ marginLeft: '0 !important' }}>{valueGet.printRawWidth} inches</span>{" "}

                    </div>
                    <div style={{ marginLeft: '1vw' }}>
                      <div className="font-82" >(ii) Preferred Finished Fabric Width:</div>
                      <div className="font-82 pre-ml-18 mt-2">(a) Print :{" "} <span className="bold-text" style={{ marginLeft: '0 !important' }}>{valueGet.printWidth} inches</span>{" "}</div>
                      <div className="font-82 pre-ml-18 mt-2">(b) Dyed :{" "}
                        <span className="bold-text" style={{ marginLeft: '0 !important' }}>{valueGet.dyedWidth} inches</span>{"  "}
                      </div></div>
                  </>
              ) : null}
              {valueGet.processType == "PRINT" ? (
                linkType == 'job_work' ?
                  <>
                    <p className="font-82 " style={{ marginLeft: '1.23vw', marginTop: '1.16vh', marginBottom: '0' }}>(i) Greige Fabric Width: <span className="align-right">{valueGet.printRawWidth} inches</span></p>

                    <p className="font-82 " style={{ marginLeft: '1.23vw', marginTop: '1.16vh', marginBottom: '0' }}>(ii) Preferred Finished Fabric Width:  <span className="align-right">{valueGet.printWidth} inches</span></p>

                  </> :
                  <p className="align-right">{valueGet.printWidth} inches</p>


              ) : null}
              {valueGet.processType == "DYED" ? (
                linkType == 'job_work' ?
                  <>
                    <p className="font-82 " style={{ marginLeft: '1.23vw', marginTop: '1.16vh', marginBottom: '0' }}>(i) Greige Fabric Width: <span className="align-right">{valueGet.dyedRawWidth} inches</span></p>

                    <p className="font-82 " style={{ marginLeft: '1.23vw', marginTop: '1.16vh', marginBottom: '0' }}>(ii) Preferred Finished Fabric Width:  <span className="align-right">{valueGet.dyedWidth} inches</span></p>

                  </> :
                  <p className="align-right">{valueGet.dyedWidth} inches</p>

              ) : null}
              {valueGet.processType == "GREIGE" ? (
                <p className="align-right">{valueGet.greigeWidth} inches</p>
              ) : null}
              {valueGet.processType.toUpperCase() == "YARNDYED" ? (
                <p className="align-right">{valueGet.greigeWidth} inches</p>
              ) : null}
            </p>
          </div>
          {linkType == 'job_work' && <p className="input-saved mb-14">
            {type.toLowerCase() == 'woven' ? '8.' : '7.'} Fabric dispatch dates to job works vendor:<span className="bold-text">{valueGet.jobWorkStartDate} to {valueGet.jobWorkEndDate}</span>
          </p>}
          {(valueGet.processType != "GREIGE" || valueGet.fabricStage == 'YarnDyed') && <hr className="hr-order-kate" />}
          <div className="tables-p-d pt-16">
            {(valueGet.fabricStage == "Processed" || valueGet.fabricStage == 'YarnDyed') ? (
              <>
                <div className="preferred-fabric d-flex mb-0">
                  <p className="input-saved mb-8 pb-1">{linkType == 'job_work' ? type.toLowerCase() == 'woven' ? '9.' : '8.' : type.toLowerCase() == 'woven' ? '8.' : '7.'} Fabric Customizations</p>
                  <img src={edit} className="edit-icon" onClick={handleBack2} />
                </div>
                {valueGet.processType == "DYED" ? (
                  <Table borderless className=" flex-column p-0 ml-3 mb-16" style={{ border: "none" }}>
                    {valueGet.dyeCustomizations.length > 0 && <thead>
                      <tr className="dyed-color-custom-head d-flex">
                        <th className="roman-no">&nbsp;</th>
                        <th className="color-box">
                          <div>Colour</div>
                        </th>
                        <th className="color-code">
                          <div>Pantone Code</div>
                        </th>
                        <th className="qty-boxx">
                          <div>Quantity</div>
                        </th>
                        <th className="qty-boxx ">
                          <div>QTX File</div>
                        </th>
                      </tr>
                    </thead>}
                    <tbody>
                      {valueGet.dyeCustomizations.length > 0
                        ? valueGet.dyeCustomizations.map((row, id) => (
                          <tr className="dyed-color-custom-head">
                            <td className="roman-no">({toRoman(id + 1)})</td>
                            <td
                              title={row.colourName.length > 8 && row.colourName}
                              className="colour-text color-box"
                            >
                              {" "}
                              {row.colourName.length > 8 ? row.colourName.substr(0, 8) + '...' : row.colourName}
                            </td>
                            <td className="colour-text color-code max-qty" title={row.colourCode}>
                              {row.colourCode || ""}
                            </td>
                            <td className="colour-text qty-boxx ">
                              {new Intl.NumberFormat('en-IN', {}).format(row.quantity)} {row.quantity > 0 && type.toLowerCase() == 'woven' ? ' m' : type.toLowerCase() == 'knit' ? ' kg' : ''}
                            </td>
                            <td className="colour-text qty-boxx text-center">
                              {row.otxFile
                                ? <>
                                  {!row.otxFile.includes("pdf") ? (
                                    <small>
                                      <a
                                        href={row.otxFile}
                                        title={row.otxFile}
                                      >
                                        <img src={save} />
                                      </a>
                                    </small>
                                  ) : (
                                    <small>
                                      <a
                                        href={row.otxFile}
                                        title={row.otxFile}
                                      >
                                        <img src={save} />
                                      </a>
                                    </small>
                                  )}
                                </> : ''}
                            </td>
                          </tr>
                        ))
                        : <tr>
                          <td style={{ 'padding': '0', fontSize: '1.6vh' }} colSpan={4}>No information provided</td>
                        </tr>}
                    </tbody>
                  </Table>
                ) : null}
                {valueGet.processType == "PRINT" ? (
                  <Table borderless className="tablerfqdyed ml-3 mb-16" style={{ border: "none" }}>
                    {valueGet.printCustomizations.length > 0 && <thead>
                      <tr className="dyed-color-custom-head d-flex">
                        <th className="roman-no">&nbsp;</th>
                        <th className="color-box">
                          <div>Print Name</div>
                        </th>
                        <th className="color-code">
                          <div>Quantity</div>
                        </th>
                        <th className="qty-boxx text-left">
                          <div>Print Design</div>
                        </th>
                      </tr>
                    </thead>}
                    <tbody>
                      {valueGet.printCustomizations.length > 0
                        ? valueGet.printCustomizations.map((row, id) => (
                          <tr className="dyed-color-custom-head">
                            {/* <p>{id + 1}</p> */}

                            <p
                              title={row.printName}
                              className="roman-no"
                            >
                              ({toRoman(id + 1)})
                            </p>

                            <p title={row.printName.length > 10 && row.printName} className="colour-text color-box">
                              {row.printName.length > 10 ? row.printName.substr(0, 10) + '...' : row.printName}

                            </p>

                            <p className="colour-text color-code">
                              {new Intl.NumberFormat('en-IN', {}).format(row.quantity)} {row.quantity > 0 && type.toLowerCase() == 'woven' ? ' m' : type.toLowerCase() == 'knit' ? ' kg' : ''}
                            </p>

                            {!row.imageUrl.includes("pdf") ? (
                              <p className="colour-text qty-boxx text-center">
                                <small>
                                  <a href={row.imageUrl} title={row.imageUrl}>
                                    <img src={save} />
                                  </a>
                                </small>
                              </p>
                            ) : (
                              <p className="colour-text qty-boxx text-center">
                                {" "}
                                <small>
                                  <a href={row.imageUrl} title={row.imageUrl}>
                                    <img src={save} />
                                  </a>
                                </small>
                              </p>
                            )}
                          </tr>
                        ))
                        : <tr>
                          <td style={{ 'padding': '0', fontSize: '1.6vh' }} colSpan={4}>No information provided</td>
                        </tr>}
                    </tbody>
                  </Table>
                ) : null}
                {valueGet.processType == "Print & Dyed" ? (
                  <>
                    <div className="row w-100 ml-0 print-dyed-title">
                      <p className="col-6 warp-p pr-0 pl-3">Print</p>
                      <p className="col-6 weft-p px-0 pl-3">Dyed</p>
                    </div>
                    <div className="Wrap-Weft-Ot">
                      <Table borderless className="tablerfqdyed  ml-3 mb-8" style={{ border: "none" }}>
                        {valueGet.printCustomizations.length > 0 && <thead>
                          <tr className="dyed-color-custom-head d-flex">
                            <th className="roman-no">&nbsp;</th>
                            <th className="color-box">
                              <div>Print Name</div>
                            </th>
                            <th className="color-code">
                              <div>Quantity</div>
                            </th>
                            <th className="qty-boxx text-left">
                              <div>Print Design</div>
                            </th>
                          </tr>
                        </thead>}
                        <tbody>
                          {valueGet.printCustomizations.length > 0
                            ? valueGet.printCustomizations.map((row, id) => (
                              <tr className="dyed-color-custom-head">
                                <p className="roman-no">({toRoman(id + 1)})</p>
                                {/* <p>{id + 1}</p> */}
                                {/* <p title={row.printName} className="data-d w-100px">({id + 1})  {row.printName.substring(0, 5)}...</p> */}
                                <p
                                  title={row.printName.length > 10 && row.printName}
                                  className="colour-text color-box"
                                >
                                  {" "}
                                  {row.printName.length > 10 ? row.printName.substr(0, 10) + '...' : row.printName}
                                </p>

                                <p className="colour-text color-code">
                                  {new Intl.NumberFormat('en-IN', {}).format(row.quantity)} {row.quantity > 0 && type.toLowerCase() == 'woven' ? ' m' : type.toLowerCase() == 'knit' ? ' kg' : ''}
                                </p>

                                <p className="colour-text qty-boxx text-center">
                                  {!row.imageUrl.includes("pdf") ? (
                                    <small>
                                      <a
                                        href={row.imageUrl}
                                        title={row.imageUrl}
                                      >
                                        <img src={save} />
                                      </a>
                                    </small>
                                  ) : (
                                    <small>
                                      <a
                                        href={row.imageUrl}
                                        title={row.imageUrl}
                                      >
                                        <img src={save} />
                                      </a>
                                    </small>
                                  )}
                                </p>
                              </tr>
                            ))
                            : <tr>
                              <td style={{ 'padding': '0', fontSize: '1.6vh' }} colSpan={4}>No information provided</td>
                            </tr>}
                        </tbody>
                      </Table>
                      <Table borderless className=" flex-column p-0 ml-3 mb-8"
                        style={{ border: "none" }}>
                        {valueGet.dyeCustomizations.length > 0 && <thead>
                          <tr className="dyed-color-custom-head d-flex">
                            <th className="roman-no">&nbsp;</th>
                            <th className="color-box">
                              <div>Colour</div>
                            </th>
                            <th className="color-code">
                              <div>Pantone Code</div>
                            </th>
                            <th className="qty-boxx ">
                              <div>Quantity</div>
                            </th>
                            <th className="qty-boxx ">
                              <div>QTX File</div>
                            </th>
                          </tr>
                        </thead>}
                        <tbody>
                          {valueGet.dyeCustomizations.length > 0
                            ? valueGet.dyeCustomizations.map((row, id) => (
                              <tr className="dyed-color-custom-head">
                                {/* <p>{id + 1}</p> */}
                                <div className="roman-no">({toRoman(id + 1)})</div>
                                <p
                                  title={row.colourName.length > 10 && row.colourName}
                                  className="colour-text color-box"
                                >
                                  {" "}
                                  {row.colourName.length > 10 ? row.colourName.substr(0, 10) + '...' : row.colourName}
                                </p>

                                <p className="colour-text color-code max-qty" title={row.colourCode}>
                                  {row.colourCode || ""}
                                </p>
                                <p className="colour-text qty-boxx text-center">
                                  {new Intl.NumberFormat('en-IN', {}).format(row.quantity)} {row.quantity > 0 && type.toLowerCase() == 'woven' ? ' m' : type.toLowerCase() == 'knit' ? ' kg' : ''}
                                </p>
                                <p className="colour-text qty-boxx text-center">
                                  {row.otxFile
                                    ? <>
                                      {!row.otxFile.includes("pdf") ? (
                                        <small>
                                          <a
                                            href={row.otxFile}
                                            title={row.otxFile}
                                          >
                                            <img src={save} />
                                          </a>
                                        </small>
                                      ) : (
                                        <small>
                                          <a
                                            href={row.otxFile}
                                            title={row.otxFile}
                                          >
                                            <img src={save} />
                                          </a>
                                        </small>
                                      )}
                                    </> : ''}
                                </p>
                              </tr>
                            ))
                            : <tr>
                              <td style={{ 'padding': '0', fontSize: '1.6vh' }} colSpan={4}>No information provided</td>
                            </tr>}
                        </tbody>
                      </Table>
                    </div>
                  </>
                ) : null}
                {valueGet.fabricStage == 'YarnDyed' && (
                  <div className=" w-100 ml-0 mb-16">
                    <p className=" warp-p pr-0 pl-3 mt-0" style={{ marginBottom: '1.6vh' }}>(i) Images/Files</p>
                    {valueGet.yarnDyedCustomisationDTO.yarnDyedImages.length > 0 && <table className="pl-3 mt-0 image-table"
                      style={{ marginLeft: '2.5vw' }}>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Size</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {valueGet.yarnDyedCustomisationDTO.yarnDyedImages.map((file, idx) => {
                          return (
                            <tr>
                              <td>{file.imageName?.split('.')[0]}</td>
                              <td>{file.imageName?.split('.')[1]}</td>
                              <td>{(file.imageSize / 1024).toFixed(1)}KB</td>
                              <td>
                                <a href={file.imageUrl} download={file.imageName}>Download</a>

                              </td>
                            </tr>
                          )

                        })}
                        <tr>

                        </tr>
                      </tbody>
                    </table>}
                    <div style={{ marginBottom: '1.6vh' }}>
                      <p className="warp-p pr-0 pl-3 mt-0" style={{ marginBottom: '1.6vh' }}>(ii) Yarn Details(Optional) </p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-saved " style={{ marginBottom: '1.6vh' }} >
                            (a) Warp
                          </div>
                          <div className="input-saved" style={{ marginBottom: '1.6vh' }}>
                            Number of Colours:<span className="bold-text">{valueGet.yarnDyedCustomisationDTO.warpNoOfColour}</span>
                          </div>

                          {valueGet.yarnDyedCustomisationDTO.yarnDyedColours.length > 0 && <table className="view-table cust-table " style={{marginLeft:'1.12vw'}} >
                            <thead>
                              <tr>
                                <th>Colour Name</th>
                                <th>Pantone Code</th>
                                <th>Qtx File</th>
                              </tr>
                            </thead>
                            <tbody>
                              {valueGet.yarnDyedCustomisationDTO.yarnDyedColours.filter(c => c.customisationType == 'WARP').map((item, idx) => {
                                return (
                                  <tr>
                                    <td>{item.colourName}</td>
                                    <td>{item.pantoneCode}</td>
                                    <td>{item.otxFile != "" && <a href={item.otxFile}
                                      title={item.otxFile} download>Download</a>}</td>
                                  </tr>
                                )
                              })}

                            </tbody>
                          </table>}
                        </div>
                        <div className="col-md-6">
                          <div className="input-saved-nomargin" style={{ marginLeft: '0 !important', marginBottom: '1.6vh' }}>
                            (b) Weft
                          </div>
                          <div className="warp-p pr-0" style={{ marginBottom: '1.6vh' }}>
                            Number of Colours:<span className="bold-text"> {valueGet.yarnDyedCustomisationDTO.weftNoOfColour}</span>
                          </div>
                          {valueGet.yarnDyedCustomisationDTO.yarnDyedColours.filter(c => c.customisationType === 'WEFT').length > 0 && <table className="view-table cust-table">
                            <thead>
                              <tr>
                                <th>Colour Name</th>
                                <th>Pantone Code</th>
                                <th>Qtx File</th>
                              </tr>
                            </thead>
                            <tbody>
                              {valueGet.yarnDyedCustomisationDTO.yarnDyedColours.filter(c => c.customisationType === 'WEFT').map((item, idx) => {
                                return (
                                  <tr>
                                    <td>{item.colourName}</td>
                                    <td>{item.pantoneCode}</td>
                                    <td>{item.otxFile != "" && <a href={item.otxFile}
                                      title={item.otxFile} download>Download</a>}</td>

                                  </tr>
                                )
                              })}

                            </tbody>
                          </table>}
                        </div>
                      </div>
                    </div>

                  </div>
                )}
              </>
            ) : null}
          </div>
          <hr className="hr-order-kate" />

          {valueGet.processType == "Print & Dyed" ? (
            <>
              {props.customizationData && ((props.customizationData.dyed && props.customizationData.dyed.length == 0)
                || (props.customizationData.print && props.customizationData.print.length == 0)) && props.fromBack2()}
              <div className="para-edit">
                <p className="input-saved">{linkType == 'job_work' ? type.toLowerCase() == 'woven' ? '10.' : '9.' : type.toLowerCase() == 'woven' ? '9.' : '8.'} Order Details</p>
                <img src={edit} className="edit-icon" onClick={handleBack} />
              </div>

              <div className="row mx-0">
                <div className="col-6">
                  <p className="input-saved mb-16">(a) Print</p>
                  <div className="ml-3">
                    <p className="input-saved mb-16">
                      (i) Quantity:
                      <span className="bold-text">
                        {new Intl.NumberFormat('en-IN', {}).format(printArr[0]?.quantity)} {type.toLowerCase() == 'woven' ? ' metres' : type.toLowerCase() == 'knit' ? ' kg' : ''}
                      </span>
                    </p>
                    <p className="input-saved mb-16">
                      (ii) Target Price:
                      <span className="bold-text">
                        {printArr[0]?.targetPrice == 0 ? 'Not provided' : printArr[0]?.targetPrice}{printArr[0]?.targetPrice != 0 && type.toLowerCase() == 'woven' ? ' INR per metre' : type.toLowerCase() == 'knit' ? ' INR per kg' : ''}
                      </span>
                    </p>
                    <p className="input-saved mb-16">
                      (iii) Expected Delivery: <div className="mb-8"></div>
                      <span className="bold-text ml-3">
                        {printArr[0]?.expectedDeliveryStartDate}
                      </span>{" "}
                      to{" "}
                      <span className="bold-text ml-0">
                        {printArr[0]?.expectedDeliveryEndDate}
                      </span>
                    </p>
                    <p className="input-saved mb-16">
                      (iv) {valueGet.processType.toLowerCase() != 'greige' ? 'Residual' : ''} Shrinkage:
                      <span className="bold-text">
                        {printArr[0]?.shrinkage}%  </span>
                    </p>
                  </div>
                  <p className="input-saved mb-16">
                    (v) End Buyer Name:
                    <span className="bold-text">
                      {valueGet.endBuyerName ? valueGet.endBuyerName : ""}
                    </span>
                  </p>
                  <p className="input-saved mb-16">
                    (vi) File:
                    {valueGet.orderImageUrl ? (
                      <>
                        {valueGet.orderImageUrl.indexOf('.pdf') == -1 ?
                          <img
                            className="gallery-section"
                            src={valueGet.orderImageUrl}
                            style={{ display: "block", marginTop: "0.7rem" }}
                          /> :
                          <a href={valueGet.orderImageUrl} className="pdf-show-mar" style={{ marginLeft: "10px;" }}><img src={save} /> </a>
                        }
                      </>
                    ) : (
                      <span className="bold-text"> </span>
                    )}
                  </p>
                  <p className="input-saved">
                    (vii) Comments:
                    <span className="bold-text" title={valueGet.orderComment}>
                      {valueGet.orderComment
                        ? `${valueGet.orderComment.length > 132 ? valueGet.orderComment.substr(0, 132) + '...' : valueGet.orderComment}`
                        : ""}
                    </span>
                  </p>
                </div>
                <div className="col-6">
                  <p className="input-saved mb-16">(b) Dyed</p>
                  <div className="ml-3">
                    <p className="input-saved mb-16">
                      (i) Quantity:
                      <span className="bold-text">
                        {new Intl.NumberFormat('en-IN', {}).format(dyedArr[0]?.quantity)} {type.toLowerCase() == 'woven' ? ' metres' : type.toLowerCase() == 'knit' ? ' kg' : ''}
                      </span>
                    </p>
                    <p className="input-saved mb-16">
                      (ii) Target Price:
                      <span className="bold-text">
                        {dyedArr[0]?.targetPrice == 0 ? 'Not provided' : dyedArr[0]?.targetPrice}{dyedArr[0]?.targetPrice != 0 && type.toLowerCase() == 'woven' ? ' INR per metre' : type.toLowerCase() == 'knit' ? ' INR per kg' : ''}
                      </span>
                    </p>
                    <p className="input-saved mb-16">
                      (iii) Expected Delivery:

                      <span className="bold-text ml-3">
                        {dyedArr[0]?.expectedDeliveryStartDate}
                      </span>{" "}
                      to{" "}
                      <span className="bold-text ml-0">
                        {dyedArr[0]?.expectedDeliveryEndDate}
                      </span>
                    </p>
                    <p className="input-saved mb-16">
                      (iv) {valueGet.fabricStage.toLowerCase() == 'processed' ? 'Residual' : ''} Shrinkage:
                      <span className="bold-text">
                        {dyedArr[0]?.shrinkage}%  </span>
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {valueGet.processType == "PRINT" ? (
            <>
              {props.customizationData && props.customizationData.print && props.customizationData.print.length == 0 && props.fromBack2()}
              <div className="para-edit">
                <p className="input-saved">{linkType == 'job_work' ? type.toLowerCase() == 'woven' ? '10.' : '9.' : type.toLowerCase() == 'woven' ? '9.' : '8.'} Order Details</p>

                <img src={edit} className="edit-icon" onClick={handleBack} />
              </div>
              <p className="input-saved mb-16 ml-3 pl-3">
                (i) Quantity:
                <span className="bold-text">
                  {new Intl.NumberFormat('en-IN', {}).format(printArr[0]?.quantity)} {type.toLowerCase() == 'woven' ? ' metres' : type.toLowerCase() == 'knit' ? ' kg' : ''}
                </span>
              </p>
              <p className="input-saved mb-16 ml-3 pl-3">
                (ii) Target Price:
                <span className="bold-text">
                  {printArr[0]?.targetPrice == 0 ? 'Not provided' : printArr[0]?.targetPrice} {printArr[0]?.targetPrice != 0 && type.toLowerCase() == 'woven' ? ' INR per metre' : type.toLowerCase() == 'knit' ? ' INR per kg' : ''}
                </span>
              </p>
              <p className="input-saved mb-16 ml-3 pl-3">
                (iii) Expected Delivery:
                <span className="bold-text">
                  {printArr[0]?.expectedDeliveryStartDate}
                </span>{" "}
                to{" "}
                <span className="bold-text ml-0">
                  {printArr[0]?.expectedDeliveryEndDate}
                </span>
              </p>
              <p className="input-saved mb-16 ml-3 pl-3">
                (iv) {valueGet.fabricStage.toLowerCase() == 'processed' ? 'Residual' : ''} Shrinkage:
                <span className="bold-text">
                  {printArr[0]?.shrinkage}%  </span>
              </p>
              <p className="input-saved mb-16 ml-3 pl-3">
                (v) End Buyer Name:
                <span className="bold-text">
                  {valueGet.endBuyerName ? valueGet.endBuyerName : ""}
                </span>
              </p>
              <p className="input-saved mb-16 ml-3 pl-3">
                (vi) Upload Image:
                <div className="bold-text" style={{ marginTop: "0.7rem" }}>
                  {valueGet.orderImageUrl ? (
                    <>
                      {valueGet.orderImageUrl.indexOf('.pdf') == -1 ?
                        <img
                          className="gallery-section"
                          src={valueGet.orderImageUrl}
                          style={{ display: "block", marginTop: "0.7rem" }}
                        /> :
                        <a href={valueGet.orderImageUrl} className="pdf-show-mar" style={{ marginLeft: "10px;" }}><img src={save} /> </a>
                      }
                    </>
                  ) : (
                    <span> </span>
                  )}
                </div>
              </p>

              <p className="input-saved ml-3 pl-3">
                (vii) Comments:
                <span className="bold-text" title={valueGet.orderComment}>
                  {valueGet.orderComment
                    ? valueGet.orderComment
                    : ""}
                </span>
              </p>
            </>
          ) : null}
          {valueGet.processType == "DYED" ? (
            <>
              {props.customizationData && props.customizationData.dyed && props.customizationData.dyed.length == 0 && props.fromBack2()}
              <div className="para-edit">
                <p className="input-saved">{linkType == 'job_work' ? type.toLowerCase() == 'woven' ? '10.' : '9.' : type.toLowerCase() == 'woven' ? '9.' : '8.'} Order Details</p>
                <img src={edit} className="edit-icon" onClick={handleBack} />
              </div>
              {/* <p>Dyed</p> */}
              <p className="input-saved pl-3 mb-16">
                (i) Quantity:
                <span className="bold-text">{new Intl.NumberFormat('en-IN', {}).format(dyedArr[0]?.quantity)} {type.toLowerCase() == 'woven' ? ' metres' : type.toLowerCase() == 'knit' ? ' kg' : ''}</span>
              </p>
              <p className="input-saved pl-3 mb-16">
                (ii) Target Price:
                <span className="bold-text">{dyedArr[0]?.targetPrice == 0 ? 'Not provided' : dyedArr[0]?.targetPrice}{dyedArr[0]?.targetPrice != 0 && type.toLowerCase() == 'woven' ? ' INR per metre' : type.toLowerCase() == 'knit' ? ' INR per kg' : ''}</span>
              </p>
              <p className="input-saved pl-3 mb-16">
                (iii) Expected Delivery:
                <span className="bold-text mr-12">
                  {dyedArr[0]?.expectedDeliveryStartDate}
                </span>{" "}
                to{" "}
                <span className="bold-text ml-1">
                  {dyedArr[0]?.expectedDeliveryEndDate}
                </span>
              </p>
              <p className="input-saved pl-3 mb-16">
                (iv) {valueGet.fabricStage.toLowerCase() == 'processed' ? 'Residual' : ''} Shrinkage:
                <span className="bold-text">
                  {dyedArr[0]?.shrinkage}%  </span>
              </p>
              <p className="input-saved pl-3 mb-16">
                (v) End Buyer Name:
                <span className="bold-text">
                  {valueGet.endBuyerName ? valueGet.endBuyerName : ""}
                </span>
              </p>
              <p className="input-saved mb-16" style={{ marginLeft: "2rem" }}>
                (vi) Image:
                {valueGet.orderImageUrl ? (
                  <>
                    {valueGet.orderImageUrl.indexOf('.pdf') == -1 ?
                      <img
                        className="gallery-section"
                        src={valueGet.orderImageUrl}
                        style={{ display: "block", marginTop: "0.7rem" }}
                      /> :
                      <a href={valueGet.orderImageUrl} className="pdf-show-mar" style={{ marginLeft: "10px;" }}><img src={save} /> </a>
                    }
                  </>
                ) : (
                  <span className="bold-text"> </span>
                )}
              </p>

              <p className="input-saved pl-3">
                (vii) Comments:
                <span className="bold-text" title={valueGet.orderComment}>
                  {valueGet.orderComment
                    ? valueGet.orderComment
                    : ""}

                </span>
              </p>
            </>
          ) : null}
          {valueGet.fabricStage != "Processed" ? (
            <>
              <div className="para-edit">
                <p className="input-saved">{valueGet.processType.toLowerCase()=='greige'?'8':'9'}.  Order Details</p>
                <img src={edit} className="edit-icon" onClick={handleBack} />
              </div>

              <p className="input-saved mb-16 ml-3 pl-3">
                (i) Quantity:
                <span className="bold-text">
                  {new Intl.NumberFormat('en-IN', {}).format(greigeArr[0]?.quantity)} {type.toLowerCase() == 'woven' ? ' metres' : type.toLowerCase() == 'knit' ? ' kg' : ''}
                </span>
              </p>
              <p className="input-saved mb-16 ml-3 pl-3">
                (ii) Target Price:
                <span className="bold-text">
                  {greigeArr[0]?.targetPrice == 0 ? 'Not provided' : greigeArr[0]?.targetPrice}{greigeArr[0]?.targetPrice != 0 && type.toLowerCase() == 'woven' ? ' INR per metre' : type.toLowerCase() == 'knit' ? ' INR per kg' : ''}
                </span>
              </p>
              <p className="input-saved mb-16 ml-3 pl-3">
                (iii) Expected Delivery:
                <span className="bold-text">
                  {greigeArr[0]?.expectedDeliveryStartDate} to{" "}
                  {greigeArr[0]?.expectedDeliveryEndDate}
                </span>
              </p>
              {valueGet.processType != 'GREIGE' && <p className="input-saved mb-16 ml-3 pl-3">
                (iv) {valueGet.fabricStage.toLowerCase() == 'yarndyed' ? ' Residual' : ''} Shrinkage:
                <span className="bold-text">
                  {greigeArr[0]?.shrinkage}%  </span>
              </p>}
              <p className="input-saved mb-16 ml-3 pl-3">
                {valueGet.processType != 'GREIGE' ? '(v)' : '(iv)'} End Buyer Name:
                <span className="bold-text">
                  {valueGet.endBuyerName ? valueGet.endBuyerName : ""}
                </span>
              </p>
              {valueGet.fabricStage != 'YarnDyed' && <p className="input-saved mb-16 ml-3 pl-3">
                {valueGet.processType != 'GREIGE' ? '(vi)' : '(v)'} Image:

                {valueGet.orderImageUrl ? (
                  <>
                    {valueGet.orderImageUrl.indexOf('.pdf') == -1 ?
                      <img
                        className="gallery-section"
                        src={valueGet.orderImageUrl}
                        style={{ display: "block", marginTop: "0.7rem" }}
                      /> :
                      <a href={valueGet.orderImageUrl} className="pdf-show-mar" style={{ marginLeft: "10px;" }}><img src={save} /> </a>
                    }
                  </>
                ) : (
                  <span className="bold-text"></span>
                )}
              </p>
              }
              <p className="input-saved mb-16 ml-3 pl-3">
                {valueGet.processType != 'GREIGE' ?  valueGet.fabricStage=='YarnDyed'?'(vi)':'(vii)' : '(vi)'} Comments:
                <span className="bold-text" title={valueGet.orderComment}>
                  {valueGet.orderComment
                    ? valueGet.orderComment
                    : ""}

                </span>
              </p>
            </>
          ) : null}

          <div className="RFQ-Buttons">
            <Button className="RFQ-Submit" disabled={isLoading} onClick={submitHandler}>
              Submit
            </Button>
            <Button className="RFQ-Back" onClick={handleBack}>
              Back
            </Button>
          </div>
          <NotificationContainer />
        </Card>
      </Row>
      {isLoading ? (
        <>
          <div className="cdf">
            {" "}
            <div className="loader" />
          </div>{" "}
        </>
      ) : null}
    </>
  );
};

export default RfqDyed14;
