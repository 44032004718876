import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import SignUp from "./Components/SignUp/SignUp";
import Login from "./Components/Login/Login";
import SignupModal from "./Components/SignUp/SignupModal";
import NewUserRequest from './Components/Admin/Users/NewUserRequest/NewUserRequest';
import Buyers from "./Components/Admin/Users/Buyer/Buyers";
import Seller from "./Components/Admin/Users/Seller/Seller";
import SignUpDashboard from "./Components/SignUp/SignUpDashboard";
import Yarn from "./Components/Admin/MetadataUpdates/Yarn"
import Fabric from "./Components/Admin/MetadataUpdates/Fabric/Fabric";
import CompanyProfile from "./Components/adminSettings/companyProfile/CompanyProfile";
import ProductProfile from "./Components/adminSettings/ProductProfile/ProductProfile";
import Rfq from "./Components/RFQ/RFQ"
import UserTeam from "./Components/adminSettings/User&Team/UserTeam";
import BuyerOpenEnq from "./Components/Buyer-Openenq/BuyerOpenEnq";
import ForgotPw from "./Components/ResetPw/ForgotPw";
import ResetPw from "./Components/ResetPw/ResetPw";
import ProfileScreen from "./Components/SideBar/Profile/ProfileScreen";
import ControlledTabs from "./Components/adminSettings/companyProfile/Preview/CompanyPreview"
import Verify from "./Components/Login/Verify";
import Viewmore from "./Components/Buyer-Openenq/Viewmore";
import RespondToQuotation from "./Components/seller/RespondToquotation";
import ErrorComponent from "./Components/error";
import Demo from "./Components/seller/NewRfq/demo";
// import {Header} from "./Components/SideBar/Header"




const Routes = () => {

  return (
    <div className="wrapper">

      <BrowserRouter>

        <Switch>

          <Route
            exact
            path="/"
            component={Login}
          />
          {/* <Route
              exact
              path="/profile"
              component={Header}
            /> */}
          <Route
            exact
            path="/verify"
            component={Verify}
          />
          <Route
            exact
            path="/dashboard"
            component={SignUpDashboard}
          />
          <Route
            exact
            path="/signup"
            component={SignUp}
          />
          <Route
            exact
            path="/resetpw"
            component={ResetPw}
          />
          <Route
            exact
            path="/success"
            component={SignupModal}
          />
          <Route
            exact
            path="/newrequest"
            component={NewUserRequest}
          />
          <Route
            exact
            path="/buyer"
            component={Buyers}
          />
          <Route
            exact
            path="/seller"
            component={Seller}
          />
          <Route
            exact
            path="/companyprofile"
            component={CompanyProfile}
          />

          {/* <Route
            exact
            path="/respondtoquotation"
            component={RespondToQuotation}
          /> */}
          <Route
            exact
            path="/metadata"
            component={Yarn}
          />
          <Route
            exact
            path="/fabric"
            component={Fabric}
          />
          <Route
            exact
            path="/forgotpw"
            component={ForgotPw}
          />
          <Route
            exact
            path="/productprofile"
            component={ProductProfile}
          />
          {/* <Route
              exact
              path="/admin/setting/userteams"
              component={UserTeam}
            /> */}
          <Route
            exact
            path="/requestforquotation"
            component={Rfq}
          /><Route
            exact
            path="/requestforquotationjob"
            component={Rfq}
          />

          {/* <Route
            exact
            path="/ordermanagement"
            component={BuyerOpenEnq}
          /> */}
          {/* <Route
            exact
            path="/profilescreen"
            component={ProfileScreen}
          /> */}

          {/* <Route
            exact
            path="/companypreview"
            component={ControlledTabs}
          /> */}

          {/* < Route
            exact
            path="/buyeropenview"
            component={Viewmore}
          /> */}

          <Route component={ErrorComponent} />


        </Switch>

      </BrowserRouter>
    </div>
  );
};
export default Routes;
