import React from 'react';
import Route from "./Route";
import 'react-notifications/lib/notifications.css';
// import {Alerts} from './services'



const App = () => {

  //  <Alerts />
  return <Route />;
};

export default App;
