import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { store } from "./helpers/store";
import { PersistGate } from 'redux-persist/integration/react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { persistor } from './helpers/store'
import axios from 'axios';
// import { Success, Errors } from './Components/Alert';
import { toast, ToastContainer } from 'react-toastify';
import {toastOptions} from "./helpers/toast-options";
import "react-toastify/dist/ReactToastify.css";


axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (erddr) => {

    if (erddr?.response?.status === 401) {
      localStorage.clear()
     // window.location.assign(`https://www.fabtrack.in`)
      // window.location.assign(`https://www.fabtrack.in`)
    }
    let tstid=0;
    if(tstid==0){
      tstid = toast.error(erddr?.response?.data?.message, toastOptions);
      tstid=0;
    }

    return Promise.reject(erddr);
  }
);
ReactDOM.render(

  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        theme="colored"
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();


