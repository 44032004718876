import React from 'react';
import { Table, Row, Col, Input, Spinner } from 'reactstrap';
import './ProductProfile.css';
import { GetProductCatalogueApi } from '../../../services';
import edit from '../../../assets/edit.png';
class ProductCatalogueEdit extends React.Component {


  constructor() {
    super();
    let companyId = localStorage.getItem('companyId');

    this.state = {
      SNO: 0,
      warpId: '',
      weftId: '',
      minGSMValue: '',
      maxGSMValue: '',
      yarnItems: [],
      items: [],
      companyId: companyId
    }
  };

  GetProductCatalogue() {
    GetProductCatalogueApi(this.state.companyId).then((res) => {
      if (res.isSuccess) {
        this.setState({ items: res.data.productResponseDTO })
      }
    })
  }

  componentDidMount = () => {
    this.GetProductCatalogue();
    // console.log(this.props.selectedProduct, 'produ');

  }
  editProduct = () => {
    this.props.handleBacks.fromBack2(1);

  }
  transform(val){
    switch(val){
      case 'PROCESSING_WITH_FABRIC':{
        return 'Processing with Fabric';
      }
      case 'JOB_WORK':{
        return 'Job Works';
      }
      case 'BOTH':{
        return 'Job Works and Processing with Fabric';
      }
      default:{
        return val;
      }
    }
  }
  capitalizeFirstLowercaseRest = str => {
    if(str)
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  render() {

    return (
      <>
        <div className="Product-catalogue">
          <p className="heading-Product1">Product Catalogue<br /><br />
          <div className="type-edit">
              <div style={{'whiteSpace':'nowrap'}}>
              <h6>
                a) Type of fabric:{" "}
                <span className="heading-backend">{`${this.props?.selectedProduct?.productName
                  ?.split("-")[0]
                  .charAt(0)
                  .toUpperCase()}${this.props?.selectedProduct?.productName
                  ?.split("-")[0]
                  .substring(1)
                  .toLowerCase()}`}</span>
              </h6>
           
            <h6>
              b) Fabric stage/Type :{" "}
              <span className="heading-backend">
                {this.props?.selectedProduct?.productName?.split("-")[1]}{" "}
              </span>
            </h6>
            </div>
            {!this.props?.selectedProduct?.productName?.includes('Greige') &&
             !this.props?.selectedProduct?.productName?.includes('Yarn') &&
            <div className='w-100 ' style={{'marginLeft':'10vw'}}>
              <div className="d-flex justify-content-between">
              <h6>
                c) Process Type:{" "}
                <span className="heading-backend">{`${this.props?.selectedProduct?.processType=='BOTH'?
                'Print & Dyed':this.capitalizeFirstLowercaseRest(this.props?.selectedProduct?.processType)}`}</span>
              </h6>

              <img
                className=" Edit-product"
                src={edit}
                alt="Profile"
                onClick={this.editProduct}
              />
           </div>
            <h6>
              d) Service Offered :{" "}
              <span className="heading-backend">
              {this.transform(this.props?.selectedProduct?.servicesOffered)}{" "}
              </span>
            </h6>
            </div>
  }
   {(this.props?.selectedProduct?.productName?.includes('Greige') ||
    this.props?.selectedProduct?.productName?.includes('Yarn') )&&
            <div className="w-100" style={{'marginLeft':'10vw'}}>
            
              <div className="d-flex justify-content-end">
              <img
                className=" Edit-product"
                src={edit}
                alt="Profile"
                onClick={this.editProduct}
              />
           </div>
            
            </div>
  }
            </div>
          </p>
          <hr />
          <Table1 edit={this.props.handleBacks}  productName={this.props?.selectedProduct?.productName?.split('-')[0].toLowerCase()} items={this.state.items} productId={this.props?.selectedProduct?.productId} delete={this.handleClickDelete} previousTab={this.props.previousTab} />
        </div>
      </>
    );
  }
}

class Table1 extends React.Component {
  constructor() {
    super();
    this.state = {
      isDataFetch: false,
      data: []
    }
  }
  componentDidMount() {
    setTimeout(() => {
      const [filterData] = this.props.items.filter(val => val.productId == this.props.productId);
      this.setState({ data: filterData?.productCatalogueDTOS });
      this.setState({ isDataFetch: true })
    }, 3000);
  }
  editProductCat = () => {

    this.props.edit.fromBack(this.props.previousTab);

  }


  render() {

    const items = this.props.items;
    return (
      <>
        <div id="TableProcat">

          <Table className="TableProductCategory profile-table mb-0">
            <tbody>
              <tr>
                <th className="srno">SI.No </th>
                <th className="fabricstorage">{this.props?.productName
                  ?.split("-")[0]
                  .toLowerCase()=='knit'?'Wale':'Warp'}
                  {/* ?.split("-")[0]
                  .toLowerCase()=='knit'?'Wale':'Warp'} */}
                   </th>
                <th className="fabricStage">{this.props?.productName
                  ?.split("-")[0]
                  .toLowerCase()=='knit'?'Course':'Weft'}</th>
                <th className="range">GSM Range</th>
              </tr>
              {this.state.isDataFetch ?
                <>

                  {this.state?.data?.length ? this.state.data.map((product, id) =>

                    <tr>
                      <td>{id + 1}</td>
                      <td>{product.warp}</td>
                      <td>{product.weft}</td>
                      <td style={{'maxWidth':'13.89vw'}}>{product.gsmRange}</td>
                    </tr>
                  ) : <tr><td>-</td><td>-</td><td>-</td><td style={{'maxWidth':'13.89vw'}}>-</td></tr>}
                </>
                : <p className="text-center" >   Please wait... <Spinner color="primary" /> </p>}
            </tbody>
          </Table>
          <img className=" Edit-product" src={edit} alt="Profile"  onClick={this.editProductCat} />

        </div>

      </>
    );
  }
}


export default ProductCatalogueEdit;
