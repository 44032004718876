import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from '../reducers';


const persistConfig = {
    key: "root",
    storage,
};

const reducer = rootReducer

const persistedReducer = persistReducer(persistConfig, reducer);

const loggerMiddleware = createLogger();

export const store = createStore(

    persistedReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
);
export let persistor = persistStore(store);
