import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Spinner } from 'reactstrap';
import { Loader } from '../Loader';
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { GetTokenApi } from '../../services';

const Verify = () => {
    const { search } = useLocation();
    const history = useHistory();


    const values = queryString.parse(search);

    const token = values.token;

    const [success, setSuccess] = useState(true)
    const [loading, setLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {
        getToken();
    }, [])

    const getToken = () => {
        GetTokenApi(token).then((res) => {
            setLoading(false);
            if (res.isSuccess) {
                setSuccess(true) 
            } else {
                setSuccess(false)         
                setErrorMessage(res.message);       
            }

        }).catch((err) => {
            setSuccess(false);
            setLoading(false)

        })
    }
    const handleClick = () => {
        history.push(`/`)
    }

    return (
        <>
            {loading? '':success ?
                <div className="verified"><h3>Your Account Has Been Verified.</h3>
                    <h5>To continue click here !</h5>
                    <Button color="primary" className='forget-btn1' onClick={handleClick} type="submit">Continue</Button>
                </div>
                : <div className="verified"><h1>Oops..!!</h1>
                    <h3>{errorMessage ? errorMessage : "Token Has Been Expired"}</h3>
                    <Button color="primary" className='forget-btn2' type="submit" onClick={() => history.push(`/`)}>Return to login</Button>
                </div>}
        </>
    )
}

export default Verify;