import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "reactstrap";
import { CompnayProfilSidebar } from "../../SideBar/Profile/profile";
import { CompanyHeader } from "../../SideBar/Header/Header";
import StepperComponent from "./StepperComponent";
import Summary from "./Summary";
import BusinessDes from "./BussinessDes";
import Locations from "./Locations";
import Gallery from "./Gallery";
import CompanyPrFinal from "./CompanyPrFinal";

import CompanyPrSub from "./CompanySub";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCompanyInfo, getUserInfo, ProfileReview } from "../../../services";
import Cookies from "js-cookie";

const AllForm = () => {
  let token = localStorage.getItem("token");
  const user = useSelector((state) => state.userReducer);
  const [currentStep, setCurrentStep] = useState(0);

  const history = useHistory();
  useEffect(() => {
    if (token && Cookies.get("token")) {
      if (user.data.accountType == "ADMIN_BUYER") {
        history.push(`/companyprofile`);
        // window.location.replace(`https://app.fabtrack.in/#/buyer/dashboard`);
      } else if (user.data.accountType == "ADMIN_SELLER") {
        history.push(`/companyprofile`);
        // window.location.replace(`https://app.fabtrack.in/#/seller/dashboard`);
      } else if (user.data.accountType == "FABTRACK_ADMIN") {
        history.push(`/newrequest`);
      }
      // else if (user.data.accountType == 'ACCOUNTS') {
      //     window.location.replace(
      //         `https://app.fabtrack.in/#/account`
      //     );
      // } 
      // else if (
      //   user.data.userType == "BUYER" &&
      //   (
      //     user.data.accountType == "OWNER" ||
      //     user.data.accountType == "JUNIOR" ||
      //     user.data.accountType == "MERCHANDISER" ||
      //     user.data.accountType == "MANAGER")
      // ) {
      //   window.location.replace(`https://app.fabtrack.in/#/buyer/dashboard`);
      // } else if (
      //   user.data.userType == "SELLER" &&
      //   (
      //     user.data.accountType == "OWNER" ||
      //     user.data.accountType == "JUNIOR" ||
      //     user.data.accountType == "MERCHANDISER" ||
      //     user.data.accountType == "MANAGER")
      // ) {
      //   window.location.replace(`https://app.fabtrack.in/#/seller/dashboard`);
      // }
      else {
        window.history.back();
      }
    } else {
      history.push(`/`);
    }

    ProfileReview(localStorage.getItem("companyId")).then((res) => {
      if (res.isSuccess) {
        if (res.data.profileCompleted) {
          localStorage.setItem("step", true);
          setCurrentStep(5);
        }
      }
    });
    if (user.data.profileCompleted) {
      localStorage.setItem("step", true);
      setCurrentStep(5);
    }
  }, []);

  // if (currentStep == 5) {

  //     localStorage.setItem('step', true)
  // }

  // if (Boolean(localStorage.getItem('step')) == true && currentStep == 0) {

  //     setCurrentStep(5)
  //     console.log(currentStep);
  //     // localStorage.setItem('step', false)
  // }
  const handleClick = () => {
    setCurrentStep(currentStep + 1);
  };
  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const summaryBack = () => {
    localStorage.removeItem("step");

    setCurrentStep(0);
    // localStorage.setItem('re-step', true)
  };
  const businessBack = () => {
    setCurrentStep(currentStep - 3);
  };
  const LocationsBack = () => {
    setCurrentStep(currentStep - 2);
  };
  const GalleryBack = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      <CompanyHeader />

      <Row className="m-0">
        <Col sm="2" className="sidenav p-0">
          <CompnayProfilSidebar />
        </Col>
        <Col sm="10" className="rightcontent">
          <div className="Main">
            <div className="Frame">
              <h4 className="heading-profile lato-font">Profile Setup</h4>

              {currentStep == 4 ||
              currentStep == 5 ||
              currentStep == 6 ? null : (
                <StepperComponent currentStep={currentStep} />
              )}

              <Card className="Card1 mt-42">
                {currentStep == 0 ? <Summary fromAction={handleClick} /> :''}
                {currentStep == 1 ? (
                  <BusinessDes fromAction={handleClick} fromBack={handleBack} />
                ) : null}
                {currentStep == 2 ? (
                  <Locations fromAction={handleClick} fromBack={handleBack} />
                ) : null}
                {currentStep == 3 ? (
                  <Gallery fromAction={handleClick} fromBack={handleBack} />
                ) : null}
                {currentStep == 4 ? (
                  <CompanyPrFinal
                    fromAction={handleClick}
                    fromBack={handleBack}
                    summaryBack={summaryBack}
                    businessBack={businessBack}
                    LocationsBack={LocationsBack}
                    GalleryBack={GalleryBack}
                  />
                ) : null}
                {currentStep == 5 ? (
                  <CompanyPrSub
                    fromAction={handleClick}
                    fromBack={handleBack}
                  />
                ) : null}
                {/* {currentStep == 6 ?
                                    <CompanyPreview fromAction={handleClick} fromBack={handleBack} /> : null
                                } */}
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AllForm;
