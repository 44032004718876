import React, { useState } from "react";
import {
  Collapse,
  UncontrolledDropdown,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Toggler from "../../../assets/toggler.png";

export const Header1 = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div className="FormHeader">
      <Navbar className="Header" color="faded" light>
        <div className="logo">
          <NavbarBrand href="/">FABTRACK</NavbarBrand>
        </div>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            <img
              src={Toggler}
              alt="Profile"
              className="toggler mr-30"
            />
          </DropdownToggle>
        </UncontrolledDropdown>
      </Navbar>
    </div>
  );
};

export const Header2 = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div className="FormHeader">
      <Navbar className="Header" color="faded" light>
        <div className="logo">
          <NavbarBrand href="/">FABTRACK</NavbarBrand>
        </div>
        <UncontrolledDropdown nav inNavbar className="mr-4">
          <DropdownToggle nav caret>
            <img
              src={Toggler}
              alt="Profile"
              className="toggler"
            />
          </DropdownToggle>
          {/* <DropdownMenu right>
                <DropdownItem>
                  Option 1
                </DropdownItem>
                <DropdownItem>
                  Option 2
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  Reset
                </DropdownItem>
              </DropdownMenu> */}
        </UncontrolledDropdown>
      </Navbar>
    </div>
  );
};
