import React, { useState, useEffect } from "react";

import "./Header.css";
import {
  ListGroup,
  ListGroupItem,
  Collapse,
  Navbar,
  NavbarToggler,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";
import { NavLink, Link, useHistory, useLocation } from "react-router-dom";
import { fabricType } from "../../../models/enum";
import {
  COMPANY_LINK,
  Company_Header,
  Meta_Header,
  RFQ_Header,
  OpenEnq_Header,
  Company_Headers,
} from "./HeaderConstant";
import { useSelector,useDispatch } from "react-redux";
import Toggler from "../../../assets/toggler.png";
import { toast } from "react-toastify";
import { toastOptions } from "../../../helpers/toast-options";
import Cookies from "js-cookie";
import useWebSocket from "react-use-websocket";
import { updateNotificationById } from "../../../services/main.service";
import { websocketRoot, redirectRoot } from "../../../services/api.service";
import { changeLinkTypeAction } from "../../../actions";
import { userConstants } from "../../../constants";
export const ResponseNotification = () => {
  const [messages, setMessages] = useState([]);
  const [popMessage, setPopMessage] = useState();
  const [opened, setOpened] = useState(false);
  const loggedInUserId = localStorage.getItem("id");
  useWebSocket(`${websocketRoot}/notifications-subscribe?id=${loggedInUserId}`, {
    onOpen: (e) => console.log('opened', e),
    onClose: (e) => console.log('closed', e),
    onMessage: (e) => {
      const d = JSON.parse(e.data);
      console.log("onMessage", d);
      if (Array.isArray(d)) {
        setMessages(d);
      } else {
        setMessages([d, ...messages]);
        setPopMessage(d);
      }
    },
    onError: (e) => console.log('error1', e),
  });
  const deleteNotification = (item) => {
    let body = {
      id: item.id,
      deleted: true
    }
    updateNotificationById(body).then((res) => {
      if (res.isSuccess) {
        const updatedNotifications = messages.filter(item => item.id != body.id);
        setMessages(updatedNotifications);
      } else {
      }
    }).catch(error => {
      console.log(error);
    });
  }
  const redirect = (notification) => {
    let body = {
      id: notification.id,
      seen: true
    }
    updateNotificationById(body).then((res) => {
      if (res.isSuccess) {
        let belongsTo = "buyer";
        if (notification.belongsTo == 'SELLER') {
          belongsTo = 'seller';
        }
        switch (notification.destination) {
          case 'SELLER_LABDIP':
          case 'ACTION_BUYER_LABDIP_RECEIVED_SELF':
          case 'SELLER_RESPONSE_EDIT':
          case 'SELLER_LABDIP_SCHEDULE':
          case 'BUYER_SWATCH_OE':
          case 'ACTION_SELLER_LABDIP_QC':
          case 'SELLER_RESPONSE': {
            // this.router.navigateByUrl(`/${this.routedefault}/ordermanagement/openenq/sellerres/${notification.rfqResponseId}`);
            window.location.replace(`${redirectRoot}/${belongsTo}/ordermanagement/openenq/sellerres/${notification.rfqResponseId}`);
            break;
          }
          case 'SELLER_SAMPLE':
          case 'SELLER_SAMPLE_SCHEDULE':
          case 'ACTION_SELLER_SAMPLE_QC':
          case 'ACTION_BUYER_SAMPLE_FILL_SELF':
            {
              // this.router.navigateByUrl(`/${this.routedefault}/ordermanagement/samplist/sellerres/${notification.rfqResponseId}`);
              window.location.replace(`${redirectRoot}/${belongsTo}/ordermanagement/samplist/sellerres/${notification.rfqResponseId}`);
              break;
            }
          case 'NEW_RFQ': {
            // this.router.navigateByUrl(`/${this.routedefault}/respondtoquotation/newrfq/${notification.rfqId}`)
            window.location.replace(`${redirectRoot}/${belongsTo}/respondtoquotation/newrfq/${notification.rfqId}`);
            break;
          }
          case 'ACTION_SELLER_LABDIP_SENT2':
          case 'ACTION_SELLER_LABDIP_SENT_FIRST':
          case 'SELLER_RESPONSE_SELF': {
            // this.router.navigateByUrl(`/${this.routedefault}/respondtoquotation/respondrfq/${notification.rfqResponseId}`)
            this.mainservice.getlateststageofrfq(notification.rfqId).then(res => {
              if (res.data == 'RESPONDED') {
                window.location.replace(`${redirectRoot}/${belongsTo}/respondtoquotation/respondrfq/${notification.rfqResponseId}`);

              } else {
                if (res.data == 'COMPLETED') {
                 
                } else {
                  window.location.replace(`${redirectRoot}/${belongsTo}/ordermanagement/openenq/${notification.rfqResponseId}`)

                }
              }
            })
            break;
          }
          case 'BUYER_LABDIP':
          case 'SELLER_LABDIP_SELF':
          case 'ACTION_SELLER_LABDIP_SENT':
          case 'ACTION_SELLER_LABDIP_PICKUP_SELF':
          case 'SELLER_LABDIP_RECEIVED_SELF':
          case 'BUYER_LABDIP_QC': {
            // this.router.navigateByUrl(`/${this.routedefault}/ordermanagement/openenq/${notification.rfqResponseId}`)
            window.location.replace(`${redirectRoot}/${belongsTo}/ordermanagement/openenq/${notification.rfqResponseId}`);
            break;
          }
          case 'BUYER_SAMPLE':
          case 'ACTION_BUYER_SAMPLE_FULFILL':
          case 'ACTION_SELLER_SAMPLE_PICKUP_SELF':
          case 'SELLER_SCHEDULE_RECEIVED_SELF':
          case 'BUYER_SAMPLE_QC': {
            // this.router.navigateByUrl(`/${this.routedefault}/ordermanagement/samplist/${notification.rfqResponseId}`)
            window.location.replace(`${redirectRoot}/${belongsTo}/ordermanagement/samplist/${notification.rfqResponseId}`);
            break;
          }

          case 'SELLER_AGREEMENT_ACCEPT':
          case 'ACTION_SELLER_BULK_CONFIRM':
            {
              // this.router.navigate([`/${this.routedefault}/confirmed-order/bulk-delivery/view`, JSON.stringify({ data: notification.rfqResponseId })])
              window.location.replace(`${redirectRoot}/${belongsTo}/confirmed-order/bulk-delivery/view/`+ JSON.stringify({ 'data': notification.rfqResponseId }));
              break;
            }

          case 'ACTION_BUYER_ACCEPT_PO':
          case 'BUYER_ORDER_CONFIRM':
          case 'ACTION_SELLER_NEGOTIATION':
          case 'ACTION_UPLOAD_PO_RELATED':
          case 'ACTION_UPLOAD_PO_RELATED_SELF':
          case 'ACTION_BUYER_ORDER_NEGOTIATION_PENDING': {
            if (belongsTo == 'buyer') {
              // this.router.navigateByUrl(`/buyer/ordermanagement/ordcnf/sellerres/${notification.rfqResponseId}`)
              window.location.replace(`${redirectRoot}/${belongsTo}/ordermanagement/ordcnf/sellerres/${notification.rfqResponseId}`);
            } else {
              // this.router.navigateByUrl(`/seller/ordermanagement/ordcnf/${notification.rfqResponseId}`)
              window.location.replace(`${redirectRoot}/${belongsTo}/ordermanagement/ordcnf/${notification.rfqResponseId}`);
            }
            break;
          }
          case 'BUYER_RECONCIL':
          case 'BUYER_RECEIVED_BULK':
          case 'SELLER_DISPATCH_REMINDER':
          case 'SELLER_AGREEMENT_ACCEPT_SELF':
          case 'ACTION_BUYER_UPLOAD_PO_SELF':
          case 'SELLER_DISPATCH_BULK': {
            window.location.replace(`${redirectRoot}/${belongsTo}/confirmed-order/bulk-delivery/view/` + JSON.stringify({ 'data': notification.rfqResponseId }));
            // this.router.navigate([`/${this.routedefault}/confirmed-order/bulk-delivery/view`, JSON.stringify({ data: notification.rfqResponseId })])
            break;
          }
          case 'BUYER_RECEIVED_BULK_SELF':
          case 'SELLER_FOC_REPLACEMENT':
          case 'BUYER_RECEIVED_BULK2':
          case 'ACTION_SELLER_RECONCILIATION_PENDING':
          case 'ACTION_BUYER_RECONCILIATION_RESPONSE_PENDING':
          case 'ACTION_BUYER_SUBMIT_BUYER_RATINGS':
          case 'ACTION_CLOSE_ORDER':
          case 'ACTION_BUYER_SUBMIT_SUPPLIER_RATINGS_SELF': {
            // this.router.navigateByUrl(`/${this.routedefault}/confirmed-order/order-recancilation/${notification.reconciliationId}`)
            window.location.replace(`${redirectRoot}/${belongsTo}/confirmed-order/order-recancilation/${notification.reconciliationId}`);
            break;
          }
          case 'ACTION_BUYER_ACCEPT_FOC_OR_REPLACEMENT':
            {
              // this.router.navigateByUrl(`/${this.routedefault}/confirmed-order/order-recancilation/${notification.reconciliationId}?focreq=${true}`)
              window.location.replace(`${redirectRoot}/${belongsTo}/confirmed-order/order-recancilation/${notification.reconciliationId}?focreq=true`);
              break;
            }
          // case 'SELLER_DISPATCH_REMINDER': {
          //   window.location.replace(`${redirectRoot}/${belongsTo}/confirmed-order/bulk-delivery/view?data=${notification.rfqResponseId}`);     
          //   // this.router.navigate(['/buyer/confirmed-order/bulk-delivery/view', JSON.stringify({ data: notification.rfqResponseId })])
          //   break;
          // }
          case 'ORDER_COMPLETE_SELLER_SELF':
          case 'ORDER_COMPLETE_SELLER': 
          case 'OORDER_COMPLETE_BUYER': 
          case 'ORDER_COMPLETE_SELF':{
            //completeorderid not coming
            // this.router.navigateByUrl(`/${this.routedefault}/completed-order/list/view/${notification.rfqId}`)
            window.location.replace(`${redirectRoot}/${belongsTo}/completed-order/list/view/${notification.completedOrderId}`);
            break;
          }
          case 'BULK':
          case 'RECONCILIATION':
          case 'COMPLETED':
          case 'BUYER_PERSONS_PAY_REMINDER':
          {
            window.location.replace(`${redirectRoot}/account`);
            break;
          }
          default :{
            break;
          }
        }


      } else {
        console.log("notification not seen");
      }
    }).catch(error => {
      console.log(error);
    });
  }
  return (
    <div>
      <div class="notificaiton-sec">
        <a onClick={() => setOpened(!opened)}>
          <svg width="15" height="20" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 1.75C7.5 0.92 8.17 0.25 9 0.25C9.83 0.25 10.5 0.92 10.5 1.75V2.11338C10.5 2.5841 10.8315 2.98382 11.2768 3.13632C14.0213 4.07616 16 6.68251 16 9.75V15.3358C16 15.601 16.1054 15.8554 16.2929 16.0429L17.7929 17.5429C17.9255 17.6755 18 17.8554 18 18.0429C18 18.4334 17.6834 18.75 17.2929 18.75H0.707107C0.316582 18.75 0 18.4334 0 18.0429C0 17.8554 0.0744982 17.6755 0.207106 17.5429L1.70711 16.0429C1.89464 15.8554 2 15.601 2 15.3358V9.75C2 6.68251 3.97874 4.07616 6.72319 3.13632C7.16853 2.98382 7.5 2.5841 7.5 2.11338V1.75ZM9 4.75C11.76 4.75 14 6.99 14 9.75V16.75H4V9.75C4 6.99 6.24 4.75 9 4.75ZM7.01 19.76C7.01 20.86 7.9 21.75 9 21.75C10.1 21.75 10.99 20.86 10.99 19.76H7.01ZM10 6.75V10.75H8V6.75H10ZM10 14.75V12.75H8V14.75H10Z" fill="#333333" />
          </svg>
        </a>

        {messages.filter(m => !m.seen).length > 0 && <div class="not-num">{messages.filter(m => !m.seen).length > 99 ? '99+' : messages.filter(m => !m.seen).length}</div>}
      </div>
      {opened &&
        <div class="not-container">
          <div class="top-section" style={{ backgroundColor: "#e0e0e0" }}> <h2 class="not-header">Notifications</h2></div>
          {messages.length > 0 ? messages.map(item =>
            <div class="single-not d-flex justify-content-" style={{ cursor: "pointer" }}>
              {!item.seen && <div class="v-center"> <div class="blue-dot"> </div></div>}
              <div class={item.seen ? 'not-message ' : 'not-message notif-bold'}>
                <a onClick={() => redirect(item)}> {item.messageType == 'ACTION' && <span>Action: &nbsp;</span>}{item.description}</a> </div>
              {item.messageType != 'ACTION' && <div class="v-center" style={{ cursor: "pointer" }}>
                <a onClick={() => deleteNotification(item)} class="cross-icon">&times;</a>
              </div>}
            </div>) :
            <div></div>}
        </div>
      }

      {popMessage && <div class="single-not1 d-flex ">
        <div className="not-message1 notif-bold1" >
          <a onClick={() => redirect(popMessage)}>{popMessage.messageType == 'ACTION' ? <><span>Action: </span></> : " "} {popMessage.description}</a>
        </div>
      </div>}
      {popMessage && setTimeout(() => setPopMessage(""), 5000)}
    </div>
  )
}
export const Header = (props) => {
  const history = useHistory();
  const [toastId, setToastId] = useState();
  const logoutHandler = () => {

    Cookies.remove("token", {path:'/',domain:'fabtrack.in'} ); 
    Cookies.remove("token"); 
    localStorage.clear();
    if (!toastId) {
      const id = toast.success("User Logout Successfully!", { ...toastOptions, onClose: () => setToastId(null) });
      setToastId(id);
    }
    history.push("/");
  };
  const { pathname } = useLocation();
  const [ActiveButton, setActiveButton] = useState("");

  const [collapsed, setCollapsed] = useState(true);
  // const [activeClass, setClass] = useState('');

  const toggleNavbar = () => setCollapsed(!collapsed);
  useEffect(() => {
    if (props?.routeesss) {
      props.routeesss(pathname);
    }
  }, pathname);

  return (
    <>
      <Navbar className="Header justify-content-start" color="faded" light>
        <div className="logo">
          <NavbarBrand href="/newrequest">FABTRACK</NavbarBrand>
        </div>
        <div className="d-flex justify-content-between right-header">
          <div className="navigation">
            <ListGroup className="top-nav">
              {COMPANY_LINK.map((o, i) => {
                return (
                  <ListGroupItem
                    className={o.to === pathname ? "activated" : null}
                    key={i}
                  >
                    <NavLink to={o.to}>{o.TopnavName}</NavLink>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </div>
          <div className="d-flex align-items-center">
            {/* <div class="form-group has-search">
              <span class="form-control-feedback">
                {" "}
                <svg
                  width="2vh"
                  height="2vh"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.16782 16.181C9.93096 16.181 11.5773 15.6128 12.9156 14.666L17.9501 19.6528C18.1838 19.8843 18.4918 20 18.8104 20C19.5008 20 20 19.474 20 18.8006C20 18.485 19.8938 18.1904 19.6601 17.959L14.6575 12.9932C15.709 11.6255 16.3356 9.93161 16.3356 8.09048C16.3356 3.64019 12.6606 0 8.16782 0C3.66437 0 0 3.64019 0 8.09048C0 12.5408 3.66437 16.181 8.16782 16.181ZM8.16782 14.4345C4.65215 14.4345 1.76314 11.5623 1.76314 8.09048C1.76314 4.61862 4.65215 1.74645 8.16782 1.74645C11.6729 1.74645 14.5725 4.61862 14.5725 8.09048C14.5725 11.5623 11.6729 14.4345 8.16782 14.4345Z"
                    fill="#333333"
                  />
                </svg>
              </span>

              <input type="text" class="form-control" placeholder="Search" />
            </div> */}
            {/* <span class="material-icons-outlined notification-icon" onClick={() => console.log("shub")}>
              notification_important
            </span> */}
            <ResponseNotification />
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <img
                  className="toggler"
                  src={Toggler}
                  alt="Profile"

                />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={logoutHandler}>
                  <Link className="logout">Logout</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </Navbar>
    </>
  );
};
export const RTQHeader = (props) => {
  const history = useHistory();
  const [toastId, setToastId] = useState();
  const logoutHandler = () => {
    Cookies.remove("token", {path:'/',domain:'fabtrack.in'} ); 
    Cookies.remove("token" ); 

    localStorage.clear();
    if (!toastId) {
      const id = toast.success("User Logout Successfully!", { ...toastOptions, onClose: () => setToastId(null) });
      setToastId(id);
    }
    history.push("/");
  };
  const user = useSelector((state) => state.userReducer);

  const { pathname } = useLocation();
  const [ActiveButton, setActiveButton] = useState("");

  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <>
      <Navbar className="Header justify-content-start" color="faded" light>
        <div className="logo">
          <NavbarBrand href="/companyprofile">FABTRACK</NavbarBrand>
        </div>
        <div className="d-flex justify-content-between right-header">
          <div className="navigation">
            <ListGroup className="top-nav"></ListGroup>
          </div>
          <div className="d-flex align-items-center">
            {/* <div class="form-group has-search">
              <span class="form-control-feedback">
                {" "}
                <svg
                  width="2vh"
                  height="2vh"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.16782 16.181C9.93096 16.181 11.5773 15.6128 12.9156 14.666L17.9501 19.6528C18.1838 19.8843 18.4918 20 18.8104 20C19.5008 20 20 19.474 20 18.8006C20 18.485 19.8938 18.1904 19.6601 17.959L14.6575 12.9932C15.709 11.6255 16.3356 9.93161 16.3356 8.09048C16.3356 3.64019 12.6606 0 8.16782 0C3.66437 0 0 3.64019 0 8.09048C0 12.5408 3.66437 16.181 8.16782 16.181ZM8.16782 14.4345C4.65215 14.4345 1.76314 11.5623 1.76314 8.09048C1.76314 4.61862 4.65215 1.74645 8.16782 1.74645C11.6729 1.74645 14.5725 4.61862 14.5725 8.09048C14.5725 11.5623 11.6729 14.4345 8.16782 14.4345Z"
                    fill="#333333"
                  />
                </svg>
              </span>

              <input type="text" class="form-control" placeholder="Search" />
            </div> */}
            {/* <span class="material-icons-outlined notification-icon">
              notification_important
            </span> */}
            <ResponseNotification />
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <img
                  className="toggler"
                  src={Toggler}
                  alt="Profile"

                />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={logoutHandler}>
                  <Link className="logout">Logout</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export const CompanyHeader = (props) => {
  const history = useHistory();
  const [toastId, setToastId] = useState();
  const logoutHandler = () => {
    // document.cookie = `token = ''`
    Cookies.remove("token", {path:'/',domain:'fabtrack.in'} );
    Cookies.remove("token" );
         localStorage.clear();
    if (!toastId) {
      const id = toast.success("User Logout Successfully!", { ...toastOptions, onClose: () => setToastId(null) });
      setToastId(id);
    }
    history.push("/");
  };

  const user = useSelector((state) => state.userReducer);

  const { pathname } = useLocation();
  const [ActiveButton, setActiveButton] = useState("");

  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);
  const transferfromlogo = () => {
    let user = localStorage.getItem('usertype');
    if (user == 'BUYER') {
      window.location.replace(`${redirectRoot}/buyer/dashboard`);

    } else {
      window.location.replace(`${redirectRoot}/seller/dashboard`);

    }
  }
  return (
    <>
      <Navbar className="Header justify-content-start" color="faded" light>
        <div className="logo">
          <NavbarBrand onClick={transferfromlogo}><a style={{ cursor: 'pointer' }}>FABTRACK</a></NavbarBrand>
        </div>
        <div className="d-flex justify-content-between right-header">
          <div className="navigation">
            <ListGroup className="top-nav">
              <ListGroupItem
                className={pathname == "/companyprofile" ? "activated" : null}
              >
                <Link to={"/companyprofile"}>
                  Company Profile
                </Link>
              </ListGroupItem>
              <ListGroupItem
                className={
                  pathname == "/admin/setting/userteams" ? "activated" : null
                }
              >
                <a href={`${redirectRoot}/admin/setting/userteams`}>
                  Users & Teams{" "}
                </a>
              </ListGroupItem>
              {user.data.userType == "SELLER"
                ? Company_Header.map((o, i) => {
                  return (
                    <ListGroupItem
                      className={o.to === pathname ? "activated" : null}
                      key={i}
                    >
                      <Link to={o.to}>{o.TopnavName}</Link>
                    </ListGroupItem>
                  );
                })
                : null}
              {user.data.userType == "BUYER"
                ? Company_Headers.map((o, i) => {
                  return (
                    <ListGroupItem
                      className={o.to === pathname ? "activated" : null}
                      key={i}
                    >
                      <Link to={o.to}>{o.TopnavName}</Link>
                    </ListGroupItem>
                  );
                })
                : null}
            </ListGroup>
          </div>
          <div className="d-flex align-items-center">
            {/* <div class="form-group has-search">
              <span class="form-control-feedback">
                {" "}
                <svg
                  width="2vh"
                  height="2vh"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.16782 16.181C9.93096 16.181 11.5773 15.6128 12.9156 14.666L17.9501 19.6528C18.1838 19.8843 18.4918 20 18.8104 20C19.5008 20 20 19.474 20 18.8006C20 18.485 19.8938 18.1904 19.6601 17.959L14.6575 12.9932C15.709 11.6255 16.3356 9.93161 16.3356 8.09048C16.3356 3.64019 12.6606 0 8.16782 0C3.66437 0 0 3.64019 0 8.09048C0 12.5408 3.66437 16.181 8.16782 16.181ZM8.16782 14.4345C4.65215 14.4345 1.76314 11.5623 1.76314 8.09048C1.76314 4.61862 4.65215 1.74645 8.16782 1.74645C11.6729 1.74645 14.5725 4.61862 14.5725 8.09048C14.5725 11.5623 11.6729 14.4345 8.16782 14.4345Z"
                    fill="#333333"
                  />
                </svg>
              </span>

              <input type="text" class="form-control" placeholder="Search" />
            </div> */}
            {/* <span class="material-icons-outlined notification-icon">
              notification_important
            </span> */}
            <ResponseNotification />
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <img
                  className="toggler"
                  src={Toggler}
                  alt="Profile"

                />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={logoutHandler}>
                  <Link className="logout">Logout</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export const MetaHeader = (props) => {
  const history = useHistory();
  const [toastId, setToastId] = useState();
  const logoutHandler = () => {
    localStorage.clear();
    Cookies.remove("token", {path:'/',domain:'fabtrack.in'} );     if (!toastId) {
      const id = toast.success("User Logout Successfully!", { ...toastOptions, onClose: () => setToastId(null) });
      setToastId(id);
    }

    history.push("/newrequest");
  };
  const { pathname } = useLocation();
  const [ActiveButton, setActiveButton] = useState("");

  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);
  const transferfromlogo = () => {
    let user = localStorage.getItem('usertype');
    if (user == 'BUYER') {
      window.location.replace(`${redirectRoot}/buyer/dashboard`);

    } else {
      window.location.replace(`${redirectRoot}/buyer/dashboard`);

    }
  }
  return (
    <>
      <Navbar className="Header justify-content-start" color="faded" light>
        <div className="logo">
          <NavbarBrand ><a onClick={transferfromlogo}>FABTRACK</a></NavbarBrand>
        </div>
        <div className="d-flex justify-content-between right-header">
          <div className="navigation">
            <ListGroup className="top-nav">
              {Meta_Header.map((o, i) => {
                return (
                  <>
                    <ListGroupItem
                      className={o.to == pathname ? "activated" : null}
                      key={i}
                    >
                      <Link to={o.to}>{o.TopnavName}</Link>
                    </ListGroupItem>
                  </>
                );
              })}
            </ListGroup>
          </div>
          <div className="d-flex align-items-center">
            {/* <div class="form-group has-search">
              <span class="form-control-feedback">
                {" "}
                <svg
                  width="2vh"
                  height="2vh"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.16782 16.181C9.93096 16.181 11.5773 15.6128 12.9156 14.666L17.9501 19.6528C18.1838 19.8843 18.4918 20 18.8104 20C19.5008 20 20 19.474 20 18.8006C20 18.485 19.8938 18.1904 19.6601 17.959L14.6575 12.9932C15.709 11.6255 16.3356 9.93161 16.3356 8.09048C16.3356 3.64019 12.6606 0 8.16782 0C3.66437 0 0 3.64019 0 8.09048C0 12.5408 3.66437 16.181 8.16782 16.181ZM8.16782 14.4345C4.65215 14.4345 1.76314 11.5623 1.76314 8.09048C1.76314 4.61862 4.65215 1.74645 8.16782 1.74645C11.6729 1.74645 14.5725 4.61862 14.5725 8.09048C14.5725 11.5623 11.6729 14.4345 8.16782 14.4345Z"
                    fill="#333333"
                  />
                </svg>
              </span>

              <input type="text" class="form-control" placeholder="Search" />
            </div> */}
            {/* <span class="material-icons-outlined notification-icon">
              notification_important
            </span> */}
            <ResponseNotification />
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <img
                  className="toggler"
                  src={Toggler}
                  alt="Profile"

                />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={logoutHandler}>
                  <Link className="logout">Logout</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export const RfqHeader = (props) => {
  // const {lastMessage, lastJsonMessage, readyState} = 
  //   useWebSocket(`ws://35.154.151.88:8080/fabtrack/api/v1/notifications-subscribe?id=${189}`, {
  //     onOpen: (e) => console.log('opened', e),
  //     onClose: (e) => console.log('closed', e),
  //     onMessage: (e) => console.log('onMessage1', e, JSON.parse(e.data)),
  //     onError: (e) => console.log('error1', e),
  //   }); 
  const link = useSelector(state => state.fabricLinkReducer);
  const dispatch= useDispatch();
  const history = useHistory();
  const [toastId, setToastId] = useState();
  const logoutHandler = () => {
    Cookies.remove("token", {path:'/',domain:'fabtrack.in'} );     localStorage.clear();
    if (!toastId) {
      const id = toast.success("User Logout Successfully!", { ...toastOptions, onClose: () => setToastId(null) });
      setToastId(id);
    }
    history.push("/");
  };
  const { pathname } = useLocation();
  const [ActiveButton, setActiveButton] = useState("");
  const [linktype, setlinkType] = useState('fabric');
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  const data = [
    {
      update: "70 new employees are shifted",
      timestamp: 1596119688264
    },
    {
      update: "Time to take a Break, TADA!!!",
      timestamp: 1596119688264
    },
    {
      update: "Shaik",
      timestamp: 1639954800000
    }
  ];
  useEffect(()=>{
     localStorage.setItem('linkType','fabric');
     setlinkType('fabric')
     dispatch({type:userConstants.fabric})
    console.log(link,'dispatch')
  },[])
  const toogle=(type)=>{
    const link=localStorage.getItem('linkType');
    console.log(link,type)
    if(!link ||link!==type||linktype!=type){
     localStorage.setItem('linkType',type);
     setlinkType(type);
     dispatch({type:userConstants[type]})
     props.fromtoogle('1');
    }
  }
  return (
    <>
      <Navbar className="Header justify-content-start" color="faded" light>
        <div className="logo">
          <NavbarBrand href={`${redirectRoot}/buyer/dashboard`}>FABTRACK</NavbarBrand>
        </div>
        <div className="d-flex justify-content-between right-header">
          <div className="navigation">
            <ListGroup className="top-nav">
              
                  <ListGroupItem
                    className={'/requestforquotation' === pathname &&link.linkType=='fabric' ? "activated" : null}
                    key={1}
                  >
                    <Link style={{minHeight:'32.5px'}} to={'requestforquotation'} onClick={()=>toogle('fabric')}>Fabric</Link>
                  </ListGroupItem> 
                  <ListGroupItem
                    className={'/requestforquotation' === pathname && link.linkType=='job_work' ? "activated" : null}
                    key={2}
                  >
                    <Link style={{minHeight:'32.5px'}} to={'requestforquotation'} onClick={()=>toogle('job_work')}>Job Works</Link>
                  </ListGroupItem>
               
            </ListGroup>
          </div>
          <div className="d-flex align-items-center">
            {/* <div class="form-group has-search">
              <span class="form-control-feedback">
                {" "}
                <svg
                  width="2vh"
                  height="2vh"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.16782 16.181C9.93096 16.181 11.5773 15.6128 12.9156 14.666L17.9501 19.6528C18.1838 19.8843 18.4918 20 18.8104 20C19.5008 20 20 19.474 20 18.8006C20 18.485 19.8938 18.1904 19.6601 17.959L14.6575 12.9932C15.709 11.6255 16.3356 9.93161 16.3356 8.09048C16.3356 3.64019 12.6606 0 8.16782 0C3.66437 0 0 3.64019 0 8.09048C0 12.5408 3.66437 16.181 8.16782 16.181ZM8.16782 14.4345C4.65215 14.4345 1.76314 11.5623 1.76314 8.09048C1.76314 4.61862 4.65215 1.74645 8.16782 1.74645C11.6729 1.74645 14.5725 4.61862 14.5725 8.09048C14.5725 11.5623 11.6729 14.4345 8.16782 14.4345Z"
                    fill="#333333"
                  />
                </svg>
              </span>

              <input type="text" class="form-control" placeholder="Search" />
            </div> */}
            {/* <span class="material-icons-outlined notification-icon">
              notification_important
            </span> */}
            <ResponseNotification />
            <div className="nav1">
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <img
                    className="toggler"
                    src={Toggler}
                    alt="Profile"

                  />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={logoutHandler}>
                    <Link className="logout">Logout</Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export const OpenEnqHeader = (props) => {
  const history = useHistory();
  const [toastId, setToastId] = useState();
  const logoutHandler = () => {
    Cookies.remove("token", {path:'/',domain:'fabtrack.in'} );     localStorage.clear();
    if (!toastId) {
      const id = toast.success("User Logout Successfully!", { ...toastOptions, onClose: () => setToastId(null) });
      setToastId(id);
    }
    history.push("/");
  };
  const { pathname } = useLocation();
  const [ActiveButton, setActiveButton] = useState("");

  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);
  const transferfromlogo = () => {
    let user = localStorage.getItem('usertype');
    if (user == 'BUYER') {
      window.location.replace(`${redirectRoot}/buyer/dashboard`);

    } else {
      window.location.replace(`${redirectRoot}/buyer/dashboard`);

    }
  }

  return (
    <>
      <Navbar className="Header justify-content-start" color="faded" light>
        <div className="logo">
          <NavbarBrand ><a onClick={transferfromlogo}>FABTRACK</a></NavbarBrand>
        </div>
        <div className="d-flex justify-content-between right-header">
          <div className="navigation">
            <ListGroup className="top-nav">
              {OpenEnq_Header.map((o, i) => {
                return (
                  <ListGroupItem
                    className={o.to === pathname ? "activated" : null}
                    key={i}
                  >
                    <Link to={o.to}>{o.TopnavName}</Link>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </div>
          <div className="d-flex align-items-center">
            {/* <div class="form-group has-search">
              <span class="form-control-feedback">
                {" "}
                <svg
                  width="2vh"
                  height="2vh"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.16782 16.181C9.93096 16.181 11.5773 15.6128 12.9156 14.666L17.9501 19.6528C18.1838 19.8843 18.4918 20 18.8104 20C19.5008 20 20 19.474 20 18.8006C20 18.485 19.8938 18.1904 19.6601 17.959L14.6575 12.9932C15.709 11.6255 16.3356 9.93161 16.3356 8.09048C16.3356 3.64019 12.6606 0 8.16782 0C3.66437 0 0 3.64019 0 8.09048C0 12.5408 3.66437 16.181 8.16782 16.181ZM8.16782 14.4345C4.65215 14.4345 1.76314 11.5623 1.76314 8.09048C1.76314 4.61862 4.65215 1.74645 8.16782 1.74645C11.6729 1.74645 14.5725 4.61862 14.5725 8.09048C14.5725 11.5623 11.6729 14.4345 8.16782 14.4345Z"
                    fill="#333333"
                  />
                </svg>
              </span>

              <input type="text" class="form-control" placeholder="Search" />
            </div> */}
            {/* <span class="material-icons-outlined notification-icon">
              notification_important
            </span> */}
            <ResponseNotification />
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <img
                  className="toggler"
                  src={Toggler}
                  alt="Profile"

                />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={logoutHandler}>
                  <Link className="logout">Logout</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </Navbar>
    </>
  );
};

