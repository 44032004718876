import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { Table, Row, Col, Input, Spinner } from "reactstrap";
import DeletPopUp from "../../Admin/MetadataUpdates/deletePopup";
import del from "../../../assets/delete.png";

import "./ProductProfile.css";
import {
  AddProductApi,
  deleteProductApi,
  GetFabricApi,
  GetProductApi,
  GetStagesApi,
} from "../../../services";
import { Errors, Success } from "../../Alert";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { toast } from "react-toastify";
import { toastOptions } from "../../../helpers/toast-options";

class ProductAdd extends React.Component {
  constructor() {
    super();

    let companyId = localStorage.getItem("companyId");
    let arr;

    this.state = {
      companyId: companyId,
      SNO: 0,
      fabricType: "",
      fabricStage: "",
      processType:"",
      servicesOffered:"",
      DelIcon: { del },
      items: [],
      dataArray: [],
      stagesArray: [],
      dataArr: [],
      fabricTypeArr: [],
      isLoading: false,
      loader: false,
      toastId: null,
      stageselected:false
    };
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    // let items = [...this.state.items];

    const modifyProduct = {
      companyId: this.state.companyId,
      id: 0,
      fabricType: this.state.fabricType,
      fabricStage: this.state.fabricStage,
      processType:this.state.processType,
      servicesOffered:this.state.servicesOffered
    };
    if (!this.state.fabricStage || !this.state.fabricType) {
      if (!this.state.toastId) {
        const id = toast.error("Please select both values", {
          ...toastOptions,
          onClose: () => {
            this.setState({ ...this.state, toastId: null });
          },
        });
        this.setState({ ...this.state, toastId: id });
      } 
    } else {
      if(this.state.fabricStage=='Processed'){
        if (!this.state.toastId && (!this.state.processType ||!this.state.servicesOffered)) {
        
          const id = toast.error("Please select  values", {
            ...toastOptions,
            onClose: () => {
              this.setState({ ...this.state, toastId: null });
            },
          });
          this.setState({ ...this.state, toastId: id });
       return;
        }
      }
      if (this.state.isLoading) {
        return;
      }
      this.setState({
        isLoading: true,
      });
      AddProductApi(modifyProduct)
        .then((res) => {
          if (res.isSuccess) {
            document.getElementById("storage1").selectedIndex = 0;
            document.getElementById("fabricStage1").selectedIndex = 0;
            this.setState({ fabricType: "", fabricStage: "" });
            this.getProductApi();
            this.setState({stageselected:false})
            this.setState({ isLoading: false });
            this.setState({ stagesArray: [] });
          }
        })
        .catch((err) => {
          document.getElementById("storage1").selectedIndex = 0;
          document.getElementById("fabricStage1").selectedIndex = 0;
          this.setState({stageselected:false})
          this.setState({ fabricType: "", fabricStage: "" });
          this.setState({ isLoading: false });
          this.setState({ stagesArray: [] });
        });
    }
  };
  handleInputChange = (e) => {
    let input = e.target;
    let name = e.target.name;
    let value = input.value;
    if(name=='fabricStage' && value=='Processed'){
      this.setState({
        stageselected:true
      })
    }else if(name==='fabricStage' && value!=='Processed'){
      this.setState({
        stageselected:false
      })
    }
    this.setState({
      [name]: value,
    });
    // this.setState({ fabricType: null })
    // document.getElementById('storage1').selectedIndex = 0;
  };

  handleFabricChange = (e) => {
    let value = e.target.value;
    this.setState({ fabricType: value });

    this.getStagesApi(value);
    document.getElementById("fabricStage1").selectedIndex = 0;
    
    this.setState({ fabricStage: null,stageselected:false });
  };

  handleItemDelete(i) {
    var items = this.state.items;
    items.splice(i, 1);
    this.setState({
      items: items,
    });
  }
  handleClickDelete = (id,data, close) => {
    deleteProductApi(id).then((data) => {
      if (data.isSuccess) {
        this.getProductApi();
        this.handleItemDelete();
        close();
      }
    });
  };

  componentDidMount() {
    this.getProductApi();
    this.getFabricApi();
  }
  getProductApi = () => {
    this.setState({ loader: true });

    GetProductApi(this.state.companyId).then((res) => {
      //
      if (res.isSuccess) {
        this.setState({ items: res.data });
        this.setState({ loader: false });
      }
    });
  };
  getFabricApi = () => {
    GetFabricApi().then((res) => {
      if (res.isSuccess) {
        this.state.dataArr = res.data;
        this.state.fabricTypeArr = this.state.dataArr.map(
          (item) => item.fabricType
        );
        this.arr = this.state.fabricTypeArr[0];
        this.setState({ dataArray: this.state.fabricTypeArr });
        if (document.getElementById("fabricStage1"))
          document.getElementById("fabricStage1").selectedIndex = 0;
        this.setState({ fabricStage: "" });
        // this.getStagesApi(this.arr)
      }
    });
  };
  getStagesApi = (value) => {
    GetStagesApi(value).then((res) => {
      if (res.isSuccess) {
        let dataArr = res.data.fabricStages;
        let stagesArr = dataArr.map((item) => item.stageName);

        this.setState({ stagesArray: stagesArr });
      }
    });
  };

  submitHandler = () => {
    console.log('submit clicked')
    this.setState({ loader: true });
    this.props.fromAction(1);
  };

  render() {
    return (
      <>
        <div className="Product-add">
          <Form
            handleFormSubmit={this.handleFormSubmit}
            handleClickDelete={this.handleClickDelete}
            handleInputChange={this.handleInputChange}
            dataArray={this.state.dataArray}
            handleFabricChange={this.handleFabricChange}
            stagesArray={this.state.stagesArray}
            newSNO={this.state.SNO}
            newStorage={this.state.fabricType}
            newfabricStage={this.state.fabricStage}
            newDelIcon={this.state.DelIcon}
            isLoading={this.state.isLoading}
            stageselected={this.state.stageselected}
          />
          <Table1 items={this.state.items} delete={this.handleClickDelete} />
          <div className="ProductCatS-button">
            {this.state.items.length > 0 ? (
              <Button
                className="btn btn-primary product-submit"
                onClick={this.submitHandler}
              >
                Submit
              </Button>
            ) : null}
          </div>{" "}
        </div>
        {this.state.loader ? (
          <>
            <div className="cdf">
              {" "}
              <div className="loader" />
            </div>{" "}
          </>
        ) : null}
      </>
    );
  }
}

class Table1 extends React.Component {
  getserviceOffered(s){
    switch(s){
      case 'BOTH':{
        return 'Job Works & Processing with Fabric'
      }
      case 'JOB_WORK':{
        return 'Job Works';
      }
      default :{
        return 'Processing with Fabric'
      }
    }
  }
   capitalizeFirstLowercaseRest = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  getfabricStage(s){
    console.log(s)
    if(s.trim()==''){
      return '';
    }
    if(s=='BOTH'){
      return '(Print and Dyed)';
    }else{
      return '('+this.capitalizeFirstLowercaseRest(s)+')'
    }
  }
  render() {
    const items = this.props.items;

    return (
      <>
        <div id="TableCat">
          <Table className="profile-table">
            <tbody>
              <div className="tabless">
                <div className="Scroll">
                  <tr>
                    <th className="srno">Sl.No.</th>
                    <th className="fabricstorage">Type of Fabric</th>
                    <th className="fabricStage">Fabric Stage/Type</th>
                    <th className="fabricstorage">Service Type</th>
                  </tr>

                  {items.length > 0 ? (
                    items.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td
                            className="text-lowercase"
                            value={item.fabricType}
                          >
                            {item.fabricType}
                          </td>
                          <td
                            className="text-capitalize"
                            value={item.fabricStage}
                          >
                            { item.fabricStage!='Greige'?item.fabricStage+this.getfabricStage(item.processType):item.fabricStage}
                          </td> 
                          <td
                            className="text-capitalize"
                            value={item.servicesOffered}
                          >
                           {item.fabricStage.toLowerCase()=='processed'? this.getserviceOffered(item.servicesOffered):'-'}
                          </td>
                          <td id="Deletetd">
                            <DeletPopUp
                              handleClickDelete={this.props.delete}
                              id={item.id}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  )}
                </div>
              </div>
            </tbody>
            <NotificationContainer />
          </Table>
        </div>

        {/* <button className="Product-Submit primary" type="submit" value="Submit"><Link to="/productcata">Submit</Link></button> */}
      </>
    );
  }
}

class Form extends React.Component {
  constructor() {
    super();
    this.state = {
      stageselected:false
     }
    
  }
   onstagechange =(event)=>{
    this.setState({
     stageselected:true
    })
   }
  render() {
    const data = this.props.dataArray;
    const stages = this.props.stagesArray;
    const isLoad = this.props.isLoading;
   
    return (
      <>
        &nbsp;
        <p className="heading-Product">
          Product Categories
          <br />
          <br />
          <span>What type of Fabric do you manufacture/sell ?</span>
        </p>
        <form
          onSubmit={this.props.handleFormSubmit}
          className="ProductForm"
          id="myForm"
        >
          <div id="Add-Fab-Fields">
            <label for="storage">
              {" "}
              <span id="textcat">a) Types Of Fabric</span>
              <Input
                id="storage1"
                autoSize={false}
                clearable={false}
                value={this.props.newStorgae}
                type="select"
                name="fabricType"
                onChange={(event) => this.props.handleFabricChange(event)}
                placeholder="Select"
                className="mt-8"
              >
                <option value="volvo" selected disabled>
                  Select
                </option>

                {data.map((item) => (
                  <option
                    className="text-lowercase"
                    value={item}
                    id="OptionProduct"
                  >
                    {item}
                  </option>
                ))}
              </Input>
            </label>

            <label for="fabricStage">
              <span id="textcat">b) Fabric Stage/Type</span>
              <Input
                id="fabricStage1"
                // value={this.props.newfabricStage}
                type="select"
                name="fabricStage"
                onChange={(event)=>{this.onstagechange(event); this.props.handleInputChange(event)}}
                className="mt-8"
              >
                <option value="volvo" selected disabled>
                  Select
                </option>

                {stages?.map((item) => (
                  <>
                    <option className="text-capitalize">{item}</option>
                  </>
                ))}
              </Input>
            </label>
            {this.props.stageselected &&
              <>
            <div className="break"></div>
            <div className="mt-2">
              <label for="fabricStage">
                <span id="textcat">c) Process Type</span>
                <Input
                  id="fabricStage2"
                  // value={this.props.newfabricStage}
                  type="select"
                  name="processType"
                  onChange={this.props.handleInputChange}
                  className="mt-8"
                >
                  <>
                    <option value="volvo" selected disabled>
                      Select
                    </option>
                    <option className="text-capitalize" value='PRINT' >Print</option>
                    <option className="text-capitalize" value='DYED' >Dyed</option>
                    <option className="text-capitalize" value='BOTH' >Both</option>
                  </>
                </Input>
              </label>
            </div>
            <div className="break"></div>
            <div className="mt-2">
              <label for="fabricStage">
                <span id="textcat"> d) Services Offered </span>
                <Input
                  id="fabricStage3"
                  // value={this.props.newfabricStage}
                  type="select"
                  name="servicesOffered"
                  onChange={this.props.handleInputChange}
                  className="mt-8"
                >
                  <option value="volvo" selected disabled>
                    Select
                  </option>
                  <option className="text-capitalize" value='JOB_WORK' >Job Work</option>
                    <option className="text-capitalize" value='PROCESSING_WITH_FABRIC' >Processing with Fabric</option>
                    <option className="text-capitalize" value='BOTH' >Both</option>
                
                </Input>
              </label>
            </div>
            <div className="empty-div"></div>
  </>
  }
            <label className="pt-1">
              <button
                type="submit"
                disabled={isLoad}
                value="Submit"
                className="ml-3 mt-4"
              >
                {isLoad ? <Spinner /> : "Add"}
              </button>
            </label>
          </div>
        </form>
        <NotificationContainer />
      </>
    );
  }
}

export default ProductAdd;
