import React, { Component } from 'react';
import { render } from 'react-dom';
import { GetProductCatalogueApi } from '../../../services';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Spinner } from 'reactstrap';

import ProductCatalogue from './ProductCatalogue';

export default class ProductCategory extends Component {
    constructor() {
        super();
        let companyId = localStorage.getItem('companyId');
        let slideIndex = 1;
        let showSlides = slideIndex;
        this.state = {
            companyId: companyId,
            productName: '',
            active: 0,
            productId: '',
            products: [],
            selectedProduct: {},


        };

    }
    componentDidMount() {
        if (this.props.editMode.isEdit) {
            this.setState({ active: this.props.editMode.previousTab })
        }
        //  
        GetProductCatalogueApi(this.state.companyId).then((res) => {
            if (res.isSuccess) {
                this.setState({ products: res.data.productResponseDTO });
              
                let selectedPro = res.data?.productResponseDTO[this.props.editMode.previousTab];

                this.setState({ selectedProduct: selectedPro });
              
            }
        })

    }

    toggle = (index, productId) => {
        this.props.previousTab(index);
        this.setState({ active: index });
        let [selectedPro] = this.state.products.filter(value => value.productId == productId);
        this.setState({ selectedProduct: selectedPro });


    }
    submitHandler = () => {

        this.props.fromAction(1);

        // console.log(this.props,'back');
    }
    prev = () => {
        this.setState({ active: -1 })
    }
    next = () => {

        this.setState({ active: +1 })

    }
    getTabName = (val) => {

        this.props.tabName(val)
    }
    render() {

        const tabs = this.state.products.map((tab, index) =>
            <NavItem className='nav-items-productcata'>
                <NavLink className={index === this.state.active ? 'navigation--active' : ''}
                    key={index} onClick={(event) => this.toggle(index, tab.productId)}  >
                    <a >{`${tab.productName.charAt(0).toUpperCase()}${tab.productName.substring(1).toLowerCase()}`}</a>
                </NavLink>
            </NavItem>
        );


        const tabContents = [];

        if (this.state.products.length) {
            for (let i = 0; i < this.state.products.length; i++) {
                tabContents.push(<ProductCatalogue handleBacks={this.props} selectedProduct={this.state.selectedProduct} handleAction={this.props} tabName={this.getTabName} previousActiveTab={this.state.active} />)
            }
        }

        const selectedTab = tabContents.map((item, index) => {
            console.log(index,this.state.active)
            if (index === this.state.active) {
                return <TabPane key={index}>{item}</TabPane>
            }
        });

        return (

            <Col sm="12" className="p-0">

                <Nav tabs className="productcata-navtabs greybox" id="tabs">

                    <TabContent className="product-tabcontent" >

                        {tabs}

                    </TabContent>
                </Nav>
                <div>
                    <Nav active={this.state.active} className=" justify-content-center">
                        {selectedTab}
                    </Nav>
                </div>

                <div>

                </div>
            </Col>

        );
    }
}



