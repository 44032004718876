import React, { useEffect, useState } from "react";
import { Col, Row, Card } from "reactstrap";
import {
  CompnayProfilSidebar,
  ProdProfilSidebar,
} from "../../SideBar/Profile/profile";
import { CompanyHeader } from "../../SideBar/Header/Header";
import ProductAdd from "./ProductAdd";
import ProductCategory from "./ProductCategory";
import CombinedProductAddCatal from "./CombinedProductAddCatal";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { GetProductCatalogueApi } from "../../../services";

const ProductProfile = () => {
  let tabnames;
  let token = localStorage.getItem("token");
  const user = useSelector((state) => state.userReducer);
  let companyId = localStorage.getItem("companyId");
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [tabStep, setTabStep] = useState("");
  const [activeProductTab, setActiveProductTab] = useState(0);
  const [isEdit, setEdit] = useState(false);

  const handleClick = () => {
    if (currentStep == 0) {
      setActiveProductTab(0);
    }
    console.log(currentStep);
    setCurrentStep(currentStep + 1);

  };

  const handleBack = (previousTab) => {
    console.log(previousTab, 'handleback')
    setCurrentStep(currentStep - 1);
    setActiveProductTab(previousTab);
    setEdit(true);
  };
  const handleBack2 = () => {
    setCurrentStep(currentStep - 2);
  };

  const tabName = (val) => {
    setTabStep(val);
  };
  useEffect(() => {
    if (companyId) {
      GetProductCatalogueApi(companyId).then((res) => {
        if (res.isSuccess) {
          if (res.data.productResponseDTO.length > 0) {
            setCurrentStep(2);
            // console.log("HAVE CATOGARY")
          } else {
            // console.log("NO cat")
          }
        }
      });
    }
  }, []);
  const set = (index) => {
    console.log('set', index)
    setActiveProductTab(index);
  };

  return (
    <>
      <CompanyHeader />

      <Row className="m-0">
        <Col sm="2" className="sidenav p-0">
          <CompnayProfilSidebar />
        </Col>
        <Col sm="10" className="rightcontent">
          <div className="Mains">
            <div className="Cards p-0 w-100">
              {currentStep == 0 ? (
                <ProductAdd fromAction={handleClick} />
              ) : null}
              {currentStep == 1 ? (
                <ProductCategory
                  previousTab={set}
                  fromAction={handleClick}
                  fromBack={handleBack}
                  tabName={tabName}
                  editMode={{ isEdit: isEdit, previousTab: activeProductTab }}
                />
              ) : null}
              {currentStep == 2 ? (
                <CombinedProductAddCatal
                  previousTab={activeProductTab}
                  fromAction={handleClick}
                  setActiveTab={set}
                  fromBack={handleBack}
                  fromBack2={handleBack2}
                  tabName={tabStep}
                />
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ProductProfile;
