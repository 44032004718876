import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  FormText,
  Card,
  Spinner,
} from "reactstrap";
import {
  BriefDescApi,
  DeleteClientApi,
  GetBriefDescriptionApi,
} from "../../../services";
// import '../../SignUp/SignUp.css';
import "./CompanyProfile.css";

import { AddLocation } from "./AddButton";
import validate from "validate.js";
import delicon from "../../../assets/delete2.png";
import plus from "../../../assets/plus.svg";
import { Success, Warning } from "../../Alert";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { toast } from "react-toastify";
import {toastOptions} from "../../../helpers/toast-options";

const BusinessDes = (props) => {
  let clients = "";
  let imageContainer = [];
  let companyId = localStorage.getItem("companyId");

  const [open, setopen] = useState(false);
  const setOpen = (e) => {
    setopen(e);
  };
  const getImage = (e) => {
    if (e.clientImageUrl && e.image) {
      let payload = {
        clientImageUrl: e.image,
      };
      let payload1 = {
        clientImageUrl: e.clientImageUrl,
      };
      imageContainer.push(payload);
      imageContainer.push(payload1);
    } else {
      imageContainer.push(e);
    }
  };

  const [isLoading, setisLoading] = useState(false);
  const [logoText, setLogoText] = useState(null);
  const [ImageContainer, setImageContainer] = useState([]);
  const [isSingle, setSingle] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [toastId, setToastId] = useState();

  const [briefDesc, setBriefDesc] = useState({
    history: "",
    products: "",
    capabilities: "",
    clients: [],
    id: Number(companyId),
  });

  const handleChange = (event) => {
    // console.log(event,'///////////////')
    const { name, value } = event.target;
    setBriefDesc((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const setFields = (val) => {
    if (
      val.clientImageUrl != "" &&
      ImageContainer.some((res) => res.clientImageUrl === val.clientImageUrl)
    ) {
      if(!toastId){
        const id = toast.error("duplicate entry - client already exists", {...toastOptions, onClose: ()=> setToastId(null)});
        setToastId(id);
      }
    }

    if (
      val.clientImageText != "" &&
      ImageContainer.some((res) => res.clientImageText === val.clientImageText)
    ) {
      if(!toastId){
        const id = toast.error("duplicate entry - client already exists", {...toastOptions, onClose: ()=> setToastId(null)});
        setToastId(id);
      }
    } else {
      if (ImageContainer.length < 21) {
        let tempClient = briefDesc.clients;
        tempClient.push(val);
        setBriefDesc({ ...briefDesc, clients: tempClient });
        // setImageContainer((pre) => [...pre, val])
        // imageContainer = val;
      } else {

      if(!toastId){
        const id = toast.error("you have exceeded the maximum number of entries", {...toastOptions, onClose: ()=> setToastId(null)});
        setToastId(id);
      }
    }
    }
  };
  const getDescriptionDetails = (val) => {
    GetBriefDescriptionApi().then((res) => {
      if (res.isSuccess) {
        setBriefDesc(res.data);
        setImageContainer(res.data.clients);
      }
    });
  };

  useEffect(() => {
    getDescriptionDetails();
  }, []);

  const deleteGallery = (id, index) => {
    console.log(ImageContainer)
    let getTempId = id.toString().search("temp-");

    if (getTempId !== -1) {
      let newCleintArry = briefDesc.clients.filter(
        (client) => client.id !== id
      );
      setBriefDesc({ ...briefDesc, clients: newCleintArry });
    } else {
      let modifiedData = {
        companyId: Number(companyId),
        clientId: parseInt(id),
      };
      console.log(id);
      let arr=ImageContainer.filter(c=>c.id!=id);
      console.log(arr)
      setImageContainer(arr);
      console.log(ImageContainer)
      DeleteClientApi(modifiedData).then((res) => {
        if (res.isSuccess) {
          let newCleintArry = briefDesc.clients.filter(
            (client) => client.id !== id
          );
          setBriefDesc({ ...briefDesc, clients: newCleintArry });
        }
      });
    }
  };
  let timerId = -1;
  const throatle = (e, func, delay) => {
    e.preventDefault();
    if (timerId === -1) {
      func(e);
      timerId = null;
    } else {
      if (timerId) {
        return;
      }
      timerId = setTimeout(() => {
        func(e);
        timerId = null;
      }, delay);
    }
  };
  const submitHandler = (event) => {
    // event.preventDefault();
    if (open != true) {
      let FInalArray = [];
      FInalArray = briefDesc.clients.filter((client) => {
        if (client.id.toString().search("temp-") !== -1) {
          client.id = 0;
        }
        return client;
      });
      if (isSingle) {
        FInalArray.push(logoText);
      }
      setisLoading(true);

      let body = { ...briefDesc, clients: FInalArray, id: Number(companyId) };

      BriefDescApi(body)
        .then((data) => {
          if (data.isSuccess) {
            props.fromAction(1);
          }
        })
        .catch((err) => {
          setisLoading(false);
        });
    } else {
      if(!toastId){
        const id = toast.error("Please fill or close the box", {...toastOptions, onClose: ()=> setToastId(null)});
        setToastId(id)
      }
    }
  };

  const handleBack = () => {
    props.fromBack(1);
  };
  function handleAdd() {
    setIsShow(true);
    setopen(true);
  }

  return (
    <>
      <div className="Cards">
        <Row>
          <Col sm="12" className="p-0">
            <h5 className="Summary-heading mb-16">Fill out information</h5>
          </Col>
        </Row>
        <Form
          onSubmit={(e) => throatle(e, submitHandler, 1000)}
          // onSubmit={submitHandler}
        >
          <FormGroup>
            <Label for="Whatwedo">History</Label>
            <Input
              type="textarea"
              name="history"
              value={briefDesc.history}
              id="exampleText"
              maxLength="3000"
              placeholder="3000 characters max"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="Whatwedo">Capabilities</Label>
            <Input
              type="textarea"
              name="capabilities"
              value={briefDesc.capabilities}
              onChange={handleChange}
              placeholder="3000 characters max"
              maxLength="3000"
              id="exampleText"
            />
          </FormGroup>
          <FormGroup>
            <Label for="Whatwedo">Products</Label>
            <Input
              type="textarea"
              name="products"
              value={briefDesc.products}
              onChange={handleChange}
              placeholder="3000 characters max"
              maxLength="3000"
              id="exampleText"
            />
          </FormGroup>
          <FormGroup>
            <p className="fontsize-16 mb-16">Clients</p>
            <div className="row">
              <div className="Col-md-12">
                <button
                  className="add ml-0"
                  type="button"
                  onClick={() => handleAdd()}
                >
                  <img src={plus} className="delete-photo" />
                  <span className="Btn-text">Add Clients</span>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 p-0">
                <AddLocation
                  value={setFields}
                  img={getImage}
                  logoText={setLogoText}
                  isShow={isShow}
                  certificate={false}
                  setIsShow={setIsShow}
                  single={setSingle}
                  open={setOpen}
                />
              </div>
            </div>
            <div className="row add-client-parent">
              {briefDesc.clients && briefDesc?.clients.length
                ? briefDesc?.clients.map((item, index) =>
                    item?.clientImageUrl?.includes(".png") ||
                    item?.clientImageUrl?.includes(".jpg") ||
                    (item?.clientImageUrl?.includes(".jpeg") &&
                      (item?.clientImageUrl != null ||
                        item?.clientImageText != null)) ? (
                      <div className="col-md-4 pl-0">
                        <div className="client-innfo-container">
                          <img
                            className="add-client-images"
                            src={item?.clientImageUrl}
                          />

                          <img
                            src={delicon}
                            className="client-delete-icon"
                            onClick={() => deleteGallery(item.id, index)}
                          />
                        </div>
                      </div>
                    ) : item?.clientImageText ? (
                      <div className="col-md-4 pl-0">
                        <div className="client-innfo-container">
                          {item?.clientImageText?.length > 10 ? (
                            <p
                              title={item?.clientImageText}
                              className="companyLogodiv"
                            >
                              {item?.clientImageText &&
                                item.clientImageText}
                            </p>
                          ) : (
                            <p
                              title={item?.clientImageText}
                              className="companyLogodiv"
                            >
                              {item?.clientImageText && item?.clientImageText}
                            </p>
                          )}
                          <img
                            src={delicon}
                            className="client-delete-icon"
                            onClick={() => deleteGallery(item.id, index)}
                          />
                        </div>{" "}
                      </div>
                    ) : null
                  )
                : null}
            </div>
          </FormGroup>

          <div className="Buis-Button pt-0 mt-26">
            <Button className="BusinessNext-btn" color="primary" type="submit">
              {/* {isLoading ? <Spinner /> : 'Next'} */}
              Next
            </Button>
            <Button
              className="BusinessBack-btn"
              outline
              color="primary"
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
          <NotificationContainer />
        </Form>
      </div>
    </>
  );
};

export default BusinessDes;
