import React from 'react';
import { AddBlendApi, ViewYarnApi, ViewBlendApi,deleteBlendApi, addSustainability } from "../../../services";
import {
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
    Table,
    Card,
    TabContent,
    TabPane,
  } from "reactstrap";
import DeletPopUp from "../MetadataUpdates/deletePopup";
import { toast } from "react-toastify";

class Blends extends React.Component {
    constructor() {
        super();
        this.state = {
            yarnlength: 2,
            blend: {
                name: '',
                blendYarns: [{ yarnId: 0, selected: false }, { yarnId: 0, selected: false }]
            },
            items:[],
            blends:[],
            refresh:false
        }
    }
    componentDidMount = () => {
        this.getYarnData();
        
    }
    getYarnData() {
        ViewYarnApi().then((res) => {
          if (res.isSuccess && res.data && res.data.length) {
           
            this.setState({ items: res.data });
          } else {
            this.setState({ items: res.data });
          }
        });
      }
    
    addMoreYarn=()=>{
        this.setState({
            ...this.state,
            blend:{
                name:this.state.blend.name,
                blendYarns:[...this.state.blend.blendYarns,{yarnId:0,selected:false}]
            }
        })
    }
    handleYarnChange=(e,index)=>{
        // this.setState({
        //     blend:{
        //         blendName:this.state.blend.blendName,
        //         blendYarns:this.state.blend.blendYarns[index].yarnId=e.target.value
        //     }
        // })
        this.state.blend.blendYarns[index].yarnId=e.target.value;
        this.setState(this.state)
        console.log(e.target.value,index,this.state.blend)
    }
    handleBlendNamechange=(e)=>{
        
        this.setState({
            blend:{
                name:e.target.value,
                blendYarns:this.state.blend.blendYarns
            }
        })
        console.log(this.state.blend)
    }
    submitNewBlend=()=>{
        console.log(this.state.blend)
        if(!this.state.blend.name || this.state.blend.name.trim()==''){
            return;
        }
        let isallyarn=false;
        this.state.blend.blendYarns.forEach(item=>{
            if(item.yarnId==0){
                isallyarn=true;
              
            }
        })
        if(isallyarn){
            toast.error('Select All Yarns');
            return;
        }
        const data=this.state.blend;
        console.log(data)
        AddBlendApi(data).then(res=>{
            console.log(res.data);
            this.setState({
                yarnlength: 2,
                blend: {
                    name: '',
                    blendYarns: [{ yarnId: 0, selected: false }, { yarnId: 0, selected: false }]
                },
                refresh:!this.state.refresh
            })
        })
    }
    render() {
        return (
            <>
                <div className="Knit">
                    <div className="row blend-top">
                        <div className="col-md-8 blends">
                            <div className="row">
                                <div className="col-md-4">
                                    <p>Blend Name</p>
                                    <div>
                                        <input
                                            placeholder='Type Blend name'
                                            value={this.state.blend.name}
                                            onChange={this.handleBlendNamechange} />
                                    </div>
                                </div>
                                {this.state.blend.blendYarns.map((item, index) => {
                                    return (
                                        <>
                                            <div className="col-md-4">
                                                <p>Yarn Type {index+1}  
                                              
                                                </p>
                                                <div className='d-flex align-items-center'>
                                                    <select className='blend-yarn' onChange={(event)=>this.handleYarnChange(event,index)}
                                                    value={item.yarnId}>
                                                        <option disabled selected value={0}> Type Of Yarn</option>
                                                        {this.state.items.map((i,index)=>{
                                                            return(
                                                                <option key={index} value={i.id} >{i.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    { index==this.state.blend.blendYarns.length-1 && <a onClick={this.addMoreYarn} className='add-blends'> <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <circle cx="8" cy="8" r="7.5" stroke="#6F6F6F" />
                                      <path d="M12.6673 8.66634H8.66732V12.6663H7.33398V8.66634H3.33398V7.33301H7.33398V3.33301H8.66732V7.33301H12.6673V8.66634Z" fill="black" fill-opacity="0.54" />
                                    </svg></a>}
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                                }

                               
                            </div>
                        </div>
                        <div className="col-md-3 align-self-center pt-10">
                            <button className='btn btn-act btn-primary'
                            onClick={this.submitNewBlend}>Submit</button>
                        </div>
                    </div>
                    <BlendTable
                    blendrefresh={this.state.refresh}
                    />
                </div>
            </>
        )
    }
}


class BlendTable extends React.Component {
    constructor() {
        super();
        this.state = {
           blends:[],
           maxCol:2
           
        }

    }
    componentDidMount = () => {
       
        this.getBlendData();
    }
    componentWillReceiveProps(props) {
        const { blendrefresh, id } = this.props;
        if (props.blendrefresh !== blendrefresh) {
            this.getBlendData();
        }
      }
    getBlendData(){
        ViewBlendApi().then((res) => {
            if (res.isSuccess && res.data && res.data.length) {
             console.log(res.data)
             this.setState({maxCol:2})
             res.data.map(item=>{
                 if(item.blendYarns.length>this.state.maxCol){
                     this.setState({
                         maxCol:item.blendYarns.length
                     })
                 }
             })
             
              this.setState({ blends: res.data });
            } else {
              this.setState({ blends: res.data });
            }
          });
    }
    gettableheader(){
        var headers=[]
        for(let i=0;i<this.state.maxCol;i++){
            headers.push(<th className='yarn-type-blend'>Yarn Type {i+1}</th>)
        }
        return headers;
    }
    getEmptytd=(item)=>{
        let length=item.blendYarns.length;
        var cols=[];
        for(let i=length;i<=this.state.maxCol;i++){
            if(i==this.state.maxCol){
                cols.push(<td className='p-0'> <DeletPopUp
                    handleClickDelete={this.deleteBlend}
                    id={item.id}
                    type='blends'
                  /></td>)
            }else{
                cols.push(<td className='yarn-name-blend'> </td>)
             }
        }
        return cols;
    }
    deleteBlend=(id,type,close)=>{
        deleteBlendApi(id).then(res=>{
            close();
            this.getBlendData();
        })
    }
    
    render(){
        return(
            <>
                 <div id="Table" className='blen-table'>
        <Table key={this.state.maxCol}>
          <tbody>
            <div className="tabless">
              <div className="Scroll yarn-table">
                <tr>
                  <th className="sno">SI.No</th>
                  <th className="yarn-type-blend">Blend Name</th>
                  {this.gettableheader()}
                </tr>

                {this.state.blends.map((item, id) => {
                  return (
                    <>
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td className='yarn-type-blend'>
                            {item.name}
                        </td>
                       {item.blendYarns.map((blend)=>{
                           return(
                               <>
                               <td className='yarn-name-blend'>
                                   {blend.yarnName}
                               </td>
                              
                               </>
                           )
                           
                       })}
                        {this.getEmptytd(item)}
                      </tr>
                     

                    </>
                  )
                })
                }

              </div>
            </div>

          </tbody>
        </Table>
      </div>
            </>
        )
    }
}



  
export default Blends;