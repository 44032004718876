import React, { useEffect, useState } from 'react';
import { Card, Button, CardTitle, CardText, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import './Rfq.css'

import { Link } from 'react-router-dom'
import { AddRfqWeaveApi, GetRFQApi } from '../../../services';
import { stages } from '../../../models/enum';
import edit from '../../../assets/edit.png';
import validate from 'validate.js';
import { useDispatch } from 'react-redux';


validate.validators.presence.options = { message: '^mandatory field must be filled to proceed' };

const constraints = {
  weave: {
    presence: { allowEmpty: false },
  },
  // fabricStage: { presence: { allowEmpty: false } },

};

const OrderWeave = (props) => {
const dispatch=useDispatch();
  // let rfdNumber = localStorage.getItem('rfqNumber')
  let rfdID = localStorage.getItem('rfqID')
  let stage = stages.RFQ_IN_PRG3
  let body;
  const [type, setType] = useState('')
  const [fabricstage, setfabricstage] = useState('')
  const [processType, setprocessType] = useState('')
  const [linkType, setLinkType] = useState("fabric");
  const [rfdNumber, setRfdNumber] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [formDetails, setFormDetails] = useState({
    submitted: false,
    errors: null,
  })

  const [weaveType, setWeaveType] = useState({
    weave: '',
    // errors: false
  })
  const [data, setData] = useState('')
  const getFabricTypeApi = () => {
    //  
    setIsLoading(true)

    if (props.viewType == 'view') {
      localStorage.setItem('rfqID', props.viewData.rfqId)
      // localStorage.removeItem('stageName')
      localStorage.setItem('orderName', props.viewData.orderName)

      localStorage.removeItem('rfqNumber')
      // localStorage.setItem('rfqNumber', props.viewData.rfqNumber)
      localStorage.setItem('rfqNumber', props.viewData.rfqNumber)


      body = {

        id: localStorage.getItem('rfqID'),
        rfqStage: stage
      }
    } else {
      //  
      body = {
        id: localStorage.getItem('rfqID'),
        rfqStage: stage
      }
    }
console.log(body)
    GetRFQApi(body).then((res) => {
      if (res.isSuccess) {
        // console.log(res, 'res');
        setType(res.data.fabricType);
        setfabricstage(res.data.fabricStage)
        setprocessType(res.data.processType)
        setWeaveType({ weave: res.data.weaveType })
        setRfdNumber(res.data.rfdNumber)
        setData(res.data.name)
        if(res.data.workType.toLowerCase()=='fabric'){
          setLinkType('fabric');
          dispatch({type:'fabric'})
          if(localStorage.getItem('linkType')!='fabric')
                  localStorage.setItem('linkType','fabric');
                  
        }else{
          setLinkType('job_work');
          dispatch({type:'job_work'})
          if(localStorage.getItem('linkType')!='job_work')
            localStorage.setItem('linkType','job_work');
           

        }
        localStorage.setItem('orderName', res.data.orderName);

        setIsLoading(false)
      }
    })

  }
  useEffect(() => {
    const errors = validate(weaveType, constraints);
    setFormDetails((prev) => ({
      ...prev,
      errors
    }));
    // getFabricTypeApi();
  }, [weaveType]);
  useEffect(() => {

    getFabricTypeApi();
  }, []);


  const handleChange = (event) => {
    const { name, value } = event.target;

    setWeaveType((prev) => ({
      ...prev,
      [name]: value
    }));
    // setFormDetails((prev) => ({
    //   ...prev,
    //   errors: null
    // }));

  };
  const submitHandlers = () => {


    setFormDetails(prev => ({
      ...prev,
      submitted: true,
    }))
    let addBody = {
      id: rfdID,
      weaveType: weaveType.weave
    }

    if (!formDetails.errors) {
      AddRfqWeaveApi(addBody).then((res) => {
        // fabricstage
        props.fromAction(1)
        // setTimeout(() => {

        // }, 3000);
        setIsLoading(false);

      }).catch(err => {
        setIsLoading(false);
      })
    }

  }
  const getprocesstype=(processtype)=>{
    if(linkType=='fabric'){
      return `(${capitalizeFirstLowercaseRest(processtype)})`;
    }else{
      switch(processtype){
        case 'PRINT':
          {
            return 'Printing';
            break;
          }
          case 'DYED':{
            return 'Dyeing';
          }
          default:{
            return 'Printing & Dyeing';
          }
      }
    }
  }
  const capitalizeFirstLowercaseRest = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleBack = () => {
    props.fromBack(1);
  }

  return (
    <>
      {/* <h4 className="RFQ-h4">{data} | RFQ #{rfdNumber}</h4> */}
      <h4 className="RFQ-h4">Order {localStorage.getItem('orderName')} | RFQ #{rfdNumber}{linkType!='fabric'&&' | Job Work'}</h4>


      <Row className="RFQ-Row-weave">
        <Card className="RFQ-order-kate1">

          <div className="para-edit py-0">
            <div className="para-para">
              <p className="input-saved">1. Type of Fabric:<span className="bold-text text-capitalize">{capitalizeFirstLowercaseRest(type)}</span></p>
              {
                fabricstage != 'Processed' ?
                  <p className="inputs-saved">2. Fabric Stage:<span className="bold-text text-capitalize">{fabricstage==='YarnDyed'?'Yarn Dyed': capitalizeFirstLowercaseRest(fabricstage)}</span></p>

                  : <p className="inputs-saved">{linkType=='fabric'?' 2. Fabric Stage:':'2. Job Work:'}<span className="bold-text text-capitalize">{linkType=='fabric'&& fabricstage} {getprocesstype(processType)}</span></p>
              }
            </div>
            <img src={edit} className="edit-icon" onClick={handleBack} />
          </div>


          <hr className="hr-order-kate" />

          <Card body className="RFQ-order-weave-card">
            <p className="RFQ-Title">Fill out information</p>


            <FormGroup>
              <Label for="exampleOrder" className="order-name">3.{type.toLowerCase()=='woven'?' Type of Weave':type.toLowerCase()=='knit'?' Fabric Structure':''}</Label>
              <Input className="Rfq-Order type-of-weave mb-16" type="text" name="weave"
                value={weaveType.weave} onChange={handleChange}
                placeholder="Enter your text here"
                maxLength='50'
                id="exampleSelectOrder">
              </Input>
              {formDetails.submitted &&
                formDetails.errors &&
                formDetails.errors.weave && (
                  <small className="form-text text-danger">
                    {formDetails.errors.weave[0]}
                  </small>
                )}

            </FormGroup>

            <div className="RFQButtons">
              <Button className="RFQ-Submit" onClick={submitHandlers} disabled={formDetails.errors}>Submit</Button>
              <Button className="RFQ-Back" onClick={handleBack}>Back</Button>
            </div>
          </Card>


        </Card>
      </Row>
      {isLoading ? <><div className="cdf"> <div className='loader' /></div> </> : null}

    </>
  );
};

export default OrderWeave;