import React, { useEffect, useState } from 'react';
import { Card, Button, CardTitle, CardText, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import './Rfq.css'
import { Link } from 'react-router-dom';
// import RfqOrder from './RfqOrder';
import { AddOrderApi, GetRFQApi,checkDuplicateRfq } from '../../../services';
import { useSelector } from 'react-redux';
import { sessionService } from '../../../services/session.service'
import { Success } from '../../Alert';
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { toast } from 'react-toastify';
import { stages } from '../../../models/enum';
import validate from 'validate.js'

const constraints = {
  orderName: { presence: { allowEmpty: false }, }
}

const CreateOrder = (props) => {
  const user = useSelector(state => state.userReducer);
  const linkType=useSelector(state=>state.fabricLinkReducer.linkType)

  let rfdID = localStorage.getItem('rfqID')
  let stage = stages.RFQ_IN_PRG0
  let body;

  const [order, setOrder] = useState({
    orderName: '',
    companyName: user.data.companyName,
    errors: false
  })

  const [formDetails, setFormDetails] = useState({
    submitted: false,
    // submittedP:false,
    errors: null,
  })

  useEffect(() => {



    const errors = validate(order, constraints);
    setFormDetails((prev) => ({
      ...prev,
      errors
    }));

  }, [order]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setOrder((prev) => ({
      ...prev,
      [name]: value
    }));


  };

  // const getFabricTypeApi = () => {
  //   if (props.viewType == 'view') {
  //     body = {
  //       id: props.viewData.rfqId,
  //       rfqStage: props.viewData.rfqStages
  //     }
  //   } else {
  //     body = {
  //       id: rfdID,
  //       rfqStage: stage
  //     }
  //   }

  //   GetRFQApi(body).then((res) => {
  //     if (res.isSuccess) {
  //       // console.log(res, 'res');
  //       // setOrder({ orderName: res.data.name })
  //     }
  //   })
  // }

  useEffect(() => {
    localStorage.removeItem('orderName')

    localStorage.removeItem('stageName')
    localStorage.removeItem('rfqID')
    localStorage.removeItem('rfqNumber')


    // if (rfdID != null) {
    //   getFabricTypeApi();
    // }

  }, []);

  const submitHandler = () => {
    setFormDetails(prev => ({
      ...prev,
      submitted: true,

    }))
    //  
    // console.log(order.orderName.replace(/\s+/g, " "));
    // if (order.orderName !== " " || order.orderName.trim().replace(/\s+/g, ' ') !== " ") {
    //   order.errors = true
    // }

    if (!formDetails.errors) {
      checkDuplicateRfq(order.orderName).then(res=>{
        if(res.isSuccess){
          sessionService.setSession('orderName', order.orderName);
          props.fromAction(1)
          props.newOrder1(true)
          props.linked(false)
        }else{
          toast.error(res.message);
        }
      })
     
      // AddOrderApi(order).then((res) => {
      //   if (res.isSuccess) {
      //     // toast.success(res.data.message);
      //     sessionService.setSession('rfqNumber', res.data.rfqNumber);
      //     sessionService.setSession('rfqID', res.data.rfqId);
      //     props.fromAction(1)
      //   }
      // })
    }
  }

  const handleBack = () => {
    props.fromBack(1)
  }

  return (
    <Col sm="9">
      <h4 className="margin-div">New {linkType=='fabric'?'Fabric':'Job Work'} RFQ</h4>

      <Card body className="RFQ-order-card">
        <p className="RFQ-Title">Fill out information</p>

        <FormGroup>
          <Label for="examplePassword" className="order-name">Order Name</Label>
          <Input className="Rfq-Order-input" type="text" name="orderName"
            value={order.orderName} onChange={handleChange}
            id="exampleOrder" placeholder="Enter Order Name"
            minLength='1'
            maxLength='60'
          />
          {formDetails.submitted &&
            formDetails.errors &&
            formDetails.errors.orderName && (
              <small className="form-text text-danger">
                {formDetails.errors.orderName[0]}
              </small>
            )}
        </FormGroup>


        <div className="RFQButtons">
          <Link classNAme="RFQ-order-select" > <Button className="RFQ-Submit" onClick={submitHandler} disabled={formDetails.errors}>Submit</Button></Link>
          <Button className="RFQ-Back" onClick={handleBack}>Cancel</Button>
        </div>
      </Card>
      <NotificationContainer />

    </Col>

  );
};

export default CreateOrder;