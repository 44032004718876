import React, { useEffect, useState } from "react";
import ProfileFianl from "../../../../assets/profile.png";
import "./Preview.css";
import { Link } from "react-router-dom";
import "../CompanyProfile.css";
import { Container, Row, Col } from "react-bootstrap";
import SVGIcon from "./Ratings";
import { GetBriefDescriptionApi } from "../../../../services";

const BusinessPre = () => {
  let companyId = localStorage.getItem("companyId");

  const [briefDesc, setBriefDesc] = useState({
    history: "",
    products: "",
    capabilities: "",
    clients: "",
    id: Number(companyId),
  });

  const getDescriptionDetails = () => {
    GetBriefDescriptionApi().then((res) => {
      if (res.isSuccess) {
        setBriefDesc(res.data);
      }
    });
  };

  useEffect(() => {
    getDescriptionDetails();
  }, []);
  return (
    <>
      <Container className="BusinessPre">
        <Row>
          <Col className="Busicol" md={6} style={{ marginTop: "5vh" }}>
            <Row className="Buisrowpre">
              <Col className="p-0">
                History
                <p>{briefDesc.history ? briefDesc.history : ""}</p>
              </Col>
            </Row>
            <Row className="Buisrowpre">
              <Col className="p-0">
                Capabilities
                <p>{briefDesc.capabilities ? briefDesc.capabilities : ""}</p>
              </Col>
            </Row>
            <Row className="Buisrowpre">
              <Col className="p-0">
                Products
                <p>{briefDesc.products ? briefDesc.products : ""}</p>
              </Col>
            </Row>
          </Col>

          <Col className="Clientspre side-col p-0" md={6}>
            <Row>
              <Col className="p-0">
                <p className="Clientspre1">Clients</p>
              </Col>
            </Row>
            <Row>
              {briefDesc.clients.length ? (
                briefDesc?.clients?.map(
                  (item) =>
                    item.clientImageUrl ? (
                      item.clientImageUrl.includes(".jpeg") ||
                      item.clientImageUrl.includes(".png") ||
                      item.clientImageUrl.includes(".jpg") ? (
                        <Col md={4} className="p-0">
                          <img
                            className="add-client-images12"
                            src={item.clientImageUrl}
                          />
                        </Col>
                      ) : item.clientImageText ? (
                        <div className="col-md-4 p-0">
                          {item.clientImageText.length > 10 ? (
                            <p
                              title={item.clientImageText}
                              className="companyLogodiv12"
                            >
                              {item.clientImageText}
                            </p>
                          ) : (
                            <p
                              title={item.clientImageText}
                              className="companyLogodiv12"
                            >
                              {item.clientImageText}
                            </p>
                          )}
                        </div>
                      ) : null
                    ) : item.clientImageText ? (
                      <div className="col-md-4 p-0">
                        {item.clientImageText.length > 10 ? (
                          <p
                            title={item.clientImageText}
                            className="companyLogodiv12"
                          >
                            {item.clientImageText}
                          </p>
                        ) : (
                          <p
                            title={item.clientImageText}
                            className="companyLogodiv12"
                          >
                            {item.clientImageText}
                          </p>
                        )}
                      </div>
                    ) : null
                  // : item.clientImageText ? <Col md={4}><p className="companyLogodiv1">{item.clientImageText}</p></Col> : null : item.clientImageText ? (<p className="companyLogodiv1">{item.clientImageText}</p>) : <Col className="Buisrowpre" style={{ color: '#828282', fontSize: '16px' }}><p> </p></Col>
                )
              ) : (
                <Col
                  className="Buisrowpre"
                  style={{ color: "#828282", fontSize: "16px" }}
                >
                  <p></p>
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        <Row className="Buisclientrow"></Row>
        <Row className="Buisclientrow"></Row>
      </Container>
    </>
  );
};

export default BusinessPre;
