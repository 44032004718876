import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import del from '../../../../assets/delete.png'
// import './Fabric/Fabric.css';
// import './Fabric/knit';
import Alert from '../../../../assets/alert.jpg'
import { BuyersSellersActionApi } from '../../../../services';
import { status } from '../../../../models/enum';
import { Button } from 'reactstrap';
import { Warning } from '../../../Alert';



class DeletePopUp extends React.Component {
    constructor(props) {

        super()
    }


    submit = (props) => {

        confirmAlert({
            customUI: ({ onClose }) => {

                return (
                    <div className='custom-ui'>
                        <p className="Alert">
                            <img src={Alert} className="alert-product" onClick={this.submit} />
                            Alert !</p>
                        <h6 className="AlertNotheading">Are you sure you would like to delete this account?</h6>
                        <div className="Alrtbuttons">
                            <button onClick={onClose} className="Notdelted">No</button>
                            <button className="YesDeleteIt" onClick={() => this.props.handleClickDelete(this.props.id, onClose)}
                            >
                                Yes
                            </button>
                        </div>

                    </div>
                );
            }
        });
    };

    render() {

        return (
            <div className='del-container'>
                {/* <img src={del} className="DeleteProductcat" onClick={this.submit} /> */}
                <Button Accept color="danger" className="typereject-btn" onClick={this.submit}  > Delete</Button>
            </div>
        );
    }
}

export default DeletePopUp;