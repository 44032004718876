import React, { useEffect, useState } from 'react';
import ProfileFianl from '../../../../assets/profile.png'
import './Preview.css';
import { Link, useHistory } from 'react-router-dom'
import '../CompanyProfile.css'
import { Container, Row, Col } from 'react-bootstrap';
import SVGIcon from './Ratings'
import { GetSummaryApi } from '../../../../services';

const SumaryPre = () => {

  const history = useHistory();

  let companyId = localStorage.getItem('companyId')

  const [summary, setSummary] = useState({
    companyName: '',
    companyWebsite: '',
    companyBriefDescription: '',
    foundationYear: '',
    numberOfEmployees: '',
    numberOfLocations: '',
    companyCertificates: [],
    // id: companyId,
    profileImageUrl: null,
    companyId: companyId
  })


  useEffect(() => {
    getSummaryDetails();
  }, [])
  const getSummaryDetails = () => {
    // console.log(companyId,'/////////////////')
    GetSummaryApi(companyId).then((res) => {
      if (res.isSuccess) {
        console.log(res.data)
        setSummary(res.data)
      }
    })
  }
  const replacehttp=(urlString)=>{
    if(urlString.includes('//')){
      return urlString.substring(urlString.lastIndexOf('/')+1);
    }else{
      return urlString;
    }
  }

  return (

    <>
      <Container className="SumarryPre">
        <div className="SumarryPre">
          <Row>
            <Col md={6}>
          <Row className="Summaryprerow">
            <Col className="SumaryhePreview p-0">What do we do ?
              <p>{summary.companyBriefDescription}</p></Col>
          </Row>
          {/* <Row className="w-65"> */}
            {/* <Col md={6} className="p-0"> */}
              <Row>
                <Col md={4} className="SumaryhePreview p-0">Year Founded
                  <p>{summary.foundationYear ? summary.foundationYear : ""}</p></Col>

             
                <Col md={4} className="SumaryhePreview p-0">Number of locations
                  <p>{summary.numberOfLocations != 0 ? summary.numberOfLocations : ""}</p>
                </Col>
              
                <Col md={4} className="SumaryhePreview p-0">Number of employees
                  <p>{summary.numberOfEmployees ? summary.numberOfEmployees : ""}</p>
                </Col>
                </Row>
                <Row>
                <Col className="SumaryhePreview p-0">Company website
                  {summary.companyWebsite ?
                    <a className="websiteemailpe" href={'//'+replacehttp(summary.companyWebsite)} target="_blank"><p>{summary.companyWebsite}</p></a>
                    : <p></p>}
                </Col>
                </Row>

            </Col>
           
          
          {/* </Col> */}
          <Col md={6} >
              <Row className="Summaryprerow">
                <Col className="SumaryhePreview">
                  Certifications/Compliances 
                  <br />
                  <div className="row mar-9" >
                {summary?.companyCertificates?.length > 0 ? (
                  summary?.companyCertificates?.map((item) =>
                  item.certificateImageUrl ? (
                      item.certificateImageUrl.includes(".jpeg") ||
                      item.certificateImageUrl.includes(".png") ||
                      item.certificateImageUrl.includes(".jpg") ? (
                        <div className="col-md-4 mar-8 col-xs-12 pl-1">
                          <img
                            className="add-certificate-images1"
                            src={item.certificateImageUrl}
                          />
                        </div>
                      ) : item.certificateImageText ? (
                        <div className="col-md-4 col-xs-12 pl-1 mar-8">
                          {item.certificateImageText.length > 10 ? (
                            <p
                              title={item.certificateImageText}
                              className="certLogodiv1"
                            >
                              {item.certificateImageText.substring(0, 10)}...
                            </p>
                          ) : (
                            <p
                              title={item.certificateImageText}
                              className="certLogodiv1"
                            >
                              {item.certificateImageText}
                            </p>
                          )}
                        </div>
                      ) : null
                    ) : item.certificateImageText ? (
                      <div className="col-md-4 mar-8 col-xs-12 pl-1">
                        {item.certificateImageText.length > 10 ? (
                          <p
                            title={item.certificateImageText}
                            className="certLogodiv1"
                          >
                            {item.certificateImageText.substring(0, 10)}...
                          </p>
                        ) : (
                          <p
                            title={item.certificateImageText}
                            className="certLogodiv1"
                          >
                            {item.certificateImageText}
                          </p>
                        )}
                      </div>
                    ) : null
                  )
                ) : (
                  <span></span>
                )}
              </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </>



  );
};

export default SumaryPre;