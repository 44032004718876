import React, { useEffect, useState } from 'react';
import './Resetpw.css';
import { Col, Row, Button, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Header2 } from '../SideBar/Header/Header-lgin';
import validate from 'validate.js';
import { useDispatch } from 'react-redux';
// import { ForgetPasswordActionCreator } from '../../actions/user.actions';
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { ResetPwApi, resetVerifyToken, verifyToken } from '../../services';
import { Success, Errors } from '../Alert';
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { toast } from 'react-toastify';



validate.validators.presence.options = { message: '^mandatory field must be filled to proceed' };

const constraints = {
  password: {
    presence: { allowEmpty: false },
    format: {

      // pattern: /^(?=.*[A-Z])(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[^_`{|}~]{8,}$/,
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,  
      flags: "i",
      message: "should contain at least 1 lowercase, 1 uppercase, 1 number, and 1 special character"
    },
    length: {
      minimum: 8, maximum: 30,
    },

  },
  confirmpassword: {
    presence: { allowEmpty: false },

    length: {
      minimum: 8, maximum: 30,
    },
    equality: "password"
  }

};

const ResetPw = (props) => {

  const history = useHistory();

  const { search } = useLocation();

  const values = queryString.parse(search);

  const token = values.token;
  const email = values.email

  const dispatch = useDispatch();
  const [userPw, setUserPw] = useState({
    password: '',
    confirmpassword: ''

  })
  const [isLoading, setisLoading] = useState(false)

  const [verify, setVerify] = useState(false)
  const [errormsg, setErrormsg] = useState('')
  const [formDetails, setFormDetails] = useState({
    submitted: false,
    errors: null
  });

  useEffect(() => {
    let body = {
      email: email,
      token: token
    }
    resetVerifyToken(body).then((res) => {

      if (res.isSuccess && res.message == "Success") {
        setVerify(false)
      } else {
        setErrormsg(res.message)
        setVerify(true)
      }
    })
  }, [])

  //state defined
  useEffect(() => {
    const errors = validate(userPw, constraints);
    setFormDetails((prev) => ({
      ...prev,
      errors
    }));
  }, [userPw]);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserPw((prev) => ({
      ...prev,
      [name]: value
    }));

  }

  const submitHandler = (event) => {
    event.preventDefault();

    setFormDetails((prev) => ({
      ...prev,
      submitted: true
    }));


    const body = {
      token: token,
      email: email,
      password: userPw.password,
    };
    if (!formDetails.errors) {
      setisLoading(true)

      ResetPwApi(body)
        .then((res) => {
          if (res.isSuccess) {
            // toast.success(res.data.message);
            setisLoading(false)

            history.push(`/?redirectURI=resetpw`);
          }
        }).catch((res) => {
          setisLoading(false)

        })
    }

  };


  return (<>

    {verify ? <>
      <div className="verified"><h3>{errormsg}</h3>
        <Button color="primary" className='forget-btn2' type="submit" onClick={() => history.push(`/`)}>Return to login</Button>
      </div>
    </>
      :
      <>
        <Row>
          <Header2 />
        </Row>
        <Form className="login-form" onSubmit={submitHandler}>
          <Row>
            <Col>
              <h5 className='headingLogin'>Reset Password</h5>
              <FormGroup className="Loginform">
                <Label className="required" for="Password">Enter New Password</Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  onChange={handleChange}
                  minLength="8"
                  maxLength="30"
                  placeholder="Enter new password"
                  value={userPw.password}
                  className="reset-pw"


                />
                {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.password && (
                    <small className="form-text text-danger">
                      {formDetails.errors.password[0]}
                    </small>
                  )}

              </FormGroup>
              <FormGroup className="Loginform">
                <Label className="required" for="Password">Re-enter Password</Label>
                <Input
                  type="password"
                  name="confirmpassword"
                  id="newPassword"
                  minLength="8"
                  maxLength="30"
                  placeholder="Re-enter the password"
                  onChange={handleChange}
                  value={userPw.confirmpassword}
                  className="reset-pw"

                />
                {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.confirmpassword && (
                    <small className="form-text text-danger">
                      Password and confirm password does not match
                    </small>
                  )}

              </FormGroup>

              <Button color="primary" disabled={isLoading} className='forget-btn1' type="submit">
                {isLoading ? <Spinner /> : 'Confirm'}
              </Button>


            </Col>

          </Row>
          <NotificationContainer />

        </Form>
      </>
    }
  </>
  )
}

export default ResetPw;