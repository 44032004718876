import React, { useEffect, useState } from 'react';
import { Card, Button, CardTitle, CardText, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import './Rfq.css'
import { Link } from 'react-router-dom';
// import RfqOrder from './RfqOrder';
import { AddOrderApi, GetLinkedOrder, GetRFQApi } from '../../../services';
import { useSelector } from 'react-redux';
import { sessionService } from '../../../services/session.service'
import { Success } from '../../Alert';
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { toast } from 'react-toastify';
import { stages } from '../../../models/enum';
import validate from 'validate.js'


const constraints = {
    orderName: { presence: { allowEmpty: false }, }
}

const LinkOrder = (props) => {
  const linkType=useSelector(state=>state.fabricLinkReducer.linkType)

    const user = useSelector(state => state.userReducer);
    let rfdID = localStorage.getItem('rfqID')
    let stage = stages.RFQ_IN_PRG0
    let body;
    const [linked, setLinked] = useState(false)
    const [order, setOrder] = useState({
        orderName: '',
        companyName: user.data.companyName,
        errors: false
    })

    const [formDetails, setFormDetails] = useState({
        submitted: false,
        // submittedP:false,
        errors: null,
    })

    useEffect(() => {
        getLinkedDatas();
        const errors = validate(order, constraints);
        setFormDetails((prev) => ({
            ...prev,
            errors
        }));
    }, [order]);
    const [data, setData] = useState('')
    const getLinkedDatas = () => {
        GetLinkedOrder().then((res) => {
            if (res.isSuccess && res.data && res.data.length) {
                setData(res)
            }
        })
    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        setOrder((prev) => ({
            ...prev,
            [name]: value
        }));
    };



    useEffect(() => {
        localStorage.removeItem('orderName')

        localStorage.removeItem('stageName')
        localStorage.removeItem('rfqID')
        localStorage.removeItem('rfqNumber')


        // if (rfdID != null) {
        //   getFabricTypeApi();
        // }

    }, []);

    const submitHandler = () => {
        setFormDetails(prev => ({
            ...prev,
            submitted: true,

        }))
        //  
        // console.log(order.orderName.replace(/\s+/g, " "));
        // if (order.orderName !== " " || order.orderName.trim().replace(/\s+/g, ' ') !== " ") {
        //   order.errors = true
        // }

        if (!formDetails.errors) {
            sessionService.setSession('orderName', order.orderName);
            props.fromAction(1)
            setLinked(true)
            props.linked(true)
        }
    }

    const handleBack = () => {
        props.fromBack(1)
    }

    return (
        <Col sm="9">
            <h4 className="margin-div">New {linkType=='fabric'?'Fabric':'Job Work'} RFQ</h4>
            <Card body className="RFQ-order-card">
                <p className="RFQ-Title">Fill out information</p>

                <FormGroup className="mb-16">
                    <Label for="examplePassword" className="existing-order">Link to existing order</Label>
                    <Input className="Rfq-Order-Kate text-capitalize" type="select" name="orderName"
                        value={order.orderName} onChange={(e) => handleChange(e)}
                        id="exampleSelectOrder1" placeholder="Enter an existing order"
                        minLength='1'
                        maxLength='30'
                    >
                        <option value="" disabled selected hidden>Select</option>

                        {
                            data?.data?.map((item) => (

                                <option className="text-capitalize" value={item} id="OptionProduct">{`${item}`}</option>
                            ))
                        }
                    </Input>
                    {/* {formDetails.submitted &&
                        formDetails.errors &&
                        formDetails.errors.orderName && (
                            <small className="form-text text-danger">
                                {formDetails.errors.orderName[0]}
                            </small>
                        )} */}
                </FormGroup>


                <div className="RFQButtons mt-16">
                    <Link  classNAme="RFQ-order-select" > <Button disabled={formDetails.errors} className="RFQ-Submit" onClick={submitHandler}>Submit</Button></Link>
                    <Button className="RFQ-Back" onClick={handleBack}>Cancel</Button>
                </div>
            </Card>
            <NotificationContainer />

        </Col>

    );
};

export default LinkOrder;