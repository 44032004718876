import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  GetFabricApi,
  GetRFQApi,
  GetStagesApi,
  sessionService,
  TypeFabricApi,
} from "../../../services";
import { Errors, Success } from "../../Alert";
import "./Rfq.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { stages } from "../../../models/enum";
import validate from "validate.js";
import { toast } from "react-toastify";
import { toastOptions } from "../../../helpers/toast-options";

// validate.validators.presence.options = { message: 'mandatory field must be filled to proceed' };

// const constraints = {
//   fabricType: {
//     presence: { allowEmpty: false },
//   },
//   fabricStage: { presence: { allowEmpty: false } },

// };

const OrderKate = (props) => {
  let body;
  let rfdNumber = localStorage.getItem("rfqNumber");
  let rfdID = localStorage.getItem("rfqID");
  let stage = stages.RFQ_IN_PRG1;
  const dispatch=useDispatch();
  const linkType=useSelector(state=>state.fabricLinkReducer.linkType)
  // let arr;
  let fabrictype;
  const [arr, setArr] = useState("");
  const [isEditCase, setEditCase] = useState(false);
  const [fabrictypes, setfabrictypes] = useState("");
  const [processType, setProcessType] = useState(null);
  const [processType1, setProcessType1] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectProcess, setSelectProcess] = useState(false);
 

  // let stage = stages.RFQ_IN_PRG1
  const [fabric, setFabric] = useState({
    fabricType: "",
    fabricStage: "",
    errors: false,
  });
  const [selectedFebricType, setselectedFebricType] = useState(null);
  const [selectedFebricStage, setselectedFebricStage] = useState(null);
  const [dataArray, setdataArray] = useState([]);
  const [stagesArray, setstagesArray] = useState([]);
  const [selectValue, setselectValue] = useState("");
  const [data, setData] = useState("");
  const [toastId, setToastId] = useState();

  const [formDetails, setFormDetails] = useState({
    submitted: false,
    errors: null,
  });

  const getFabricTypeApi = () => {
    setIsLoading(true);
    // console.log(props, '//');
    if (props.viewType == "view") {
      localStorage.setItem("rfqID", props.viewData.rfqId);
      // localStorage.removeItem('stageName')
      localStorage.removeItem("rfqNumber");
      // localStorage.setItem('rfqNumber', props.viewData.rfqNumber)
      localStorage.setItem("rfqNumber", props.viewData.rfdNumber);
      localStorage.setItem("orderName", props.viewData.orderName);
      body = {
        id: localStorage.getItem("rfqID"),
        rfqStage: stage,
      };
    } else {
      if (localStorage.getItem("rfqID") != null) {
        body = {
          id: rfdID,
          rfqStage: stage,
        };
      } else {
        body = {
          id: 0,
          rfqStage: stage,
        };
      }
    }
    GetRFQApi(body).then((res) => {
      setIsLoading(true);
      if (res.isSuccess) {
        //
        localStorage.setItem("rfqNumber", res.data.rfdNumber);
        if (res.data.fabricStage !== null && res.data.fabricType !== null) {
          setEditCase(true);
          if(res.data.workType=='FABRIC'){
            dispatch({type:'fabric'})
            if(localStorage.getItem('linkType')!='fabric')
                    localStorage.setItem('linkType','fabric');
          }else{
            dispatch({type:'job_work'})
            if(localStorage.getItem('linkType')!='job_work')
              localStorage.setItem('linkType','job_work');
          }
          if (res.data.fabricStage === "Processed") {
            setSelectProcess(true);
            if (res.data.processType === "Print & Dyed") {
              setProcessType("BOTH");
              setProcessType1("BOTH");
            } else {
              setProcessType(res.data.processType);
              setProcessType1(res.data.processType);
            }
          }
          getStagesApi(res.data.fabricType);
          setselectedFebricType(res.data.fabricType);
          setselectedFebricStage(res.data.fabricStage);
          setfabrictypes(res.data.fabricType);
          setArr(res.data.fabricStage);
          setData(res.data);
          getFabricApi();
        } else {
          // setData(res.data)
          getFabricApi();
        }

        setIsLoading(false);
      }
    });
    setIsLoading(true);
  };

  const submitHandlers = () => {
    let body;
    if (props.linkExisting == true) {
      body = {
        fabricType: fabrictypes,
        fabricStage: arr,
        workType:linkType.toUpperCase(),
        orderName: localStorage.getItem("orderName"),
        type: "LINK",
      };
    } else {
      body = {
        fabricType: fabrictypes,
        fabricStage: arr,
        workType:linkType.toUpperCase(),
        orderName: localStorage.getItem("orderName"),
        type: "NEW",
      };
    }
    if (!localStorage.getItem("rfqID")) {
      body.id = 0;
    } else {
      body.id = localStorage.getItem("rfqID");
    }
    // if (body.fabricType == 'WOVEN' && body.fabricStage != 'Processed') {
    //   body.processType = 'GREIGE'
    // }
    // if (body.fabricType == 'WOVEN' && body.fabricStage == 'Processed') {
    //   body.processType = processType1
    // }
    console.log(body.fabricStage)
    if (body.fabricStage == "Greige") {
      body.processType = "GREIGE";
    }
   else if (body.fabricStage == "Processed") {
      body.processType = processType1;
    }
    else {
      body.processType = "YARNDYED";
    }
    localStorage.setItem("stageName", body.fabricStage);
    if (body.fabricType && body.fabricStage) {
      if (body.fabricStage == "Processed" && body.processType == "") {
        if (!toastId) {
          const id = toast.error("Please select process type", {
            ...toastOptions,
            onClose: () => setToastId(null),
          });
          setToastId(id);
        }
      } else {
        TypeFabricApi(body)
          .then((res) => {
            setIsLoading(true);
            if (res.isSuccess) {
              setIsLoading(false);
              sessionService.setSession("rfqID", res.data.rfqId);

              sessionService.setSession("rfqNumber", res.data.rfqNumber);
              if (rfdNumber) {
                localStorage.setItem("rfqNumber", rfdNumber);
              }
              props.fromAction(1);
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log("error duplicate found");
            localStorage.removeItem("fabricType");
            localStorage.removeItem("orderName");
            localStorage.removeItem("stageName");
            setIsLoading(false);
          });
      }
    } else {
      if (!toastId) {
        const id = toast.error("Please select both values", {
          ...toastOptions,
          onClose: () => setToastId(null),
        });
        setToastId(id);
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("rfqID") != null) {
      getFabricTypeApi();
    } else {
      let type = localStorage.getItem("linkType");
    
      console.log(linkType)
      if (linkType == "job_work") {
        setSelectProcess(true);

        if (isEditCase) {
          setselectedFebricStage(null);
        }
        setFabric({
          ["fabricStage"]: "Processed",
        });
        setselectValue((prev) => ({
          ...prev,
          ["fabricStage"]: "Processed",
        }));
        setArr('Processed');
      }
        getFabricApi();
      
    }
  }, []);

  const handleProcessType = (e) => {
    let input = e.target;
    let name = e.target.name;
    let value = input.value;
    if (value.length == 0) {
      fabric.errors = true;
    }
    if (value.length > 0) {
      fabric.errors = false;
    }
    if (isEditCase) {
      setProcessType(null);
    }
    setProcessType(value);
    setProcessType1(value);
  };

  const handleInputChange = (e) => {
    /// fabricstage
    let input = e.target;
    let name = e.target.name;
    let value = input.value;
    if (value === "Processed") {
      setSelectProcess(true);
    } else {
      setSelectProcess(false);
    }

    if (isEditCase) {
      setselectedFebricStage(null);
    }

    setArr(e.target.value);

    setFabric({
      [name]: value,
    });
    setselectValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleFabricChange = (e) => {
    //fabrictype
   
    let value = e.target.value;
    if (isEditCase) {
      setselectedFebricType(null);
      if(linkType=='fabric'){
      setArr("");
      }
    }

    // fabrictype = value
    setfabrictypes(value);
    localStorage.setItem("fabricType", value);
    
    if(linkType=='fabric'){
      setSelectProcess(false);
      getStagesApi(value);
    document.getElementById("exampleSelectOrder").selectedIndex = 0;
    setselectedFebricStage(null);
    }
  };

  const getFabricApi = () => {
    GetFabricApi().then((res) => {
      if (res.isSuccess) {
        let dataArr = res.data;
       
        let fabricTypeArr = dataArr.map((item) => item.fabricType);
        fabrictype = fabricTypeArr[0];
        setdataArray(fabricTypeArr);
      }
    });
  };
  const getStagesApi = (value) => {
    GetStagesApi(value).then((res) => {
      if (res.isSuccess) {
        let dataArr = res.data.fabricStages;
        // console.log(dataArr, 'dataArr');
        let stagesArr = dataArr.map((item) => item.stageName);
        setstagesArray(stagesArr);
      }
    });
  };

  return (
    <>
      {/* <h4 className="RFQ-h4">{data.name} | RFQ #{rfdNumber}</h4> */}
      <h4 className="RFQ-h4">{localStorage.getItem("orderName")} </h4>

      <Row className="RFQ-Row">
        <div className="RFQ-order">
          <Col sm="6">
            <Card body className="RFQ-orderkate-card">
              <p className="RFQ-Title">Fill out information</p>
              <FormGroup>
                <Label for="exampleOrder" className="order-name">
                  1. Type of Fabric
                </Label>
                <Input
                  className="Rfq-Order-Kate text-capitalize"
                  type="select"
                  name="fabricType"
                  onChange={(event) => handleFabricChange(event)}
                  placeholder="Select fabric type"
                  value={selectedFebricType ? selectedFebricType : null}
                  id="exampleSelectOrder1"
                >
                  <option value="volvo" selected disabled>
                    Select
                  </option>
                  {dataArray.map((item) => (
                    // <a >{`${tab.productName.charAt(0).toUpperCase()}${tab.productName.substring(1).toLowerCase()}`}</a>

                    <option
                      className="text-capitalize"
                      value={item}
                      id="OptionProduct"
                    >{`${item.toLowerCase()}`}</option>
                  ))}
                </Input>
              </FormGroup>
              {/* {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.fabricType && (
                    <small className="form-text text-danger">
                      mandatory field must be filled to proceed
                    </small>
                  )} */}
              {linkType == "fabric" && (
                <>
                  <Label for="exampleOrder" className="stage-label order-name">
                    2. Fabric Stage
                  </Label>
                  <Input
                    className="Rfq-Order-Kate text-capitalizes"
                    onChange={handleInputChange}
                    type="select"
                    name="fabricStage"
                    value={selectedFebricStage ? selectedFebricStage : null}
                    placeholder="Select fabric stage"
                    id="exampleSelectOrder"
                  >
                    <option value="volvo" selected disabled>
                      Select
                    </option>

                    {stagesArray?.map((item) => (
                      <>
                        <option className="text-capitalizes" value={item}>{item=='YarnDyed'?'Yarn Dyed':item}</option>
                        {/* {console.log(item,'item')} */}
                      </>
                    ))}
                  </Input>
                  {/* {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.fabricStage && (
                    <small className="form-text text-danger">
                      mandatory field must be filled to proceed
                    </small>
                  )} */}

                  {/* {console.log(arr, 'item')}
              {console.log(fabrictypes, 'fd')} */}

                  {selectProcess ? (
                    <div className="select-process mt-3 mb-16">
                      <p className="p-select">Select process:</p>
                      <Input
                        className="Rfq-Order-Kate ml-2 w-214"
                        type="select"
                        name="processType"
                        onChange={(e) => handleProcessType(e)}
                        value={processType ? processType : null}
                        placeholder="Select fabric type"
                        id="exampleSelectOrder4"
                      >
                        <option value="volvo" selected disabled>
                          Select
                        </option>
                        <option value="PRINT">Print</option>
                        <option value="DYED">Dyed</option>
                        <option value="BOTH">Both</option>
                      </Input>
                      {/* {fabric.errors == true ?
                    <small className="form-text text-danger">
                      mandatory field must be filled to proceed
                    </small>
                    : null} */}
                    </div>
                  ) : null}
                </>
              )}{" "}
              {linkType == "job_work" && (
                <>
                  <Label for="exampleOrder" className="stage-label order-name">
                  2. Select Job Work
                  </Label>
                  {selectProcess ? (
                      <Input
                        className="Rfq-Order-Kate"
                        type="select"
                        name="processType"
                        onChange={(e) => handleProcessType(e)}
                        value={processType ? processType : null}
                        placeholder="Select fabric type"
                        id="exampleSelectOrder"
                      >
                        <option value="volvo" selected disabled>
                          Select
                        </option>
                        <option value="PRINT">Printing</option>
                        <option value="DYED">Dyeing</option>
                        <option value="BOTH">Printing &#38; Dyeing</option>
                      </Input>
                     
                  
                  ) : null}
                  {/* {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.fabricStage && (
                    <small className="form-text text-danger">
                      mandatory field must be filled to proceed
                    </small>
                  )} */}

                  {/* {console.log(arr, 'item')}
              {console.log(fabrictypes, 'fd')} */}

                  
                </>
              )}
              {/* {console.log(stagesArray,'item')}
                 {
                   stagesArray[1]=="Processed"?
                   <p>hhhhh</p>:null} */}
              <div className="RFQButtons">
                <Button
                  // disabled={!(fabrictypes && arr && (arr == 'Processed' ? processType1 : true))}
                  className="RFQ-Submit mt-16"
                  onClick={submitHandlers}
                >
                  Submit
                </Button>

                {/* <Button className="RFQ-Back" onClick={handleBack}>Back</Button> */}
              </div>
            </Card>
          </Col>
          <NotificationContainer />
          {isLoading ? (
            <>
              <div className="cdf">
                {" "}
                <div className="loader" />
              </div>{" "}
            </>
          ) : null}
        </div>
      </Row>
    </>
  );
};

export default OrderKate;
