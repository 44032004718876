import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import del from '../../../assets/delete.png'
import './Fabric/Fabric.css';
import './Fabric/knit';
import Alert from '../../../assets/alert.jpg'



class DeletPopUp extends React.Component {
  constructor(props) {


    super()
  }
  submit = (props) => {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <p className="Alert">   <img src={Alert} className="alert-product" onClick={this.submit} />Alert !</p>
            <h6 className="AlertNotheading">Are you sure you want to permanently delete this entry?</h6>
            <div className="Alrtbuttons">
              <button onClick={onClose} className="Notdelted">No</button>
              <button className="YesDeleteIt" onClick=
                {() => this.props.handleClickDelete(this.props.id,this.props.type, onClose)}>
                Yes
              </button>
            </div>

          </div>
        );
      }
    });
  };

  render() {

    return (
      <div className='del-container del-new-container'>
        <img src={del} className="DeleteProductcat1" onClick={this.submit} />
      </div>
    );
  }
}

export default DeletPopUp;