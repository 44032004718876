import React, { useState } from 'react';
import { Header2 } from '../SideBar/Header/Header-lgin';
import './SignUp.css';
import SignUpForm from './SignUpForm';
// import Header1 from '../SideBar/Header/Header-lgin';

const SignUp = (props) => {

  return (
    <>
      <Header2 />
      <SignUpForm />

    </>
  );
}

export default SignUp;