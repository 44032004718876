import React, { useEffect, useState } from "react";
import ProfileFianl from "../../../../assets/profile.png";
import "./Preview.css";
import { Link } from "react-router-dom";
import "../CompanyProfile.css";
import { Container, Row, Col } from "react-bootstrap";
import { GetAddressApi } from "../../../../services";

const AddressPre = () => {
  let companyId = localStorage.getItem("companyId");

  const [getfield, setgetfield] = useState([]);

  const getAddressDetails = () => {
    GetAddressApi(companyId).then((res) => {
      if (res.isSuccess) {
        setgetfield(res.data);
      }
    });
  };

  useEffect(() => {
    getAddressDetails();
  }, []);

  return (
    <>
      <Container className="AddressPre">
        <Col className="Addcol">
          <Row className="Addrowpre">
            {getfield.length > 0
              ? getfield.map((val, index) => (
                  <>
                    <div className="d-flex">
                      <p className="m-0 bold loc1">Address - {index + 1}</p>
                      {val.locationType == "CORPORATE_OFFICE" ? (
                        <p className=" m-0 bold loc1">(Corporate Office)</p>
                      ) : (
                        <p className=" m-0 bold loc1">
                         &nbsp; (
                          {`${val.locationType
                            .charAt(0)
                            .toUpperCase()}${val.locationType
                            .split("_")
                            .join(" ")
                            .substring(1)
                            .toLowerCase()}`}
                          )
                        </p>
                      )}
                      {/* <p className=" m-0">({`${val.locationType.charAt(0).toUpperCase()}${val.locationType.split('_').join(' ').substring(1).toLowerCase()}`})</p> */}
                    </div>
                    <p className="address-para">
                      {val.streetAddress}<br/>{val.city}, {val.state}, {val.postalCode}
                    </p>
                  </>
                ))
              : null}
          </Row>
        </Col>
      </Container>
    </>
  );
};

export default AddressPre;
