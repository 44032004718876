import React, { useEffect, useState } from 'react';
import './Resetpw.css';
import { Col, Row, Button, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Header2 } from '../SideBar/Header/Header-lgin';
import validate from 'validate.js';
import { useDispatch } from 'react-redux';
import { ForgetPasswordActionCreator } from '../../actions/user.actions';
// import { Success } from '../Alert';
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { ForgotPwApi } from '../../services';

import { Errors, Success } from '../Alert'
import { toast } from 'react-toastify';
import {toastOptions} from "../../helpers/toast-options";

validate.validators.presence.options = { message: '^mandatory field must be filled to proceed' };

const constraints = {
  email: {
    presence: { allowEmpty: false },
    format: {
      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      flags: "i",
      message: "invalid "
    }

  }
}

const ForgotPw = (props) => {

  const history = useHistory();
  const dispatch = useDispatch();


  const [useremail, setUserEmail] = useState({
    email: ''
  })
  const [isLoading, setisLoading] = useState(false)
  const [toastId, setToastId] = useState();

  const [formDetails, setFormDetails] = useState({
    submitted: false,
    errors: null
  });

  //state defined
  useEffect(() => {
    const errors = validate(useremail, constraints);
    setFormDetails((prev) => ({
      ...prev,
      errors
    }));
  }, [useremail]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserEmail((prev) => ({
      ...prev,
      [name]: value
    }));
  }


  const submitHandler = (event) => {
    event.preventDefault();
    setFormDetails((prev) => ({
      ...prev,
      submitted: true
    }));
    if(formDetails.errors){
      const currentError = Object.keys(formDetails.errors).map(key => formDetails.errors[key][0]);
      if(!toastId){
        const id = toast.error(currentError[0], {...toastOptions, onClose: ()=> setToastId(null)});
        setToastId(id);
      }
      return;
    }
    if (!formDetails.errors) {
      setisLoading(true)

      ForgotPwApi(useremail.email).then((res) => {
        setisLoading(false)
        if (res.isSuccess) {
          if(!toastId){
            const id = toast.success(res.message,
              {...toastOptions, style:{background: "#07bc0c"}, onClose: ()=> setToastId(null)});
            setToastId(id);
          }
          history.push(`/`);

        }
      }).catch((res) => {
        setisLoading(false)

        // console.log(res)
        // Errors('User does not exist!')
      })
    }


    //   if (useremail.email) {

    //     dispatch(ForgetPasswordActionCreator(useremail.email));
    //     if (useremail.email.isSuccess) {
    //        
    //       Success("Password sent to the email id successfully")
    //     history.push(`/`);
    //     }
    // }

    // API
  };
  const clickHandler = () => {

  }

  return (
    <>
      <Row>
        <Header2 />
      </Row>
      <Form className="login-form" onSubmit={submitHandler}>
        <Row>
          <Col>
            <h5 className='headingLogin'>Reset Password</h5>
            <FormGroup className="Loginform">
              <Label className="required" for="Firstname">User Id / Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your registered User id or email id"
                value={useremail.email}
                onChange={handleChange}

              />
              {/* {formDetails.submitted &&
                formDetails.errors &&
                formDetails.errors.email && (
                  <small className="form-text text-danger">
                    {formDetails.errors.email[0]}
                  </small>
                )} */}
            </FormGroup>

            <Button color="primary" disabled={isLoading} className='login-btn1' type="submit">

              {isLoading ? <Spinner></Spinner> : 'Reset'}
            </Button>
            <Link to="/" className="link-forgetps" onClick={clickHandler}>Log in Instead </Link>

          </Col>
          <NotificationContainer />

        </Row>
      </Form>
    </>
  )
}

export default ForgotPw;