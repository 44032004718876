import React, { useEffect, useState } from "react";
import { RfqHeader } from "../SideBar/Header/Header";
import { CompnayProfilSidebar } from "../SideBar/Profile/profile";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Button,
  Card,
} from "reactstrap";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import RfqDyed from "./New/Rfqdyed";
// import RfqOrder from './New/RfqOrder';
import CreateOrder from "./New/NewOrder";
import OrderKateweave from "./New/OrderkateWeave";
import OrderKate from "./New/OrderKate";
import OrderWeave from "./New/OrderWeave";
import OrdereWeaveNext from "./New/OrderWeavenext";
import RfqDyed13 from "./New/RfqDyed13";
import RfqDyed14 from "./New/Rfqdyed14";
import SavedRfq from "./SavedRfq";
import { GetRFQApi } from "../../services";
import { useSelector } from "react-redux";
import { deleteRfqApi, GetSavedRFQApi } from "../../services";
import InfiniteScroll from "react-infinite-scroll-component";
import uuid from "react-uuid";
import { useDispatch } from 'react-redux';
import { getUserInfoAction } from '../../actions/user.actions'

import DeleteRfq from "./DeletRfq";
import { stages } from "../../models/enum";
import Cookies from "js-cookie";
import LinkOrder from "./New/LinkOrder";
import "./rfq.css";



const Rfq = (props) => {
  
  const dispatch = useDispatch()
  let step;
  const [viewedRfq, setViewedRfq] = useState(false);
  const [viewtype, setviewtype] = useState("");
  const [viewdata, setviewdata] = useState({});
  const [viewStep, setViewStep] = useState("");
  const [viewSteps, setViewSteps] = useState(true);
  const [saved, setsaved] = useState([]);
  const [total, setTotal] = useState(0);
  const [pno, setPno] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  let token = localStorage.getItem("token");
  const user = useSelector((state) => state.userReducer);
  const [startNewRFQ, setStartNewRFQ] = useState(false);
  let getStage;
  const history = useHistory();
  const link = useSelector(state => state.fabricLinkReducer);
  useEffect(() => {
    
    if (token && Cookies.get("token")) {
      if (user.data.accountType == "ADMIN_SELLER") {
        history.push(`/companyprofile`);
      } else if (user.data.accountType == "FABTRACK_ADMIN") {
        history.push(`/newrequest`);
      }
    } else {
      history.push(`/`);
    }

    let userId=localStorage.getItem('id');
    dispatch(getUserInfoAction(userId));
  },[]);
  useEffect(()=>{
    toggle('1');
    console.log('url changes')
  },[history])
  const [toggles, setToggles] = useState(true);
  const [getNewOrder, setGetNewOrder] = useState("");

  const [stage, setStage] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [currentCustomizationData, setCurrentCustomizationData] = useState();

  const handleClick = (body) => {
    if (startNewRFQ || linked) {
      setviewdata({});
    }
    setCurrentCustomizationData({print: body.printCustomizationDTOS, dyed: body.dyeCustomizationDTOS});
    setCurrentStep(currentStep + 1);
  };
  const handleClick2 = () => {
    setCurrentStep(currentStep + 2);
  };
  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };
  const handleBack2 = () => {
    setCurrentStep(currentStep - 2);
  };
  const handleBack3 = () => {
    setCurrentStep(currentStep - 3);
  };
  const handleBack4 = () => {
    setCurrentStep(currentStep - 4);
  };
  const handleBack5 = () => {
    setCurrentStep(currentStep - 5);
  };
  const handleBack6 = () => {
    setCurrentStep(currentStep - 6);
  };
  const handleNewOrder = () => {
    setviewdata({});
    localStorage.removeItem("orderName");
    localStorage.removeItem("stageName");
    localStorage.removeItem("rfqNumber");
    localStorage.removeItem("rfqID");
    setLinked(false);
    setGetNewOrder(true);
    // setPno((pre) => ({ ...pre, 0 }))
    setsaved([])
    setViewedRfq(false);
    setPno(0);
    setviewtype('new');
    setCurrentStep(1);
  };

  const [activeTab, setActiveTab] = useState("1");
  const [linked, setLinked] = useState(false);
  const [newOne, setNewOne] = useState(false);
  const toggle = async (tab) => {
    // setPno((pre) => ({ ...pre, 0 }))
    setsaved([])
    setViewedRfq(false);
    setPno(0);
    setviewtype('new');

    setToggles(true);
    if (activeTab !== tab) setActiveTab(tab);
    localStorage.setItem("activeTab", tab);

    if (tab == "2") {
      let page = 0;
      // fetchData1();
      const res = await GetSavedRFQApi(page);

      if (res.isSuccess) {
        console.log(link)
        if(link.linkType=='fabric'){
          res.data.rfq=res.data.rfq.filter(r=>r.workType=='FABRIC')
        }else{
          res.data.rfq=res.data.rfq.filter(r=>r.workType=='JOB_WORK')

        }
        setsaved([...res.data.rfq]);
        setTotal(res.data.total);
        setPno(pno + 1);
        console.log(saved,total,pno)

      }
    } else {
      setActiveTab("1");
      localStorage.removeItem("orderName");
      localStorage.removeItem("stageName");
      localStorage.removeItem("rfqNumber");
      localStorage.removeItem("rfqID");

      setCurrentStep(0);
    }
  };

  const getStageApi = () => {
    let stage = stages.RFQ_IN_PRG0;

    let body = {
      id: localStorage.getItem("rfqID"),
      rfqStage: stage,
    };
    GetRFQApi(body).then((res) => {
      if (res.isSuccess) {
        if(res.data.latestStage==null){
          localStorage.removeItem("orderName");
          localStorage.removeItem("activeTab");
          localStorage.removeItem("stageName");
          localStorage.removeItem("rfqNumber");
          localStorage.removeItem("rfqID");
          return;
        }
        let step1 = res.data.latestStage.split("");
        let viewStep1 = step1[step1.length - 1];

        if (viewStep1 == 3 && localStorage.getItem("stageName") == "greige") {
          setCurrentStep(Number(viewStep1) + 3);
        } else {
          setCurrentStep(Number(viewStep1) + 2);
        }
      }
    });
  };

  useEffect(() => {

    if (token && Cookies.get("token")) {
      if (
        localStorage.getItem("activeTab") == "1" &&
        localStorage.getItem("stageName")
      ) {
        getStageApi();
      } 
      //else if (localStorage.getItem("activeTab") == "2") {
        
      //   setPno(0);

      //   setActiveTab("2");
      //   step = stage.split("");
      //   setViewStep(step[step.length - 1]);
      //   GetSavedRFQApi(pno).then((res) => {
      //     if (res.isSuccess) {
      //       setsaved([...res.data.rfq]);
      //       setTotal(res.data.total);
      //       setPno(pno + 1);
      //     }
      //   });
        
      // } 
      else {
        return () => {
          localStorage.removeItem("orderName");
          localStorage.removeItem("stageName");
          localStorage.removeItem("rfqNumber");
          localStorage.removeItem("rfqID");
        };
      }
    } else {
      history.push(`/`);
    }
  }, []);

  const isLinked = (e) => {
    setLinked(e);
  };
  const newOrder1 = (e) => {
    setNewOne(e);
  };
  ///saved rfq

  const fetchData1 = () => {
    console.log(total,pno,'scroll')
    if (activeTab === "2" && total > pno * 10) {
      GetSavedRFQApi(pno).then((res) => {
        if (res.isSuccess) {
          if(link.linkType=='fabric'){
            res.data.rfq=res.data.rfq.filter(r=>r.workType=='FABRIC')
          }else{
            res.data.rfq=res.data.rfq.filter(r=>r.workType=='JOB_WORK')
  
          }
          setsaved([...saved, ...res.data.rfq]);
          setPno(pno + 1);
        }
      });
    }
  };

  const viewRfqHandler = (data, type) => {
    setViewedRfq(true);
    setLinked(false)
    setStartNewRFQ(false)
    step = data.rfqStages.split("");
    setViewStep(step[step.length - 1]);
    setStage(data.rfqStages);
    //setLinked(false)
    // props.fromAction();

    if (Number(step[step.length - 1]) == 3 && data.fabricStage == "Greige") {
      setCurrentStep(Number(step[step.length - 1]) + 3);
    } else {
      setCurrentStep(Number(step[step.length - 1]) + 2);
    }

    // props.steps(viewStep, localStorage.getItem('stageName'))
    localStorage.setItem("stageName", data.fabricStage);
    // props.stage(data.fabricStage)
    localStorage.setItem("orderName", data.orderName);
    localStorage.setItem("rfqNumber", data.rfqNumber);
    // props.toggle("1")
    setViewSteps(true);
    setActiveTab("1");
    // props.data(data)
    setviewdata(data);
    setviewtype(type);
    // props.type(type)
    if(data){
      let body = {
        id: data.rfqId,
        rfqStage: data.rfqStages,
      };
      GetRFQApi(body).then((res) => {
        if (res.isSuccess) {
          setCurrentCustomizationData({print: res.data.printCustomizations, dyed: res.data.dyeCustomizations});
        }
      });
    }
    
  };

  const  deleteRfq = (id, close) => {
    setIsLoading(true);
    deleteRfqApi(id).then(async (res) => {
      if (res.isSuccess) {
        const res = await GetSavedRFQApi(0);

        if (res.isSuccess) {
          console.log(link)
          if(link.linkType=='fabric'){
            res.data.rfq=res.data.rfq.filter(r=>r.workType=='FABRIC')
          }else{
            res.data.rfq=res.data.rfq.filter(r=>r.workType=='JOB_WORK')
  
          }
          setsaved([...res.data.rfq]);
          setTotal(res.data.total);
          // setPno(pno + 1);
          console.log(saved,total,pno)
  
        }
        close();
      }
    });
  };
  const newOrder = (e) => {
    setGetNewOrder(e);
  };
  return (
    <>
      <RfqHeader
      fromtoogle={toggle}
      />

      <Row className="m-0">
        <Col sm="2" className="sidenav p-0">
          <CompnayProfilSidebar />
        </Col>
        <Col sm="10" className="rightcontent">
          <Nav tabs className="admin-tab greybox">
            <NavItem>
              {viewedRfq ? (
                <NavLink
                  className={classnames({ active: false })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  New RFQ
                </NavLink>
              ) : (
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  New RFQ
                </NavLink>
              )}
            </NavItem>
            <NavItem>
              {viewedRfq ? (
                <NavLink
                  className={classnames({ active: true })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Saved RFQ
                </NavLink>
              ) : (
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Saved RFQ
                </NavLink>
              )}
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {currentStep == 2 ||
              currentStep == 3 ||
              currentStep == 4 ||
              currentStep == 5 ||
              currentStep == 6 ||
              currentStep == 7 ? (
                <Button className="newOrder" onClick={handleNewOrder}>
                  Create new RFQ
                </Button>
              ) : null}
              {currentStep == 0 ? (
                <RfqDyed
                  fromAction={handleClick}
                  newForm={setStartNewRFQ}
                  newOrder={(e) => newOrder(e)}
                />
              ) :
              currentStep == 1 && getNewOrder == true ? (
                <CreateOrder
                  fromAction={handleClick}
                  fromBack={handleBack}
                  linked={(e) => isLinked(e)}
                  viewData={viewdata}
                  viewType={viewtype}
                  newOrder1={(e) => newOrder1(e)}
                />
              ) : currentStep == 1 && getNewOrder == false ? (
                <LinkOrder
                  fromAction={handleClick}
                  fromBack={handleBack}
                  linked={(e) => isLinked(e)}
                />
              ) :

              currentStep == 2 ? (
                <OrderKate
                  fromAction={handleClick}
                  fromBack={handleBack}
                  viewData={viewdata}
                  viewType={viewtype}
                  linkExisting={linked}
                  linkNew={newOne}
                />
              ) :
              currentStep == 3 ? (
                <OrderWeave
                  fromAction={handleClick}
                  fromBack={handleBack}
                  viewData={viewdata}
                  viewType={viewtype}
                />
              ) :
              currentStep == 4 ? (
                <OrderKateweave
                  fromAction={handleClick}
                  fromBack={handleBack}
                  fromBack2={handleBack2}
                  viewData={viewdata}
                  viewType={viewtype}
                  fromActions={handleClick2}
                />
              ) :
              currentStep == 5 ? (
                
                <OrdereWeaveNext
                  fromAction={handleClick}
                  fromBack={handleBack}
                  fromBack2={handleBack2}
                  fromBack3={handleBack3}
                  viewData={viewdata}
                  viewType={viewtype}
                />
              ) :
              currentStep == 6 ? (
                <RfqDyed13
                  fromAction={handleClick}
                  fromBack={handleBack}
                  fromBack2={handleBack2}
                  fromBack3={handleBack3}
                  viewData={viewdata}
                  viewType={viewtype}
                  fromBack4={handleBack4}
                  customizationData={currentCustomizationData}
                />
              ) :
              currentStep == 7 ? (
                <RfqDyed14
                  fromAction={handleClick}
                  fromBack={handleBack}
                  fromBack2={handleBack2}
                  fromBack3={handleBack3}
                  fromBack4={handleBack4}
                  viewData={viewdata}
                  viewType={viewtype}
                  fromBack5={handleBack5}
                  fromBack6={handleBack6}
                  customizationData={currentCustomizationData}
                  />
              ) : 
              <RfqDyed
                  fromAction={handleClick}
                  newForm={setStartNewRFQ}
                  newOrder={(e) => newOrder(e)}
                />
              }
            </TabPane>
            <TabPane tabId="2">
              <>
                {/* <Row className="RFQ-Row-saved"> */}
                  <div className="RFQ-Row " >
                    <Col sm="12" >
                      <div className="inline-div-saved">
                        <p>Sr. No. </p>
                        <p className="date-saved">Date Saved</p>
                        <p className="particulars">Particulars</p>
                      </div>

                      {saved.length > 0 ?
                      <div style={{
                        height: '80vh',
                        overflow: 'auto',
                       
                      }}
                      id="scrollableDiv">
                      <InfiniteScroll
                        dataLength={saved.length} //This is important field to render the next data
                        next={fetchData1}
                        hasMore={true}
                        className="RFQ-Card-saved"
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            <b>Yay! You have seen it all</b>
                          </p>
                        }
                        scrollableTarget="scrollableDiv"
                        
                      >
                        {
                          saved?.map((data, id) => (
                            <Card body className="saved-card" key={uuid()}>
                              <div className="saved-div" >
                                <p className="mlr-3">{id + 1}</p>
                                <p className="ml-neg">{data.createdDate}</p>
                                <p
                                  className="created-data mb-0"
                                  style={{ marginLeft: "12px" }}
                                >
                                  {data.workType=='FABRIC'?data.particular:data.particular+' | Job Works' }
                                </p>
                                <div
                                  className="button-align"
                                  style={{ width: "135px" }}
                                >
                                  <Button
                                    className="BusinessNext-btn "
                                    style={{ width: "7.5vw" }}
                                    color="primary"
                                    onClick={() => viewRfqHandler(data, "view")}
                                  >
                                    View
                                  </Button>
                                  <DeleteRfq
                                    handleClickDelete={deleteRfq}
                                    id={data.rfqId}
                                  />
                                </div>
                              </div>
                            </Card>
                          ))
                        }
                      </InfiniteScroll>
                      </div>
                      :
                        <div style={{marginTop: "10px"}}>No saved RFQ found.</div>
                      }
                    </Col>
                  </div>
                {/* </Row> */}
              </>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </>
  );
};

export default Rfq;
