import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Col, FormGroup, Label, Input } from "reactstrap";
import "./Rfq.css";
import plus from "../../../assets/plus.svg";
import edit from "../../../assets/edit.png";
import delicon from "../../../assets/delete.png";
import { Link } from "react-router-dom";
import {
  AddFabricCustomizationApi,
  deleteRfqCustomDyedApi,
  deleteRfqCustomPrintApi,
  FileUploadApi,
  GetRFQApi,
} from "../../../services";
import { stages } from "../../../models/enum";
import { Errors, Success } from "../../Alert";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import validate from "validate.js";
import { toast } from "react-toastify";
import save from "../../../assets/save.png";
import uuid from "react-uuid";
import { toastOptions } from "../../../helpers/toast-options";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { FileUpload } from "./FileUpload";

validate.validators.presence.options = {
  message: "^mandatory field must be filled to proceed",
};

const constraints = {

  printName: {
    presence: { allowEmpty: false },

    length: {
      minimum: 1,
      maximum: 30,
    },
  },
  imageUrl: {
    presence: { allowEmpty: false },
  },
};
const constraintsColor = {
  colourName: {
    presence: { allowEmpty: false },

    length: {
      minimum: 1,
      maximum: 30,
      message: "must be between 1 and 30 charaters",
    },
  },

};

const OrdereWeaveNext = (props) => {
  let rfdID = localStorage.getItem("rfqID");
  const dispatch = useDispatch();
  const linkType = useSelector(state => state.fabricLinkReducer.linkType)
  const uploadedFiles = useSelector(state => state.uploadCustfileReducer.files)
  // let stage = stages.RFQ_IN_PRG3 GREIGE
  let stage = stages.RFQ_IN_PRG3; //PRINT & DYED
  let rfdNumber = localStorage.getItem("rfqNumber");

  let imageUrl;
  let body;
  // let arr = []

  const [showControls, setshowControls] = useState(false);
  const [showControlsColor, setshowControlsColor] = useState(false);
  const [showControlsColorWeft, setshowControlsColorWeft] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileModel, setfileModel] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const warpQtxUpload = useRef()
  const weftQtxUpload = useRef()

  const [fieldsColor, setFieldsColor] = useState([]);
  const [fieldsColorweft, setFieldsColorweft] = useState([]);
  const [colorFields, setColorFields] = useState({
    id: "",
    colourName: "",
    colourCode: "",
    quantity: "",
    otxFile: "",
    imageName: "",
    customisationType: "Warp"
  });
  const [colorFieldsWeft, setColorFieldsWeft] = useState({
    id: "",
    colourName: "",
    colourCode: "",
    quantity: "",
    otxFile: "",
    imageName: "",
    customisationType: "Weft"
  });

  const [fields, setFields] = useState([]);
  const [controlsData, setcontrolsData] = useState({
    imageUrl: "",
    imageName: "",
    printName: "",
    quantity: "",
    // clientImageUrl: ''
  });
  const [yarnDyedCustomisationDTOS, setyarnDyedCustomisationDTOS] = useState({
    warpNoOfColour: '',
    weftNoOfColour: '',
    yarnDyedColours: [{
      colourName: '',
      qtxFile: '',
      pantoneCode: '',
      customisationType: ''
    }],
    yarnDyedImages: [{
      imageUrl: '',
      imageName: '',
      imageSize: ''
    }]
  })
  const [formDetails, setFormDetails] = useState({
    submitted: false,
    errors: null,
  });
  const [type, setType] = useState("");
  const [fabricstage, setfabricstage] = useState("");
  const [weave, setWeave] = useState("");
  const [toastId, setToastId] = useState();
  const [valueGet, setValueGet] = useState({
    warpCount: "",
    warpTypeId: "",
    weftComment: "",
    weftCount: "",
    weftTypeId: "",
    warpComment: "",
    fabricConstructionComment: "",
    gsmValue: "",
    processType: "",
    fabricReed: "",
    fabricPick: "",
    dyedWidth: "",
    printWidth: "",
    printCustomizations: [],
    dyeCustomizations: [],
    warpYarnDTOList: [],
    weftYarnDTOList: [],
    jobWorkStartDate: '',
    jobWorkEndDate: ''
  });
  const fun = (e) => {
    if (e.keyCode === 190 && e.target["value"].split(".").length === 2) {
      return false;
    }

    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) || // Cmd+X (Mac)
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    if (e.keyCode == 190) {
      return;
    }
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  };

  const getFabricTypeApi = (retainedprints = [], retainedColors = []) => {
    setIsLoading(true);

    if (props.viewType == "view") {
      localStorage.setItem("rfqID", props.viewData.rfqId);
      // localStorage.removeItem('stageName')

      localStorage.removeItem("rfqNumber");
      // localStorage.setItem('rfqNumber', props.viewData.rfqNumber)
      localStorage.setItem("rfqNumber", props.viewData.rfqNumber);

      body = {
        id: localStorage.getItem("rfqID"),
        rfqStage: stage,
      };
    } else {
      body = {
        id: rfdID,
        rfqStage: stage,
      };
    }

    GetRFQApi(body).then((res) => {
      if (res.isSuccess) {
        setType(res.data.fabricType);
        setfabricstage(res.data.fabricStage);
        setWeave(res.data.weaveType);
        setValueGet(res.data);
        console.log(res.data.yarnDyedCustomisationDTO?.yarnDyedImages)
       let images=res.data.yarnDyedCustomisationDTO?.yarnDyedImages?
       res.data.yarnDyedCustomisationDTO?.yarnDyedImages:[];
       console.log(images)
        dispatch({type:'ADD_ALL_IMAGES',payload:images})
        setyarnDyedCustomisationDTOS(res.data.yarnDyedCustomisationDTO)
        if(res.data.fabricStage=='YarnDyed' && res.data.yarnDyedCustomisationDTO?.yarnDyedColours){
          setFieldsColor(res.data.yarnDyedCustomisationDTO?.yarnDyedColours.filter(c=>c.customisationType=='WARP'))
          setFieldsColorweft(res.data.yarnDyedCustomisationDTO?.yarnDyedColours.filter(c=>c.customisationType=='WEFT'))
        }else{
        retainedColors.length > 0 ? setFieldsColor(retainedColors) : setFieldsColor(res.data.dyeCustomizations)
        }
        console.log(res.data.workType)
        if (res.data.workType == 'FABRIC') {

          dispatch({ type: 'fabric' })
          if (localStorage.getItem('linkType') != 'fabric')
            localStorage.setItem('linkType', 'fabric');

        } else {

          dispatch({ type: 'job_work' })
          if (localStorage.getItem('linkType') != 'job_work')
            localStorage.setItem('linkType', 'job_work');


        }
        // setFields(res.data.printCustomizations);
        retainedprints.length > 0 ? setFields(retainedprints) : setFields(res.data.printCustomizations)
        // setFieldsColor(res.data.dyeCustomizations);
        setIsLoading(false);
      }
    });
  };
  useEffect(() => {
    const errors = validate(controlsData, constraints);
    // if (formDetails.submitted){
    setFormDetails((prev) => ({
      ...prev,
      errors,
    }));
    // }
  }, [controlsData]);
  useEffect(() => {
    const errors = validate(colorFields, constraintsColor);
    // if (formDetails.submitted){
    setFormDetails((prev) => ({
      ...prev,
      errors,
    }));
    // }
  }, [colorFields]);
  useEffect(() => {
    const errors = validate(colorFieldsWeft, constraintsColor);
    // if (formDetails.submitted){
    console.log(errors)
    setFormDetails((prev) => ({
      ...prev,
      errors,
    }));
    // }
  }, [colorFieldsWeft]);

  useEffect(() => {
    getFabricTypeApi(fields, fieldsColor);
  }, []);

  function handleChange(event, type) {
    if (type == "printName") {
      setcontrolsData({ ...controlsData, printName: event.target.value });
    }
    if (type == "quantity") {
      setcontrolsData({ ...controlsData, quantity: event.target.value });
    }
  }
  function handleColorChange(event, type) {
    if (type == "colourName") {
      setColorFields({ ...colorFields, colourName: event.target.value });
    }
    if (type == "colourCode") {
      setColorFields({ ...colorFields, colourCode: event.target.value,pantoneCode:event.target.value });
    }
    if (type == "quantity") {
      setColorFields({ ...colorFields, quantity: event.target.value });
    }
  }
  function handleColorChangeweft(event, type) {
    if (type == "colourName") {
      setColorFieldsWeft({ ...colorFieldsWeft, colourName: event.target.value });
    }
    if (type == "colourCode") {
      setColorFieldsWeft({ ...colorFieldsWeft, colourCode: event.target.value,pantoneCode:event.target.value });
    }

  }

  function handleCustChange(event, type) {
    if (type == 'weftNoOfColour') {
      setyarnDyedCustomisationDTOS({
        ...yarnDyedCustomisationDTOS,
        weftNoOfColour: event.target.value
      })
    } else {
      setyarnDyedCustomisationDTOS({
        ...yarnDyedCustomisationDTOS,
        warpNoOfColour: event.target.value
      })
    }

    console.log(yarnDyedCustomisationDTOS)
  }


  function handleAdd() {
    setshowControlsColor(false);
    setshowControls(true);
    setcontrolsData({ imageUrl: "", printName: "", quantity: "", imageName: "" });
    setFormDetails((prev) => ({
      ...prev,
      submitted: false,
    }));
  }
  function submitHandlerPrint() {
    setFormDetails((prev) => ({
      ...prev,
      submitted: true,
    }));
    // setcontrolsData({ imageUrl: "", printName: "", quantity: "" });
    if (!formDetails.errors) {
      if (fields.length < 10) {
        if (fields.length == 0) {
          if (controlsData.printName != "") {
            setFields((pre) => [...pre, controlsData]);
          }
          setshowControls(false);
        } else {
          if (controlsData.printName != "") {
            setFields((pre) => [...pre, controlsData]);
          }
          setshowControls(false);
        }
      } else {
        if (!toastId) {
          const id = toast.error("you have exceeded the maximum number of entries", { ...toastOptions, onClose: () => setToastId(null) });
          setToastId(id);
        }
      }
    }
    // setFormDetails({ errors: null })
    // setshowControls(false);
  }

  function handleColorAdd() {
    if(!showControlsColor){
    setshowControls(false);
    setshowControlsColor(true);
    setColorFields({ id: "", colourName: "", colourCode: "", quantity: "", otxFile: "", imageName: "" });
    setFormDetails((prev) => ({
      ...prev,
      submitted: false,
    }));
  }
  }
  function handleColorAddWeft() {
    if(!showControlsColorWeft){
    setshowControls(false);
    setshowControlsColorWeft(true);
    setColorFieldsWeft({ id: "", colourName: "", colourCode: "", quantity: "", otxFile: "", imageName: "" });
    setFormDetails((prev) => ({
      ...prev,
      submitted: false,
    }));
  }
  }
  function submitHandlerColor() {
    console.log('submit color')
    setFormDetails((prev) => ({
      ...prev,
      submitted: true,
    }));
    // setColorFields({ colourName: "", colourCode: "", quantity: "" });

    if (!formDetails.errors) {
      if (fields.length < 11) {
        if (fieldsColor.length == 0) {
          if (colorFields.colourName != "") {
            setFieldsColor((pre) => [
              ...pre,
              { ...colorFields, id: "temp-" + uuid() },
            ]);
          }
          setshowControlsColor(false);
        } else {
          if (colorFields.colourName != "") {
            setFieldsColor((pre) => [
              ...pre,
              { ...colorFields, id: "temp-" + uuid() },
            ]);
          }

          setshowControlsColor(false);
        }
      } else {
        if (!toastId) {
          const id = toast.error("you have exceeded the maximum number of entries", { ...toastOptions, onClose: () => setToastId(null) });
          setToastId(id);
        }
      }
    }
  }
  function submitHandlerColorWeft() {
    console.log('submit color')
    setFormDetails((prev) => ({
      ...prev,
      submitted: true,
    }));
    // setColorFields({ colourName: "", colourCode: "", quantity: "" });

    if (!formDetails.errors) {
      if (fields.length < 11) {
        if (fieldsColorweft.length == 0) {
          if (colorFieldsWeft.colourName != "") {
            setFieldsColorweft((pre) => [
              ...pre,
              { ...colorFieldsWeft, id: "temp-" + uuid() },
            ]);
          }
          setshowControlsColorWeft(false);
        } else {
          if (colorFieldsWeft.colourName != "") {
            setFieldsColorweft((pre) => [
              ...pre,
              { ...colorFieldsWeft, id: "temp-" + uuid() },
            ]);
          }

          setshowControlsColorWeft(false);
        }
      } else {
        if (!toastId) {
          const id = toast.error("you have exceeded the maximum number of entries", { ...toastOptions, onClose: () => setToastId(null) });
          setToastId(id);
        }
      }
    }
  }

  function handleRemoveField(i, idx) {
    fields.forEach((val, index, array) => {
      if (idx === index) {
        array.splice(index, 1);
      }
    });
    if (i == undefined) {
      setFields([...fields]);
    } else {
      deleteRfqCustomPrintApi(i).then((res) => {
        if (res.isSuccess) {
          getFabricTypeApi([...fields]);
          // setFields([...fields]);
        }
      });
    }
  }
  function handleRemove(i, idx) {
    if (i == undefined) {
      fields.forEach((val, index, array) => {
        if (idx === index) {
          array.splice(index, 1);
        }
      });
      setFields([...fields]);
    } else {
      deleteRfqCustomDyedApi(i).then((res) => {
        if (res.isSuccess) {
          getFabricTypeApi();
          // setFieldsColor([...fieldsColor]);
        }
      });
    }
  }
  function handleColorRemove(i, idx) {
    if (i != 0) {
      let tempId = i.toString().search("temp-");
      const newCol = fieldsColor.filter((color) => {
        return color.id !== i;
      });
      if (tempId !== -1) {
        setFieldsColor(newCol);
      } else {
        deleteRfqCustomDyedApi(i).then((res) => {
          if (res.isSuccess) {
            getFabricTypeApi(fields, newCol);
            // setFieldsColor([...fieldsColor]);
          }
        });
      }
    }
    else {
      const newCol = fieldsColor.filter((color) => {
        return color.id !== 0;
      })
      setFieldsColor(newCol);
    }
  }
  function handleColorRemoveWeft(i, idx) {
    if (i != 0) {
      let tempId = i.toString().search("temp-");
      const newCol = fieldsColorweft.filter((color) => {
        return color.id !== i;
      });
      if (tempId !== -1) {
        setFieldsColorweft(newCol);
      }
    }
    else {
      const newCol = fieldsColorweft.filter((color) => {
        return color.id !== 0;
      })
      setFieldsColor(newCol);
    }
  }
  function romanize(num) {
    if (isNaN(num)) return NaN;
    var digits = String(+num).split(""),
      key = [
        "",
        "c",
        "cc",
        "ccc",
        "cd",
        "d",
        "dc",
        "dcc",
        "dccc",
        "cm",
        "",
        "x",
        "xx",
        "xxx",
        "xl",
        "l",
        "lx",
        "lxx",
        "lxxx",
        "xc",
        "",
        "i",
        "ii",
        "iii",
        "iv",
        "v",
        "vi",
        "vii",
        "viii",
        "ix",
      ],
      roman = "",
      i = 3;
    while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
  }
  const handlePicChange = async (e, type) => {
    setIsLoadingImg(true);
    const files = e.target.files;

    let fileImage = e.target.files[0];
    if (files !== null && files.length) {
      if (files[0].size <= 10485760) {
        let formData = new FormData();
        formData.append("file", fileImage);

        await FileUploadApi(formData).then((data) => {
          if (data.isSuccess) {
            setIsLoadingImg(false);
            imageUrl = data.data;
            if (type == 'imageUrl') {
              setcontrolsData({ ...controlsData, imageUrl: imageUrl, imageName: fileImage.name });
            } else {
              setColorFields({ ...colorFields, otxFile: imageUrl, imageName: fileImage.name });
            }
            console.log(colorFields)
          }
        });
      } else {
        if (!toastId) {
          const id = toast.error("Image size exceeds the limit of 10 mb", { ...toastOptions, onClose: () => setToastId(null) });
          setToastId(id);
        }
      }
    }
  };
  const removeotxFile = (type) => {
    if (type == 'weft') {
      setColorFieldsWeft({
        ...colorFieldsWeft,
        otxFile: ''

      })
    } else {
      setColorFields({ ...colorFields, otxFile: '' })
    }
  }
  const handlePicChangeweft = async (e, type) => {
    setIsLoadingImg(true);
    const files = e.target.files;

    let fileImage = e.target.files[0];
    if (files !== null && files.length) {
      if (files[0].size <= 10485760) {
        let formData = new FormData();
        formData.append("file", fileImage);

        await FileUploadApi(formData).then((data) => {
          if (data.isSuccess) {
            setIsLoadingImg(false);
            imageUrl = data.data;

            setColorFieldsWeft({ ...colorFieldsWeft, otxFile: imageUrl, imageName: fileImage.name });

            console.log(colorFields)
          }
        });
      } else {
        if (!toastId) {
          const id = toast.error("Image size exceeds the limit of 10 mb", { ...toastOptions, onClose: () => setToastId(null) });
          setToastId(id);
        }
      }
    }
  };
  const submitHandler = async () => {
    let isdupcolor = false;
    let isdupprint = false;
    for (let index = 0; index < fieldsColor.length; index++) {
      for (let i = 0; i < fieldsColor.length; i++) {
        if (index != i) {
          if (fieldsColor[index].colourName == fieldsColor[i].colourName &&
            fieldsColor[index].colourCode == fieldsColor[i].colourCode) {
            isdupcolor = true;
          }
        }
        if (isdupcolor) {
          break;
        }
      }
      if (isdupcolor) {
        break;
      }
    }
    for (let index = 0; index < fields.length; index++) {
      for (let i = 0; i < fields.length; i++) {
        if (index != i) {
          if (fields[index].printName == fields[i].printName) {
            isdupprint = true;
          }
        }
        if (isdupprint) {
          break;
        }
      }
      if (isdupprint) {
        break;
      }
    }
    if (isdupcolor) {
      console.log(toastId)
      if (toastId) {
        return;
      }
      const id = toast.error("duplicate color not allowed", { ...toastOptions, onClose: () => setToastId(null) });
      setToastId(id);
      return;
    }
    if (isdupprint) {
      if (toastId) {
        return;
      }
      const id = toast.error("duplicate print not allowed", { ...toastOptions, onClose: () => setToastId(null) });
      setToastId(id);
      return;
    }


    // setFormDetails((prev) => ({
    //   ...prev,
    //   submitted: true
    // }));
    const idRemovedArray = fieldsColor.filter((color) => {
      if (color.id.toString().search("temp-") !== -1) {
        color.id = 0;
      }
      return color;
    });
   setFieldsColorweft( fieldsColorweft.map((color) => {
      if (color.id.toString().search("temp-") !== -1) {
        color.id = 0;
      }
      return color;
    }))
    let body;
    if (valueGet.fabricStage.toUpperCase() == 'YARNDYED') {

    await  setyarnDyedCustomisationDTOS({
        ...yarnDyedCustomisationDTOS,
        yarnDyedColours: [
          ...fieldsColor.map(c => {
            c.customisationType = 'WARP'
            return c;

          }),
          ...fieldsColorweft.map(c => {
            c.customisationType = 'WEFT'
            return c;
          })
        ],
        yarnDyedImages: uploadedFiles

      })

      body = {
        id: rfdID,
        yarnDyedCustomisationDTOS:{
          warpNoOfColour: yarnDyedCustomisationDTOS?.warpNoOfColour,
    weftNoOfColour: yarnDyedCustomisationDTOS?.weftNoOfColour,
    yarnDyedColours: [
      ...fieldsColor.map(c => {
        c.customisationType = 'WARP';
        c.pantoneCode=c.colourCode;
        return c;
      }),
      ...fieldsColorweft.map(c => {
        c.customisationType = 'WEFT';
        c.pantoneCode=c.colourCode;
        return c;
      })
    ],
    yarnDyedImages: uploadedFiles
        } 
      };

    } else {
      body = {
        id: rfdID,
        dyeCustomizationDTOS: fieldsColor,
        printCustomizationDTOS: fields,
      };
    }
    console.log(body);
    if (!showControlsColor && !showControls) {
      // if (!fields.length && !fieldsColor.length) {
      //   if (!toastId) {
      //     const id = toast.error("Please add customizations", { ...toastOptions, onClose: () => setToastId(null) });
      //     setToastId(id);
      //   }
      // } else {
      // if (showControls == false || showControlsColor == false) {
      setIsLoading(true);
      AddFabricCustomizationApi(body).then((res) => {
        setIsLoading(false);
        if (res.isSuccess) {
          // props.fromAction(1);
          props.fromAction(body);
        }
      });

    } else {
      if (!toastId) {
        const id = toast.error("Please fill or close the box", { ...toastOptions, onClose: () => setToastId(null) });
        setToastId(id);
      }
    }
  };

  const handleCloseColor = (idx) => {
    setshowControlsColor(false);
    setColorFields({ colourName: "", colourCode: "", quantity: "", otxFile: "", imageName: "" });
  };
  const handleClosePrint = (idx) => {
    // setFormDetails({ errors: null })

    setshowControls(false);
    setcontrolsData({ imageUrl: "", printName: "", quantity: "", imageName: "" });
  };
  const removeFile = (index) => {
    console.log(index)
    dispatch({ type: 'REMOVE_IMAGE', payload: index })

  }
  const handleBack = () => {
    props.fromBack(1);
  };
  const handleBack3 = () => {
    props.fromBack3(1);
  };
  const handleBack2 = () => {
    props.fromBack2(1);
  };
  const showsusforwarp = () => {
    let i = valueGet.warpYarnDTOList.findIndex(w => w.sustainabilityId != 0);
    console.log(i)
    if (i != -1) {
      return true;
    }
    else {
      return false;
    }
  }
  const getprocesstype = (processtype) => {
    if (linkType == 'fabric') {
      return `(${capitalizeFirstLowercaseRest(processtype)})`;
    } else {
      switch (processtype) {
        case 'PRINT':
          {
            return 'Printing';
          }
        case 'DYED': {
          return 'Dyeing';
        }
        default: {
          return 'Printing & Dyeing';
        }
      }
    }
  }
  const capitalizeFirstLowercaseRest = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleFileUploadBack = () => {
    setfileModel(false)
  }
  const handleFileUploadSubmit = () => {
    setfileModel(false)
  }

  const openUploadFileModel = () => {
    setfileModel(true)
  }
  return (
    <>
      <h4 className="RFQ-h4">
        {/* {valueGet.name} | RFQ #{rfdNumber} */}
        <h4 className="RFQ-h4">
          Order {localStorage.getItem("orderName")} | RFQ #{rfdNumber}{linkType != 'fabric' && ' | Job Work'}
        </h4>
      </h4>

      <Row className="RFQ-Row">
        <Card className="RFQ-order-kate1">
          <div className="para-edit pt-0">
            <div className="para-para">
              <p className="input-saved">
                1. Type of Fabric:<span className="bold-text text-capitalize">{capitalizeFirstLowercaseRest(type)}</span>
              </p>

              {fabricstage != "Processed" ? (
                <p className="inputs-saved">
                  2. Fabric Stage:
                  <span className="bold-text text-capitalize">{fabricstage==='YarnDyed'?'Yarn Dyed': capitalizeFirstLowercaseRest(fabricstage)}</span>
                </p>
              ) : (
                <p className="inputs-saved mb-0">
                  {linkType == 'fabric' ? ' 2. Fabric Stage:' : '2. Job Work:'}
                  <span className="bold-text text-capitalize">
                    {linkType == 'fabric' && fabricstage} {getprocesstype(valueGet.processType)}
                  </span>
                </p>
              )}
            </div>
            <img src={edit} className="edit-icon" onClick={handleBack3} />
          </div>

          <hr className="hr-order-kate" />
          <div className="para-edit">
            <p className="input-saved">
              {" "}
              3. {type.toLowerCase() == 'woven' ? ' Type of Weave:' : type.toLowerCase() == 'knit' ? ' Fabric Structure:' : ''}<span className="bold-text">{weave}</span>
            </p>
            <img src={edit} className="edit-icon" onClick={handleBack2} />
          </div>

          <hr className="hr-order-kate" />
          {linkType == 'job_work' && <p className="input-saved"><i>Details of fabric being sent for job work</i> </p>}
          <div className="para-edit">
            <p className="input-saved">4. {type.toLowerCase() == 'woven' ? ' Warp and Weft parameters' : type.toLowerCase() == 'knit' ? ' Wale and Course parameters' : ''}</p>
            <img src={edit} className="edit-icon" onClick={handleBack} />
          </div>
          <div className="row w-100 ml-0">
            <p className="col-6 warp-p pr-0 pl-32">(i) {type.toLowerCase() == 'woven' ? ' Warp' : type.toLowerCase() == 'knit' ? ' Wale' : ''}</p>
            <p className="col-6 weft-p px-0">(ii) {type.toLowerCase() == 'woven' ? ' Weft' : type.toLowerCase() == 'knit' ? ' Course' : ''}</p>
          </div>

          <div className="row w-100 ml-0 mb-24">
            <div className="col-6">
              <div className="input-saved mb-8 ml-41">
                Count:
                <span className="bold-text ">{valueGet.warpCount}</span>
              </div>
              <div className="input-saved mb-8 ml-41">
                Type of yarn:
                <span className="bold-text">{valueGet.warp}</span>
              </div>
              {valueGet.warpYarnDTOList.findIndex(w => w.sustainabilityId != 0) != -1 && <div className="input-saved d-flex mb-8 ml-41">
                <div> Sustainability:</div><div>
                  {valueGet.warpYarnDTOList.map(item => {
                    return (
                      <>
                        {item.sustainabilityName ? <>  <span className="bold-text">{item.yarnName}: {item.sustainabilityName}</span><br /></> : ''}
                      </>
                    )
                  })}
                </div>
              </div>}
              <div className="input-saved ml-41">
                Comments:
                <span className="bold-text ml-18" title={valueGet.warpComment}>
                  {valueGet.warpComment
                    ? `${valueGet.warpComment.length > 400
                      ? valueGet.warpComment.substr(0, 400) + "..."
                      : valueGet.warpComment
                    }`
                    : ""}
                </span>
              </div>
            </div>

            <div className="col-6">
              <div className="input-saved mb-8 ml-2 pl-1">
                Count:
                <span className="bold-text ">{valueGet.weftCount}</span>
              </div>
              <div className="input-saved mb-8 ml-2 pl-1">
                Type of yarn:
                <span className="bold-text">{valueGet.weft}</span>
              </div>
              {valueGet.weftYarnDTOList.length > 0 && valueGet.weftYarnDTOList.findIndex(w => w.sustainabilityId != 0) != -1 && <div className="input-saved d-flex mb-8 ">
                <div> Sustainability:</div><div>
                  {valueGet.weftYarnDTOList.map(item => {
                    return (
                      <>
                        {item.sustainabilityName ? <> <span className="bold-text">{item.yarnName}: {item.sustainabilityName}</span><br /></> : ''}
                      </>
                    )
                  })}
                </div>
              </div>}
              <div className="input-saved ml-2 pl-1">
                Comments:
                <span className="bold-text ml-18" title={valueGet.weftComment}>
                  {valueGet.weftComment
                    ? `${valueGet.weftComment.length > 400
                      ? valueGet.weftComment.substr(0, 400) + "..."
                      : valueGet.weftComment
                    }`
                    : ""}
                </span>
              </div>
            </div>
          </div>
          {type.toLowerCase() == 'woven' ? <>
            <p className="input-saved">
              5. Fabric Construction:
              <span className="bold-text">
                {valueGet.fabricReed} x {valueGet.fabricPick}
              </span>
            </p>
            <p className="input-saved ml-3 pl-3 mb-16 mt-8">
              Comments:
              <span
                className="bold-text"
                title={valueGet.fabricConstructionComment}
              >
                {valueGet.fabricConstructionComment
                  ? `${valueGet.fabricConstructionComment.length > 600
                    ? valueGet.fabricConstructionComment.substr(0, 600) +
                    "..."
                    : valueGet.fabricConstructionComment
                  }`
                  : ""}
              </span>
            </p></>
            : ''}
          <p className="input-saved mb-14">
            {type.toLowerCase() == 'woven' ? '6.' : '5.'} GSM:<span className="bold-text">{valueGet.gsmValue}</span>
          </p>
          <div className="mb-14 d-flex">
            <p className="input-saved">
              {type.toLowerCase() == 'woven' ? '7.' : '6.'} {linkType === 'job_work' ? 'Fabric' : 'Preferred Fabric'} width {type.toLowerCase() == 'woven' ? '(L-100)' : '(open width)'}:
              {valueGet.processType == "Print & Dyed" ? (
                linkType !== 'job_work' ?
                  <p className="align-right">
                    {" "}
                    <span className="font-82">(a) Print :</span>{" "}
                    <span>{valueGet.printWidth} inches</span>{" "}
                    <span className="font-82 ml-3">(b) Dyed :</span>{" "}
                    <span>{valueGet.dyedWidth} inches</span>
                  </p> :
                  <>
                    <div className="mb-2 font-82" style={{ marginLeft: '1vw', marginTop: '1.6vh' }}>
                      {" "}
                      (i) Greige Fabric Width: <span className="bold-text" style={{ marginLeft: '0 !important' }}>{valueGet.printRawWidth} inches</span>{" "}

                    </div>
                    <div style={{ marginLeft: '1vw' }}>
                      <div className="font-82" >(ii) Preferred Finished Fabric Width:</div>
                      <div className="font-82 pre-ml-18 mt-2">(a) Print :{" "} <span className="bold-text" style={{ marginLeft: '0 !important' }}>{valueGet.printWidth} inches</span>{" "}</div>
                      <div className="font-82 pre-ml-18 mt-2">(b) Dyed :{" "}
                        <span className="bold-text" style={{ marginLeft: '0 !important' }}>{valueGet.dyedWidth} inches</span>{"  "}
                      </div></div>
                  </>

              ) : null}
              {valueGet.processType == "PRINT" ? (
                linkType == 'job_work' ?
                  <>
                    <p className="font-82 " style={{ marginLeft: '1.23vw', marginTop: '1.16vh', marginBottom: '0' }}>(i) Greige Fabric Width: <span className="align-right">{valueGet.printRawWidth} inches</span></p>

                    <p className="font-82 " style={{ marginLeft: '1.23vw', marginTop: '1.16vh', marginBottom: '0' }}>(ii) Preferred Finished Fabric Width:  <span className="align-right">{valueGet.printWidth} inches</span></p>

                  </> :
                  <p className="align-right">{valueGet.printWidth} inches</p>


              ) : null}
              {valueGet.processType == "DYED" ? (
                linkType == 'job_work' ?
                  <>
                    <p className="font-82 " style={{ marginLeft: '1.23vw', marginTop: '1.16vh', marginBottom: '0' }}>(i) Greige Fabric Width: <span className="align-right">{valueGet.dyedRawWidth} inches</span></p>

                    <p className="font-82 " style={{ marginLeft: '1.23vw', marginTop: '1.16vh', marginBottom: '0' }}>(ii) Preferred Finished Fabric Width:  <span className="align-right">{valueGet.dyedWidth} inches</span></p>

                  </> :
                  <p className="align-right">{valueGet.dyedWidth} inches</p>

              ) : null}
              {valueGet.processType == "GREIGE" || valueGet.processType == 'YARNDYED' ? (
                <p className="align-right">{valueGet.greigeWidth} inches</p>
              ) : null}
            </p>
          </div>
          {linkType == 'job_work' && <p className="input-saved mb-14">
            {type.toLowerCase() == 'woven' ? '8.' : '7.'} Fabric dispatch dates to job works vendor:<span className="bold-text">{valueGet.jobWorkStartDate} to {valueGet.jobWorkEndDate}</span>
          </p>}
          <hr className="hr-order-kate" />

          <Card body className="RFQ-order-weave-card">
            <p className="RFQ-Title">Fill out information</p>

            <FormGroup>
              <Label for="exampleZip" className="input-weave mb-16">
                {linkType == 'job_work' ? type.toLowerCase() == 'woven' ? '9.' : '8.' : type.toLowerCase() == 'woven' ? '8.' : '7.'} Fabric Customization
              </Label>
              {/* //////////////////////////////////////////PRINT/////////////////////////////////// */}

              {valueGet.processType == "PRINT" ? (
                <div className="App app-left">
                  <div className="row">
                    <div className="col mb-16 pl-0">
                      <button
                        className="add-clour m-0 px-0"
                        type="button"
                        onClick={() => handleAdd()}
                      >
                        <img src={plus} className="delete-photo" />
                        <span className="Order-Btn-text">Add Print</span>
                      </button>
                    </div>
                  </div>

                  {fields && fields.length ? (
                    <>
                      <table className="d-flex flex-column">
                        <thead>
                          <tr className="dyed-color-custom-head d-flex">
                            <th className="roman-no">&nbsp;</th>
                            <th className="color-box">
                              <div>Print Name</div>
                            </th>
                            <th className="color-code" style={{ textAlign: "left" }}>
                              <div>Quantity</div>
                            </th>
                            <th className="qty-boxx-new text-left" style={{ textAlign: "left" }}>
                              <div >Print Design</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fields.map((item, idx) => (
                            <tr className="dyed-color-custom-head">
                              <td className="roman-no">
                                ({romanize(idx + 1)})
                              </td>
                              <td className="colour-text color-box">
                                <span
                                  title={
                                    item.printName.length > 8 && item.printName
                                  }
                                >
                                  {item.printName.length > 8
                                    ? item.printName.substr(0, 8) + "..."
                                    : item.printName}
                                </span>
                              </td>
                              <td className="colour-text color-code" style={{ textAlign: "left" }}>
                                {item.quantity} {item.quantity > 0 ? type.toLowerCase() == 'woven' ? ' m' : type.toLowerCase() == 'knit' ? ' kg' : '' : ''}
                              </td>
                              <td className="colour-text qty-boxx ml-0">
                                <div className="d-flex">
                                  <div
                                    style={{
                                      flex: "1 1 auto",
                                      textAlign: "center",
                                    }}
                                  >
                                    {!item.imageUrl.includes("pdf") ? (
                                      <small>
                                        <a
                                          href={item.imageUrl}
                                          title={item.imageUrl}
                                        >
                                          <img src={save} />
                                        </a>
                                      </small>
                                    ) : (
                                      <small>
                                        <a
                                          href={item.imageUrl}
                                          title={item.imageUrl}
                                        >
                                          <img src={save} />
                                        </a>
                                      </small>
                                    )}
                                  </div>
                                  <div
                                    className="cross"
                                    onClick={() =>
                                      handleRemoveField(item.id, idx)
                                    }
                                  >
                                    X
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : null}
                  {showControls ? (
                    <div className="customization-div">
                      <div className="customization-form">
                        <div className="validate mr-10">
                          <input
                            type="text"
                            // id="printName"
                            placeholder="Print name"
                            onChange={(e) => handleChange(e, "printName")}
                            value={controlsData.printName}
                            name="printName"
                            minLength="1"
                            maxLength="30"
                            className="input-100px"
                          />
                        </div>
                        <div className="validate">
                          <input
                            type="text"
                            placeholder={type.toLowerCase() == 'woven' ? ' Quantity(m)' : type.toLowerCase() == 'knit' ? ' Quantity(kg)' : ''}

                            onPaste={(e) => e.preventDefault()}
                            className="input-100px"
                            autoComplete="off"
                            minLength="1"
                            maxLength="10"
                            onChange={(e) => handleChange(e, "quantity")}
                            value={controlsData.quantity}
                            name="quantity"
                          />
                        </div>
                        <div className="picss">
                          <Button
                            color="primary mt-8"
                            onClick={() => submitHandlerPrint()}
                            className="Add-button-custom"

                          >
                            Add
                          </Button>
                        </div>
                        <p
                          className="close-x-custom m-0"
                          onClick={() => handleClosePrint()}
                        >
                          x
                        </p>
                      </div>
                      <div className="validate">

                        {formDetails.submitted &&
                          formDetails.errors &&
                          formDetails.errors.printName && (
                            <small className="upload-print-design max-size">
                              {/* {formDetails.errors.printName[0]} */}
                              {"mandatory field print name must be filled to proceed"}
                            </small>
                          )}
                        {formDetails.submitted &&
                          formDetails.errors &&
                          formDetails.errors.quantity && (
                            <small className="upload-print-design max-size">
                              {/* {formDetails.errors.quantity[0]} */}
                              {"mandatory field quantity must be filled to proceed"}
                            </small>
                          )}
                        {
                          <>
                            <label
                              htmlFor="upload-print"
                              className="upload-print-design"
                              style={{ cursor: "pointer" }}
                            >
                              <span style={{ color: "#2f80ed" }}>Upload print design</span>
                              {controlsData.imageUrl && (
                                <>
                                  {controlsData.imageUrl.includes("png") ||
                                    controlsData.imageUrl.includes("jpg") ||
                                    controlsData.imageUrl.includes("jpeg") ? (
                                    <small className="text-primary" style={{ fontSize: "10px", marginLeft: "10px" }}>
                                      <a title={controlsData.imageName}>
                                        {controlsData.imageName.length > 25 ? controlsData.imageName.substr(0, 25) + "..." : controlsData.imageName}
                                      </a>
                                    </small>
                                  ) : controlsData.imageUrl.includes("pdf") ? (
                                    <small className="text-primary" style={{ fontSize: "10px", marginLeft: "10px" }}>
                                      <a
                                        className="ml-3"
                                        title={controlsData.imageName}
                                      >
                                        {controlsData.imageName.length > 25 ? controlsData.imageName.substr(0, 25) + "..." : controlsData.imageName}
                                      </a>
                                    </small>
                                  ) : null}
                                </>
                              )}
                            </label>
                            <input
                              type="file"
                              onChange={(e) => handlePicChange(e, "imageUrl")}
                              accept="image/x-png,image/jpeg,image/svg+xml,application/pdf"
                              // id={controlsData?.img == '' ? 'editBusinessProfile' : 'notcontrol'}
                              id="upload-print"
                              name="photo"
                              content="upload print design"
                              style={{ display: "none" }}
                            />
                          </>
                        }
                        <small className="upload-print-design max-size">
                          {"Max size 10mb allowed"}
                        </small>
                        {formDetails.submitted &&
                          formDetails.errors &&
                          formDetails.errors.imageUrl && (
                            <small className="upload-print-design max-size">
                              {/* {formDetails.errors.imageUrl[0]} */}
                              {"mandatory field image must be filled to proceed"}
                            </small>
                          )}
                        {/* <button type="button" onClick={() => handleRemoveField()}>X</button> */}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}

              {/* ///////////////////////////////////////DYED///////////////////////////////////////// */}

              {valueGet.processType == "DYED" ? (
                <div className="App app-right" style={{ marginLeft: "0" }}>
                  <div className="row">
                    <div className="col mb-16 pl-0">
                      <button
                        className="add-clour p-0"
                        type="button"
                        onClick={() => handleColorAdd()}
                      >
                        <img src={plus} className="delete-photo" />
                        <span className="Order-Btn-text">Add Colour</span>
                      </button>
                    </div>
                  </div>
                  <table
                    className="d-flex flex-column"
                    style={{
                      marginTop: "-0.8vh",
                      marginBottom: "2vh",
                    }}
                  >
                    <thead>
                      {fieldsColor.length > 0 ? (
                        <tr className="dyed-color-custom-head d-flex">
                          <th className="roman-no">&nbsp;</th>
                          <th className="color-box">
                            <div>Colour</div>
                          </th>
                          <th className="color-code">
                            <div>Pantone Code</div>
                          </th>
                          <th className="qty-boxx ">
                            <div>Quantity</div>
                          </th>
                          <th className="qty-boxx ">
                            <div>Qtx File</div>
                          </th>
                        </tr>
                      ) : null}
                    </thead>
                    <tbody>
                      {fieldsColor.length > 0
                        ? fieldsColor.map((item, idx) => (
                          <tr className="dyed-color-custom-head" key={uuid()}>
                            <td className="roman-no">
                              ({romanize(idx + 1)})
                            </td>
                            <td
                              className="colour-text color-box"
                              style={{ wordBreak: "break-word" }}
                            >
                              <span
                                title={
                                  item.colourName.length > 10 &&
                                  item.colourName
                                }
                              >
                                {item.colourName.length > 10
                                  ? item.colourName.substr(0, 10) + "..."
                                  : item.colourName}
                              </span>
                            </td>
                            <td className="colour-text color-code max-qty" title={item.colourCode}>
                              {item.colourCode || ""}
                            </td>

                            <td className="colour-text qty-boxx">
                              {item.quantity}{item.quantity > 0 ? type.toLowerCase() == 'woven' ? ' m' : type.toLowerCase() == 'knit' ? ' kg' : '' : ''}{" "}

                            </td>
                            <td className="colour-text qty-boxx ml-0">
                              <div className="d-flex">
                                <div
                                  style={{
                                    flex: "1 1 auto",
                                    textAlign: "center",
                                  }}
                                >
                                  {item.otxFile ?
                                    <>
                                      {!item.otxFile.includes("pdf") ? (
                                        <small>
                                          <a
                                            href={item.otxFile}
                                            title={item.otxFile}
                                          >
                                            <img src={save} />
                                          </a>
                                        </small>
                                      ) : (
                                        <small>
                                          <a
                                            href={item.otxFile}
                                            title={item.otxFile}
                                          >
                                            <img src={save} />
                                          </a>
                                        </small>
                                      )}
                                    </> : ''
                                  }
                                </div>
                                <div
                                  className="cross"
                                  onClick={() =>
                                    handleColorRemove(item.id, idx)
                                  }
                                >
                                  X
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                        : null}
                    </tbody>
                  </table>
                  {showControlsColor ? (
                    <div className="customization-div d-flex flex-column">
                      <p
                        className="close-x-custom m-0"
                        onClick={() => handleCloseColor()}
                      >
                        x
                      </p>
                      <div className="customization-form">
                        <div className="validate mr-10">
                          <input
                            type="text"
                            placeholder="Colour"
                            name="colourName"
                            minLength="1"
                            maxLength="30"
                            value={colorFields.colourName}
                            onChange={(e) => handleColorChange(e, "colourName")}
                            className="input-100px"
                          />
                          {formDetails.submitted &&
                            formDetails.errors &&
                            formDetails.errors.colourName && (
                              <small className="form-text text-danger input-100px">
                                {formDetails.errors.colourName[0]}
                              </small>
                            )}
                        </div>
                        <div className="mr-10">
                          <input
                            type="text"
                            placeholder="Pantone Code (Optional)"
                            value={colorFields.colourCode}
                            name="colourCode"

                            autoComplete="off"
                            minLength="1"

                            onChange={(e) => handleColorChange(e, "colourCode")}
                            className="input-100px new-color-input"
                          />
                        </div>
                        <div className="validate">
                          <input
                            type="text"
                            placeholder={type.toLowerCase() == 'woven' ? ' Quantity(m)' : type.toLowerCase() == 'knit' ? ' Quantity(kg)' : ''}
                            value={colorFields.quantity}
                            name="quantity"
                            onPaste={(e) => e.preventDefault()}
                            autoComplete="off"
                            minLength="1"
                            maxLength="10"
                            onChange={(e) => handleColorChange(e, "quantity")}
                            className="input-100px"
                          />
                          {formDetails.submitted &&
                            formDetails.errors &&
                            formDetails.errors.quantity && (
                              <small className="form-text text-danger input-100px">
                                {formDetails.errors.quantity[0]}
                              </small>
                            )}
                        </div>
                      </div>

                      <div>
                        {
                          <>
                            <label
                              htmlFor="upload-print"
                              className="upload-print-design"
                              style={{ cursor: "pointer" }}
                            >
                              <span style={{ color: "#2f80ed" }}>Upload QTX File (Optional)</span>
                              {colorFields.otxFile && (
                                <>
                                  {colorFields.otxFile.includes("png") ||
                                    colorFields.otxFile.includes("jpg") ||
                                    colorFields.otxFile.includes("jpeg") ? (
                                    <small className="text-primary" style={{ fontSize: "10px", marginLeft: "10px" }}>
                                      <a title={colorFields.imageName}>
                                        {colorFields.imageName.length > 25 ? colorFields.imageName.substr(0, 25) + "..." : colorFields.imageName}
                                      </a>
                                    </small>
                                  ) : colorFields.otxFile.includes("pdf") ? (
                                    <small className="text-primary" style={{ fontSize: "10px", marginLeft: "10px" }}>
                                      <a
                                        className="ml-3"
                                        title={colorFields.imageName}
                                      >
                                        {colorFields.imageName.length > 25 ? colorFields.imageName.substr(0, 25) + "..." : colorFields.imageName}
                                      </a>
                                    </small>
                                  ) : null}
                                </>
                              )}
                            </label>
                            <input
                              type="file"
                              onChange={(e) => handlePicChange(e, "otxFile")}
                              accept="image/x-png,image/jpeg,image/svg+xml,application/pdf"
                              // id={controlsData?.img == '' ? 'editBusinessProfile' : 'notcontrol'}
                              id="upload-print"
                              name="photo"
                              content="upload otx file"
                              style={{ display: "none" }}
                            />
                          </>
                        }
                        <Button
                          color="primary"
                          onClick={() => submitHandlerColor()}
                          className="Add-button-custom"

                        >
                          Add
                        </Button>
                      </div>

                      {/* <button type="button" onClick={() => handleRemove()}>
                        X
                      </button> */}
                    </div>
                  ) : null}
                </div>
              ) : null}

              {/* /////////////////////PRINT & DYED////////////////////////////////////// */}

              {valueGet.processType == "Print & Dyed" ? (
                <div className="row w-100 ml-0 mb-16">
                  <div className="col-6 px-0">
                    <button
                      className="add-clour m-0 p-0"
                      type="button"
                      onClick={() => handleAdd()}
                    >
                      <img src={plus} className="delete-photo" />
                      <span className="Order-Btn-text">Add Print</span>
                    </button>
                    {fields.length > 0 ? (
                      <>
                        <div className="d-flex align-items-center dyed-color-custom-head mt-16">
                          <p className="roman-no"></p>
                          <p className="color-box text-underline">Print Name</p>
                          <p className="color-code text-underline" style={{ textAlign: "left !important" }}>Quantity</p>
                          <p className="qty-boxx text-left text-underline">
                            Print Design
                          </p>
                        </div>

                        {fields.map((item, idx) => (
                          <div className="para-flex dyed-color-custom-head">
                            <p className="roman-no">({romanize(idx + 1)})</p>
                            <p className="colour-text color-box">
                              <span
                                title={
                                  item.printName.length > 10 && item.printName
                                }
                              >
                                {item.printName.length > 10
                                  ? item.printName.substr(0, 10) + "..."
                                  : item.printName}
                              </span>
                            </p>

                            <p className="colour-text color-code" style={{ textAlign: "left !important" }}>
                              {item.quantity} {item.quantity > 0 ? type.toLowerCase() == 'woven' ? ' m' : type.toLowerCase() == 'knit' ? ' kg' : '' : ''}
                            </p>

                            <p className="colour-text qty-boxx d-flex justify-content-around">
                              {!item.imageUrl.includes("pdf") ? (
                                <small>
                                  <a href={item.imageUrl} title={item.imageUrl}>
                                    <img src={save} />
                                  </a>
                                </small>
                              ) : (
                                <small>
                                  <a href={item.imageUrl} title={item.imageUrl}>
                                    <img src={save} />
                                  </a>
                                </small>
                              )}

                              <div
                                className="cross"
                                onClick={() => handleRemoveField(item.id, idx)}
                              >
                                X
                              </div>
                            </p>

                            {/* <img src={delicon} className="certificate-delete-icon" onClick={() => handleRemoveField(idx)} /> */}
                          </div>
                        ))}
                      </>
                    ) : null}
                    {showControls ? (
                      <div className="customization-div custom-left">
                        <div className="customization-form justify-content-start">
                          <div className="validate">
                            <input
                              type="text"
                              value={controlsData.printName}
                              name="printName"
                              placeholder="Print name"
                              minLength="1"
                              maxLength="30"
                              className="input-100px"
                              onChange={(e) => handleChange(e, "printName")}
                            />
                          </div>
                          <div className="validate">
                            <input
                              type="text"
                              placeholder={type.toLowerCase() == 'woven' ? ' Quantity(m)' : type.toLowerCase() == 'knit' ? ' Quantity(kg)' : ''}
                              value={controlsData.quantity}
                              name="quantity"
                              onPaste={(e) => e.preventDefault()}
                              autoComplete="off"
                              minLength="1"
                              maxLength="10"
                              className="input-100px"
                              onChange={(e) => handleChange(e, "quantity")}
                            />
                          </div>
                          <div className="picss">
                            <Button
                              color="primary"
                              onClick={() => submitHandlerPrint()}
                              className="Add-button-custom"

                            >
                              Add
                            </Button>
                          </div>
                          <div>
                            <p
                              className="close-x-custom m-0"
                              onClick={() => handleClosePrint()}
                            >
                              x
                            </p>
                          </div>
                        </div>
                        <div className="validate">
                          {formDetails.submitted &&
                            formDetails.errors &&
                            formDetails.errors.printName && (
                              // <small className="form-text text-danger small input-100px">
                              <small className="upload-print-design max-size d-block mt-8 mb-0">
                                {/* {formDetails.errors.printName[0]} */}
                                {"mandatory field print name must be filled to proceed"}
                              </small>
                            )}
                          {formDetails.submitted &&
                            formDetails.errors &&
                            formDetails.errors.quantity && (
                              // <small className="form-text text-danger input-100px">
                              <small className="upload-print-design max-size d-block mt-8 mb-0">
                                {/* {formDetails.errors.quantity[0]} */}
                                {"mandatory field quantity must be filled to proceed"}
                              </small>
                            )}

                          <label
                            htmlFor="upload-print-2"
                            className="upload-print-design"
                            style={{ cursor: "pointer" }}
                          >
                            <span style={{ color: "#2f80ed" }}>Upload print design</span>
                            {controlsData.imageUrl && (
                              <>
                                {controlsData.imageUrl.includes("png") ||
                                  controlsData.imageUrl.includes("jpg") ||
                                  controlsData.imageUrl.includes("jpeg") ? (
                                  <small className="text-primary" style={{ fontSize: "10px", marginLeft: "10px" }}>
                                    <a title={controlsData.imageName}>
                                      {controlsData.imageName.length > 25 ? controlsData.imageName.substr(0, 25) + "..." : controlsData.imageName}
                                    </a>
                                  </small>
                                ) : controlsData.imageUrl.includes("pdf") ? (
                                  // <small className="form-text text-primary small">
                                  <small className="text-primary" style={{ fontSize: "10px", marginLeft: "10px" }}>
                                    <a title={controlsData.imageName}>
                                      {controlsData.imageName.length > 25 ? controlsData.imageName.substr(0, 25) + "..." : controlsData.imageName}
                                    </a>
                                  </small>
                                ) : null}
                              </>
                            )}
                          </label>
                          <input
                            type="file"
                            onChange={(e) => handlePicChange(e, "imageUrl")}
                            accept="image/x-png,image/jpeg,image/svg+xml,application/pdf"
                            // id={controlsData?.img == '' ? 'editBusinessProfile' : 'notcontrol'}
                            id="upload-print-2"
                            name="photo"
                            content="upload print design"
                            style={{ display: "none" }}
                            className="ml-2 mr-0"
                          />

                          <small className="upload-print-design max-size d-block mt-8 mb-0">
                            {"Max size 10mb allowed"}
                          </small>
                          {formDetails.submitted &&
                            formDetails.errors &&
                            formDetails.errors.imageUrl && (
                              // <small className="form-text text-danger small">
                              <small className="upload-print-design max-size d-block mt-8 mb-0">
                                {/* {formDetails.errors.imageUrl[0]} */}
                                {"mandatory field image must be filled to proceed"}
                              </small>
                            )}
                        </div>
                        {/* <button type="button" onClick={() => handleRemoveField()}>
                          X
                        </button> */}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-6 px-0">
                    <button
                      className="add-clour m-0 p-0"
                      type="button"
                      onClick={() => handleColorAdd()}
                    >
                      <img src={plus} className="delete-photo" />
                      <span className="Order-Btn-text">Add Colour</span>
                    </button>

                    {fieldsColor && fieldsColor.length ? (
                      <>
                        <div className="d-flex align-items-center dyed-color-custom-head mt-16">
                          <p className="roman-no"></p>
                          <p className="color-box text-underline">Colour</p>
                          <p className="color-code text-underline">
                            Pantone Code
                          </p>
                          <p className="qty-boxx  text-underline">
                            Quantity
                          </p>
                          <p className="qty-boxx  text-underline">
                            QTX File
                          </p>
                        </div>
                        {fieldsColor.map((item, idx) => (
                          <>
                            <div className="para-flex dyed-color-custom-head">
                              <div className="roman-no">
                                ({romanize(idx + 1)})
                              </div>
                              <p className="colour-text color-box">
                                <span
                                  title={
                                    item.colourName.length > 10 &&
                                    item.colourName
                                  }
                                >
                                  {item.colourName.length > 10
                                    ? item.colourName.substr(0, 10) + "..."
                                    : item.colourName}
                                </span>
                              </p>

                              <p className="colour-text color-code max-qty" title={item.colourCode}>
                                {item.colourCode || ""}
                              </p>

                              <p className="d-flex justify-content-around colour-text qty-boxx">
                                <span>{item.quantity}{item.quantity > 0 ? type.toLowerCase() == 'woven' ? ' m' : type.toLowerCase() == 'knit' ? ' kg' : '' : ''}</span>

                              </p>
                              <p className="colour-text qty-boxx ml-0">
                                <div className="d-flex" style={{ marginLeft: '5px' }}>
                                  <div
                                    style={{
                                      flex: "1 1 auto",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item.otxFile ?
                                      <>
                                        {!item.otxFile.includes("pdf") ? (
                                          <small>
                                            <a
                                              href={item.otxFile}
                                              title={item.otxFile}
                                            >
                                              <img src={save} />
                                            </a>
                                          </small>
                                        ) : (
                                          <small>
                                            <a
                                              href={item.otxFile}
                                              title={item.otxFile}
                                            >
                                              <img src={save} />
                                            </a>
                                          </small>
                                        )}
                                      </> : ''
                                    }
                                  </div>
                                  <div
                                    className="cross"
                                    onClick={() =>
                                      handleColorRemove(item.id, idx)
                                    }
                                  >
                                    X
                                  </div>
                                </div>
                              </p>

                              {/* <img src={delicon} className="certificate-delete-icon" onClick={() => handleRemove(idx)} /> */}
                            </div>
                          </>
                        ))}
                      </>
                    ) : null}

                    {showControlsColor ? (
                      <div className="customization-div custom-right">
                        <div className="customization-form flex-column">
                          <div className="d-flex" style={{ marginBottom: '1vh' }}>
                            <div className="validate">
                              <input
                                type="text"
                                placeholder="Colour"
                                value={colorFields.colourName}
                                name="colourName"
                                minLength="1"
                                maxLength="30"
                                className="input-100px"
                                onChange={(e) =>
                                  handleColorChange(e, "colourName")
                                }
                              />
                              {formDetails.submitted &&
                                formDetails.errors &&
                                formDetails.errors.colourName && (
                                  <small className="form-text text-danger small input-100px">
                                    {formDetails.errors.colourName[0]}
                                  </small>
                                )}
                            </div>
                            <div className="validate">
                              <input
                                type="text"
                                value={colorFields.colourCode}
                                name="colourCode"
                                placeholder="Pantone code (Optional)"
                                autoComplete="off"
                                minLength="1"

                                className="input-100px new-color-input"
                                onChange={(e) =>
                                  handleColorChange(e, "colourCode")
                                }
                              />
                            </div>
                            <div className="validate">
                              <input
                                type="text"
                                value={colorFields.quantity}
                                name="quantity"
                                onPaste={(e) => e.preventDefault()}
                                autoComplete="off"
                                minLength="1"
                                maxLength="10"
                                className="input-100px mr-0"
                                placeholder={type.toLowerCase() == 'woven' ? ' Quantity(m)' : type.toLowerCase() == 'knit' ? ' Quantity(kg)' : ''}
                                onChange={(e) =>
                                  handleColorChange(e, "quantity")
                                }
                              />
                              {formDetails.submitted &&
                                formDetails.errors &&
                                formDetails.errors.quantity && (
                                  <small className="form-text text-danger small input-100px">
                                    {formDetails.errors.quantity[0]}
                                  </small>
                                )}
                            </div>
                            <p
                              className="close-x-custom"
                              onClick={() => handleCloseColor()}
                            >
                              x
                            </p>
                          </div>
                          <div>
                            {
                              <>
                                <label
                                  htmlFor="upload-print"
                                  className="upload-print-design"
                                  style={{ cursor: "pointer" }}
                                >
                                  <span style={{ color: "#2f80ed" }}>Upload QTX File (Optional)</span>
                                  {colorFields.otxFile && (
                                    <>
                                      {colorFields.otxFile.includes("png") ||
                                        colorFields.otxFile.includes("jpg") ||
                                        colorFields.otxFile.includes("jpeg") ? (
                                        <small className="text-primary" style={{ fontSize: "10px", marginLeft: "10px" }}>
                                          <a title={colorFields.imageName}>
                                            {colorFields.imageName.length > 25 ? colorFields.imageName.substr(0, 25) + "..." : colorFields.imageName}
                                          </a>
                                        </small>
                                      ) : colorFields.otxFile.includes("pdf") ? (
                                        <small className="text-primary" style={{ fontSize: "10px", marginLeft: "10px" }}>
                                          <a
                                            className="ml-3"
                                            title={colorFields.imageName}
                                          >
                                            {colorFields.imageName.length > 25 ? colorFields.imageName.substr(0, 25) + "..." : colorFields.imageName}
                                          </a>
                                        </small>
                                      ) : null}
                                    </>
                                  )}
                                </label>
                                <input
                                  type="file"
                                  onChange={(e) => handlePicChange(e, "otxFile")}
                                  accept="image/x-png,image/jpeg,image/svg+xml,application/pdf"
                                  // id={controlsData?.img == '' ? 'editBusinessProfile' : 'notcontrol'}
                                  id="upload-print"
                                  name="photo"
                                  content="upload otx file"
                                  style={{ display: "none" }}
                                />
                              </>
                            }
                            <Button
                              color="primary"
                              onClick={() => submitHandlerColor()}
                              className="Add-button-custom"

                            >
                              Add
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {/*/////////////////////////Yard Dyed Customisation////////////////////*/}

              {valueGet.fabricStage == 'YarnDyed' && (
                <div className=" w-100 ml-0 mb-16">
                  <div style={{ marginBottom: '1.6vh', lineHeight: 'initial' }}>  <a className="cust-link" onClick={openUploadFileModel}> (i) Upload File/Image</a></div>
                  {fileModel && <FileUpload
                    show={fileModel}
                    handleBack={handleFileUploadBack}
                    handleSubmit={handleFileUploadSubmit}
                  />}
                  {uploadedFiles?.length > 0 && <table className="image-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Size</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {uploadedFiles.map((file, idx) => {
                        return (
                          <tr>
                            <td>{file.imageName?.split('.')[0]}</td>
                            <td>{file.imageName?.split('.')[1]}</td>
                            <td>{(file.imageSize/1024).toFixed(1)}KB</td>
                            <td>
                              <a href={file.imageUrl} download={file.imageName}>Download</a>
                              <a onClick={() => removeFile(idx)}>Delete</a>
                            </td>
                          </tr>
                        )

                      })}
                      <tr>

                      </tr>
                    </tbody>
                  </table>}
                  <div style={{ marginBottom: '1.6vh' }}>
                    <p className="input-weave">(ii) Yarn Details(Optional) </p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-saved-nomargin" >
                          (a) Warp
                        </div>
                        <div className="input-weave d-flex  align-items-center">
                          Number of Colours:&nbsp;&nbsp; <input
                            className="colorcountinput form-control"
                            value={Number(yarnDyedCustomisationDTOS?.warpNoOfColour)>0?yarnDyedCustomisationDTOS?.warpNoOfColour:''}
                            onChange={(e) => handleCustChange(e, 'warpNoOfColour')}
                            id="exampleZip"
                          />
                        </div>
                        <div style={{ marginBottom: '1.6vh' }}>   <button
                          className="add-clour m-0 p-0"
                          type="button"
                          onClick={() => handleColorAdd()}
                        >
                          <img src={plus} className="delete-photo" />
                          <span className="Order-Btn-text">Add Colour</span>
                        </button></div>
                        {(showControlsColor || fieldsColor.length > 0) && <table className="cust-table">
                          <thead>
                            <tr>
                              <th>Colour Name</th>
                              <th>Pantone Code</th>
                              <th>Qtx File</th>
                              <th className="hide-border"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fieldsColor.map((item, idx) => {
                              return (
                                <tr>
                                  <td>{item.colourName}</td>
                                  <td>{item.pantoneCode}</td>
                                  <td>{item.otxFile!=""&&<a href={item.otxFile}
                                    title={item.otxFile} download>Download</a>}</td>
                                  <td className="hide-border"> <div
                                    className="cross"
                                    onClick={() =>
                                      handleColorRemove(item.id, idx)
                                    }
                                  >
                                    <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.14648 0.625H5.85482L6.39648 1.16667H8.29232V2.25H0.708984V1.16667H2.60482L3.14648 0.625ZM1.25065 2.79167H7.75065V9.29167C7.75065 9.8875 7.26315 10.375 6.66732 10.375H2.33398C1.73815 10.375 1.25065 9.8875 1.25065 9.29167V2.79167Z" fill="black" fill-opacity="0.54" />
                                    </svg>

                                  </div></td>
                                </tr>
                              )
                            })}
                            {showControlsColor && <tr  >
                              <td>
                                <input
                                  name="colourName"
                                  className="input-50px new-color-input"
                                  onChange={(e) => handleColorChange(e, "colourName")}
                                ></input>
                              </td>
                              <td>
                                <input
                                  name="pantoneCode"
                                  className="input-50px new-color-input"
                                  onChange={(e) => handleColorChange(e, "colourCode")}
                                ></input>
                              </td>
                              <td>
                                {colorFields.otxFile == '' ?
                                  <>
                                    <a className="upload-link" onClick={() => warpQtxUpload.current.click()} > Upload</a>
                                    <input
                                      type="file"
                                      onChange={(e) => handlePicChange(e, "otxFile")}
                                      accept="image/x-png,image/jpeg,image/svg+xml,application/pdf"
                                      // id={controlsData?.img == '' ? 'editBusinessProfile' : 'notcontrol'}
                                      id="upload-print"
                                      ref={warpQtxUpload}
                                      name="photo"
                                      content="upload otx file"
                                      style={{ display: "none", width: '1px', height: '1px !important' }}
                                    /></>
                                  :
                                  <>
                                    <a href={colorFields.otxFile}
                                      title={colorFields.otxFile} download>Download</a>
                                    <a onClick={(e) => removeotxFile('warp')} >Remove</a>
                                  </>}
                              </td>
                              <td className="hide-border">
                                <button
                                  color="primary"
                                  onClick={() => submitHandlerColor()}
                                  className="Add-button-custom btn-primary"
                                  style={{ cursor: 'pointer', marginLeft: '0 !important' }}
                                >
                                  Add
                                </button>
                              </td>
                            </tr>}
                          </tbody>
                        </table>}
                      </div>
                      <div className="col-md-6">
                        <div className="input-saved-nomargin" style={{ marginLeft: '0 !important' }}>

                          (b) Weft
                        </div>
                        <div className="input-weave d-flex align-items-center">
                        Number of Colours:&nbsp;&nbsp; <input
                            className="colorcountinput form-control"
                            id="exampleZip"
                            value={Number(yarnDyedCustomisationDTOS?.weftNoOfColour)>0?yarnDyedCustomisationDTOS?.weftNoOfColour:''}

                            onChange={(e) => handleCustChange(e, 'weftNoOfColour')}
                          />
                        </div>
                        <div style={{ marginBottom: '1.6vh' }}> <button
                          className="add-clour m-0 p-0"
                          type="button"
                          onClick={() => handleColorAddWeft()}
                        >
                          <img src={plus} className="delete-photo" />
                          <span className="Order-Btn-text">Add Colour</span>
                        </button></div>
                        {(showControlsColorWeft || fieldsColorweft.length > 0) && <table className="cust-table">
                          <thead>
                            <tr>
                              <th>Colour Name</th>
                              <th>Pantone Code</th>
                              <th>Qtx File</th>
                              <th className="hide-border"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {fieldsColorweft.map((item, idx) => {
                              return (
                                <tr>
                                  <td>{item.colourName}</td>
                                  <td>{item.colourCode}</td>
                                  <td>{item.otxFile!=""&&<a href={item.otxFile}
                                    title={item.otxFile} download>Download</a>}</td>
                                  <td className="hide-border"> <div
                                    className="cross"
                                    onClick={() =>
                                      handleColorRemoveWeft(item.id, idx)
                                    }
                                  >
                                    <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.14648 0.625H5.85482L6.39648 1.16667H8.29232V2.25H0.708984V1.16667H2.60482L3.14648 0.625ZM1.25065 2.79167H7.75065V9.29167C7.75065 9.8875 7.26315 10.375 6.66732 10.375H2.33398C1.73815 10.375 1.25065 9.8875 1.25065 9.29167V2.79167Z" fill="black" fill-opacity="0.54" />
                                    </svg>

                                  </div></td>
                                </tr>
                              )
                            })}
                            {showControlsColorWeft && <tr  >
                              <td>
                                <input
                                  name="colourName"
                                  className="input-50px new-color-input"
                                  onChange={(e) => handleColorChangeweft(e, "colourName")}
                                ></input>
                              </td>
                              <td>
                                <input
                                  name="pantoneCode"
                                  className="input-50px new-color-input"
                                  onChange={(e) => handleColorChangeweft(e, "colourCode")}
                                ></input>
                              </td>
                              <td>
                                {colorFieldsWeft.otxFile == '' ? <>
                                  <a className="upload-link" onClick={() => weftQtxUpload.current.click()} > Upload</a>
                                  <input
                                    type="file"
                                    onChange={(e) => handlePicChangeweft(e, "otxFile")}
                                    accept="image/x-png,image/jpeg,image/svg+xml,application/pdf"
                                    // id={controlsData?.img == '' ? 'editBusinessProfile' : 'notcontrol'}
                                    id="upload-print"
                                    ref={weftQtxUpload}
                                    name="photo"
                                    content="upload otx file"
                                    style={{ display: "none", width: '1px', height: '1px !important' }}
                                  />
                                </> :
                                  <>
                                    <a href={colorFieldsWeft.otxFile}
                                      title={colorFieldsWeft.otxFile} download>Download</a>
                                    <a onClick={(e) => removeotxFile('weft')} >Remove</a>
                                  </>}
                              </td>
                              <td className="hide-border">
                                <button
                                  color="primary"
                                  onClick={() => submitHandlerColorWeft()}
                                  className="Add-button-custom btn-primary"
                                  style={{ cursor: 'pointer', marginLeft: '0 !important' }}
                                >
                                  Add
                                </button>
                              </td>
                            </tr>}
                          </tbody>
                        </table>}
                      </div>
                    </div>
                  </div>

                </div>
              )}

            </FormGroup>

            <div className="RFQButtons">
              <Link>
                {" "}
                {/* disabled={
                   isLoading|| valueGet.processType == "Print & Dyed" ? (!fields.length || !fieldsColor.length) :
                      valueGet.processType == "PRINT" ? !fields.length : !fieldsColor.length
                  } */}
                <Button
                  className="RFQ-Submit"
                  type="submit"
                  onClick={submitHandler}

                >
                  Submit
                </Button>
              </Link>
              <Button className="RFQ-Back" onClick={handleBack}>
                Back
              </Button>
            </div>
          </Card>

          <NotificationContainer />
        </Card>
      </Row>
      {isLoading ? (
        <>
          <div className="cdf">
            {" "}
            <div className="loader" />
          </div>{" "}
        </>
      ) : null}
    </>
  );
};

export default OrdereWeaveNext;
