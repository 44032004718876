import React, { useEffect, useState } from 'react';
import ReactStars from "react-rating-stars-component";
import './Preview.css'
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import GalleryPre from './GalletPre';
import SumaryPre from './SummaryPre'
import BusinessPre from './BusiDesPreview'
import AddressPre from './Address'
import { SVGIcon } from './Ratings'
import { GetSummaryApi } from '../../../../services';
import company from '../../../../assets/company.jpg'



const Rfq = (props) => {
  var Rating = require('react-rating');
  const history = useHistory();

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }


  let companyId = localStorage.getItem('companyId')

  const [summary, setSummary] = useState({
    companyName: '',

    // id: companyId,
    profileImageUrl: null,

  })


  useEffect(() => {


    getSummaryDetails();


  }, [])
  const getSummaryDetails = () => {
    // console.log(companyId,'/////////////////')

    GetSummaryApi(companyId).then((res) => {

      if (res.isSuccess) {
        setSummary(res.data)
      }
    })
  }
  const getrating = (rate) => {
    return Math.round(rate / 20);
  }

  return (
    <>
      {summary &&
        <div className="PreviewCompany">
          <NavItem className="pro-le">
            <div className="tabsimage"><img src={summary.profileImageUrl ? summary.profileImageUrl : company} />
              <div className="tabsaddress">
                <p>{summary.companyName}</p>
                
                  <div className='d-flex rating' >
                    { summary.FabtrackRating?
                    <ReactStars
                      value={getrating(summary.FabtrackRating)}
                      edit="false"
                      count={5}
                      size={13}
                      half={true}
                      activeColor="#f2994agit config --global --add safe.directory /home/anand/fabtrack-staging/Fabtrack-frontend"
                      color='#828282'
                    />
                      :''}
                    {summary.FabtrackRating  > 0 ?
                      Math.round(summary.FabtrackRating * 100) / 100 + '%' : ''}
                      
                  </div>
                {/* <span>New Delhi</span> */}
              </div></div>
            {/* <Rating
  emptySymbol={<img src="assets/images/star-empty.png" className="icon" />}
  fullSymbol={<img src="assets/images/star-full.png" className="icon" />}
/> */}

          </NavItem>
          <Nav tabs className="ComapnyPreviewtabs">


            <NavItem className="ComapnyPreviewitems">
              <NavLink className="compnyPrevielink"
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}
              >
                Summary
              </NavLink>
            </NavItem>
            <NavItem className="ComapnyPreviewitems">
              <NavLink className={classnames({ active: activeTab === '2' })}
                onClick={() => { toggle('2'); }}>
                Business Description
              </NavLink>
            </NavItem>
            <NavItem className="ComapnyPreviewitems">
              <NavLink className={classnames({ active: activeTab === '3' })}
                onClick={() => { toggle('3'); }}>
                Gallery
              </NavLink>
            </NavItem>
            <NavItem className="ComapnyPreviewitems">
              <NavLink className={classnames({ active: activeTab === '4' })}
                onClick={() => { toggle('4'); }}>
                Locations
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" className="ComapnyPreviewTabpane">

              <SumaryPre />
            </TabPane>
            <TabPane tabId="2" className="ComapnyPreviewTabpane">

              <BusinessPre />

            </TabPane>
            <TabPane tabId="3" className="ComapnyPreviewTabpane">
              <GalleryPre />
            </TabPane>

            <TabPane tabId="4" className="ComapnyPreviewTabpane">
              <AddressPre />
            </TabPane>
          </TabContent>
        </div>
      }
    </>
  );
}


export default Rfq;