import React, { useEffect } from 'react';
import { Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import './Rfq.css';
import { useSelector } from "react-redux";


const RfqDyed = (props) => {
  const linkType=useSelector(state=>state.fabricLinkReducer.linkType)

  const submitHandlers = () => {

    props.fromAction(1);
    props.newForm(true)
    props.newOrder(true)
  }
  const submitHandlersLink = () => {
    props.fromAction(1);
    props.newOrder(false)
  }

  return (
    <>
      <h4 className="RFQ-h4">New {linkType=='fabric'?'Fabric':'Job Work'} RFQ</h4>
      <Row className="RFQ-Row">
        <div className="RFQ-Row">
          <Col sm="6" className="px-3">
            <Card body className="RFQ-Card">
              <p className="RFQ-Title">Create New Order</p>
              <div className="RFQButtons">
                <Button className="Rfq-button" onClick={submitHandlers}>Select</Button>
              </div>
            </Card>
          </Col>
          <Col sm="6" className="px-3">
            <Card body className="RFQ-Card">
              <p className="RFQ-Title">Link to Existing Order</p>
              <div className="RFQButtons">
                <Button className="Rfq-button" onClick={submitHandlersLink}>Select</Button>
              </div>
            </Card>
          </Col>
        </div>
      </Row>

    </>
  );
};

export default RfqDyed;