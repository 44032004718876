import React, { useState } from 'react';
import '../Active-Buyers/ActiveBuyers.css'
import { Button, Spinner, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BuyersApi } from '../../../../../services';
import { status } from '../../../../../models/enum';
import { roles } from '../../../../../models/enum'
import { useEffect } from 'react';


const DeleteBuyerTable = (props) => {

    const [pageNumber, setpageNumber] = useState(0);
    const [tdata, setTdata] = useState([]);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        BuyersApi(pageNumber, status.DELETE, roles.BUYER)
            .then((res) => {
                props.requestCount(res.data.total);
                setTdata(res.data.company);
            })
            .catch((err) => console.log(err));
    }
    return (
        <div className="admin-table">
            <InfiniteScroll
                dataLength={tdata.length}
                next={fetchData}
                hasMore={true}
            >
                <Table bordered className="admin-table">
                    <thead>
                        <tr className="heading-row">
                            <th>Sr.No</th>
                            <th>Account No</th>
                            <th>Account ID</th>
                            <th>Date of Joining</th>
                            <th>Fabsource Rating</th>
                            <th>RFQs Generated</th>
                            <th>Completed Orders</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tdata.length > 0 ? tdata.map((row, id) => (
                            <tr className="data-row">
                                <td>{id + 1}</td>
                                <td>{row.companyName}</td>
                                <td>{row.companyUid}</td>
                                <td>{row.dateOfJoining}</td>
                                <td>{row.fabTrackCompanyRating}</td>
                                <td>{row.rfqsGenerated}</td>
                                <td>{row.completedOrders}</td>
                            </tr>
                        )) : <> <Spinner color="primary" /> <p>No Delete Buyer requests </p></>}
                    </tbody>
                </Table>
            </InfiniteScroll>
        </div>
    );
}

export default DeleteBuyerTable;