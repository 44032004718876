export const roles = {
    FABTRACK_ADMIN: "FABTRACK_ADMIN",
    ADMIN_SELLER: "ADMIN_SELLER",
    ADMIN_BUYER: "ADMIN_BUYER",
    BUYER: "BUYER",
    SELLER: "SELLER",

}
export const secretKey = {
    secretKey: 'NEHA$$$'
}

export const fabricType = {
    KNIT: "Knit",
    WOVEN: "Woven"
}

export const status = {
    NEW: "NEW",
    REJECTED: "REJECTED",
    ACCEPTED: "ACCEPTED",
    DELETE: "DELETE",
    BLOCK: "BLOCK",
    UNBLOCK: "UNBLOCK"
}

export const stages = {
    RFQ_IN_PRG0: "RFQ_IN_PRG0",
    RFQ_IN_PRG1: "RFQ_IN_PRG1",
    RFQ_IN_PRG2: "RFQ_IN_PRG2",
    RFQ_IN_PRG3: "RFQ_IN_PRG3",
    RFQ_IN_PRG4: "RFQ_IN_PRG4",
    RFQ_IN_PRG5: "RFQ_IN_PRG5"

}
export const reqType = {
    NEW: "NEW",
    RESPONDED: "RESPONDED"
}