import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Modal.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Header2 } from '../SideBar/Header/Header-lgin';

const SignupModal = (props) => {
  const {
    buttonLabel,
    className
  } = props;


  const [modal, setModal] = useState(true);

  // const submitHandler = () => setModal(!modal);
  const history = useHistory();

  const clickHandler = () => {
    history.push(`/`);
  };

  return (
    <>
      <Header2 />

      <div className="SignupModalContent">
        <Modal isOpen={modal} fade={false} className="SignupModalContent">

          <div className="SignUp-Modal pb-4 congratulations">Congratulations !</div>
          <div className="ModalSignupBOdy">
            <ModalBody>
              Thank you signing up with Fabsource. Please wait for the
              verification process from our team.
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={clickHandler} className="Modal-back-button">Back to Home</Button>{' '}
            </ModalFooter>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default SignupModal;