import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';


export class MetaDataSide extends React.Component {
  state = {
    links: [
      {
        id: 1,
        name: "Users",
        pathname: '/companyprofile',
        className: "side_nav_item"
      },
      {
        id: 2,
        name: "Messages",
        to: "/companyprofile",
        className: "side_nav_item"
      },
      {
        id: 3,
        name: "Queries",
        href: "/companyprofile",
        className: "side_nav_item"
      },
      {
        id: 4,
        name: "Metadata Update",
        href: "/metadata",
        className: "side_nav_item"
      },
      {
        id: 5,
        name: "Third Party Integrations",
        href: "/metadata",
        className: "side_nav_item"
      },
      {
        id: 6,
        name: "Logistics",
        to: "/cms",
        className: "side_nav_item"
      },
      {
        id: 7,
        name: "Accounts",
        to: "/cms",
        className: "side_nav_item"
      }
    ],
    activeLink: null
  };


  handleClick = id => {
    this.setState({ activeLink: id });
  };

  render() {
    const { links, activeLink } = this.state;

    return (
      <div>
        {links.map(link => {
          return (
            <div key={link.id}>
              <ListGroup className="list-group-flush">
                <ListGroupItem><NavLink

                  onClick={() => this.handleClick(link.id)}
                  className={
                    link.className +
                    (link.id === activeLink ? " active_item" : "")
                  }
                >
                  {link.name} {link.id === activeLink && ""}
                </NavLink>
                </ListGroupItem>
              </ListGroup>
            </div>
          );
        })}
      </div>
    );
  }
}



export class CompanyProfileSide extends React.Component {
  state = {
    links: [
      {
        id: 1,
        name: "Respond to Quotation",
        to: "/cms",
        className: "side_nav_item"
      },
      {
        id: 2,
        name: "Order Management",
        to: "/cms",
        className: "side_nav_item"
      },
      // {
      //   id: 3,
      //   name: "Showrooms",
      //   to: "/cms",
      //   className: "side_nav_item"
      // },
      {
        id: 4,
        name: "Messages",
        to: "/metadata",
        className: "side_nav_item"
      },
      // {
      //   id: 5,
      //   name: "Reports",
      //   to: "/cms",
      //   className: "side_nav_item"
      // },
      {
        id: 6,
        name: "Admin Settings",
        href: "/companyprofile",
        className: "side_nav_item"
      }

    ],
    activeLink: null
  };


  handleClick = id => {
    this.setState({ activeLink: id });
  };
  render() {
    const { links, activeLink } = this.state;

    return (
      <div>
        {links.map(link => {
          return (
            <div key={link.id}>
              <ListGroup className="list-group-flush">
                <ListGroupItem><Link

                  onClick={() => this.handleClick(link.id)}
                  className={
                    link.className +
                    (link.id === activeLink ? " active_item" : "")
                  }
                >
                  {link.name} {link.id === activeLink && ""}
                </Link>
                </ListGroupItem>
              </ListGroup>
            </div>
          );
        })}
      </div>
    );
  }
}


