
import React, { useState } from 'react';
import { Button, Form, Col, Row, FormGroup, Label, Input, FormText } from 'reactstrap';
import './CompanyProfile.css'
// import plus from '../../../assets/plus.png';
import { FileUploadApi } from '../../../services';
import delicon from '../../../assets/delete2.png'
import { toast } from 'react-toastify';
import uuid from 'react-uuid'


export const AddLocation = (props) => {
  let fileImage;
  const [fields, setFields] = useState([]);
  const [showControls, setshowControls] = useState(false)
  //const [isShow, setIsShow] = useState(false)
  const [isRquired, setisRquired] = useState(false)

  const [controlsData, setcontrolsData] = useState({
    img: '',
    text: '',
    clientImageUrl: '',
    imageName: "",
  })
  // function handleAdd() {
  //   setcontrolsData({ img: '', text: '' })
  //   props.setIsShow(true)
  //   props.single(true);
  //   props.open(true)
  // }
  const submitHandler = () => {


    if ((controlsData.text == "" || controlsData.text.replace(/\s+/g, ' ') == " ") && controlsData.img == "") {
      if (controlsData.img != "") {
        setisRquired(false);


        props.setIsShow(false)
        setcontrolsData({ img: '', text: '' })
        if (controlsData.text && controlsData.img) {
          controlsData.text = '';
          setFields(pre => [...pre, controlsData])
          let payload = {
            id: "temp-" + uuid(),
            clientImageUrl: controlsData.img,
            clientImageText: ''
          }
          props.img(payload);
          props.value(payload)

        }
        else if (controlsData.text) {
          setFields(pre => [...pre, controlsData])
          let payload = {
            id: "temp-" + uuid(),
            clientImageText: controlsData.text,
            clientImageUrl: ''
          }
          props.img(payload);
          props.value(payload);


        } else if (controlsData.img) {
          setFields(pre => [...pre, controlsData])
          let payload = {
            id: "temp-" + uuid(),
            clientImageUrl: controlsData.img,
            clientImageText: ''
          }
          props.img(payload);
          props.value(payload)

        }

      } else {
        setisRquired(true);
      }

    }
    // else if (controlsData.3img != "" || controlsData.text != "") {
    //   setisRquired(false);

    // }

    else {
      setisRquired(false);
      props.open(false)

      props.setIsShow(false)
      setcontrolsData({ img: '', text: '' })
      if (controlsData.text && controlsData.img) {
        controlsData.text = '';
        setFields(pre => [...pre, controlsData])
        let payload = {
          id: "temp-" + uuid,
          clientImageUrl: controlsData.img,
          clientImageText: ''
        }
        props.img(payload);
        props.value(payload)

      }
      else if (controlsData.text) {
        setFields(pre => [...pre, controlsData])
        let payload = {
          id: "temp-" + uuid(),
          clientImageText: controlsData.text,
          clientImageUrl: ''
        }
        props.img(payload);
        props.value(payload);


      } else if (controlsData.img) {
        setFields(pre => [...pre, controlsData])
        let payload = {
          id: "temp-" + uuid(),
          clientImageUrl: controlsData.img,
          clientImageText: ''
        }
        props.img(payload);
        props.value(payload)

      }

    }
    props.single(false);
    // props.open(false)

  }
  const handleClose = (idx) => {
    setcontrolsData({ img: '', text: '' })
    props.setIsShow(false)
    setisRquired(false);
    props.single(false);
    props.open(false)

  }

  function handleChange(event) {
    const { name, value } = event.target
    setcontrolsData((pre) => ({
      ...pre,
      [name]: value
    }));
    let payload = {
      id: "temp-" + uuid(),
      clientImageText: event.target.value,
      clientImageUrl: ''
    }
    // props.logoText(payload)
    setisRquired(false);

  }


  const handlePicChange = async (e) => {
    const files = e.target.files;
    fileImage = e.target.files[0]
    if (files !== null && files.length) {
      let formData = new FormData();
      formData.append('file', fileImage)


      await FileUploadApi(formData).then((data) => {
        if (data.isSuccess) {
          //  
          let payload = {
            id: "temp-" + uuid(),
            clientImageText: '',
            clientImageUrl: data.data,

          }
          // props.logoText(payload);
          setisRquired(false);
          setcontrolsData({ ...controlsData, img: payload.clientImageUrl, imageName: fileImage.name });
        }

      })
    }
  }



  return (<>


    {props.isShow ?
      <div id="App" className="App" >
        <div className="add-client-div">
          <Form className="add-client-form" >

            <FormGroup>
              <div className="Input-Upload client-elem" style={{marginBottom: "-0.4rem"}}>
                <div style={{ flexBasis: '100%' }}>
                  <Input
                    type="text"
                    onInput={handleChange}
                    id="exampleBusinessphoto"
                    className="input"
                    value={controlsData.text}
                    minLength={1}
                    maxLength={40}
                    name="text"
                  />
                </div>
                <div className="Upload-Photo" style={{ flexBasis: '100%' }}>
                  {!fileImage ?
                    <>
                      <label htmlFor={controlsData?.img == '' ? 'editBusinessProfile' : 'notcontrol'} className="client-image-upload">Upload {props.certificate?'Certificate': ' Company Logo'}</label>
                      <input
                        type="file"
                        onChange={(e) => handlePicChange(e)}
                        accept="image/x-png,image/jpeg,image/svg+xml"
                        id={controlsData?.img == '' ? 'editBusinessProfile' : 'notcontrol'}
                        name="photo"
                        style={{ display: 'none' }}
                      />
                      </>
                    : null}
                </div>
                <div>
                  <Button disabled={!controlsData.img && !controlsData.text} color="primary" onClick={() => submitHandler()} className="Add-button">
                    Add
                  </Button>
                </div>
                <p className="close-x" onClick={() => handleClose()}>x</p>
                
                </div>
              <div className="row w-100">
                <div className="col-md-6">
                  {/* {
                    controlsData?.img ?
                      <>
                        <img src={controlsData?.img} alt="" className="gallery-section" />
                      </>
                      : null
                  } */}
                </div>
                <div className="col-md-6">


                </div>
              </div>


            </FormGroup>
            { controlsData.imageName ? <div style={{fontSize: "10px", marginLeft: "59%", color: "#2f80ed"}}>
              {controlsData.imageName.length > 20 ? controlsData.imageName.substr(0, 20) + '...' : controlsData.imageName}</div> : null} 
          </Form>
          {
            isRquired ?
              <small className="text-danger" >Required</small>
              : null

          }


        </div>


      </div>
      : null}
  </>
  );
}



export const AddButton = (props) => {
  let fileImage;
  const [fields, setFields] = useState('');
  // const values = [...fields];
  //const [isShow, setIsShow] = useState(false)
  const [isRequired, setRequired] = useState(false)
  const [isRquired, setisRquired] = useState(false)
  const [controlsData, setcontrolsData] = useState({
    img: '',
    text: '',
    clientImageUrl: '',
    imageName: "",
  })

  function handleChange(event) {

    const { name, value } = event.target;
    setFields((prev) => ({
      ...prev,
      [name]: value
    }));
    // props.value(event.target.value);
    setRequired(false)
  }
  const handlePicChange = async (e) => {
    const files = e.target.files;
    fileImage = e.target.files[0]
    if (files !== null && files.length) {

      let formData = new FormData();
      formData.append('file', fileImage)


      await FileUploadApi(formData).then((data) => {
        if (data.isSuccess) {
          //  
          let payload = {
            id: "temp-" + uuid(),
            clientImageText: '',
            clientImageUrl: data.data,

          }
          // props.logoText(payload);
          setisRquired(false);

          setcontrolsData({ ...controlsData, img: payload.clientImageUrl, imageName: fileImage.name });


        }

      })
    }
  }
  const submitHandler = () => {

    if (fields.fields && fields.fields.replace(/\s+/g, ' ') !== " ") {
      setRequired(false)

      props.multipleCertificate(fields.fields)

      props.setIsShow(false)
      setFields('');
      props.isSingle(false);
      props.open(false)

    } else {
      setRequired(true)
    }
  }
  const handleClose = (idx) => {
    setFields('')
    setRequired(false);
    props.setIsShow(false);
    props.isSingle(false);
    props.open(false)

    // setIsShow(false)
  }


  return (
    <>

      <div id="App" className="App" >

        {props.isShow ?

          <>
            <div className="certificate-div">
              <Form className="certi-delete-icon" >
                <FormGroup className="certificate-form">
                  <Input
                    type="text"
                    onChange={e => handleChange(e)}
                    value={fields.fields}
                    minLength={1}
                    maxLength={50}
                    id="examplephoto"
                    name="fields"
                    className="input"
                  />
                  <div className="Upload-Photo" style={{ flexBasis: '100%' }}>
                  {!fileImage ?
                    <>
                      <label htmlFor={controlsData?.img == '' ? 'editBusinessProfile' : 'notcontrol'} className="client-image-upload">Upload Certificate</label>
                      <input
                        type="file"
                        onChange={(e) => handlePicChange(e)}
                        accept="image/x-png,image/jpeg,image/svg+xml"
                        id={controlsData?.img == '' ? 'editBusinessProfile' : 'notcontrol'}
                        name="photo"
                        style={{ display: 'none' }}
                      />
                      </>
                    : null}
                </div>
                  <Button disabled={!fields || !fields.fields} color="primary" onClick={() => submitHandler()} className="Add-button">
                    Add
                  </Button>
                  <p className="close-x" onClick={() => handleClose()}>x</p>
                </FormGroup>
              </Form>


            </div>
            {isRequired ?
              <small className="text-danger">Required</small> : null
            }
          </>

          : null}



        {/* {
          fields.length ?

            fields.map((field, idx) => {

              return (
                <div key={`${field}-${idx}`} className="Add-field-button">

                  <Row form>
                    <Col lg={12}>
                      <FormGroup>
                        <Label for="exampleEmail"></Label>
                        <div className="certi-delete-icon">

                          <Input
                            type="text"
                            onChange={e => handleChange(idx, e)}
                            value={field.value}

                            id="examplephoto"
                            name="value"
                            className="input"
                          />
                          <img src={delicon} className="certificate-delete-icon" onClick={() => handleRemove(field, idx)} />
                        </div>
                      </FormGroup>

                    </Col>
                  </Row>

                </div>
              );
            })
            : null} */}
      </div>
    </>
  );
}



