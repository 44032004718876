import React, { useEffect } from 'react';
import './SignupDashboard.css';
import { Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Header2 } from '../SideBar/Header/Header-lgin';
import { roles } from '../../models/enum';
import { encrypt } from '../../services/crypto.service';

const SignUpDashboard = (props) => {

  const history = useHistory();

  const clickHandler = (roles) => {

    history.push(`/signup?roles=${roles}`);

  };


  return (
    <>
      <Header2 />
      <Row>
        <Col sm='12'>
          <h5 className='heading'>Sign Up</h5>
        </Col>
      </Row>
      {/* <Row>
        <Col sm='12'>
         
        </Col>
      </Row> */}
      <Row className="Main1 mt-4 mb-4">

        <Col sm="3">
          <h5 className='sub-heading'>Tell us who you are</h5>
          <Card body>
            <CardTitle className="SignDastitle" tag="h5">Fabric Producer/ Trader: </CardTitle>
            <CardText className="SignDasText">Someone who is engaged in the manufacturing or trading
              of greige or finished fabrics. This includes processing houses, Greige fabric
              manufacturers, fabric mills and fabric traders.</CardText>
            <div className="button-right">
              <Button onClick={() => clickHandler(roles.SELLER)} className="Card-Button">Select</Button></div>
          </Card>
        </Col>
        <Col sm="3">
          <h5 className='sub-heading opactiy-0'>d</h5>
          <Card body>
            <CardTitle className="SignDastitle" tag="h5">Apparel manufacturer/<br />
              <span>Fabric Buyer:</span></CardTitle>
            <CardText className="SignDasText">Someone who purchases greige and finished fabrics as well
              as textile accessories (thread, labels, buttons, packaging, laces/ropes,
              sequences/embellishments & zippers). This includes apparel exporters, domestic apparel manufacturers, designer boutiques/retail brands</CardText>
            <div className="button-right">
              <Button onClick={() => clickHandler(roles.BUYER)} className="Card-Button">Select</Button></div>
          </Card>
        </Col>
        <Col sm="3">
          <h5 className='sub-heading opactiy-0'>d</h5>
          <Card body>
            <CardTitle className="SignDastitle" tag="h5">Textile accessory manufacturers:</CardTitle>
            <CardText className="SignDasText">Textile accessory manufacturers: manufacturers of the following products- thread,
              labels, buttons, packaging, laces/ropes, sequences/embellishments & zippers</CardText>
            <div className="button-right">
              <Button onClick={() => clickHandler(roles.MANUFACTURER)} className="Card-Button">Select</Button></div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SignUpDashboard;