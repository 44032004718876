import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Card, ListGroup, ListGroupItem, Link } from "react-bootstrap";
import ProfilePic from "../../../assets/profile.png";
import edit from "../../../assets/edit.png";
import { useSelector, useDispatch } from "react-redux";
import { FileUploadApi, updateUserInfo, UpdatePwApi } from "../../../services";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import { getUserInfoAction } from "../../../actions";
import {
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Button,
  Row,
  Col,
} from "reactstrap";
import validate from "validate.js";
import "./Profilescreen.css";
import getStoredStateMigrateV4 from "redux-persist/lib/integration/getStoredStateMigrateV4";

const ProfileScreen = (props) => {
  const user = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const [imageData, setImageData] = useState({
    file: "",
    url: user?.data?.profileImage ? user.data.profileImage : "",
  });
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  useEffect(() => {
    dispatch(getUserInfoAction(localStorage.getItem("id")));
  }, [imageData]);
  const handleLocalImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setImageData({
        file: file,
        url: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    if (imageData.file) {
      const isTypeCorrect =
        imageData.file.type === "image/png" ||
        imageData.file.type === "image/jpeg" ||
        imageData.file.type === "image/jpg";
      const isSizeCorrect = imageData.file.size < 10485760;
      if (isTypeCorrect && isSizeCorrect) {
        let formData = new FormData();
        formData.append("file", imageData.file);
        const uploadImageResponse = await FileUploadApi(formData);
        if (uploadImageResponse.isSuccess) {
          const saveImageResponse = await updateUserInfo({
            profileImage: uploadImageResponse.data,
          });
          if (saveImageResponse.isSuccess) {
            setImageData({
              file: imageData.file,
              url: uploadImageResponse.data,
            });
            props.handleImageUrl(uploadImageResponse.data);
            NotificationManager.success(saveImageResponse.message, "", 1000);
          } else {
            NotificationManager.error(saveImageResponse.message, "", 1000);
          }
        } else {
          NotificationManager.error(uploadImageResponse.message, "", 1000);
        }
      } else if (!isTypeCorrect) {
        NotificationManager.error(
          "Image Type is not correct, only jpg, jpeg and png format allowed",
          "",
          1000
        );
      } else if (!isSizeCorrect) {
        NotificationManager.error(
          "Image Size must be less than 10Mb.",
          "",
          1000
        );
      }
    } else {
      NotificationManager.info("Please select image to upload", "", 1000);
    }
  };

  const constraints = {
    oldpassword: {
      presence: { allowEmpty: false },
      length: {
        minimum: 8,
        maximum: 30,
      },
    },
    password: {
      format: {
        // pattern: /^(?=.*[A-Z])(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[^_`{|}~]{8,}$/,
        pattern:
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        flags: "i",
        message:
          "should contain at least 1 lowercase, 1 uppercase, 1 number, and 1 special character",
      },
      length: {
        minimum: 8,
        maximum: 30,
      },
    },
    confirmpassword: {
      // presence: { allowEmpty: false },
      // length: {
      //   minimum: 8,
      //   maximum: 30,
      // },
      equality: "password",
    },
  };
  const ChangePasswordModal = () => {
    const [userPw, setUserPw] = useState({
      oldpassword: "",
      password: "",
      confirmpassword: "",
    });
    const [isLoading, setisLoading] = useState(false);
    const [errormsg, setErrormsg] = useState("");
    const [formDetails, setFormDetails] = useState({
      submitted: false,
      errors: null,
    });

    useEffect(() => {
      const errors = validate(userPw, constraints);
      setFormDetails((prev) => ({
        ...prev,
        errors,
      }));
    }, [userPw]);

    const handleChange = (event) => {
      const { name, value } = event.target;
      setUserPw((prev) => ({
        ...prev,
        [name]: value,
      }));
    };

    const submitHandler = (e) => {
      e.preventDefault();
      setFormDetails((prev) => ({
        ...prev,
        submitted: true,
      }));
      const body = {
        oldPassword: userPw.oldpassword,
        newPassword: userPw.password,
      };
      if (!formDetails.errors) {
        setisLoading(true);
        UpdatePwApi(body)
          .then((res) => {
            if (res.isSuccess) {
              setisLoading(false);
              toggle();
              NotificationManager.success("successss", "", 1000);
            }
          })
          .catch((res) => {
            setisLoading(false);
          });
      }
    };

    return (
      <>
        <Modal
          isOpen={modal}
          size="md"
          zIndex="1"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75%', width: '40vh' }}
          toggle={toggle}
        >
          <ModalHeader className="change-password-header">
            Change Password
          </ModalHeader>
          <ModalBody className="change-password-form">
            {/* <div> */}
            <Form>
              <FormGroup className="d-flex">
                <Label  size="sm" for="Password" className="p-0 labelpass">
                  Old Password:
                </Label>
                <div  className="p-0 psw-input">
                  <Input
                    bsSize="sm"
                    type="password"
                    name="oldpassword"
                    id="password"
                    onChange={handleChange}
                    minLength="8"
                    maxLength="30"
                    value={userPw.oldpassword}
                  />
                </div>
              </FormGroup>

              {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.oldpassword && (
                    <small className="form-text">
                      {formDetails.errors.oldpassword[0]}
                    </small>
                  )}
              <FormGroup className="d-flex">
                <Label sm={5} size="sm" for="Password" className="p-0 labelpass">
                  New Password:
                </Label>
                <div sm={7} className="p-0 psw-input">
                  <Input
                    bsSize="sm"
                    type="password"
                    name="password"
                    id="password"
                    onChange={handleChange}
                    minLength="8"
                    maxLength="30"
                    value={userPw.password}
                  />
                </div>
              </FormGroup>

              {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.password && (
                    <small className="form-text" >
                      {formDetails.errors.password[0]}
                    </small>
                  )}
              <FormGroup className="d-flex">
                <Label sm={5} size="sm" for="Password" className="p-0 labelpass">
                  Re-enter Password:
                </Label>
                <div sm={7} className="p-0 psw-input">
                  <Input
                    bsSize="sm"
                    type="password"
                    name="confirmpassword"
                    id="newPassword"
                    minLength="8"
                    maxLength="30"
                    onChange={handleChange}
                    value={userPw.confirmpassword}
                  />
                </div>
              </FormGroup>

              {formDetails.submitted &&
                  formDetails.errors &&
                  formDetails.errors.confirmpassword && (
                    <small className="form-text">
                      Password and confirm password does not match
                    </small>
                  )}
            </Form>

            {/* </div> */}
          </ModalBody>
          <span
            style={{
              fontSize: "1.4vh",
              lineHeight: '1.7vh',
              letterSpacing: '0.01em',
              color: '#828282',
              margin: '0.8vh 1.12vw 0'
            }}
          >
            Minimum 8 characters
          </span>
          <ModalFooter
            className="change-password-footer"
          >
            <div className="btnwrapper">
            <Button
              size="sm"
              outline
              color="primary"
              onClick={toggle}
              style={{ width: '15vh' }}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              color="primary"
              onClick={submitHandler}
              style={{ width: '15vh' }}
            >
              {isLoading ? <Spinner /> : "Save"}
            </Button>
            </div>
          </ModalFooter>
          
        </Modal>
      </>
    );
  };
  return (
    <div className="Profile-Admin">
      <Card className="ProfileScreen-Card">
        <div className="top-header-profile">Profile</div>
        <div className="ProfilePic-profile">
          
            <img
              for="photo-upload"
              src={imageData.url || ProfilePic}
            />
            <a style={{position: 'absolute',right: '7.5vw'}}>
              <svg  width="2vh" height="1.8vh" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-asf-c200="" fill-rule="evenodd" clip-rule="evenodd" d="M7.08317 4.41667H4.58317V6.91667H2.9165V4.41667H0.416504V2.75H2.9165V0.25H4.58317V2.75H7.08317V4.41667ZM15.2748 4.41667H17.9165C18.8332 4.41667 19.5832 5.16667 19.5832 6.08333V16.0833C19.5832 17 18.8332 17.75 17.9165 17.75H4.58317C3.6665 17.75 2.9165 17 2.9165 16.0833V8.58333H4.58317V16.0833H17.9165V6.08333H14.5415L13.0165 4.41667H8.74984V2.75H13.7498L15.2748 4.41667ZM11.2498 15.25C8.94984 15.25 7.08317 13.3833 7.08317 11.0833C7.08317 8.78333 8.94984 6.91667 11.2498 6.91667C13.5498 6.91667 15.4165 8.78333 15.4165 11.0833C15.4165 13.3833 13.5498 15.25 11.2498 15.25ZM11.2498 8.58333C12.6248 8.58333 13.7498 9.70833 13.7498 11.0833C13.7498 12.4583 12.6248 13.5833 11.2498 13.5833C9.87484 13.5833 8.74984 12.4583 8.74984 11.0833C8.74984 9.70833 9.87484 8.58333 11.2498 8.58333Z" fill="#BDBDBD"></path></svg></a>
            <input
              id="photo-upload"
              class="upload-profile-image"
              type="file"
              onChange={handleLocalImage}
            />
            
          
        </div>

        <Card.Body className="Card-ProfileScreen">
          <p className="PersonalinfoHeading">Personal Information</p>
          <Card.Text className="Personal-Info-card">
            <ListGroup>
              <ListGroupItem
                className="persoanlinfo-li mb-10"
              >
                Name:{" "}
                <span>{user.data.fullName}</span>
              </ListGroupItem>
              <ListGroupItem className="persoanlinfo-li mb-10">
                Designation:{" "}
                <span>
                  { 
                    getdesig(user.data.accountType)
                  }
                </span>
              </ListGroupItem>
              <ListGroupItem className="persoanlinfo-li">
                Company:{" "}
                <span>
                  {user.data.companyName}
                </span>
              </ListGroupItem>
            </ListGroup>
          </Card.Text>
        </Card.Body>
        <Card.Body
          className="Card-ProfileScreen"
        >
          <p className="PersonalinfoHeading">Contact Information</p>
          <Card.Text className="Contact-Info-card">
            <ListGroup>
              <ListGroupItem className="persoanlinfo-li mb-10">
                Phone:<span>{user.data.phoneNumber}</span>
              </ListGroupItem>
              {/* <ListGroupItem className="persoanlinfo-li">Address: {user.data.designation}</ListGroupItem> */}
              <ListGroupItem className="persoanlinfo-li">
                Email:<span>{user.data.email}</span>
              </ListGroupItem>
              {/* <ListGroupItem className="persoanlinfo-li">Website: {user.data.designation}</ListGroupItem> */}
            </ListGroup>
          </Card.Text>
        </Card.Body>
        {/* <div className="Changepassp"> <Link className="Changepas">Change Password


        </Link></div> */}
        <div onClick={toggle} className="change-password-text">
          Change Password
        </div>
        <div className="Buis-button">
        <Button
            className="BusinessBack-btn mt-0"
            color="primary"
            size="sm"
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            className="BusinessBack-btn m-0"
            outline
            size="sm"
            color="primary"
            type="submit"
            onClick={props.handleClick}
            style={{ width: '15vh' }}
          >
            Discard
          </Button>
          
        </div>
      </Card>
      <NotificationContainer />
      <ChangePasswordModal />
    </div>
  );
};
 
const getdesig=  (name)=>{
  let userType = window.localStorage.getItem('usertype');
  if (userType === 'BUYER') {

    if (name === 'MANAGER') {
      return 'Purchase Manager'
    } else if (name === 'JUNIOR') {
      return 'Jr. Purchase Manager'

    } else if (name === 'MERCHANDISER') {
      return 'Merchandiser'

    } else if (name === 'OWNER') {
      return 'Owner'

    } else if (name === 'ACCOUNTS') {
      return 'Accountant'

    } else if (name === 'ADMIN_BUYER') {
      return 'Administrator'

    }


  } else if (userType === 'SELLER') {

    if (name === 'MANAGER') {
      return 'Sales Manager'
    } else if (name === 'JUNIOR') {
      return 'Jr. Sales Manager'

    } else if (name === 'MERCHANDISER') {
      return 'Merchandiser'

    } else if (name === 'OWNER') {
      return 'Owner'

    } else if (name === 'ACCOUNTS') {
      return 'Accountant'

    } else if (name === 'ADMIN_SELLER') {
      return 'Administrator'

    }




  }
  else if (userType === 'INTERNAL') {
    return 'Fabtrack Admin'
  }

};
export default ProfileScreen;
