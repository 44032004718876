import React, { useState } from 'react';
import './ActiveBuyers.css'
import { Button, FormGroup, Input, Spinner, Table, UncontrolledCollapse } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BuyersSellersActionApi, BuyersApi, changeCommissionApi, changePaymentApi } from '../../../../../services';
import { useEffect } from 'react';
import { status } from '../../../../../models/enum'
import { roles } from '../../../../../models/enum'
import { toast } from 'react-toastify';
import DeletePopUp from '../../Seller/DeletePopUp';
import { Form } from 'react-bootstrap';
import edit2 from '../../../../../assets/edit2.png'
import Cookies from 'js-cookie'


const ActiveBuyersTable = (props) => {
    let token = localStorage.getItem("token")
    const history = useHistory();
    const [pageNumber, setpageNumber] = useState(0);
    const [tdata, setTdata] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [dataUpdate, setDataUpdate] = useState({
        commissionRate: '',
        paymentCycle: ''
    })

    useEffect(() => {
        if (token && Cookies.get('token')) {

            fetchData();

        } else {
            history.push(`/`)
        }
    }, [])


    const fetchData = () => {
        setIsLoading(true)

        BuyersApi(pageNumber, status.ACCEPTED, roles.BUYER)
            .then((res) => {
                setIsLoading(false)
                props.requestCount(res.data.total);
                let newData = res.data.company?.map((item) => {
                    item.isShow = false
                    item.isShow1 = false
                    return item
                })
                setTdata(newData);


            })
            .catch((err) => console.log(err));
    }

    const handleBlock = (id) => {
        let blockBody = {
            action: status.BLOCK,
            id: id
        }

        BuyersSellersActionApi(blockBody).then((data) => {
            if (data.isSuccess) {
                // toast.success(data.message);

                fetchData();
            }
        })
    };

    const handleUnblock = (id) => {
        let unblockBody = {
            action: status.UNBLOCK,
            id: id
        }
        BuyersSellersActionApi(unblockBody).then((data) => {
            if (data.isSuccess) {
                toast.success(data.message)
                fetchData();
            }
        })
    };

    const handleChange = (event) => {
        //  
        const { name, value } = event.target;

        setDataUpdate((prev) => ({
            ...prev,
            [name]: value
        }));

    };
    const showClicked = (id, type) => {

        if (type == 'commission') {
            let newArr = tdata.map((item, i) => {

                if (i == id) {
                    item.isShow = true;
                    dataUpdate.commissionRate = item.commissionRate

                } else {
                    item.isShow = false;
                }
                return item
            })
            setTdata([...newArr])
        }
        if (type == 'payment') {
            let newArr = tdata.map((item, i) => {

                if (i == id) {
                    item.isShow1 = true
                    dataUpdate.paymentCycle = item.paymentCycle

                } else {
                    item.isShow1 = false;
                }
                return item
            })
            setTdata([...newArr])
        }


    }
    const submitHandler1 = (id, index) => {

        let body = {
            id: id,
            commission: Number(dataUpdate.commissionRate)
        }

        changeCommissionApi(body).then((res) => {
            if (res.isSuccess) {
                let newArr = tdata.map((item, i) => {
                    if (i == index) {
                        item.isShow = false

                    }
                    return item
                })
                fetchData();

                setDataUpdate({ commissionRate: '' })
            }
        })
    }
    const submitHandler2 = (id, index) => {

        let body = {
            id: id,
            paymentCycle: Number(dataUpdate.paymentCycle)
        }

        changePaymentApi(body).then((res) => {
            if (res.isSuccess) {
                let newArr = tdata.map((item, i) => {
                    if (i == index) {
                        item.isShow1 = false

                    }
                    return item
                })
                fetchData();

                setDataUpdate({ paymentCycle: '' })
            }
        })
    }

    const handleDelete = (id, close) => {
        //  
        let deleteBody = {
            action: status.DELETE,
            id: id
        }
        BuyersSellersActionApi(deleteBody).then((data) => {
            if (data.isSuccess) {
                toast.warn(data.message)
                // this.props.handleClickDelete();
                fetchData();
                close();
            }
        })
    };
    return (
        <div className="admin-table">
            <InfiniteScroll
                dataLength={tdata.length}
                next={fetchData}
                hasMore={true}
            >
                <Table bordered className="admin-table buyer-table">
                    <thead>
                        <tr className="heading-row">
                            <th>Sr.No.</th>
                            <th>Account Name</th>
                            <th>Account ID</th>
                            <th>Date of Joining</th>
                            <th>Fabsource Rating</th>
                            <th>RFQs Generated</th>
                            <th>Completed Orders</th>
                            <th>Comission</th>
                            <th>Payment Cycle</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tdata.length > 0 ? tdata.map((row, id) => (
                            <tr className={(row.blocked ? 'gray-row' : 'data-row')}>
                                <td>{id + 1}</td>
                                <td>{row.companyName}</td>
                                <td>{row.companyUid}</td>
                                <td>{row.dateOfJoining}</td>
                                <td>{row.fabTrackCompanyRating}</td>
                                <td>{row.rfqsGenerated}</td>
                                <td>{row.completedOrders}</td>
                                <td ><div className="">
                                    {row.isShow == false ? (<><span>{row.commissionRate}</span> <span className="ml-2">%</span>
                                    <img className="fix-width" color="primary" src={ edit2} onClick={() => showClicked(id, 'commission')} />
                                    </>) : null}
                                    </div>
                                    {row.isShow ?
                                        <div className="setCommission" >
                                            <div className="div-comm">  <span className="newEntry">New Entry</span></div>
                                            <div className="flex-form">
                                                <Form className="commissionForm" >
                                                    <FormGroup >
                                                        <Input
                                                            type="number"
                                                            name="commissionRate"
                                                            className="commissionRate"
                                                            id="weaveType*"
                                                            onInput={(e) => e.target.value = e.target.value.slice(0, 4)}
                                                            min="0"
                                                            value={dataUpdate.commissionRate}
                                                            onChange={(e) => handleChange(e)}
                                                            required
                                                        />
                                                    </FormGroup>
                                                </Form>
                                                <span className="days"> % </span>
                                            </div>
                                            <Button color="primary" type="submit" onClick={() => submitHandler1(row.id, id)} className="button-commission">
                                                Confirm
                                            </Button>

                                        </div>
                                        : null}



                                </td>
                                <td><div className="d-flex">{row.isShow1 == false ? (<><span>{row.paymentCycle}</span><span className="ml-2">Days</span>
                                <img className="fix-width" color="primary" src={ edit2} id="toggler" onClick={() => showClicked(id, 'payment')} /></>) : null}
                                    </div>
                                    {row.isShow1 ?
                                        <div className="setCommission" >
                                            <div className="div-comm">  <span className="newEntry">New Entry</span></div>
                                            <div className="flex-form">
                                                <Form className="commissionForm" >
                                                    <FormGroup >
                                                        <Input
                                                            type="number"
                                                            name="paymentCycle"
                                                            className="commissionRate"
                                                            id="weaveType*"
                                                            onInput={(e) => e.target.value = e.target.value.slice(0, 4)}
                                                            min="0"
                                                            // max="9999"
                                                            value={dataUpdate.paymentCycle}
                                                            onChange={(e) => handleChange(e)}
                                                            required
                                                        />
                                                    </FormGroup>
                                                </Form>
                                                <span className="days"> Days </span>
                                            </div>
                                            <Button color="primary" type="submit" onClick={() => submitHandler2(row.id, id)} className="button-commission">
                                                Confirm
                                            </Button>

                                        </div>
                                        : null}

                                </td>
                                <td>
                                    <div className="d-flex">
                                        {/* <Button Accept color="danger" className="typereject-btn"
                                            onClick={() => handleDelete(row.id)}>Delete</Button> */}
                                        <DeletePopUp
                                            handleClickDelete={handleDelete} id={row.id}
                                        // handleClick={handleDelete}
                                        />
                                        {!row.blocked ? (<Button Accept color="warning" size="md" className="typeblock-btn" onClick={() => handleBlock(row.id)}>Block</Button>) : (<Button Accept color="success" size="md" className="typeUnblock-btn" onClick={() => handleUnblock(row.id)}>Unblock</Button>)}
                                    </div>
                                </td>
                            </tr>
                        )) : <> <Spinner color="primary" /> <p>No Active Buyers </p></>}
                    </tbody>
                </Table>
            </InfiniteScroll>
           
            {isLoading ? <><div className="cdf"> <div className='loader' /></div> </> : null}

        </div>
    );
}

export default ActiveBuyersTable;