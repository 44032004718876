import React, { useEffect, useState, useToggle } from "react";
import "./CompanyProfile.css";
import { Button, Spinner } from "reactstrap";
import {
  DeleteGalleryApi,
  FileUploadApi,
  GalleryApi,
  GetGalleryApi,
} from "../../../services";
import edit from "../../../assets/edit.png";
import plus from "../../../assets/plus.svg";
import delicon from "../../../assets/delete2.png";
import { toast } from "react-toastify";
import {toastOptions} from "../../../helpers/toast-options";
import uuid from "react-uuid";
import { ReactSortable } from "react-sortablejs";

const Gallery = (props) => {
  let tempArray = [];

  let fileImage = "";
  const handleBack = () => {
    props.fromBack(1);
  };
  let companyId = localStorage.getItem("companyId");

  const [galleries, setGalleries] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isValidImage, setIsvalidImage] = useState(false);
  const [delApiCalled, setdeleteAPIcalled] = useState(false);
  const [toastId, setToastId] = useState();

  const submitHandler = async () => {
    if (galleries.length != 0) {
      setisLoading(true);
      let idRemoveGallay = galleries.filter((img) => {
        if (img.id.toString().search("temp-") !== -1) {
          img.id = 0;
        }
        return img;
      });
      let pos = 0;
      idRemoveGallay = idRemoveGallay.map((img) => ({ ...img, pos: pos++ }));
      const body = {
        id: Number(companyId),
        galleries: idRemoveGallay,
      };

      GalleryApi(body)
        .then((data) => {
          if (data.isSuccess) {
            props.fromAction(1);
            // Success(data.message)
            // setTimeout(() => {

            // }, 3000);
          }
        })
        .catch((err) => {
          setisLoading(false);
        });

      // props.fromAction(1)
    } else {
      props.fromAction(1);
    }
  };

  let payload;

  const handlePicChange = async (e) => {
    if (galleries.length > 19) {
      if(!toastId){
        const id = toast.error("You have exceeded the maximum number of uploads", {...toastOptions, onClose: ()=> setToastId(null)});
        setToastId(id);
      }
    } else {
      const files = e.target.files;
      fileImage = e.target.files[0];
      const exts = ["png", "jpg", "jpeg"];
      const breckedName = files[0]?.name?.split(".");

      if (
        breckedName &&
        exts.indexOf(breckedName[breckedName.length - 1].trim().toLowerCase()) === -1
      ) {
        setIsvalidImage(true);
      } else {
        setIsvalidImage(false);
        if (fileImage) {
          let formData = new FormData();
          formData.append("file", fileImage);

          await FileUploadApi(formData).then((data) => {
            if (data.isSuccess) {
              //
              payload = {
                id: "temp-" + uuid(),
                galleryImageUrl: data.data,
              };

              galleries.push(payload);
              // console.log(galleries);

              setGalleries([...galleries]);
            }
          });
        }
      }
    }
  };

  const getGalleryDetails = () => {
    // console.log(companyId,'/////////////////')
    GetGalleryApi().then((res) => {
      if (res.isSuccess) {
        setGalleries(res.data);
      }
    });
  };
  useEffect(() => {
    getGalleryDetails();
  }, []);

  const deleteGallery = (id, url) => {
    
  if(!delApiCalled){
    setdeleteAPIcalled(true);
    let tempId = id.toString().search("temp-");
    if (tempId !== -1) {
      let updatedGallary = galleries.filter((img) => img.id !== id);
      setGalleries(updatedGallary);
    } else {
      let modifiedData = {
        id: Number(companyId),
        fileName: url,
      };

      DeleteGalleryApi(modifiedData).then((res) => {
        setdeleteAPIcalled(false);
        if (res.isSuccess) {
          getGalleryDetails();
          
        }
      
      });
    }
  
  }};
  return (
    <>
      <div className="Cards">
        <span className="Btn-add-photos">Add Photos</span>
        <div className="Gallery">
          <div>
            <div className="gallerybox">
              <ReactSortable
                list={galleries}
                setList={setGalleries}
                className="gallery-img"
              >
                {galleries.map((item) => (
                  <div className="imageboxx">
                    {" "}
                    <img
                      src={
                        galleries.length > 0 && item.galleryImageUrl
                          ? item.galleryImageUrl
                          : edit
                      }
                      className="gallery-section"
                    />
                    <img
                      src={delicon}
                      className="gallery-delete-icon"
                      onClick={() =>
                        deleteGallery(item.id, item.galleryImageUrl)
                      }
                    />
                  </div>
                ))}
              </ReactSortable>
            </div>

            <input
              type="file"
              onChange={(e) => handlePicChange(e)}
              accept="image/x-png,image/jpeg,image/svg+xml"
              // className="sr sr-only"
              id="for-gallay"
              name="photo"
              style={{display: "none"}}
            />
            {isValidImage ? (
              <p className="text-danger"> Invalid format</p>
            ) : null}
            {galleries.length > 0 ? (
              <p className="drag mb-16 mt-8">Click and drag to arrange images</p>
            ) : null}
            <div className="d-flex align-items-center">
            <img src={plus} className="add-gallery-photo " />
            <label for="for-gallay" className="gallary-upload-label mb-0 ml-8">
              Upload Images
            </label>
            </div>
          </div>

          <div className="Buis-Button mt-18 p-0">
            <Button
              className="BusinessNext-btn"
              disabled={isLoading}
              color="primary"
              type="submit"
              onClick={submitHandler}
            >
              {" "}
              {isLoading ? <Spinner /> : "Next"}
            </Button>
            <Button
              className="BusinessBack-btn"
              outline
              color="primary"
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
          {/* <NotificationContainer /> */}
        </div>
      </div>
    </>
  );
};
export default Gallery;
